import React from 'react'
import {SenderIdProvider} from '../context/SenderIdProvider'
import SenderId from './SenderId'

const SenderIdWrapper = () => {
  return (
    <SenderIdProvider>
      <SenderId />
    </SenderIdProvider>
  )
}

export default SenderIdWrapper
