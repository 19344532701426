import {useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {useAuth} from '../../auth'
import toast from 'react-hot-toast'
import {SendSpamFilter} from '../core/_model'
import {useSpamFilterProvider} from '../SpamFIlterProvider'

const AddNewFilterModal = () => {
  const {currentUser} = useAuth()
  const {
    createSpamFilter,
    updatedSpamFilterTextApi,
    updateSpamText,
    showModal: show,
    hide,
  } = useSpamFilterProvider()
  const [filterText, setFilterText] = useState('')

  const handleCreate = async () => {
    if (!filterText) {
      toast.error(`Error: Filter text can't be empty`)
      return
    }

    if (updateSpamText) {
      const spamFilter: SendSpamFilter = {
        id: updateSpamText.id,
        userId: currentUser?.user_profile,
        filterText,
      }

      await updatedSpamFilterTextApi(spamFilter)
      setFilterText('')
      hide()
    } else {
      const spamFilter: SendSpamFilter = {
        userId: currentUser?.user_profile,
        filterText,
      }

      await createSpamFilter(spamFilter)
      setFilterText('')
      hide()
    }
  }

  useEffect(() => {
    if (show && updateSpamText) {
      console.log(updateSpamText)
      setFilterText(updateSpamText.filterText)
    } else {
      console.log(updateSpamText)
      setFilterText('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return (
    <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'> Create New Spam Filter</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={hide}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <div className='mb-3'>
              <label htmlFor='exampleFormControlInput1' className='form-label'>
                Filter Name:
              </label>
              <input
                type='email'
                className='form-control'
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                placeholder='Enter Filter Name'
              />
            </div>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-light'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={hide}
            >
              Close
            </button>
            <button
              type='button'
              className='btn btn-primary'
              onClick={handleCreate}
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              {updateSpamText ? 'Update' : 'Save'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddNewFilterModal
