import {Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import UpdatePass from './Modal/UpdatePass'
import UpdateBalanceModal from './Modal/UpdateBalanceModal'
import {useUserListProvider} from './UserListProvider'
import TableContent from '../../utils/components/Table/TableContent'
import TablePagination from '../../utils/components/Table/TablePagination'
import {UserListColumn} from './UserColumn'
import {KTIcon} from '../../../_metronic/helpers'
import UserFilter from './UserFilter'
import {UserListSelectContext, useUserListSelectProvider} from './UserListSelectProvider'
import SelectList from '../PaymentRequest/SelectList'

const UserList = () => {
  const {
    isLoading,
    filteredUser,
    totalElement,
    itemsPerPage,
    currentPage,
    handlePageChange,
    downloadFile,
    searchTerm,
    setSearchTerm,
  } = useUserListProvider()

  const {selected} = useUserListSelectProvider()

  return (
    <div>
      <div className='rounded card'>
        <div className='d-flex justify-content-between mb-2 align-items-center card-header border-0'>
          <h3 className='mb-0'>User List</h3>

          <div className='d-flex gap-2'>
            <Link to='/dashboard'>
              <Button variant='primary' size='sm'>
                Dashboard
              </Button>
            </Link>
          </div>
        </div>

        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='Search Group'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search user'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <div className='card-title m-0'>
            {selected.length > 0 ? (
              <SelectList context={UserListSelectContext} />
            ) : (
              <>
                <UserFilter />

                <button type='button' className='btn btn-light-primary' onClick={downloadFile}>
                  <KTIcon iconName='exit-up' className='fs-2' />
                  Export
                </button>
              </>
            )}
          </div>
        </div>
        {/* <Row className='mt-8'>         
          <Col className='d-flex justify-content-end align-items-end'>
            <div className='fs-6 bg-secondary rounded px-6 py-3'>
              <p className='d-inline'>
                Client:
                <span className='fw-bold'> {counts.client}</span>
              </p>
              <p className='d-inline'>
                {' '}
                Reseller:
                <span className='fw-bold'> {counts.reseller}</span>
              </p>
              <p className='d-inline'>
                {' '}
                Unknown:
                <span className='fw-bold'> {counts.unknown}</span>
              </p>
            </div>
          </Col>
        </Row> */}

        <TableContent isLoading={isLoading} tableData={filteredUser} tableColumn={UserListColumn}>
          <TablePagination
            totalElement={totalElement}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            isLoading={isLoading}
          />
        </TableContent>
      </div>

      <UpdatePass />
      <UpdateBalanceModal />
    </div>
  )
}

export default UserList
