import React from 'react'
import {useAuth} from '../../auth'
import {userType} from '../../../utils/helperFunction/_helper'

const UserDefine = () => {
  const {currentUser} = useAuth()
  return <div>{userType(currentUser?.user_profile)}</div>
}

export default UserDefine
