import {BASE_URL} from '../../config'
import Recharge from './Recharge'

const GET_RECHARGE_FROM_URL = `${BASE_URL}/api/protect/rechargeTo`

const RechargeFrom = () => {
  return <Recharge apiUrl={GET_RECHARGE_FROM_URL} title='Recharge To' />
}

export default RechargeFrom
