import {ReactNode} from 'react'
import {BASE_URL} from '../../config'

export const GET_USER_LIST_URL = `${BASE_URL}/api/protect/getUserByParentWithpage`
export const GET_LAST_RECHARGE_URL = `${BASE_URL}/api/protect/getLastRecharge`
export const APPROVE_USER_URL = `${BASE_URL}/api/protect/approveUserDummy`
export const UPDATE_BALANCE_URL = `${BASE_URL}/api/protect/updateBalance`

export interface UserProviderProps {
  children: ReactNode
}

export interface IUser {
  id: number
  charging_route_id: number
  userbalance: number
  username: string
  user_profile: number
  user_status: string
  user_email: string
  user_mobile: string
  user_full_name: string
  userpass?: string
  last_recharge_date?: string
  is_kyc_form_filled: number
}

export interface ISenderIdArray extends Array<IUser> {}

export interface ICountState {
  [key: string]: number
}

export interface IBalanceInfo {
  transactionType: string
  amount: number
  paymentMethod: string
  note: string
}
