import React, {FormEvent, useEffect, useRef, useState} from 'react'
import {Badge, Button, Table} from 'react-bootstrap'
import {useLocation, useNavigate} from 'react-router-dom'
import {useAuth} from '../auth'
import toast from 'react-hot-toast'
import axios from 'axios'
import AdditionalCreateModal from './Modal/AdditionalCreateModal'
import {convertPaisa, settingsPriceList} from './core/helper'
import {
  AdditionalPrice,
  GET_ADDITIONAL_PRICE,
  IPriceList,
  IPriceListArray,
  IUpdateEachEntity,
  IUpdateEachEntityArray,
  UPDATE_PRICE_LIST_URL,
} from './core/_model'

const PriceUpdate = () => {
  const {auth, currentUser} = useAuth()
  const {state: updateUser} = useLocation()
  const [priceList, setPriceList] = useState<IPriceListArray | undefined>([])
  const [updateEntity, setUpdateEntity] = useState<IUpdateEachEntityArray>([])
  const [additionalPrice, setAdditionalPrice] = useState<AdditionalPrice | undefined>(undefined)
  const [additionalPriceUpdate, setAdditionalPriceUpdate] = useState<AdditionalPrice | undefined>(
    undefined
  )
  const sellMaskingRefs = useRef<HTMLInputElement[]>([])
  const sellNonMaskingRefs = useRef<HTMLInputElement[]>([])
  const [updating, setUpdating] = useState(false)

  const navigate = useNavigate()

  const getPriceList = async () => {
    try {
      toast.loading('Loading Price Lists..')
      const priceList = await settingsPriceList(
        auth?.accessToken,
        currentUser?.charging_route_id,
        updateUser.charging_route_id
      )
      setPriceList(priceList)
      toast.dismiss()
      toast.success('Fetched Price Lists Successfully')
    } catch (error: any) {
      console.log(error)
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
    }
  }

  const handleInputChange = (index: number, list: IPriceList) => {
    console.log(list)

    const updateEachEntity: IUpdateEachEntity = {
      id: list.entityId,
      prefix: list.prefix,
      sellMaskPrice: convertPaisa(parseFloat(sellMaskingRefs.current[index].value)),
      sellNonMaskPrice: convertPaisa(parseFloat(sellNonMaskingRefs.current[index].value)),
    }

    const existingEntity = updateEntity.findIndex((entity) => entity.id === updateEachEntity.id)

    if (existingEntity !== -1) {
      setUpdateEntity((prevValues) => {
        const updatedValues = [...prevValues]
        updatedValues[existingEntity] = updateEachEntity
        return updatedValues
      })
    } else {
      setUpdateEntity((prevValues) => [...prevValues, updateEachEntity])
    }
  }

  const handleUpdateSubmit = async (event: FormEvent) => {
    event.preventDefault()

    try {
      toast.loading('Updating Price List...')
      const response = await axios.put(
        `${UPDATE_PRICE_LIST_URL}`,
        {updatePriceRequests: updateEntity},
        {
          headers: {Authorization: `Bearer ${auth?.accessToken}`},
        }
      )
      toast.dismiss()
      toast.success('User Price Updated Successfully')
      // settingsPriceList()
      getPriceList()
      return response
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Can't Update, Error: ${error.message} `)
    }
  }

  const getAdditionalPrice = async () => {
    try {
      const response = await axios.get(`${GET_ADDITIONAL_PRICE}/${updateUser.charging_route_id}`, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      const data = await response.data
      console.log(data)
      setAdditionalPrice(data)
    } catch (error: any) {
      console.log(error.message)
    }
  }

  const handleAction = () => {
    setAdditionalPriceUpdate(additionalPrice)
    // console.log(additionalPriceUpdate)
    console.log(additionalPrice)
  }

  useEffect(() => {
    document.title = 'Price Update'

    // settingsPriceList()
    getPriceList()
    getAdditionalPrice()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0 d-flex justify-content-between'>
          <div className='card-title'>
            <h4 className='er mb-0'>
              Update Price: <Badge bg='secondary'>{updateUser.username}</Badge>
            </h4>
          </div>
        </div>
        <div className='card-body'>
          {priceList && priceList.length > 0 ? (
            <form onSubmit={handleUpdateSubmit}>
              <Table striped bordered hover responsive size='sm'>
                <thead>
                  <tr className='text-center'>
                    <th>#</th>
                    <th>Prefix</th>
                    <th>Buy Masking Price</th>
                    <th>Buy Non-Masking Price</th>
                    <th>Sell Masking Price</th>
                    <th>Sell Non-Masking Price</th>
                  </tr>
                </thead>

                <tbody>
                  {priceList.map((list: IPriceList, index: number) => {
                    return (
                      <tr className='text-center' key={list.entityId}>
                        <td>{list.entityId}</td>
                        <td>{list.prefix}</td>
                        <td>{list.buyMaskPrice}</td>
                        <td>{list.buyNonMaskPrice}</td>
                        <td>
                          <input
                            type='text'
                            className='form-control'
                            ref={(price) =>
                              (sellMaskingRefs.current[index] = price as HTMLInputElement)
                            }
                            defaultValue={list.sellMaskPrice}
                            onChange={() => handleInputChange(index, list)}
                          />
                        </td>
                        <td>
                          <input
                            type='text'
                            className='form-control'
                            ref={(price) =>
                              (sellNonMaskingRefs.current[index] = price as HTMLInputElement)
                            }
                            defaultValue={list.sellNonMaskPrice}
                            onChange={() => handleInputChange(index, list)}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
              <Button variant='primary' type='submit' disabled={updating}>
                {updating ? (
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Updating...</span>
                  </div>
                ) : (
                  'Update Price'
                )}
              </Button>
            </form>
          ) : (
            <p>
              <span className='fs-4 fw-semibold'>Data will be availabel soon... </span>
              <span
                className='spinner-border spinner-border-sm'
                role='status'
                aria-hidden='true'
              ></span>
            </p>
          )}

          <div className='mt-16'>
            <div className='d-flex justify-content-between align-items mb-6'>
              <h3 className='er mb-8'>Additional Price Setting</h3>
              {!additionalPrice && (
                <Button variant='primary' data-bs-toggle='modal' data-bs-target='#kt_modal_1'>
                  Create
                </Button>
              )}
            </div>
            <Table striped bordered hover responsive size='sm'>
              <thead>
                <tr className='text-center'>
                  <th>Submission Count</th>
                  <th>Service Charge</th>
                  <th>#</th>
                </tr>
              </thead>
              <tbody>
                {additionalPrice ? (
                  <tr className='text-center'>
                    <td>{additionalPrice.scSubmissionCount === 0 ? 'Submission' : 'Count'}</td>
                    <td>{additionalPrice.serviceCharge / 100}</td>
                    <td>
                      <Button
                        variant='secondary'
                        size='sm'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_1'
                        onClick={handleAction}
                      >
                        Action
                      </Button>
                    </td>
                  </tr>
                ) : (
                  <div className='d-flex gap-2 justify-content-center mt-8'>
                    <p className='fs-4 fw-bold'>No Data Available</p>
                  </div>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <AdditionalCreateModal
        updateUser={updateUser}
        additionalPrice={additionalPriceUpdate}
        getAdditionalPrice={getAdditionalPrice}
      />
    </div>
  )
}

export default PriceUpdate
