import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Select from 'react-select'
import {useThemeMode} from '../../../../_metronic/partials'
import ImageInput from '../../PaymentRequest/Modal/ImageInput'
import {FormEvent, useCallback, useEffect, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import FsLightbox from 'fslightbox-react'

const options = [
  {value: 'option one', label: 'option one'},
  {value: 'option two', label: 'option two'},
  {value: 'option three', label: 'option three'},
]

const priorityOptions = [
  {value: 'High', label: 'High'},
  {value: 'Medium', label: 'Medium'},
  {value: 'Low', label: 'Low'},
]

const NewTicketModal = () => {
  const {mode} = useThemeMode()
  const [files, setFiles] = useState<File[]>([])
  const [filesSrc, setFilesSrc] = useState<string[]>([])
  // const [lightboxController, setLightboxController] = useState({
  //   toggler: false,
  //   slide: 1,
  // })

  const onNidDrop = useCallback(
    (acceptedFiles: any) => {
      setFiles([...files, ...acceptedFiles])

      const file = acceptedFiles[0]
      console.log(file)
      if (file) {
        const reader = new FileReader()
        reader.onload = () => {
          const result = reader.result as string
          setFilesSrc((prev) => [...prev, result])
        }
        reader.readAsDataURL(file)
      }
    },
    [files]
  )

  const {
    acceptedFiles,
    getRootProps: getContactRootProps,
    getInputProps: getContactInputProps,
  } = useDropzone({onDrop: onNidDrop})

  const contactFileItems = files.map((file, index) => (
    <div key={index}>
      <div
        className='image-input image-input-outline overlay'
        data-kt-image-input='true'
        style={{backgroundImage: `url(${filesSrc[index]})`}}
      >
        <div
          className='overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded image-input-wrapper w-100px h-100px'
          style={{backgroundImage: `url(${filesSrc[index]})`, cursor: 'pointer'}}
          // onClick={() =>
          //   setLightboxController((prevState) => ({
          //     ...prevState,
          //     toggler: !prevState.toggler,
          //     slide: index + 1,
          //   }))
          // }
        ></div>
        {/* <div
          className='overlay-layer card-rounded bg-dark bg-opacity-25 shadow'
          onClick={() => {
            setLightboxController((prevState) => ({
              toggler: !prevState.toggler,
              slide: index + 1,
            }))
          }}
          style={{cursor: 'pointer'}}
        >
          <i className='bi bi-eye-fill text-white fs-3x'></i>
        </div> */}
        <label
          className={
            'btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow d-none'
          }
          data-kt-image-input-action='change'
          data-bs-toggle='tooltip'
          data-bs-dismiss='click'
          title='Change avatar'
        >
          <i className='ki-duotone ki-pencil fs-6'>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
          <input type='hidden' name='avatar_remove' />
        </label>
      </div>
    </div>
  ))

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '8px',
      padding: '4px',
      border: 0,
      backgroundColor: mode === 'light' ? '#f9f9f9' : '#1b1b29',
      borderColor: state.isFocused ? '#6c63ff' : '#d1d1d1',
      boxShadow: state.isFocused ? '0 0 0 1px #6c63ff' : null,
      '&:hover': {
        borderColor: '#6c63ff',
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#6c63ff' : null,
      color: state.isSelected ? '#ffffff' : null,
      '&:hover': {
        backgroundColor: '#6c63ff',
        color: '#ffffff',
      },
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '8px',
      backgroundColor: mode === 'light' ? '#f8f0f4' : '#1b1b29',
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)', // Add box shadow
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: mode === 'light' ? '#5e6278' : '#92929f', // Change color of selected value
    }),
  }

  return (
    <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
      <div className='modal-dialog modal-xl'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'> Create New Ticket</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <form>
              <div className='row mb-4'>
                <label className='col-lg-4 col-form-label fs-6'>
                  <span className='required'>Ticket Code</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    name='ticketCode'
                    placeholder='Ticket Code'
                    className='form-control form-control-lg form-control-solid'
                    required
                  />
                </div>
              </div>

              <div className='row mb-4'>
                <label className='col-lg-4 col-form-label fs-6'>
                  <span className='required'>Subject</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    name='subject'
                    placeholder='Enter Subject'
                    className='form-control form-control-lg form-control-solid'
                    required
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fs-6'>
                  <span>Projects</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <Select
                    name='projects'
                    placeholder='Select project'
                    options={options}
                    styles={customStyles}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fs-6'>
                  <span className='required'>Priority</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <Select
                    name='priority'
                    placeholder='Select priority'
                    options={priorityOptions}
                    styles={customStyles}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fs-6'>
                  <span className='required'>Departments</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <Select
                    name='departments'
                    placeholder='Select department'
                    options={options}
                    styles={customStyles}
                  />
                </div>
              </div>

              <div className='row mb-4'>
                <label className='col-lg-4 col-form-label fs-6'>
                  <span>Tags</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    name='tags'
                    placeholder='Add tags'
                    className='form-control form-control-lg form-control-solid'
                  />
                </div>
              </div>

              <div className='row mb-4'>
                <label className='col-lg-4 col-form-label fs-6'>
                  <span>Attachment</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <div {...getContactRootProps({className: 'dropzone'})}>
                    <input {...getContactInputProps()} />
                    <p>Drag 'n' drop files here, or click to select files</p>
                  </div>
                </div>
              </div>

              <div className='row mb-4'>
                <label className='col-lg-4 col-form-label fs-6'></label>

                <div className='col-lg-8 d-flex flex-wrap'>{contactFileItems}</div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fs-6'>Ticket Message</label>
                <div className='col-lg-8 fv-row-3'>
                  <textarea
                    name='ticketMessage'
                    placeholder='Message..'
                    className='form-control form-control-lg form-control-solid'
                    rows={5}
                    style={{resize: 'vertical', fontSize: '1.0rem'}}
                    required
                  ></textarea>
                </div>
              </div>

              <div className='d-flex justify-content-end'>
                <button
                  type='button'
                  className='btn btn-light me-4'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  Close
                </button>
                <button
                  type='button'
                  className='btn btn-primary'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewTicketModal
