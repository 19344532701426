import {FC, createContext, useContext, useEffect, useState} from 'react'
import {WithChildren} from '../../../../_metronic/helpers'
import {useAuth} from '../../auth'
import {IArticle} from '../core/_model'

export const ArticleContext = createContext<any>(null)

export const ArticleProvider: FC<WithChildren> = ({children}) => {
  const {auth} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [articles, setArticles] = useState<IArticle[]>([])
  const [filteredArticles, setFilteredArticles] = useState<IArticle[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')

  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalElement, setTotalElement] = useState(0)
  const [isLastPage, setIsLastPage] = useState(false)

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)

  let showPageLastEntries = !isLastPage ? currentPage * itemsPerPage : totalElement
  let showPageFirstEntries = !isLastPage
    ? showPageLastEntries + 1 - itemsPerPage
    : (currentPage - 1) * itemsPerPage + 1

  useEffect(() => {
    const filteredData = articles?.filter(
      (list) =>
        list.title?.toLowerCase().includes(searchTerm) ||
        list.category?.toLowerCase().includes(searchTerm)
    )
    setFilteredArticles(filteredData)
  }, [articles, searchTerm])

  useEffect(() => {
    document.title = 'Articles'
  }, [])

  const values = {
    isLoading,
    articles,
    filteredArticles,
    searchTerm,
    setSearchTerm,
    handlePageChange,
    itemsPerPage,
    setItemsPerPage,
    setCurrentPage,
    totalElement,
    currentPage,
  }

  return <ArticleContext.Provider value={values}>{children}</ArticleContext.Provider>
}

export const useArticleProvider = () => useContext(ArticleContext)
