import React from 'react'
import Select from 'react-select'
import {useThemeMode} from '../../../../_metronic/partials'
import {KTSVG} from '../../../../_metronic/helpers'

const options = [
  {value: 'option one', label: 'option one'},
  {value: 'option two', label: 'option two'},
  {value: 'option three', label: 'option three'},
]

const MaintenanceFormModal = () => {
  const {mode} = useThemeMode()

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '8px',
      padding: '4px',
      border: 0,
      backgroundColor: mode === 'light' ? '#f9f9f9' : '#1b1b29',
      borderColor: state.isFocused ? '#6c63ff' : '#d1d1d1',
      boxShadow: state.isFocused ? '0 0 0 1px #6c63ff' : null,
      '&:hover': {
        borderColor: '#6c63ff',
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#6c63ff' : null,
      color: state.isSelected ? '#ffffff' : null,
      '&:hover': {
        backgroundColor: '#6c63ff',
        color: '#ffffff',
      },
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '8px',
      backgroundColor: mode === 'light' ? '#f8f0f4' : '#1b1b29',
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)', // Add box shadow
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: mode === 'light' ? '#5e6278' : '#92929f', // Change color of selected value
    }),
  }

  return (
    <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
      <div className='modal-dialog modal-xl'>
        <div className='modal-content'>
          <div className='modal-header'>
            <div className='fs-5 text-gray-900 fw-bolder'>Schedule Maintenance</div>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>

          <div className='modal-body'>
            <form>
              <div className='row mb-4'>
                <label className='col-lg-4 col-form-label fs-6'>
                  <span>Title</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    name='title'
                    placeholder='Title..'
                    className='form-control form-control-lg form-control-solid'
                    required
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fs-6'>Description</label>
                <div className='col-lg-8 fv-row-3'>
                  <textarea
                    name='description'
                    placeholder='Discription'
                    className='form-control form-control-lg form-control-solid'
                    rows={5}
                    style={{resize: 'vertical', fontSize: '1.0rem'}}
                    required
                  ></textarea>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fs-6'>
                  <span>Affected Monitors</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <Select
                    name='affectedMonitors'
                    placeholder='Pick affected monitors..'
                    options={options}
                    styles={customStyles}
                  />
                  <p className='fs-8 fw-regular text-gray-800'>
                    Select monitors that are affected by current maintenance
                  </p>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fs-6'>
                  <span>Status Pages</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <Select
                    name='statusPages'
                    placeholder='Select status pages..'
                    options={options}
                    styles={customStyles}
                  />
                  <p className='fs-8 fw-regular text-gray-800'>
                    Show this maintenance message on selected status pages
                  </p>
                </div>
              </div>

              <div className='mt-16'>
                <h4 className='mb-8 text-gray-800'>Date and Time</h4>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fs-6'>
                    <span>Strategy</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <Select
                      name='strategy'
                      placeholder='Select values..'
                      options={options}
                      styles={customStyles}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fs-6'>
                    <span>Timezone</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <Select
                      name='timezone'
                      placeholder='Select timezone..'
                      options={options}
                      styles={customStyles}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <div className='mb-4 col-lg-6'>
                    <label className='form-label fs-6 fw-bold'>
                      <span>Start Time:</span>
                    </label>
                    <input
                      type='datetime-local'
                      className='form-control'
                      min={new Date().toISOString()}
                    />
                  </div>

                  <div className='mb-4 col-lg-6'>
                    <label className='form-label fs-6 fw-bold'>
                      <span>End Time:</span>
                    </label>
                    <input
                      type='datetime-local'
                      className='form-control'
                      min={new Date().toISOString()}
                    />
                  </div>
                </div>
              </div>

              <div className='d-flex justify-content-end'>
                <button
                  className='btn btn-secondary me-4'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  Close
                </button>
                <button type='submit' className='btn btn-primary'>
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MaintenanceFormModal
