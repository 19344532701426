import {Column, HeaderProps} from 'react-table'
import {PropsWithChildren} from 'react'
import {SpamFilterData} from './core/_model'
import SpamFilterAction from './SpamFilterAction'
import {CustomHeader} from '../../utils/components/Table/CustomHeader'
import ListSerial from './ListSerial'
import SelectionHeader from '../../utils/components/Table/SelectionHeader'
import {ListViewContext} from '../../utils/contextProvider/ListViewProvider'
import {SelectionCell} from '../../utils/components/Table/SelectionCell'

const SpamFilterColumn: ReadonlyArray<Column<SpamFilterData>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props as any} context={ListViewContext} />,
    id: 'selection',
    Cell: ({...props}) => (
      <SelectionCell id={props.data[props.row.index].id} context={ListViewContext} />
    ),
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Sl' className='min-w-125px text-center' />
    ),
    id: 'serial',
    Cell: ({...props}) => <ListSerial indexNo={props.row.index} />,
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Filter Text' className='min-w-125px text-center' />
    ),
    accessor: 'filterText',
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Actions' className='min-w-100px text-center' />
    ),
    id: 'actions',
    Cell: ({...props}) => <SpamFilterAction spam={props.data[props.row.index]} />,
  },
]

export {SpamFilterColumn}
