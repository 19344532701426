import {ChangeEvent, FC, createContext, useContext, useEffect, useState} from 'react'
import {
  CREATE_SPAM_FILTER_URL,
  DELETE_SPAM_TEXT_URL,
  GET_SPAM_FILTER_URL,
  SendSpamFilter,
  SpamFilterContextProps,
  SpamFilterData,
  UPDATE_SPAM_FILTER_TEXT,
} from './core/_model'
import {useAuth} from '../auth'
import axios from 'axios'
import toast from 'react-hot-toast'

export const SpamFilterContext = createContext<any>(null)

export const SpamFilterProvider: FC<SpamFilterContextProps> = ({children}) => {
  const {auth, currentUser} = useAuth()
  const [spamFilter, setSpamFilter] = useState<SpamFilterData[]>([])
  const [filteredSpam, setFilteredSpam] = useState<SpamFilterData[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState({isError: true, message: ''})
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [updateSpamText, setUpdateSpamText] = useState<SpamFilterData | undefined>(undefined)
  const [deleteSpamText, setDeleteSpamText] = useState<SpamFilterData | undefined>(undefined)
  const [showModal, setShowModal] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalElement, setTotalElement] = useState(0)
  const [isLastPage, setIsLastPage] = useState(false)

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)

  const setParams = {
    pageNumber: currentPage - 1,
    pageSize: itemsPerPage,
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const getSpamFilter = async () => {
    try {
      setIsLoading(true)
      toast.loading('Getting Spam Filter')
      const response = await axios.get(`${GET_SPAM_FILTER_URL}/${currentUser?.user_profile}`, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
        params: setParams,
      })
      const data = await response.data
      const result = await data.data
      const totalResult = await data.totalElement
      const lastPage = await data.lastpage
      setIsLastPage(lastPage)
      setTotalElement(totalResult)
      setSpamFilter(result ? result : [])
      setIsLoading(false)
      setError({isError: false, message: ''})
      toast.dismiss()
      toast.success('Fetched Spam Filter')
      setIsLoading(false)
    } catch (error: any) {
      console.log(error.message)
      setError({
        isError: true,
        message: error.response.message ? error.response.message : error.message,
      })
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
      setIsLoading(false)
    }
  }

  const createSpamFilter = async (spamFilter: SendSpamFilter) => {
    try {
      toast.loading('Creating new Spam Filter Text')
      await axios.post(`${CREATE_SPAM_FILTER_URL}`, spamFilter, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      toast.dismiss()
      toast.success('New Spam Filter Text Created')
      getSpamFilter()
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
    }
  }

  const updatedSpamFilterTextApi = async (spamText: SendSpamFilter) => {
    try {
      toast.loading(`Updating ${spamText.filterText}`)
      await axios.put(`${UPDATE_SPAM_FILTER_TEXT}/${spamText.id}`, spamText, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      toast.dismiss()
      toast.success(`Update ${spamText.filterText} successfully`)
      getSpamFilter()
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
    }
  }

  const deleteSpamFilterTextApi = async (deleteSpamText: SendSpamFilter) => {
    try {
      toast.loading(`Deleting ${deleteSpamText.filterText}`)
      await axios.delete(`${DELETE_SPAM_TEXT_URL}/${deleteSpamText.id}`, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      toast.dismiss()
      toast.success(`Delete ${deleteSpamText.filterText} successfully`)
      getSpamFilter()
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
    }
  }

  const handleUpdateClick = (spam: SpamFilterData) => {
    setUpdateSpamText(spam)
    setShowModal(true)
  }

  const hide = () => {
    setUpdateSpamText(undefined)
    setShowModal(false)
  }

  useEffect(() => {
    const filteredData = spamFilter.filter((spam) =>
      spam.filterText.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setFilteredSpam(filteredData)
  }, [spamFilter, searchTerm])

  let showPageLastEtries = !isLastPage ? currentPage * itemsPerPage : totalElement
  let showPageFirstEntries = !isLastPage
    ? showPageLastEtries + 1 - itemsPerPage
    : (currentPage - 1) * itemsPerPage + 1

  let serial: number[] = []

  for (let i = showPageFirstEntries; i <= showPageLastEtries; i++) {
    serial.push(i)
  }

  useEffect(() => {
    document.title = 'Spam Filter'

    getSpamFilter()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage])

  const values = {
    isLoading,
    spamFilter,
    filteredSpam,
    searchTerm,
    setSearchTerm,
    updateSpamText,
    setUpdateSpamText,
    deleteSpamText,
    setDeleteSpamText,
    showModal,
    currentPage,
    itemsPerPage,
    totalElement,
    setCurrentPage,
    setItemsPerPage,
    handlePageChange,
    getSpamFilter,
    createSpamFilter,
    updatedSpamFilterTextApi,
    deleteSpamFilterTextApi,
    handleUpdateClick,
    hide,
    serial,
  }

  return <SpamFilterContext.Provider value={values}>{children}</SpamFilterContext.Provider>
}

export const useSpamFilterProvider = () => useContext(SpamFilterContext)
