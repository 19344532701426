import {BASE_URL} from '../config'
import Schedule from './Schedule'

const GET_PENDING_SMS_URL = `${BASE_URL}/api/protect/pending`

const Pending = () => {
  return <Schedule apiUrl={GET_PENDING_SMS_URL} title='Schedule SMS Pending' />
}

export default Pending
