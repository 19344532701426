import {useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {usePaymentProvider} from './PaymentProvider'
import Select from 'react-select'

const options = [
  {value: 'chocolate', label: 'Chocolate'},
  {value: 'strawberry', label: 'Strawberry'},
  {value: 'vanilla', label: 'Vanilla'},
]

const PaymentListFilter = () => {
  const {isLoading, itemsPerPage, setItemsPerPage, setCurrentPage} = usePaymentProvider()
  const [paymentType, setPaymentType] = useState<string | undefined>()
  const [status, setStatus] = useState<string | undefined>()

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        id='dropdownMenuButton'
        data-bs-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
      >
        <KTIcon iconName='filter' className='fs-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div
        className='dropdown-menu menu menu-sub menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-300px w-md-325px py-4'
        aria-labelledby='dropdownMenuButton'
      >
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-gray-900 fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Show Entries:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select entries'
              data-hide-search='true'
              name='entries'
              id='entries'
              value={itemsPerPage}
              onChange={(e) => {
                setCurrentPage(1)
                setItemsPerPage(parseInt(e.target.value))
              }}
            >
              <option value='10'>10</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
              <option value='250'>250</option>
            </select>
          </div>

          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Payment Type</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='paymentType'
              data-hide-search='true'
              onChange={(e) => setPaymentType(e.target.value)}
              value={paymentType}
            >
              <option value=''>Select Type</option>
              <option value='cash'>Cash</option>
              <option value='bkash'>Bkash</option>
              <option value='nagad'>Nagad</option>
              <option value='bankDepositeOrCheque'>Bank Deposite Or Cheque</option>
            </select>
          </div>
          {/* end::Input group */}

          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Test Type</label>
            <Select options={options} />
          </div>

          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Status:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='two-step'
              data-hide-search='true'
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            >
              <option value=''>Select Status</option>
              <option value='submitted'>Submitted</option>
              <option value='rejected'>Rejected</option>
              <option value='approve'>Approved</option>
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export default PaymentListFilter
