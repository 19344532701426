import {useEffect, useState} from 'react'
import {BASE_URL} from '../modules/config'
import {useAuth} from '../modules/auth'
import axios from 'axios'
import {FormikProps} from 'formik'
import {ISenderIdArray} from '../modules/SMSBroadCast/core/_model'

const GET_ALL_SENDERIDS_URL = `${BASE_URL}/api/protect/getallSenders`

export interface ISenderIdsOption {
  value: any
  label: string
  id: number
  default: number | undefined
  branded: number
}

export interface ISenderIdsOptionsArray extends Array<ISenderIdsOption> {}

const useSenderIdsOptions = (formik?: FormikProps<any>) => {
  const {auth, currentUser} = useAuth()
  const [senderIdsOptions, setSenderIdsOptions] = useState<ISenderIdsOptionsArray>([])
  const [defaultSender, setDefaultSender] = useState<ISenderIdsOptionsArray>([])
  const [allDefaultIds, setAllDefaultIds] = useState<ISenderIdArray>([])

  const getAllSenderIds = async () => {
    try {
      const response = await axios.get(`${GET_ALL_SENDERIDS_URL}/${currentUser?.user_profile}`, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      const data = await response.data
      setAllDefaultIds(data)
      formattedIdsOptions(data)
      // console.log(data)
    } catch (err) {
      const errorMessage = (err as Error)?.message || 'An error occurred'
      throw new Error(errorMessage)
    }
  }

  const formattedIdsOptions = (senderIds: ISenderIdArray) => {
    const arrangedOptions: ISenderIdsOptionsArray = []
    senderIds.map((id: any) => {
      return arrangedOptions.push({
        value: id.sender,
        label: id.sender,
        id: id.id,
        default: id?.markAsDefault,
        branded: id.branded,
      })
    })
    const defaultOption = arrangedOptions.filter((item: ISenderIdsOption) => item.default)
    setSenderIdsOptions(arrangedOptions)
    setDefaultSender(defaultOption)
    console.log(defaultOption)

    // console.log(formik?.values)
    return arrangedOptions
  }

  const getSenderIdsOptions = () => {
    const options = [
      {value: '1', label: '1'},
      {value: '2', label: '2'},
      {value: '3', label: '3'},
    ]
    return options
  }

  useEffect(() => {
    getAllSenderIds()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {senderIdsOptions, getSenderIdsOptions, allDefaultIds, defaultSender}
}

export default useSenderIdsOptions
