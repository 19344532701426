export interface DummyReport {
  text: string
  sender: string
  destination: string
  sendAt: number // Timestamp
  doneAt: number // Timestamp
  externalMessageId: string
  status: string
  error: string
  errorGroup: string
  reason: string
  requestType: number
  smsCount: number
  networkName: string
  accountName: string
  encryptedOptions: string
  applicationId: string
}

const dummyReport = {
  reports: [
    {
      text: "KAZI AFIA is absent today Date:August 25, 2024 Regards:Rabeya Basri Girl's High School",
      sender: '8809617600013',
      destination: '8801741006996',
      sendAt: 1724571407466,
      doneAt: 1724571410437,
      externalMessageId: '130082507364746615',
      status: 'Delivered',
      error: 'No Error (code 0)',
      errorGroup: 'No Errors',
      reason: 'DELIVERED_TO_HANDSET',
      requestType: 1,
      smsCount: 1,
      networkName: 'Grameenphone',
      accountName: 'IATL',
      encryptedOptions: 'ALWAYS_SHOW_DECRYPTED',
      applicationId: 'default',
    },
    {
      text: "TASNIM TARANNUM is absent today Date:August 25, 2024 Regards:Rabeya Basri Girl's High School",
      sender: '8809617600013',
      destination: '8801816038094',
      sendAt: 1724571406754,
      doneAt: 1724571410167,
      externalMessageId: '143082507364675433',
      status: 'Delivered',
      error: 'No Error (code 0)',
      errorGroup: 'No Errors',
      reason: 'DELIVERED_TO_HANDSET',
      requestType: 1,
      smsCount: 1,
      networkName: 'Robi (Robi Axiata Ltd)',
      accountName: 'IATL',
      encryptedOptions: 'ALWAYS_SHOW_DECRYPTED',
      applicationId: 'default',
    },
    {
      text: "MUSHFIQA MAHJABIN is absent today Date:August 25, 2024 Regards:Rabeya Basri Girl's High School",
      sender: '8809617600013',
      destination: '8801815725282',
      sendAt: 1724571406072,
      doneAt: 1724571409417,
      externalMessageId: '141082507364607267',
      status: 'Delivered',
      error: 'No Error (code 0)',
      errorGroup: 'No Errors',
      reason: 'DELIVERED_TO_HANDSET',
      requestType: 1,
      smsCount: 1,
      networkName: 'Robi (Robi Axiata Ltd)',
      accountName: 'IATL',
      encryptedOptions: 'ALWAYS_SHOW_DECRYPTED',
      applicationId: 'default',
    },
    {
      text: "TANIA AKTER is absent today Date:August 25, 2024 Regards:Rabeya Basri Girl's High School",
      sender: '8809617600013',
      destination: '8801884074168',
      sendAt: 1724571405362,
      doneAt: 1724571411763,
      externalMessageId: '143082507364536212',
      status: 'Delivered',
      error: 'No Error (code 0)',
      errorGroup: 'No Errors',
      reason: 'DELIVERED_TO_HANDSET',
      requestType: 1,
      smsCount: 1,
      networkName: 'Robi (Robi Axiata Ltd)',
      accountName: 'IATL',
      encryptedOptions: 'ALWAYS_SHOW_DECRYPTED',
      applicationId: 'default',
    },
    {
      text: "PERAM AKTER is absent today Date:August 25, 2024 Regards:Rabeya Basri Girl's High School",
      sender: '8809617600013',
      destination: '8801849972093',
      sendAt: 1724571404585,
      doneAt: 1724571410733,
      externalMessageId: '126082507364458468',
      status: 'Delivered',
      error: 'No Error (code 0)',
      errorGroup: 'No Errors',
      reason: 'DELIVERED_TO_HANDSET',
      requestType: 1,
      smsCount: 1,
      networkName: 'Grameenphone',
      accountName: 'IATL',
      encryptedOptions: 'ALWAYS_SHOW_DECRYPTED',
      applicationId: 'default',
    },
    {
      text: "SUMAIYA AKTER is absent today Date:August 25, 2024 Regards:Rabeya Basri Girl's High School",
      sender: '8809617600013',
      destination: '8801863641168',
      sendAt: 1724571403744,
      doneAt: 1724573603903,
      externalMessageId: '129082507364374490',
      status: 'Delivered',
      error: 'No Error (code 0)',
      errorGroup: 'No Errors',
      reason: 'DELIVERED_TO_HANDSET',
      requestType: 1,
      smsCount: 1,
      networkName: 'Robi (Robi Axiata Ltd)',
      accountName: 'IATL',
      encryptedOptions: 'ALWAYS_SHOW_DECRYPTED',
      applicationId: 'default',
    },
    {
      text: "JAYANTI RANI DEVI is absent today Date:August 25, 2024 Regards:Rabeya Basri Girl's High School",
      sender: '8809617600013',
      destination: '8801816014318',
      sendAt: 1724571402989,
      doneAt: 1724572794077,
      externalMessageId: '140082507364298834',
      status: 'Delivered',
      error: 'No Error (code 0)',
      errorGroup: 'No Errors',
      reason: 'DELIVERED_TO_HANDSET',
      requestType: 1,
      smsCount: 1,
      networkName: 'Robi (Robi Axiata Ltd)',
      accountName: 'IATL',
      encryptedOptions: 'ALWAYS_SHOW_DECRYPTED',
      applicationId: 'default',
    },
    {
      text: "KRISHNA RANI DEVI is absent today Date:August 25, 2024 Regards:Rabeya Basri Girl's High School",
      sender: '8809617600013',
      destination: '8801718179091',
      sendAt: 1724571402313,
      doneAt: 1724571408837,
      externalMessageId: '146082507364231345',
      status: 'Delivered',
      error: 'No Error (code 0)',
      errorGroup: 'No Errors',
      reason: 'DELIVERED_TO_HANDSET',
      requestType: 1,
      smsCount: 1,
      networkName: 'Grameenphone',
      accountName: 'IATL',
      encryptedOptions: 'ALWAYS_SHOW_DECRYPTED',
      applicationId: 'default',
    },
    {
      text: "NANDITA RANI DEVI is absent today Date:August 25, 2024 Regards:Rabeya Basri Girl's High School",
      sender: '8809617600013',
      destination: '8801833420801',
      sendAt: 1724571401642,
      doneAt: 1724571411460,
      externalMessageId: '136082507364164237',
      status: 'Delivered',
      error: 'No Error (code 0)',
      errorGroup: 'No Errors',
      reason: 'DELIVERED_TO_HANDSET',
      requestType: 1,
      smsCount: 1,
      networkName: 'Robi (Robi Axiata Ltd)',
      accountName: 'IATL',
      encryptedOptions: 'ALWAYS_SHOW_DECRYPTED',
      applicationId: 'default',
    },
    {
      text: "MASHFIQA JAHAN TAIMA is absent today Date:August 25, 2024 Regards:Rabeya Basri Girl's High School",
      sender: '8809617600013',
      destination: '8801824065808',
      sendAt: 1724571400950,
      doneAt: 1724571407787,
      externalMessageId: '133082507364095080',
      status: 'Delivered',
      error: 'No Error (code 0)',
      errorGroup: 'No Errors',
      reason: 'DELIVERED_TO_HANDSET',
      requestType: 1,
      smsCount: 1,
      networkName: 'Robi (Robi Axiata Ltd)',
      accountName: 'IATL',
      encryptedOptions: 'ALWAYS_SHOW_DECRYPTED',
      applicationId: 'default',
    },
    {
      text: "AFRIN AKTER is absent today Date:August 25, 2024 Regards:Rabeya Basri Girl's High School",
      sender: '8809617600013',
      destination: '8801976134442',
      sendAt: 1724571399263,
      doneAt: 1724571469707,
      externalMessageId: '255082507363926349',
      status: 'Undeliverable',
      error: 'Unknown Subscriber (code 1)',
      errorGroup: 'Handset Errors',
      reason: 'UNDELIVERABLE_NOT_DELIVERED',
      requestType: 1,
      smsCount: 1,
      networkName: 'Banglalink (Orascom Telecom Ltd)',
      accountName: 'IATL',
      encryptedOptions: 'ALWAYS_SHOW_DECRYPTED',
      applicationId: 'default',
    },
  ],
  pagination: {total: 59, limit: 25, offset: 0},
}

export const formatDateTime = (timestamp: number): string => {
  const date = new Date(timestamp)

  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long', // "Monday"
    year: 'numeric', // "2024"
    month: 'long', // "August"
    day: 'numeric', // "25"
    hour: '2-digit', // "10"
    minute: '2-digit', // "43"
    second: '2-digit', // "27"
    timeZoneName: 'short', // "GMT" or your local time zone abbreviation
  }

  return date.toLocaleString('en-US', options)
}

export default dummyReport
