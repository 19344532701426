import React, {useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import {useAuth} from '../../auth'
import {useUserListProvider} from '../UserListProvider'
import {IBalanceInfo} from '../core/_model'

const UpdateBalanceModal = () => {
  const {auth} = useAuth()
  const {
    updateUser,
    showUpdateModal: show,
    closeUpdateModal: hide,
    updateBalanceAPI,
  } = useUserListProvider()
  const [username, setUsername] = useState('')
  const [currentBalance, setCurrentBalance] = useState(2)
  const [transactionType, setTransactionType] = useState('deposite')
  const [amount, setAmount] = useState<number>(0)
  const [paymentMethod, setPaymentMethod] = useState<string>('')
  const [note, setNote] = useState<string>('')

  const handleTransactionType = (event: {target: {value: React.SetStateAction<string>}}) => {
    setTransactionType(event.target.value)
  }

  useEffect(() => {
    if (show) {
      setUsername(updateUser?.user_full_name || '')
      setCurrentBalance(updateUser?.userbalance)
    }
  }, [show, updateUser])

  const updateBalance = () => {
    const balanceInfo: IBalanceInfo = {
      transactionType,
      amount,
      paymentMethod,
      note,
    }
    updateBalanceAPI(auth?.accessToken, updateUser.id, balanceInfo)
    console.log(balanceInfo)
    hide()
  }

  return (
    <div
      className='modal fade'
      style={{visibility: !show ? 'hidden' : undefined}}
      tabIndex={-2}
      id='kt_modal_2'
    >
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Update User Balance</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <form>
            <div className='modal-body'>
              <div className='d-flex flex-column align-items-start mb-4'>
                <label className='col-form-label fs-4 fw-bold'>
                  <span className=''>User Name</span>
                </label>

                <div className='col-lg-8 fv- w-100'>
                  <input
                    className='form-control form-control-lg form-control-solid'
                    value={username && username}
                    disabled
                  />
                </div>
              </div>

              <div className='d-flex flex-column align-items-start mb-4'>
                <label className='col-form-label fs-4 fw-bold'>
                  <span className=''>Current Balance</span>
                </label>

                <div className='col-lg-8 fv- w-100'>
                  <input
                    className='form-control form-control-lg form-control-solid'
                    value={currentBalance && currentBalance}
                    disabled
                  />
                </div>
              </div>
              <div className='d-flex flex-column align-items-start mb-4'>
                <label className='col-form-label fs-6'>
                  <span className='required'>Transection Type</span>
                </label>
                <select
                  name='transactiontype'
                  id='transactiontype'
                  className='form-control'
                  value={transactionType}
                  onChange={handleTransactionType}
                >
                  <option value='deposite'>Deposite</option>
                  <option value='withdraw'>Withdraw</option>
                  <option value='refund'>Refund</option>
                </select>
              </div>
              <div className='d-flex flex-column align-items-start mb-4'>
                <label className='col-form-label fs-4 fw-bold'>
                  <span className=''>Amount</span>
                </label>

                <div className='col-lg-8 fv- w-100'>
                  <input
                    type='number'
                    name=''
                    placeholder='Enter Amount'
                    className='form-control form-control-lg form-control-solid'
                    value={amount}
                    onChange={(e) => setAmount(parseInt(e.target.value))}
                  />
                </div>
              </div>
              <div className='d-flex flex-column align-items-start mb-4'>
                <label className='col-form-label fs-4 fw-bold'>
                  <span className=''>Payment Method</span>
                </label>

                <div className='col-lg-8 fv- w-100'>
                  <input
                    type='text'
                    name='paymentMethod'
                    placeholder='Enter method'
                    className='form-control form-control-lg form-control-solid'
                    value={paymentMethod}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  />
                </div>
              </div>
              <div className='d-flex flex-column align-items-start mb-4'>
                <label className='col-form-label fs-4 fw-bold'>
                  <span className=''>Note</span>
                </label>

                <div className='col-lg-8 fv- w-100'>
                  <input
                    type='text'
                    name='note'
                    placeholder='Enter note'
                    className='form-control form-control-lg form-control-solid'
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <div>
                <Button variant='secondary' data-bs-dismiss='modal' aria-label='Close'>
                  Close
                </Button>{' '}
                <Button
                  type='button'
                  variant='primary'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={updateBalance}
                >
                  Update
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default UpdateBalanceModal
