import React, {FC} from 'react'
import {IUser} from './core/_model'
import {UserListProvider, useUserListProvider} from './UserListProvider'

type UsernameProps = {
  user: IUser
}

const Username: FC<UsernameProps> = ({user}) => {
  const {handleUserDetails} = useUserListProvider()
  return (
    <div>
      <button className='btn btn-link text-primary' onClick={() => handleUserDetails(user)}>
        {user.user_full_name ? user.user_full_name : 'Unknown'}
      </button>
    </div>
  )
}

export default Username
