import React, {FormEvent, useEffect, useState} from 'react'
import {useSettingsProvider} from './SettingsProvider'
import {OptionsObject} from './core/_model'
import ContentLoading from '../../utils/components/Loading/ContentLoading'

const UrlSettings = () => {
  const {isLoading, setIsLoading, urlOptionsObj, updateOptions, getAllOptions} =
    useSettingsProvider()

  const [urlOptions, setUrlOptions] = useState<OptionsObject>({
    downloadLive: undefined,
    downloadArchive: undefined,
    apiUrl: undefined,
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = event.target
    setUrlOptions((prevData) => ({
      ...prevData,
      [name]: {
        ...prevData[name],
        optionsValue: value,
      },
    }))
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    setIsLoading(true)

    for (const key in urlOptions) {
      await updateOptions(urlOptions[key])
    }

    getAllOptions()
  }

  useEffect(() => {
    setUrlOptions({
      downloadLive: urlOptionsObj.download_live_url,
      downloadArchive: urlOptionsObj.download_archive_url,
      apiUrl: urlOptionsObj.api_url,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return (
    <div className='card col-12 col-md-10'>
      <div className='card-header'>
        <div className='card-title'>
          <h3 className='text-gray-700'>Payment Settings</h3>
        </div>
      </div>
      <div className='card-body'>
        {isLoading ? (
          <ContentLoading />
        ) : (
          <form onSubmit={handleSubmit}>
            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label fs-6'>
                <span>Download Live URL</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  name='downloadLive'
                  className='form-control form-control-lg form-control-solid'
                  value={urlOptions.downloadLive?.optionsValue}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label fs-6'>
                <span>Download Archive URL</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  name='downloadArchive'
                  className='form-control form-control-lg form-control-solid'
                  value={urlOptions.downloadArchive?.optionsValue}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label fs-6'>
                <span>API URL</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  name='apiUrl'
                  placeholder='Gateway app key'
                  className='form-control form-control-lg form-control-solid'
                  value={urlOptions.apiUrl?.optionsValue}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className='d-flex justify-content-end'>
              <button type='submit' className='btn btn-primary'>
                Save Changes
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

export default UrlSettings
