/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {DepartmentHeaderProps} from './core/_model'
import {ListViewContext, useListView} from '../../utils/contextProvider/ListViewProvider'
import SelectList from '../../utils/components/SelectList'

const DepartmentHeader: FC<DepartmentHeaderProps> = ({
  isLoading,
  itemsPerPage,
  setItemsPerPage,
  setCurrentPage,
}) => {
  const {selected} = useListView()

  return (
    <div>
      {selected.length > 0 ? (
        <SelectList context={ListViewContext} />
      ) : (
        <>
          <button
            disabled={isLoading}
            type='button'
            className='btn btn-light-primary me-3'
            id='dropdownMenuButton'
            data-bs-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            <KTIcon iconName='filter' className='fs-2' />
            Filter
          </button>
          <div
            className='dropdown-menu menu menu-sub menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-300px w-md-325px py-4'
            aria-labelledby='dropdownMenuButton'
          >
            <div className='mb-10'>
              <div className='px-7 py-5'>
                <div className='fs-5 text-gray-900 fw-bolder'>Filter Options</div>
              </div>

              <div className='px-7 py-5'>
                <label className='form-label fs-6 fw-bold'>Show Entries:</label>
                <select
                  className='form-select form-select-solid fw-bolder'
                  data-kt-select2='true'
                  data-placeholder='Select entries'
                  data-hide-search='true'
                  name='entries'
                  id='entries'
                  value={itemsPerPage}
                  onChange={(e) => {
                    setCurrentPage(1)
                    setItemsPerPage(parseInt(e.target.value))
                  }}
                >
                  <option value='10'>10</option>
                  <option value='50'>50</option>
                  <option value='100'>100</option>
                  <option value='250'>250</option>
                </select>
              </div>
            </div>
          </div>
          <button
            className='btn btn-light-primary me-3'
            type='button'
            id='dropdownMenuButton'
            data-bs-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            <KTIcon iconName='exit-up' className='fs-2' />
            Export
          </button>
          <ul
            className='dropdown-menu menu menu-sub menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4'
            aria-labelledby='dropdownMenuButton'
          >
            <div className='menu-item px-3'>
              <a className='menu-link px-3'>CSV</a>

              <a className='menu-link px-3'>PDF</a>
            </div>
          </ul>
          <button
            type='button'
            className='btn btn-primary'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_2'
          >
            <KTIcon iconName='plus' className='fs-2' />
            Add New Department
          </button>
        </>
      )}
    </div>
  )
}

export default DepartmentHeader
