import {Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import PaymentRequestModal from './Modal/PaymentRequestModal'
import PaymentDeleteModal from './Modal/PaymentDeleteModal'
import ViewPaymentReqModal from './Modal/ViewPaymentReqModal'
import ListPagination from './ListPagination'
import {KTCard, KTIcon} from '../../../_metronic/helpers'
import {usePaymentProvider} from './PaymentProvider'
import PaymentListFilter from './PaymentListFilter'
import {ListViewContext, ListViewProvider, useListView} from './core/ListViewProvider'
import SelectList from './SelectList'
import TableContent from '../../utils/components/Table/TableContent'
import {PaymentColumns} from './PaymentColumns'
import TablePagination from '../../utils/components/Table/TablePagination'

const PaymentRequest = () => {
  const {
    isLoading,
    searchTerm,
    setSearchTerm,
    setUpdatePayment,
    setShowModal,
    totalElement,
    itemsPerPage,
    currentPage,
    handlePageChange,
    setFileBlob,
    filteredReq,
  } = usePaymentProvider()

  const {selected} = useListView()

  return (
    <KTCard className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle=''
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='er m-0'>Payment Request</h3>
        </div>

        <div className='card-title m-0'>
          <div className='d-flex gap-4'>
            <Link to='/dashboard'>
              <Button variant='primary' size='sm'>
                Dashboard
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <div className='card-header border-0'>
        <div className='card-title'>
          <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-250px ps-14'
              placeholder='Search user'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <div className='card-title'>
          {selected.length > 0 ? (
            <SelectList context={ListViewContext} />
          ) : (
            <>
              <PaymentListFilter />
              <button type='button' className='btn btn-light-primary me-3'>
                <KTIcon iconName='exit-up' className='fs-2' />
                Export
              </button>
              <button
                type='button'
                className='btn btn-primary'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_1'
                onClick={() => {
                  setUpdatePayment(undefined)
                  setShowModal(true)
                  setFileBlob('')
                }}
              >
                <KTIcon iconName='plus' className='fs-2' />
                Create Payment
              </button>
            </>
          )}
        </div>
      </div>
      {/* <TestTable>
        <ListPagination
          totalElement={totalElement}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          isLoading={isLoading}
        />
      </TestTable> */}

      <TableContent isLoading={isLoading} tableData={filteredReq} tableColumn={PaymentColumns}>
        <TablePagination
          totalElement={totalElement}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          isLoading={isLoading}
        />
      </TableContent>

      <PaymentRequestModal />

      <ViewPaymentReqModal />

      <PaymentDeleteModal />
    </KTCard>
  )
}

export default PaymentRequest
