import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Alert, Button, Modal} from 'react-bootstrap'

interface ErrorModalProps {
  apiError: {isError: boolean; message: string}
  setApiError: Dispatch<SetStateAction<{isError: boolean; message: string}>>
}

const ErrorModal: React.FC<ErrorModalProps> = ({apiError, setApiError}) => {
  const [show, setShow] = useState(apiError.isError)

  const handleClose = () => {
    setShow(false)
    setApiError({isError: false, message: ''})
  }

  useEffect(() => {
    setShow(apiError.isError)
  }, [apiError.isError])

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Error Message</Modal.Title>
        <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Alert variant='danger'>
          <Alert.Heading>{apiError.message}</Alert.Heading>
          <p>Change those duplicate things and try again.</p>
        </Alert>
      </Modal.Body>
    </Modal>
  )
}

export default ErrorModal
