import {Button, Table} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import CodeExampleModal from './Modal/CodeExampleModal'
import {CodeBlock} from '../../../_metronic/partials'
import {KTIcon} from '../../../_metronic/helpers'
import {IStatusCodeMeaning, statusCodeMeaning} from './core/ExampleCodeBase'
import {useState} from 'react'
import {handleClickCopy} from './ApiKey'
import {generateApiKey, multipleSms, singleSms} from './core/_helper'
import {useAuth} from '../auth'

const Documentation = () => {
  const {auth, currentUser} = useAuth()
  const [copied, setCopied] = useState(false)
  const [key, setKey] = useState<string | undefined>(currentUser?.api_key)

  const singleSmsUrlValue = `https://api.smsbangla.com.bd/smsapi?user=topreseller&password=topreseller&sender=8809617613673&msisdn=${currentUser?.user_mobile}&smstext=SMS Success`
  const multipleDestinationsUrlValue = `https://api.smsbangla.com.bd/smsapiv2?user=topreseller&password=topreseller&sender=8809617613673&msisdn=${currentUser?.user_mobile},yyyyyyyyyy,zzzzzzzzzzz&smstext=SMS Success`
  const multipleDestinationsApiKeyUrlValue = `https://api.smsbangla.com.bd/smsapiv3?apikey=${key}&sender=8809617613673&msisdn=${currentUser?.user_mobile},yyyyyyyyyy,zzzzzzzzzzz&smstext=SMS Success`
  const usernamePasswordUrlValue =
    'https://api.smsbangla.com.bd/getbalance?user=topreseller&password=topreseller'
  const usingKeyUrlValue = `https://api.smsbangla.com.bd/getbalancev3?apikey=${key}`

  const generateKey = async () => {
    const key = await generateApiKey(currentUser?.user_profile, auth?.accessToken)
    setKey(key)
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle=''
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='er m-0'>
            Documentation <span className='form-text'></span>
          </h3>
        </div>

        <div className='card-title m-0'>
          <Link to='/dashboard'>
            <Button variant='primary' size='sm'>
              Dashboard
            </Button>
          </Link>
        </div>
      </div>

      <div className='card-body'>
        <div className='d-flex align-items-center mb-4 gap-2'>
          <KTIcon iconName='key' className='fs-2 text-success' />
          <span className='text-dark fs-4 fw-bold'>Your API Key:</span>
        </div>
        <div className='d-flex align-items-center gap-2 bg-light p-5 rounded mb-8'>
          <button type='button' className='btn btn-info' onClick={generateKey}>
            Generate Key
          </button>
          <div className='input-group' style={{flex: 2}}>
            <input type='text' className='form-control text-dark' value={key} disabled />
            <div className='input-group-text' style={{padding: 0}}>
              <button
                type='button'
                className='btn btn-primary'
                style={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0}}
                onClick={() => handleClickCopy(currentUser?.api_key, setCopied)}
                disabled={copied}
              >
                Copy
              </button>
            </div>
          </div>
        </div>
        <button
          type='button'
          className='btn btn-success btn-sm'
          data-bs-toggle='modal'
          data-bs-target='#kt_modal_1'
        >
          See code examples
        </button>

        <div className='mt-8'>
          <div className='d-flex align-items-center mb-4 gap-2'>
            <KTIcon iconName='pencil' className='fs-2 text-success' />
            <span className='text-dark fs-4 fw-bold'>API URL (GET & POST):</span>
          </div>
          <p className='fs-4 fw-semibold mx-6'>
            {`https://api.smsbangla.com.bd/smsapiv3?apikey=${key}&sender=(Approved
            Sender ID)&msisdn=${currentUser?.user_mobile}&smstext=(Message Content)`}
          </p>
        </div>

        <div className='mt-8'>
          <div className='d-flex align-items-center gap-2'>
            <KTIcon iconName='pencil' className='fs-2 text-success' />
            <div>
              <span className='text-dark fs-4 fw-bold'>Send message using plain HTTP</span>
              <p className='fs-6'>Below parameters required before sending message</p>
            </div>
          </div>
          <div className='mx-6'>
            <div className='d-flex align-items-center'>
              <i className='fa fa-genderless text-secondary fs-1'></i>
              <span className='fs-6 fw-bold text-gray-800 ps-3'>User ID</span>
            </div>
            <div className='d-flex align-items-center'>
              <i className='fa fa-genderless text-secondary fs-1'></i>
              <span className='fs-6 fw-bold text-gray-800 ps-3'>Password</span>
            </div>
            <div className='d-flex align-items-center'>
              <i className='fa fa-genderless text-secondary fs-1'></i>
              <span className='fs-6 fw-bold text-gray-800 ps-3'>Sender ID</span>
            </div>
            <div className='d-flex align-items-center'>
              <i className='fa fa-genderless text-secondary fs-1'></i>
              <span className='fs-6 fw-bold text-gray-800 ps-3'>SmS Text</span>
            </div>
            <div className='d-flex align-items-center'>
              <i className='fa fa-genderless text-secondary fs-1'></i>
              <span className='fs-6 fw-bold text-gray-800 ps-3'>Type</span>
            </div>
            <code className='fs-6 d-inline-block m-2'>
              For Long sms below additional parameter required
            </code>
            <div className='d-flex align-items-center'>
              <i className='fa fa-genderless text-secondary fs-1'></i>
              <span className='fs-6 fw-bold text-gray-800 ps-3'>SmsFormat</span>
            </div>
            <code className='fs-6 d-inline-block m-2'>
              For unicode sms below additional parameter required
            </code>
          </div>
        </div>

        <div className='mt-8'>
          <div className='d-flex align-items-center gap-2'>
            <KTIcon iconName='category' className='fs-2 text-success' />
            <div>
              <span className='text-dark fs-4 fw-bold'>Parametres Details</span>
            </div>
          </div>
          <div className='mx-6'>
            <div className='mt-4'>
              <p className='text-dark fs-6 fw-bold'>1. User Validation</p>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>User</th>
                    <th>User Password</th>
                    <th>API-Key</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Merchant user name for panel login, must be http encoded format </td>
                    <td>Merchant password for panel login, must be http encoded format </td>
                    <td>Client API key</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className='mt-4'>
              <p className='text-dark fs-6 fw-bold'>2. Message</p>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>Sender</th>
                    <th>SMS Text</th>
                    <th>Type</th>
                    <th>SMS Format</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Dynamic sender ID available in the panel</td>
                    <td>
                      Message body must be http encoded. For single ASCII sms it must be within 160
                      characters and for Unicode sms it must be 70 characters. Must be http encoded
                      format
                    </td>
                    <td>
                      For long sms value will be long and for single sms this parameter not required
                      Example type=long{' '}
                    </td>
                    <td>
                      For Unicode sms use 8 and for ASCII this parameter not required
                      Example:smsformat=8 (for Unicode)
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className='mt-4'>
              <p className='text-dark fs-6 fw-bold'>3. Destination</p>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>MSISDN</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Message recipient address Example: msisdn=88019XXXXXXXX for multiple SMS
                      msisdn=88019XXXXXXXX,88017XXXXXXXX....
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <div className='mt-8'>
          <div className='d-flex align-items-center mb-4 gap-2'>
            <KTIcon iconName='key' className='fs-2 text-success' />
            <span className='text-dark fs-4 fw-bold'>API Request</span>
          </div>
          <div className='mx-6'>
            <div className='mb-8'>
              <span className='text-dark fs-6 fw-medium'>API for single SMS :</span>
              <div className='input-group mt-2'>
                <input
                  type='text'
                  className='form-control text-dark'
                  value={singleSmsUrlValue}
                  disabled
                />
                <div className='input-group-text' style={{padding: 0}}>
                  <button
                    type='button'
                    className='btn btn-primary'
                    style={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0}}
                    onClick={() => handleClickCopy(singleSmsUrlValue, setCopied)}
                    disabled={copied}
                  >
                    Copy
                  </button>
                </div>
              </div>
            </div>
            <div className='mb-8'>
              <span className='text-dark fs-6 fw-medium'>API for multiple destinations :</span>
              <div className='input-group mt-2'>
                <input
                  type='text'
                  className='form-control text-dark'
                  value={multipleDestinationsUrlValue}
                  disabled
                />
                <div className='input-group-text' style={{padding: 0}}>
                  <button
                    type='button'
                    className='btn btn-primary'
                    style={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0}}
                    onClick={() => handleClickCopy(multipleDestinationsUrlValue, setCopied)}
                    disabled={copied}
                  >
                    Copy
                  </button>
                </div>
              </div>
            </div>
            <div className='mb-8'>
              <span className='text-dark fs-6 fw-medium'>
                API for multiple destinations using api key :
              </span>
              <div className='input-group mt-2'>
                <input
                  type='text'
                  className='form-control text-dark'
                  value={multipleDestinationsApiKeyUrlValue}
                  disabled
                />
                <div className='input-group-text' style={{padding: 0}}>
                  <button
                    type='button'
                    className='btn btn-primary'
                    style={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0}}
                    onClick={() => handleClickCopy(multipleDestinationsApiKeyUrlValue, setCopied)}
                    disabled={copied}
                  >
                    Copy
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='mt-8'>
          <div className='d-flex align-items-center mb-4 gap-2'>
            <KTIcon iconName='pencil' className='fs-2 text-success' />
            <span className='text-dark fs-4 fw-bold'>API Responses</span>
          </div>
          <div className='mx-6'>
            <div className='mt-4'>
              <span className='text-dark fs-6 fw-medium'>
                Successful response in JSON format for single SMS
              </span>
              <CodeBlock code={singleSms} language='javascript' />
            </div>
            <div className='mt-4'>
              <span className='text-dark fs-6 fw-medium'>
                Successful response in JSON format for Multiple Destinations SMS
              </span>
              <CodeBlock code={multipleSms} language='javascript' />
            </div>
          </div>
        </div>

        <div className='mt-8'>
          <div className='d-flex align-items-center mb-4 gap-2'>
            <KTIcon iconName='pencil' className='fs-2 text-success' />
            <span className='text-dark fs-4 fw-bold'>API Response Details</span>
          </div>
          <div className='mx-6'>
            <span className='text-dark fs-6 fw-medium mb-2 d-block'>
              Successful Response JSON format for single SMS
            </span>
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Id</th>
                  <th>MSISDN</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    It will inform the status whether it is SUCCESS or FAIL.Check Status Code
                    Meaning
                  </td>
                  <td>For each SMS, system will provide a uniq ID</td>
                  <td>Client provided MSISDN will be here</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <div className='mt-8'>
          <div className='d-flex align-items-center mb-4 gap-2'>
            <KTIcon iconName='pencil' className='fs-2 text-success' />
            <span className='text-dark fs-4 fw-bold'>Balance Check API</span>
          </div>
          <div className='mx-6'>
            <p className='text-dark fs-6 fw-bold'>Parameter Details</p>
            <Table striped bordered>
              <thead>
                <tr>
                  <th>User</th>
                  <th>User Password</th>
                  <th>API-Key</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Merchant user name for panel login, must be http encoded format </td>
                  <td>Merchant password for panel login, must be http encoded format </td>
                  <td>Client API key</td>
                </tr>
              </tbody>
            </Table>
            <div className='mb-8 mx-6'>
              <span className='text-dark fs-6 fw-medium'>
                API for Balance Check using User Name & Passwoard :
              </span>
              <div className='input-group mt-2'>
                <input
                  type='text'
                  className='form-control text-dark'
                  value={usernamePasswordUrlValue}
                  disabled
                />
                <div className='input-group-text' style={{padding: 0}}>
                  <button
                    type='button'
                    className='btn btn-primary'
                    style={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0}}
                    onClick={() => handleClickCopy(usernamePasswordUrlValue, setCopied)}
                  >
                    Copy
                  </button>
                </div>
              </div>
            </div>
            <div className='mb-8 mx-6'>
              <span className='text-dark fs-6 fw-medium'>API for Balance Check using Key :</span>
              <div className='input-group mt-2'>
                <input
                  type='text'
                  className='form-control text-dark'
                  value={usingKeyUrlValue}
                  disabled
                />
                <div className='input-group-text' style={{padding: 0}}>
                  <button
                    type='button'
                    className='btn btn-primary'
                    style={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0}}
                    onClick={() => handleClickCopy(usingKeyUrlValue, setCopied)}
                  >
                    Copy
                  </button>
                </div>
              </div>
            </div>
            <div className='mt-4'>
              <p className='text-dark fs-6 fw-bold'>API Responses</p>
              <span className='text-dark fs-6 fw-medium'>
                Successful response in json format for single SMS
              </span>
              <CodeBlock code={`{"response":"xxxx.xx"}`} language='javascript' />
            </div>
          </div>
        </div>
        <div className='mt-8'>
          <div className='d-flex align-items-center mb-4 gap-2'>
            <KTIcon iconName='pencil' className='fs-2 text-success' />
            <span className='text-dark fs-4 fw-bold'>Status Code Meaning</span>
          </div>
          <Table striped bordered className='mx-6'>
            <thead>
              <tr>
                <th>Status Code</th>
                <th>Meaning</th>
              </tr>
            </thead>
            <tbody>
              {statusCodeMeaning.map((item: IStatusCodeMeaning) => {
                return (
                  <tr key={item.id}>
                    <td>{item.statusCode}</td>
                    <td>{item.meaning}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      </div>

      <CodeExampleModal apikey={key} />
    </div>
  )
}

export default Documentation
