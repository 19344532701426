import {Context, FC, useContext} from 'react'
import {HeaderProps} from 'react-table'

// Define the expected properties for SelectionHeader
export interface SelectionHeaderProps<T extends object> {
  tableProps: HeaderProps<T>
  context: Context<any>
}

const SelectionHeader: FC<SelectionHeaderProps<any>> = ({tableProps, context}) => {
  const {isAllSelected, onSelectAll} = useContext(context)

  return (
    <th {...tableProps.column.getHeaderProps()} className='w-10px pe-2'>
      <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
        <input
          className='form-check-input'
          type='checkbox'
          data-kt-check={isAllSelected}
          data-kt-check-target='#kt_table_users .form-check-input'
          checked={isAllSelected}
          onChange={onSelectAll}
        />
      </div>
    </th>
  )
}

export default SelectionHeader
