import {IExampleCodeBaseArray} from './_model'

export const exampleCodeBaseArray = (
  apiKey: string | undefined,
  msisDn: string | undefined
): IExampleCodeBaseArray => {
  return [
    {
      id: 0,
      language: 'php',
      languageType: 'PHP',
      codes: [
        `
            public function send()
                {
                    $msg = 'SMS Success';
                    $apikey = '${apiKey}';
                    $sender = '8809617613673';
                    $msisdn = '${msisDn}';
                
                    // API url //
                    $url = "https://api.smsbangla.com.bd/smsapiv3";
                
                    //passing parameters
                    $data = array(
                        'msisdn' => $msisdn,
                        'smstext' => $msg,
                        'apikey' => $apikey,
                        'sender' => $sender
                    );
                    $ch = curl_init();
                    curl_setopt($ch, CURLOPT_URL, $url);
                    curl_setopt($ch, CURLOPT_SSL_VERIFYHOST, 0);
                    curl_setopt($ch, CURLOPT_SSL_VERIFYPEER, 0);
                    curl_setopt($ch, CURLOPT_ENCODING, '');
                    curl_setopt($ch, CURLOPT_POSTFIELDS, http_build_query($data));
                    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
                    $smsresult = curl_exec($ch);
                
                    // Result //
                    echo $smsresult;
                
                    // Error Display //
                    echo curl_error($ch);
                }
            `,
      ],
    },
    {
      id: 1,
      language: 'javascript',
      languageType: 'NODE JS',
      codes: [
        `
                const send = (req, res) => {
        
                    let msg = 'SMS Success';
                    let apikey = '${apiKey}';
                    let sender = '8809617613673';
                    let msisdn = '${msisDn}';
                
                    fetch(\`https://api.smsbangla.com.bd/smsapiv3?apikey=\${apikey}&sender=\${sender}&msisdn=\${msisdn}&smstext=\${msg}\`, {
                        method: 'GET',
                    })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        console.log("responseJson",responseJson);
                    })
                    .catch((error) => {
                        res.status(400).json("error");
                    });
        
                }
                `,
      ],
    },
    {
      id: 2,
      language: 'javascript',
      languageType: 'JAVASCRIPT',
      codes: [
        `
                // Fetch Request //
    
                let msg = 'SMS Success';
                let apikey = '${apiKey}';
                let sender = '8809617613673';
                let msisdn = '${msisDn}';    
            
                async function send() {
                    try {
                        const response = await fetch(\`https://api.smsbangla.com.bd/smsapiv3?apikey=\${apikey}&sender=\${sender}&msisdn=\${msisdn}&smstext=\${msg}\`);
                        const data = await response.json();
                        console.log(data);
                    } catch (error) {
                        console.error(error);
                    }
                }
            
                send();
                `,
        `
                // Axios Request //
    
                import axios from "axios";
                axios
                .get(\`https://api.smsbangla.com.bd/smsapiv3?apikey=${apiKey}&sender=8809617613673&${msisDn}=msisdn&smstext=SMS Success\`)
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => console.error(error));
                `,
        `
                // XMLHttpRequest //
    
                const xhr = new XMLHttpRequest();
                xhr.open('GET', \`https://api.smsbangla.com.bd/smsapiv3?apikey=${apiKey}&sender=8809617613673&msisdn=${msisDn}&smstext=SMS Success\`);
                xhr.onload = function() {
                    if (xhr.status === 200) {
                        const data = JSON.parse(xhr.responseText);
                        console.log(data);
                    } else {
                        onsole.error(\`Error: \${xhr.status}\`);
                    }
                };
                xhr.onerror = function() {
                    console.error('Request error');
                };
                xhr.send();
                `,
      ],
    },
    {
      id: 3,
      language: 'javascript',
      languageType: 'JQUERY',
      codes: [
        `
            $msg = 'SMS Success';
            $apikey = '${apiKey}';
            $sender = '8809617613673';
            $msisdn = '${msisDn}';
            
                $.ajax({
                    url: \`https://api.smsbangla.com.bd/smsapiv3?apikey=\${apikey}&sender=\${sender}&msisdn=\${msisdn}&smstext=\${msg}\`,
                    method: 'GET',
                    success: function(data) {
                        console.log(data);
                    },
                    error: function(error) {
                        console.error(error);
                    }
                });
            `,
      ],
    },
    {
      id: 4,
      language: 'dart',
      languageType: 'DART',
      codes: [
        `
                var request = http.Request('GET', Uri.parse(\`https://api.smsbangla.com.bd/smsapiv3?apikey=${apiKey}&sender=8809617613673&msisdn=${msisDn}&smstext=SMS Success\`));
    
    
                http.StreamedResponse response = await request.send();
        
                if (response.statusCode == 200) {
                    print(await response.stream.bytesToString());
                }
                else {
                    print(response.reasonPhrase);
                }
                `,
        `
                var dio = Dio();
                var response = await dio.request(
                    \`https://api.smsbangla.com.bd/smsapiv3?apikey=${apiKey}&sender=8809617613673&msisdn=${msisDn}&smstext=SMS Success\`,
                    options: Options(
                        method: 'GET',
                    ),
                );
        
                if (response.statusCode == 200) {
                    print(json.encode(response.data));
                }
                else {
                    print(response.statusMessage);
                }
                `,
      ],
    },
    {
      id: 5,
      language: 'python',
      languageType: 'PYTHON',
      codes: [
        `
                import requests
        
                url = \`https://api.smsbangla.com.bd/smsapiv3?apikey=${apiKey}&sender=8809617613673&msisdn=${msisDn}&smstext=SMS Success\`
        
                payload = {}
                headers = {}
        
                response = requests.request("GET", url, headers=headers, data=payload)
        
                print(response.text)
                `,
        `
                import http.client
    
                conn = http.client.HTTPSConnection("labapi.smlbulksms.com")
                payload = ''
                headers = {}
                conn.request("GET", \`https://api.smsbangla.com.bd/smsapiv3?apikey=${apiKey}&sender=8809617613673&msisdn=${msisDn}&smstext=SMS Success\`, payload, headers)
                res = conn.getresponse()
                data = res.read()
                print(data.decode("utf-8"))
                `,
      ],
    },
    {
      id: 6,
      language: 'java',
      languageType: 'JAVA',
      codes: [
        `
                OkHttpClient client = new OkHttpClient().newBuilder()
                .build();
                MediaType mediaType = MediaType.parse("text/plain");
                RequestBody body = RequestBody.create(mediaType, "");
                Request request = new Request.Builder()
                .url(\`https://api.smsbangla.com.bd/smsapiv3?apikey=${apiKey}&sender=8809617613673&msisdn=${msisDn}&smstext=SMS Success\`)
                .method("GET", body)
                .build();
                Response response = client.newCall(request).execute();
                `,
      ],
    },
    {
      id: 7,
      language: 'go-native',
      languageType: 'GO-NATIVE',
      codes: [
        `
                package main
    
                import (
                import { exampleCodeBase } from './ExampleCodeBase';
    "fmt"
                "net/http"
                "io/ioutil"
                )
    
                func main() {
    
                    url := \`https://api.smsbangla.com.bd/smsapiv3?apikey=${apiKey}&sender=8809617613673&msisdn=${msisDn}&smstext=SMS Success\`
                    method := "GET"
    
                    client := &http.Client {
                    }
                    req, err := http.NewRequest(method, url, nil)
    
                    if err != nil {
                        fmt.Println(err)
                        return
                    }
                    res, err := client.Do(req)
                    if err != nil {
                        fmt.Println(err)
                        return
                    }
                    defer res.Body.Close()
    
                    body, err := ioutil.ReadAll(res.Body)
                    if err != nil {
                        fmt.Println(err)
                        return
                    }
                    fmt.Println(string(body))
    
                }
                `,
      ],
    },
    {
      id: 8,
      language: 'kotlin',
      languageType: 'KOTLIN',
      codes: [
        `
                val client = OkHttpClient()
                val request = Request.Builder()
                    .url(\`https://api.smsbangla.com.bd/smsapiv3?
                    apikey=${apiKey}&sender=8809617613673&msisdn=${msisDn}&smstext=SMS Success\`)
                    .build()
                val response = client.newCall(request).execute()
                `,
      ],
    },
    {
      id: 9,
      language: 'swift',
      languageType: 'SWIFT',
      codes: [
        `
                var request = URLRequest(url: URL(string: \`https://api.smsbangla.com.bd/smsapiv3?
                apikey=${apiKey}&sender=8809617613673&msisdn=${msisDn}&smstext=SMS Success\`)!,timeoutInterval: Double.infinity)
                request.httpMethod = "GET"
    
                let task = URLSession.shared.dataTask(with: request) { data, response, error in
                guard let data = data else {
                    print(String(describing: error))
                    return
                }
                    print(String(data: data, encoding: .utf8)!)
                }
    
                task.resume()
                `,
      ],
    },
  ]
}
export interface IStatusCodeMeaning {
  id: number
  statusCode: string
  meaning: string
}

export const statusCodeMeaning: IStatusCodeMeaning[] = [
  {
    id: 0,
    statusCode: '0',
    meaning: 'Success',
  },
  {
    id: 101,
    statusCode: '101',
    meaning: 'Message length error',
  },
  {
    id: 102,
    statusCode: '102',
    meaning: 'Invalid Sender',
  },
  {
    id: 103,
    statusCode: '103',
    meaning: 'Authentication Failure',
  },
  {
    id: 104,
    statusCode: '104',
    meaning: 'User Invalid',
  },
  {
    id: 105,
    statusCode: '105',
    meaning: 'Wrong MSISDN',
  },
  {
    id: 106,
    statusCode: '106',
    meaning: 'Wrong API Key',
  },
  {
    id: 107,
    statusCode: '107',
    meaning: 'User Suspended',
  },
  {
    id: 1000,
    statusCode: '1000',
    meaning: 'Low Balance',
  },
  {
    id: 2300,
    statusCode: '2300',
    meaning: 'Destination Route Error',
  },
  {
    id: 3300,
    statusCode: '3300',
    meaning: 'Internal Error',
  },
  {
    id: 3000,
    statusCode: '2000, 3000, 4000',
    meaning: 'Destination provider not available',
  },
]
