import {BASE_URL} from '../../config'

export const GET_ALL_OPTIONS_URL = `${BASE_URL}/api/protect/getAllOptions`
export const UPDATE_OPTIONS_URL = `${BASE_URL}/api/protect/updateOption`

export interface EmailOptions {
  createdAt: string
  id: number
  optionsName: string
  optionsValue?: string
}

export interface PaymentOptions {
  id: number
  optionsName: string
  optionsValue?: string
}

export interface URLOptions {
  id: number
  optionsName: string
  optionsValue?: string
}

export interface OtherOptions {
  id: number
  optionsName: string
  optionsValue?: string
}

export interface SettingOptions {
  createdAt?: string
  id: number
  optionsName: string
  optionsValue?: string
}

export interface OptionsObject {
  // [key: string]: string | undefined | number | boolean
  [key: string]: any
}

export interface AccountInfo {
  account_name: string | undefined
  account_number: string | undefined
  bank_address: string | undefined
  bank_name: string | undefined
  branch_name: string | undefined
  routing_no: string | undefined
  swift_id: string | undefined
}

export interface InvoiceFooter {
  contact: string | undefined
  email: string | undefined
  section_one: string | undefined
  section_two?: AccountInfo
}

export interface InvoiceHeader {
  address: string | undefined
  bin_no: string | undefined
  company_name: string | undefined
  tin_no: string | undefined
}

export interface Invoice {
  invoice_header: InvoiceHeader
  invoice_footer: InvoiceFooter
}

export interface InvoiceState {
  id: string
  optionsName: string
  optionsValue: Invoice
}
