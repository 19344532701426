/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {ListViewContext, useListView} from '../../../utils/contextProvider/ListViewProvider'
import SelectList from '../../../utils/components/SelectList'
import {SearchFormFIlterProps} from '../../../modules/Billing/core/_model'
import SearchFormFIlter from '../../../modules/Billing/TransactionReports/SearchFormFIlter'

const TableHeader: FC<SearchFormFIlterProps> = ({
  startTime,
  endTime,
  itemsPerPage,
  setStartTime,
  setEndTime,
  handleSearchSubmit,
  setItemsPerPage,
  setCurrentPage,
}) => {
  const {selected} = useListView()
  console.log(selected)

  return (
    <div>
      {selected.length > 0 ? (
        <SelectList context={ListViewContext} />
      ) : (
        <>
          <SearchFormFIlter
            startTime={startTime}
            endTime={endTime}
            itemsPerPage={itemsPerPage}
            setStartTime={setStartTime}
            setEndTime={setEndTime}
            handleSearchSubmit={handleSearchSubmit}
            setItemsPerPage={setItemsPerPage}
            setCurrentPage={setCurrentPage}
          />
          <button
            className='btn btn-light-primary'
            type='button'
            id='dropdownMenuButton'
            data-bs-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            <KTIcon iconName='exit-up' className='fs-2' />
            Export
          </button>
          <ul
            className='dropdown-menu menu menu-sub menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4'
            aria-labelledby='dropdownMenuButton'
          >
            <div className='menu-item px-3'>
              <a className='menu-link px-3'>CSV</a>

              <a className='menu-link px-3'>PDF</a>
            </div>
          </ul>
        </>
      )}
    </div>
  )
}

export default TableHeader
