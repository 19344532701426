import {Column, HeaderProps} from 'react-table'
import {ICategory} from '../core/_model'
import SelectionHeader from '../../../utils/components/Table/SelectionHeader'
import {ListViewContext} from '../../../utils/contextProvider/ListViewProvider'
import {SelectionCell} from '../../../utils/components/Table/SelectionCell'
import {PropsWithChildren} from 'react'
import {CustomHeader} from '../../../utils/components/Table/CustomHeader'
import CategoryAction from './CategoryAction'

export const CategoryColumn: ReadonlyArray<Column<ICategory>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props as any} context={ListViewContext} />,
    id: 'selection',
    Cell: ({...props}) => (
      <SelectionCell id={props.data[props.row.index].id} context={ListViewContext} />
    ),
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Category' className='min-w-150px' />
    ),
    id: 'category',
    accessor: 'category',
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Description' className='min-w-150px' />
    ),
    id: 'description',
    accessor: 'description',
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Active' className='min-w-150px' />
    ),
    id: 'active',
    accessor: 'active',
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Order' className='min-w-150px' />
    ),
    id: 'order',
    accessor: 'order',
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Action' className='min-w-150px' />
    ),
    id: 'action',
    Cell: () => <CategoryAction />,
  },
]
