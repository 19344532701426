import React, {Dispatch, SetStateAction, useRef, useState} from 'react'
import useSenderIdsOptions, {
  ISenderIdsOption,
  ISenderIdsOptionsArray,
} from '../../../hooks/useSenderIdsOptions'
import {useThemeMode} from '../../../../_metronic/partials'
import Select from 'react-select'
import toast from 'react-hot-toast'
import {ISearchParams} from '../../../modules/LiveReports/core/_model'

export interface ISMSChannelOpt {
  value: string
  label: string
}

export interface IStatusOpt {
  value: number
  label: string
}

export interface ISMSChannelOptArray extends Array<ISMSChannelOpt> {}

export interface IStatusOptArray extends Array<IStatusOpt> {}

export interface ISearchFormProps {
  onSearchFormSubmit: (searchData: ISearchParams) => void
  onDownload?: (searchData: ISearchParams, type: string) => void
  require?: boolean
  itemsPerPage: number
  setItemsPerPage: Dispatch<SetStateAction<number>>
  setCurrentPage: Dispatch<SetStateAction<number>>
}

const smsChannelOptions: ISMSChannelOptArray = [
  {value: 'Panel', label: 'Panel'},
  {value: 'API', label: 'API'},
]

const statusOptions: IStatusOptArray = [
  {value: 0, label: 'Send'},
  {value: 1, label: 'Rejected'},
]

const SearchFilterFormModal: React.FC<ISearchFormProps> = ({
  onSearchFormSubmit,
  onDownload,
  require,
  itemsPerPage,
  setItemsPerPage,
  setCurrentPage,
}) => {
  const {senderIdsOptions} = useSenderIdsOptions()
  const {mode} = useThemeMode()
  const [selectedValues, setSelectedValues] = useState<ISenderIdsOptionsArray[]>([])
  const [selectedSenderId, setSelectedSenderId] = useState<number[]>([])
  const [selectChannel, setSelectChannel] = useState<ISMSChannelOpt | undefined>(undefined)
  const [selectStatus, setSelectStatus] = useState<IStatusOpt | undefined>(undefined)
  const [msisdn, setMsisdn] = useState<number | null>(null)
  const [startTime, setStartTime] = useState<string>('')
  const [endTime, setEndTime] = useState<string>('')

  const isRequireFilled = selectChannel && startTime && endTime ? true : false
  console.log(isRequireFilled)

  const handleSelectChange = (selectedOptions: any) => {
    if (selectedOptions) {
      const values = selectedOptions.map((option: ISenderIdsOption) => option.value)
      const id = selectedOptions.map((option: ISenderIdsOption) => option.id)
      setSelectedValues(values)
      setSelectedSenderId(id)
    } else {
      setSelectedValues([])
    }
  }

  const handleSelectChangeChannel = (selectOption: any) => {
    setSelectChannel(selectOption)
  }

  const handleSelectChangeStatus = (selectOption: any) => {
    setSelectStatus(selectOption)
  }

  const handleApplyFilter = () => {
    const formData: ISearchParams = {
      senderIds: selectedSenderId,
      status: selectStatus?.value,
      channel: selectChannel?.value.toLowerCase(),
      msisdn: msisdn,
      startTime: startTime,
      endTime: endTime,
    }

    onSearchFormSubmit(formData)
  }

  const handleDownload = async (type: string) => {
    toast.loading('Getting Download file..')
    const formData: ISearchParams = {
      senderIds: selectedSenderId,
      status: selectStatus?.value,
      channel: selectChannel?.value.toLowerCase(),
      msisdn: msisdn,
      startTime: startTime,
      endTime: endTime,
    }

    if (onDownload) {
      onDownload(formData, type)
    }
  }

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '8px',
      padding: '4px',
      border: 0,
      backgroundColor: mode === 'light' ? '#f9f9f9' : '#1b1b29',
      borderColor: state.isFocused ? '#6c63ff' : '#d1d1d1',
      boxShadow: state.isFocused ? '0 0 0 1px #6c63ff' : null,
      '&:hover': {
        borderColor: '#6c63ff',
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#6c63ff' : null,
      color: state.isSelected ? '#ffffff' : null,
      '&:hover': {
        backgroundColor: '#6c63ff',
        color: '#ffffff',
      },
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '8px',
      backgroundColor: mode === 'light' ? '#f8f0f4' : '#1b1b29',
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)', // Add box shadow
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: mode === 'light' ? '#5e6278' : '#92929f', // Change color of selected value
    }),
  }

  return (
    <div className='modal-dialog'>
      <div className='modal-content'>
        <div className='modal-header'>
          <div className='fs-5 text-gray-900 fw-bolder'>Filter Options</div>
        </div>

        <div className='modal-content'>
          <div className='px-7 py-5'>
            <div className='mb-4'>
              <label className='form-label fs-6 fw-bold'>Show Entries:</label>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select entries'
                data-hide-search='true'
                name='entries'
                id='entries'
                value={itemsPerPage}
                onChange={(e) => {
                  setCurrentPage(1)
                  setItemsPerPage(parseInt(e.target.value))
                }}
              >
                <option value='10'>10</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
                <option value='250'>250</option>
              </select>
            </div>

            <div className='mb-4'>
              <label className='form-label fs-6 fw-bold'>Select Sender ID:</label>
              <Select
                isMulti
                options={senderIdsOptions}
                value={senderIdsOptions.filter((option) => selectedValues.includes(option.value))}
                onChange={handleSelectChange}
                isSearchable
                placeholder='Select Sender Ids'
                styles={customStyles}
              />
            </div>

            <div className='mb-4'>
              <label className='form-label fs-6 fw-bold'>
                <span className='required'>Select SMS Channel:</span>
              </label>
              <Select
                options={smsChannelOptions}
                value={selectChannel}
                onChange={handleSelectChangeChannel}
                placeholder='Select SMS Channel'
                required={require}
                styles={customStyles}
              />
            </div>

            <div className='mb-4'>
              <label className='form-label fs-6 fw-bold'>
                <span>Status:</span>
              </label>
              <Select
                options={statusOptions}
                value={selectStatus}
                onChange={handleSelectChangeStatus}
                placeholder='Select Status'
                styles={customStyles}
              />
            </div>

            <div className='mb-4'>
              <label className='form-label fs-6 fw-bold'>
                <span className='required'>Start Time:</span>
              </label>
              <input
                type='datetime-local'
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                className='form-control'
                min={new Date().toISOString()}
              />
            </div>

            <div className='mb-4'>
              <label className='form-label fs-6 fw-bold'>
                <span className='required'>End Time:</span>
              </label>
              <input
                type='datetime-local'
                className='form-control'
                min={new Date().toISOString()}
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
              />
            </div>

            <div className='mb-4'>
              <label className='form-label fs-6 fw-bold'>
                <span>MSSISDN:</span>
              </label>
              <input
                type='number'
                className='form-control'
                value={msisdn === null ? '' : msisdn}
                onChange={(e) => {
                  const value = e.target.value
                  setMsisdn(value === '' ? null : parseInt(value))
                }}
                placeholder='Enter MSISDN'
              />
            </div>

            <div className='d-flex justify-content-end'>
              <button
                type='button'
                className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                Reset
              </button>
              <button
                type='button'
                className='btn btn-primary fw-bold px-6'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={handleApplyFilter}
                disabled={!isRequireFilled}
              >
                Apply
              </button>
            </div>
          </div>
        </div>

        {/* <div className='modal-footer'>
        <button
          type='button'
          className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
          data-bs-dismiss='modal'
          aria-label='Close'
        >
          Reset
        </button>
        <button type='button' className='btn btn-primary fw-bold px-6'>
          Apply
        </button>
      </div> */}
      </div>
    </div>
  )
}

export default SearchFilterFormModal
