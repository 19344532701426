import {Column, HeaderProps} from 'react-table'
import {CustomHeader} from '../../../utils/components/Table/CustomHeader'
import {PropsWithChildren} from 'react'
import {ISMSTemplate} from '../core/_model'
import UserDefine from '../Sender/UserDefine'
import SMSTemplateAction from './SMSTemplateAction'
import SelectionHeader from '../../../utils/components/Table/SelectionHeader'
import {ListViewContext} from '../../../utils/contextProvider/ListViewProvider'
import {SelectionCell} from '../../../utils/components/Table/SelectionCell'

const SMSTemplateColumn: ReadonlyArray<Column<ISMSTemplate>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props as any} context={ListViewContext} />,
    id: 'selection',
    Cell: ({...props}) => (
      <SelectionCell id={props.data[props.row.index].id} context={ListViewContext} />
    ),
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Template Name' className='min-w-125px' />
    ),
    id: 'sender',
    accessor: 'templateName',
    // Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='SMS Text' className='min-w-125px' />
    ),
    accessor: 'smsText',
  },

  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='User' className='min-w-125px' />
    ),
    id: 'user',
    Cell: () => <UserDefine />,
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Actions' className='min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <SMSTemplateAction template={props.data[props.row.index]} />,
  },
]

export {SMSTemplateColumn}
