import {Dispatch, SetStateAction} from 'react'
import {ISearchParams} from '../../LiveReports/core/_model'
import SearchFilterFormModal from '../../../utils/components/Modal/SearchFilterFormModal'

export interface ISearchFormProps {
  onSearchFormSubmit: (searchData: ISearchParams) => void
  onDownload?: (searchData: ISearchParams, type: string) => void
  require?: boolean
  itemsPerPage: number
  setItemsPerPage: Dispatch<SetStateAction<number>>
  setCurrentPage: Dispatch<SetStateAction<number>>
}

const FilterModal: React.FC<ISearchFormProps> = ({
  onSearchFormSubmit,
  require,
  itemsPerPage,
  setItemsPerPage,
  setCurrentPage,
}) => {
  return (
    <div className='modal fade' tabIndex={-2} id='kt_modal_2'>
      <SearchFilterFormModal
        onSearchFormSubmit={onSearchFormSubmit}
        require={require}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  )
}

export default FilterModal
