import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js'
import {useContext} from 'react'
import {Line} from 'react-chartjs-2'
import {DashboardContext} from '../DashboardContext'
import CardSkeleton from '../../../utils/components/Skeleton/CardSkeleton'
import {IChartData} from '../core/_model'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const BrandedNonBrandedChart = () => {
  const contextValue = useContext(DashboardContext)
  const chartData = contextValue?.maskingInfo

  const labels = chartData?.map((data: IChartData) => data.month)
  const nonMaskingData = chartData?.map((data: IChartData) => data.nonMasking)
  const maskingData = chartData?.map((data: IChartData) => data.masking)

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Non-Masking',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 3,
        hoverBackgroundColor: 'rgba(255, 99, 132, 0.4)',
        hoverBorderColor: 'rgba(255, 99, 132, 1)',
        data: nonMaskingData,
      },
      {
        label: 'Masking',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 3,
        hoverBackgroundColor: 'rgba(54, 162, 235, 0.4)',
        hoverBorderColor: 'rgba(54, 162, 235, 1)',
        data: maskingData,
      },
    ],
  }

  const options: any = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Values',
          font: {
            size: 20,
            weight: 'bold',
          },
        },
        ticks: {
          font: {
            size: 18,
          },
        },
      },
      x: {
        title: {
          display: true,
          text: 'Months',
          font: {
            size: 20,
            weight: 'bold',
          },
        },
        ticks: {
          maxRotation: 90,
          minRotation: 25,
          autoSkip: false,
          font: {
            size: 18,
          },
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          font: {
            size: 18,
          },
        },
      },
      title: {
        display: true,
        text: 'Branded & Non-Branded SMS Count',
        font: {
          size: 20,
        },
      },
    },
  }

  if (!chartData) {
    return <CardSkeleton />
  }

  return <Line data={data} options={options} style={{width: '800px', height: '400px'}} />
}

export default BrandedNonBrandedChart
