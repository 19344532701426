/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {PropsWithChildren, useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {useAuth} from '../auth'
import axios from 'axios'
import toast from 'react-hot-toast'
import SMSDetailsModal from './Modal/SMSDetailsModal'
import TableContent from '../../utils/components/Table/TableContent'
import TablePagination from '../../utils/components/Table/TablePagination'
import {GET_TODAY_BROADCAST_URL, ITodayBroadcast, ITodayBroadcastArray} from './core/_model'
import {KTCard, KTCardBody, KTIcon} from '../../../_metronic/helpers'
import {Column, HeaderProps} from 'react-table'
import {CustomHeader} from '../../utils/components/Table/CustomHeader'
import {formatDate} from '../../utils/helperFunction/_helper'
import TodayBroadCastAction from './TodayBroadCastAction'
import SelectionHeader from '../../utils/components/Table/SelectionHeader'
import {ListViewContext, ListViewProvider} from '../../utils/contextProvider/ListViewProvider'
import {SelectionCell} from '../../utils/components/Table/SelectionCell'
import TodayBroadcastHeader from './TodayBroadcastHeader'
import FormFilter from './FormFilter'
import dummyReport, {DummyReport} from './dummyReport'
import MessageCell from './MessageCell'
import FromCell from './FromCell'
import ToCell from './ToCell'

const TodayBroadCast = () => {
  const {auth, currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [todayBroadcast, setTodayBroadcast] = useState<ITodayBroadcastArray>([])
  const [filteredBroadcast, setFilteredBroadcast] = useState<ITodayBroadcastArray>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [singleBroadcast, setSingleBroadcast] = useState<ITodayBroadcast>()

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalElement, setTotalElement] = useState(0)
  const [isLastPage, setIsLastPage] = useState(false)

  const [dummyReports, setDummyReports] = useState(dummyReport.reports)
  const [filteredDummyReports, setFilteredDummyReports] = useState<DummyReport[]>([])

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)

  let params = {
    pageNumber: currentPage - 1,
    pageSize: itemsPerPage,
  }

  const getTodayBroadcast = async () => {
    try {
      setIsLoading(true)
      toast.loading('Loading Today SMS..')
      const response = await axios.get(`${GET_TODAY_BROADCAST_URL}/${currentUser?.user_profile}`, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
        params: params,
      })
      const result = await response.data
      const totalResult = await result.totalElement
      const lastPage = await result.lastpage
      const data = await result.data
      setTodayBroadcast(data ? data : [])
      setIsLastPage(lastPage)
      setTotalElement(totalResult)
      toast.dismiss()
      toast.success('Today Broadcast SMS Fetched Successfully')
      setIsLoading(false)
    } catch (error: any) {
      setIsLoading(false)
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
    }
  }

  let showPageLastEtries = !isLastPage ? currentPage * itemsPerPage : totalElement
  let showPageFirstEntries = !isLastPage
    ? showPageLastEtries + 1 - itemsPerPage
    : (currentPage - 1) * itemsPerPage + 1

  let serial: number[] = []

  for (let i = showPageFirstEntries; i <= showPageLastEtries; i++) {
    serial.push(i)
  }

  const TodayBroadCastColumn: ReadonlyArray<Column<ITodayBroadcast>> = [
    {
      Header: (props) => <SelectionHeader tableProps={props as any} context={ListViewContext} />,
      id: 'selection',
      Cell: ({...props}) => (
        <SelectionCell id={props.data[props.row.index].id} context={ListViewContext} />
      ),
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Date' className='min-w-100px' />
      ),
      id: 'date',
      Cell: ({...props}) => <>{formatDate(props.data[props.row.index].dateTime)}</>,
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='User' className='min-w-100px' />
      ),
      accessor: 'user',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='SMS Part' className='min-w-100px' />
      ),
      accessor: 'smsPart',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='SMS Type' className='min-w-100px' />
      ),
      id: 'smsType',
      Cell: ({...props}) => (
        <>{props.data[props.row.index].smsType === 1 ? 'English' : 'Unicode'}</>
      ),
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Sender' className='min-w-100px' />
      ),
      accessor: 'sender',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Count' className='min-w-100px' />
      ),
      accessor: 'count',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='SMS Text' className='min-w-100px' />
      ),
      accessor: 'smsText',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Action' className='min-w-100px' />
      ),
      id: 'action',
      Cell: ({...props}) => (
        <TodayBroadCastAction
          setSingleBroadcast={setSingleBroadcast}
          broadcast={props.data[props.row.index]}
        />
      ),
    },
  ]

  const dummyReportColumn: ReadonlyArray<Column<DummyReport>> = [
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Message' className='min-w-100px' />
      ),
      id: 'message',
      Cell: ({...props}) => <MessageCell message={props.data[props.row.index]} />,
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='From' className='min-w-120px' />
      ),
      id: 'from',
      Cell: ({...props}) => <FromCell message={props.data[props.row.index]} />,
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='To' className='min-w-120px' />
      ),
      id: 'to',
      Cell: ({...props}) => <ToCell message={props.data[props.row.index]} />,
    },
  ]

  useEffect(() => {
    document.title = 'Today Broadcast'

    getTodayBroadcast()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage])

  useEffect(() => {
    const filteredData = todayBroadcast?.filter(
      (list) =>
        list.smsText?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        list.user?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        list.sender?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        list.dateTime?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setFilteredBroadcast(filteredData)
  }, [todayBroadcast, searchTerm])

  useEffect(() => {
    const filteredData = dummyReports?.filter(
      (list) =>
        list.sender?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        list.destination?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        list.status?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        list.networkName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        list.accountName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        list.text?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setFilteredDummyReports(filteredData)
  }, [dummyReports, searchTerm])

  return (
    <ListViewProvider isLoading={isLoading} data={filteredBroadcast}>
      <div className='d-flex flex-column flex-lg-row'>
        <div className='flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0'>
          <div className='card'>
            <div className='card-header'>
              <h3 className='card-title fs-2'>Filters</h3>

              <button type='button' className='btn btn-transparent text-gray-700'>
                Reset Filters
              </button>
            </div>
            <div className='card-body'>
              <FormFilter />
            </div>
          </div>
        </div>

        <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10'>
          <div className='card'>
            <div className='card-header'>
              <div className='card-title m-0 w-100'>
                <div className='d-flex align-items-center position-relative my-1 w-100'>
                  <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                  <input
                    type='text'
                    data-kt-user-table-filter='Search Group'
                    className='form-control form-control-solid w-250px ps-14 w-md-100'
                    placeholder='Search by sender, destination, status, account name, network name'
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className='px-3'>
              <TableContent
                isLoading={isLoading}
                tableData={filteredDummyReports}
                tableColumn={dummyReportColumn}
              >
                <TablePagination
                  totalElement={totalElement}
                  itemsPerPage={itemsPerPage}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  isLoading={isLoading}
                />
              </TableContent>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='card mb-5 mb-xl-10 pb-4'>
        <div className='card-header border-0 d-flex justify-content-between align-items-center'>
          <div className='card-title'>
            <h3 className='er mb-0'>Today Broadcast</h3>
          </div>
          <Link to='/dashboard'>
            <Button variant='primary' size='sm'>
              Dashboard
            </Button>
          </Link>
        </div>
        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='Search Group'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <div className='card-title m-0'>
            <TodayBroadcastHeader
              isLoading={isLoading}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
        <TableContent
          isLoading={isLoading}
          tableData={filteredBroadcast}
          tableColumn={TodayBroadCastColumn}
        >
          <TablePagination
            totalElement={totalElement}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            isLoading={isLoading}
          />
        </TableContent>

        <SMSDetailsModal broadcast={singleBroadcast} setBroadcast={setSingleBroadcast} />
      </div> */}
    </ListViewProvider>
  )
}

export default TodayBroadCast
