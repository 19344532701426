import {Column, HeaderProps} from 'react-table'
import {PropsWithChildren} from 'react'
import {IUser} from './core/_model'
import {CustomHeader} from '../../utils/components/Table/CustomHeader'
import Username from './Username'
import {setUserType} from './core/helper'
import UserKycFormButton from './UserKycFormButton'
import {formatDate} from '../../utils/helperFunction/_helper'
import UserAction from './UserAction'
import SelectionHeader from '../../utils/components/Table/SelectionHeader'
import {UserListSelectContext} from './UserListSelectProvider'
import {SelectionCell} from '../../utils/components/Table/SelectionCell'

const UserListColumn: ReadonlyArray<Column<IUser>> = [
  {
    Header: (props) => (
      <SelectionHeader tableProps={props as any} context={UserListSelectContext} />
    ),
    id: 'selection',
    Cell: ({...props}) => (
      <SelectionCell id={props.data[props.row.index].id} context={UserListSelectContext} />
    ),
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Username' className='min-w-125px' />
    ),
    id: 'username',
    Cell: ({...props}) => <Username user={props.data[props.row.index]} />,
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Type' className='min-w-125px' />
    ),
    id: 'type',
    Cell: ({...props}) => <span>{setUserType(props.data[props.row.index].user_profile)}</span>,
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => (
      <span>
        {props.data[props.row.index].username ? props.data[props.row.index].username : 'Unknown'}
      </span>
    ),
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Balance' className='min-w-125px' />
    ),
    id: 'balance',
    Cell: ({...props}) => (
      <span>
        {props.data[props.row.index].userbalance
          ? props.data[props.row.index].userbalance
          : 'Not Found'}
      </span>
    ),
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'status',
    accessor: 'user_status',
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Email' className='min-w-125px' />
    ),
    id: 'email',
    accessor: 'user_email',
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Contact No' className='min-w-125px' />
    ),
    id: 'contactNo',
    accessor: 'user_mobile',
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='KYC Form' className='min-w-125px' />
    ),
    id: 'kycform',
    Cell: ({...props}) => <UserKycFormButton user={props.data[props.row.index]} />,
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Last Recharge Date' className='min-w-125px' />
    ),
    id: 'lastRechargeDate',
    accessor: 'last_recharge_date',
    Cell: ({...props}) => <p>{formatDate(props.data[props.row.index].last_recharge_date)}</p>,
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Action' className='min-w-125px' />
    ),
    id: 'action',
    Cell: ({...props}) => <UserAction user={props.data[props.row.index]} />,
  },
]

export {UserListColumn}
