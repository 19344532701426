import {IUser} from './_model'

export const setUserType = (user_profile: number) => {
  let type: string = ''

  if (user_profile === 1) {
    type = 'Admin'
  } else if (user_profile === 2) {
    type = 'Reseller'
  } else if (!user_profile) {
    type = 'Unknown'
  } else {
    type = 'Client'
  }

  return type
}
