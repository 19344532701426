import clsx from 'clsx'
import {FC} from 'react'
import {Row} from 'react-table'

interface Props<T extends object> {
  row: Row<T>
}

const CustomRow: FC<Props<any>> = ({row}) => (
  <tr {...row.getRowProps()}>
    {row.cells.map((cell) => {
      return (
        <td
          {...cell.getCellProps()}
          className={clsx({
            'text-end min-w-100px relative': cell.column.id === 'actions relative',
          })}
        >
          {cell.render('Cell')}
        </td>
      )
    })}
  </tr>
)

export {CustomRow}
