import {configureStore} from '@reduxjs/toolkit'

import authReducer from './modules/auth/authSlice'
import sliceReducer from './PriceSlice'

export const store = configureStore({
  reducer: {
    //   product: productReducer,
    auth: authReducer,
    price: sliceReducer,
    //   cart: cartReducer,
    //   order: orderReducer,
    //   user: userReducer
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
