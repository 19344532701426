/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {
  ListViewContext,
  ListViewProvider,
  useListView,
} from '../../../utils/contextProvider/ListViewProvider'
import {useCategoryProvider} from './CategoryProvider'
import {KTIcon} from '../../../../_metronic/helpers'
import SelectList from '../../../utils/components/SelectList'
import TableContent from '../../../utils/components/Table/TableContent'
import TablePagination from '../../../utils/components/Table/TablePagination'
import {CategoryColumn} from './CategoryColumn'
import NewCategoryModal from '../Modal/NewCategoryModal'

const CategoryContent = () => {
  const {
    isLoading,
    filteredCategory,
    searchTerm,
    setSearchTerm,
    handlePageChange,
    itemsPerPage,
    setItemsPerPage,
    setCurrentPage,
    totalElement,
    currentPage,
  } = useCategoryProvider()

  const {selected} = useListView()

  return (
    <>
      <div className='card'>
        <div className='card-header d-flex justify-content-between'>
          <h3 className='card-title'>All Category</h3>
          <div className='card-title'></div>
        </div>

        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='Search Group'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <div className='card-title m-0'>
            {selected.length > 0 ? (
              <SelectList context={ListViewContext} />
            ) : (
              <>
                <button
                  disabled={isLoading}
                  type='button'
                  className='btn btn-light-primary me-3'
                  id='dropdownMenuButton'
                  data-bs-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  <KTIcon iconName='filter' className='fs-2' />
                  Filter
                </button>
                <div
                  className='dropdown-menu menu menu-sub menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-300px w-md-325px py-4'
                  aria-labelledby='dropdownMenuButton'
                >
                  <div className='mb-10'>
                    <div className='px-7 py-5'>
                      <div className='fs-5 text-gray-900 fw-bolder'>Filter Options</div>
                    </div>

                    <div className='px-7 py-5'>
                      <label className='form-label fs-6 fw-bold'>Show Entries:</label>
                      <select
                        className='form-select form-select-solid fw-bolder'
                        data-kt-select2='true'
                        data-placeholder='Select entries'
                        data-hide-search='true'
                        name='entries'
                        id='entries'
                        value={itemsPerPage}
                        onChange={(e) => {
                          setCurrentPage(1)
                          setItemsPerPage(parseInt(e.target.value))
                        }}
                      >
                        <option value='10'>10</option>
                        <option value='50'>50</option>
                        <option value='100'>100</option>
                        <option value='250'>250</option>
                      </select>
                    </div>
                  </div>
                </div>

                <button
                  className='btn btn-light-primary me-3'
                  type='button'
                  id='dropdownMenuButton'
                  data-bs-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  <KTIcon iconName='exit-up' className='fs-2' />
                  Export
                </button>
                <ul
                  className='dropdown-menu menu menu-sub menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4'
                  aria-labelledby='dropdownMenuButton'
                >
                  <div className='menu-item px-3'>
                    <a className='menu-link px-3'>CSV</a>

                    <a className='menu-link px-3'>PDF</a>
                  </div>
                </ul>

                <button
                  type='button'
                  className='btn btn-primary'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_2'
                >
                  <KTIcon iconName='plus' className='fs-2' />
                  New Category
                </button>
              </>
            )}
          </div>
        </div>

        <TableContent
          isLoading={isLoading}
          tableData={filteredCategory}
          tableColumn={CategoryColumn}
        >
          <TablePagination
            totalElement={totalElement}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            isLoading={isLoading}
          />
        </TableContent>
      </div>

      <NewCategoryModal />
    </>
  )
}

const Category = () => {
  const {isLoading, filteredCategory} = useCategoryProvider()

  return (
    <ListViewProvider isLoading={isLoading} data={filteredCategory}>
      <CategoryContent />
    </ListViewProvider>
  )
}

export default Category
