import React, {useEffect, useState} from 'react'
import {Button, Table} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {useAuth} from '../auth'
import toast from 'react-hot-toast'
import axios from 'axios'
import {
  AdditionalPrice,
  GET_ADDITIONAL_PRICE,
  GET_CHARGING_URL,
  IChargingRoute,
  IChargingRouteArray,
  IPrice,
  IPriceArray,
} from './core/_model'
import TableContent from '../../utils/components/Table/TableContent'
import {PriceListColumn} from './PriceListColumn'
import {AdditionalPriceColumn} from './AdditionalPriceColumn'

const Price = () => {
  const {auth, currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [chargingRouteList, setChargingRouteList] = useState<IChargingRouteArray>([])
  const [priceList, setPriceList] = useState<IPriceArray>([])
  const [additionalPrice, setAdditionalPrice] = useState<AdditionalPrice[]>([])

  const getChargingRouteList = async () => {
    try {
      setIsLoading(true)
      toast.loading('Loading Price List..')
      const response = await axios.get(
        `${GET_CHARGING_URL}/${currentUser?.charging_route_id}?charingRouteconfId=${currentUser?.charging_route_id}`,
        {
          headers: {Authorization: `Bearer ${auth?.accessToken}`},
        }
      )
      const data = await response.data
      // console.log(response)
      setChargingRouteList(data)
      formatData(data)
      toast.dismiss()
      toast.success('Fetched Price List Successfully')
      setIsLoading(false)
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
      setIsLoading(false)
    }
  }

  const formatData = (data: IChargingRouteArray) => {
    let formattedData: IPriceArray = []
    data.map((singleItem: IChargingRoute) => {
      const brandTaka = singleItem.brandPrice / 100
      const nonBrandTaka = singleItem.nonBrandPrice / 100
      const singleData: IPrice = {
        country: 'Bangladesh',
        prefix: singleItem.operatorPrefix,
        price: `TK ${brandTaka}/${nonBrandTaka}`,
        status: singleItem.status === 'A' ? 'Allowed' : 'Unknown',
      }
      formattedData.push(singleData)
      return formattedData
    })
    setPriceList(formattedData)
  }

  const getAdditionalPrice = async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(
        `${GET_ADDITIONAL_PRICE}/${currentUser?.charging_route_id}`,
        {
          headers: {Authorization: `Bearer ${auth?.accessToken}`},
        }
      )
      const data = await response.data
      console.log([data])
      setAdditionalPrice([data])
      setIsLoading(false)
    } catch (error: any) {
      console.log(error.message)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    document.title = 'Price List'

    getChargingRouteList()
    getAdditionalPrice()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0 d-flex justify-content-between'>
          <div className='card-title'>
            <h3 className='er mb-0'>Price</h3>
          </div>
          <div className='card-title'>
            <Link to='/dashboard'>
              <Button variant='primary' size='sm'>
                Dashboard
              </Button>
            </Link>
          </div>
        </div>
        <TableContent
          isLoading={isLoading}
          tableData={priceList}
          tableColumn={PriceListColumn}
          className='text-center'
        />

        <div className='card-body'>
          <h3 className='er mb-8'>Additional Price Setting</h3>
          <TableContent
            isLoading={isLoading}
            tableData={additionalPrice}
            tableColumn={AdditionalPriceColumn}
            className='text-center'
          />
        </div>
      </div>
    </div>
  )
}

export default Price
