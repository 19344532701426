import React from 'react'
import {CategoryProvider} from './CategoryProvider'
import Category from './Category'

const CategoryWrapper = () => {
  return (
    <CategoryProvider>
      <Category />
    </CategoryProvider>
  )
}

export default CategoryWrapper
