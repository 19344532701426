import React, {useEffect, useState} from 'react'
import {Button, Spinner, Table} from 'react-bootstrap'
import axios from 'axios'
import {useAuth} from '../../auth'
import toast from 'react-hot-toast'
import {
  AdditionalChargeData,
  BraodcastHistoryProps,
  GET_ADDITIONAL_CHARGE_URL,
  GET_MSSISDN_URL,
  GET_SMS_TEXT_URL,
  GET_SMS_TOTAL_COST_URL,
  SmsData,
} from '../core/_model'
import {KTIcon} from '../../../../_metronic/helpers'

const BraodcastHIstoryViewModal: React.FC<BraodcastHistoryProps> = ({broadcast, setBroadcast}) => {
  const {auth} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [cost, setCost] = useState(0)
  const [mssisdn, setMssisdn] = useState(0)
  const [additionalCharge, setAdditionalCharge] = useState<AdditionalChargeData>()
  const [smsText, setSmsText] = useState<SmsData>()

  const getSmsBroadcastDetails = async (id: number) => {
    try {
      setIsLoading(true)
      toast.loading('Getting broadcast details..')

      const responseAdditionalCharge = await axios.get(`${GET_ADDITIONAL_CHARGE_URL}/${id}`, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      const additionalChargeData = await responseAdditionalCharge.data

      const responseSmsTotalCost = await axios.get(`${GET_SMS_TOTAL_COST_URL}/${id}`, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      const smsTotalCostData = await responseSmsTotalCost.data
      const totalCost = (await smsTotalCostData.cost) / 100

      const responseSmsText = await axios.get(`${GET_SMS_TEXT_URL}/${id}`, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      const smsTextData = await responseSmsText.data

      const responseMssisdn = await axios.get(`${GET_MSSISDN_URL}/${id}`, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      const mssisdnData = await responseMssisdn.data
      const mssisdnCount = await mssisdnData.mssisdnCount

      setAdditionalCharge(additionalChargeData)
      setCost(totalCost)
      setSmsText(smsTextData)
      setMssisdn(mssisdnCount)

      toast.dismiss()
      toast.success('Broadcast details get successfully')
      setIsLoading(false)
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (broadcast) {
      getSmsBroadcastDetails(broadcast.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcast])

  return (
    <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
      <div className='modal-dialog modal-xl'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h3 className='modal-title text-gray-700'>SMS Broadcast Details</h3>
            <button type='button' className='btn btn-light-primary me-3'>
              <KTIcon iconName='exit-up' className='fs-2' />
              Export
            </button>
          </div>

          <div className='modal-body text-gray-700'>
            {!isLoading && additionalCharge && smsText ? (
              <div>
                <ul>
                  <li className='fs-4 fw-semibold'>
                    SMS Text: <span className='fw-normal'>{smsText.smsText}</span>
                  </li>
                  <li className='fs-4 fw-semibold'>
                    SMS Type:{' '}
                    <span className='fw-normal'>
                      {smsText.smsType === 1 ? 'English' : 'Unicode'}
                    </span>
                  </li>
                  <li className='fs-4 fw-semibold'>
                    No Of Part: <span className='fw-normal'>{smsText.noOfPart}</span>
                  </li>
                  <li className='fs-4 fw-semibold'>
                    Insert Date: <span className='fw-normal'>{smsText.insertDate}</span>
                  </li>
                  <li className='fs-4 fw-semibold'>
                    Campaign: <span className='fw-normal'>{smsText.campaignId}</span>
                  </li>
                </ul>

                <Table striped bordered responsive hover size='sm' className='text-gray-700'>
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>MSSISDN</th>
                      <th>Total SMS</th>
                      <th>Per SMS Cost</th>
                      <th>Costing</th>
                      <th>Vat</th>
                      <th>Service Charge</th>
                      <th>Total Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='text-gray-700'>{smsText.status}</td>
                      <td className='text-gray-700'>{mssisdn}</td>
                      <td className='text-gray-700'>{smsText.noOfPart * mssisdn}</td>
                      <td className='text-gray-700'>{cost / mssisdn}</td>
                      <td className='text-gray-700'>{cost}</td>
                      <td className='text-gray-700'>{additionalCharge.vat / 100}</td>
                      <td className='text-gray-700'>
                        {mssisdn * (additionalCharge.serviceCharge / 100)}
                      </td>
                      <td className='text-gray-700'>
                        {(cost + mssisdn * (additionalCharge.serviceCharge / 100)).toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            ) : (
              <div className='d-flex gap-2 justify-content-center mt-8'>
                <p className='fs-6'>Please wait..</p>
                <Spinner animation='border' size='sm' />
              </div>
            )}
          </div>

          <div className='modal-footer'>
            <Button
              variant='secondary'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={() => setBroadcast(undefined)}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BraodcastHIstoryViewModal
