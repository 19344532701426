import {useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {Content} from '../../../_metronic/layout/components/content'
import {OverviewCard} from './OverviewCard'

const KnowledgeBaseOverview = () => {
  const [searchTerm, setSearchTerm] = useState('')

  return (
    <Content>
      <div className='card-header border-0'>
        <p className='fs-2 fw-semibold text-gray-800'>Quick Search</p>
        <div className='d-flex align-items-center position-relative mb-12 mx-auto'>
          <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
          <input
            type='text'
            data-kt-user-table-filter='Search by category'
            className='form-control form-control-solid w-250px ps-14'
            placeholder='Search by category'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <OverviewCard
            className='card-xl-stretch mb-xl-8'
            image='abstract-1.svg'
            title='Summer'
            articleCount={2}
            description='I Hate Summer'
          />
        </div>

        <div className='col-xl-4'>
          <OverviewCard
            className='card-xl-stretch mb-xl-8'
            image='abstract-2.svg'
            title='Winter'
            articleCount={10}
            description='We Love Winter'
          />
        </div>

        <div className='col-xl-4'>
          <OverviewCard
            className='card-xl-stretch mb-xl-8'
            image='abstract-3.svg'
            title='Fruits'
            articleCount={3}
            description='Articles abut fruits'
          />
        </div>
      </div>
    </Content>
  )
}

export default KnowledgeBaseOverview
