/* eslint-disable no-control-regex */
import React, {useCallback, useEffect, useState} from 'react'
import {
  ISenderId,
  SmartSmsForm,
  SmartSmsFormInitValues as initialValues,
} from '../SMSBroadCast/SettingsModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {Badge, Button} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {Link} from 'react-router-dom'
import DateTimePicker from 'react-datetime-picker'
import 'react-datetime-picker/dist/DateTimePicker.css'
import 'react-calendar/dist/Calendar.css'
import 'react-clock/dist/Clock.css'
import Select from 'react-select'
import {useDropzone} from 'react-dropzone'
import useSenderIdsOptions from '../../hooks/useSenderIdsOptions'
import clsx from 'clsx'
import {useAuth} from '../auth'
import SmsTemplateModal from './Modal/SmsTemplateModal'
import {useThemeMode} from '../../../_metronic/partials'
import * as xlsx from 'xlsx'
import toast from 'react-hot-toast'
import {CSVData} from './ContactFileSMS'
import SmartSMSOverviewModal from './Modal/SmartSMSOverviewModal'
import {TemplateText, getCurrentTimestamp} from './QuickSMS'
import ContactFeedbackModal from '../ContactAndGroup/Modal/ContactFeedbackModal'
import {fileupload, numberCounts} from '../ContactAndGroup/core/helper'
import SmartContactFeedbackModal from './Modal/SmartContactFeedback'
import useSms from './hook/useSms'

export interface ColumnOption {
  value: string
  label: string
}

const smartSmsSchema = Yup.object().shape({
  senderid: Yup.object().required('Sender Id is required'),
  mobileno: Yup.object().required('Select column is required'),
  file: Yup.mixed()
    .required('Contact file is required')
    .test('fileFormat', 'Only CSV and Excel file can be upload', (value: any) => {
      if (value) {
        const supportedFormat = ['csv', 'xls', 'xlsx']
        return supportedFormat.includes(value.name.split('.').pop())
      }
      return true
    }),
  sms_content: Yup.string().required('SMS content is required.'),
})

const checkWordsInText = (text: string, words: string[]) => {
  for (const word of words) {
    if (text.includes(word)) {
      return true
    } else {
      return false
    }
  }
}

const SmartSMS: React.FC = () => {
  const {auth, currentUser} = useAuth()
  const {mode} = useThemeMode()
  const {senderIdsOptions, defaultSender} = useSenderIdsOptions()
  const {smsState, handleSmsContentChange, handleSmsTypeChange, backToCountDefault} = useSms()
  const [columnOptions, setColumnOptions] = useState<ColumnOption[]>([])
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<SmartSmsForm>(initialValues)
  const [disableDateTime, setDisableDateTime] = useState<Boolean>(true)
  const [templateText, setTemplateText] = useState<TemplateText>({
    isUse: false,
    smsText: '',
    isSmartTemplate: 0,
  })
  const [varMatch, setVarMatch] = useState<boolean | undefined>(true)

  const [invalidNumber, setinvalidNumber] = useState(0)
  const [duplicate, setDuplicate] = useState(0)
  const [success, setSuccess] = useState(0)

  const [isCreated, setIsCreated] = useState(false)

  const providedCmName = `${currentUser?.user_full_name}- ${getCurrentTimestamp()}`

  const formik = useFormik<SmartSmsForm>({
    initialValues,
    validationSchema: smartSmsSchema,
    onSubmit: async (values) => {
      let fileData = new FormData()
      fileData.append('file', values.file)

      const uploadValues = await fileupload(
        auth?.accessToken,
        fileData,
        (values.mobileno as ISenderId)?.value
      )

      setinvalidNumber(uploadValues?.invalidCount)
      setDuplicate(uploadValues?.duplicateCount)
      setSuccess(uploadValues?.successCount)

      // const noCol = (values.mobileno as ISenderId)?.value

      setFormData({
        ...values,
        sms_count: smsState.noOfSms,
        smsTextCharacterCount: smsState.characterCount,
        reciepient: uploadValues?.successCount,
        noOfSms:
          (typeof values?.reciepient === 'number' ? uploadValues?.successCount : 0) *
          smsState.noOfSms,
        fileId: uploadValues?.fileId,
        sms_type: smsState.smsType,
      })
    },
  })

  const handleCancel = () => {
    formik.resetForm()
    formik.setFieldValue('cm_name', providedCmName)
    formik.setFieldValue('senderid', defaultSender[0])
    setFormData(initialValues)
    setColumnOptions([])
    setIsCreated(false)
    backToCountDefault()
    // Additional logic or redirection if needed
  }

  if (isCreated) {
    handleCancel()
  }

  if (templateText.isUse) {
    formik.setFieldValue('sms_content', templateText.smsText)
    setTemplateText({
      isUse: false,
      smsText: templateText.smsText,
      isSmartTemplate: templateText.isSmartTemplate,
    })
    const formateOpt = columnOptions.map((col) => `#${col.value}#`)

    if (templateText.isSmartTemplate) {
      const isMatched = checkWordsInText(templateText.smsText, formateOpt)
      setVarMatch(isMatched)
    }
  }

  const onFileDrop = useCallback((acceptedFiles: any) => {
    formik.setFieldValue('file', acceptedFiles[0])
    validateFile(acceptedFiles[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({onDrop: onFileDrop})

  const validateFile = async (file: File | string) => {
    if (typeof file === 'string') {
      return
    }

    const fileType = file.name.split('.').pop()?.toLowerCase()
    if (fileType === 'csv') {
      await validateCSV(file)
    } else if (fileType === 'xlsx' || fileType === 'xls') {
      await validateExcel(file)
    } else {
      toast.error('Unsupported file type. Please upload a CSV or Excel file.')
    }
  }

  const validateCSV = async (file: File) => {
    try {
      const fileUrl = URL.createObjectURL(file)
      const response = await fetch(fileUrl)

      // Check if the response is ok
      if (response.ok) {
        const text = await response.text()
        const lines = text.split('\n')

        const columnHeadings = lines[0].split(',').map((column) => column.trim())

        if (columnHeadings.length < 0) {
          toast.error('Column is empty')
          return
        }

        const jsonData: any[] = [] // Array to store JSON data

        // Iterate through each line (starting from the second line) to convert to JSON
        for (let i = 1; i < lines.length; i++) {
          const values = lines[i].split(',')

          // Skip empty lines
          if (values.length === 0 || values.every((value) => value.trim() === '')) {
            continue
          }

          const row: any = {}
          // Assign each value to the corresponding column heading
          for (let j = 0; j < Math.min(columnHeadings.length, values.length); j++) {
            row[columnHeadings[j]] = values[j]
          }
          jsonData.push(row)
        }

        formik.setFieldValue('reciepient', jsonData.length)

        formik.setFieldValue('fileData', jsonData[0])
        console.log(jsonData[0])

        const formatedOptions = columnHeadings.map((column) => {
          const option: ColumnOption = {
            value: column,
            label: column,
          }
          return option
        })
        setColumnOptions(formatedOptions)
      } else {
        toast.error(`Something went wrong, try again`)
      }
    } catch (error: any) {
      console.error(error)
      toast.error(`Error: ${error.message}`)
    }
  }

  const validateExcel = async (file: File) => {
    try {
      const reader = new FileReader()
      reader.onload = async (event) => {
        const data = new Uint8Array(event.target?.result as ArrayBuffer)
        const workbook = xlsx.read(data, {type: 'array'})

        const sheet = workbook.Sheets[workbook.SheetNames[0]]

        const jsonData = xlsx.utils.sheet_to_json<CSVData>(sheet)

        const count = jsonData.length
        formik.setFieldValue('reciepient', count)

        // console.log(jsonData)

        // const {successItem} = numberCounts(jsonData)

        formik.setFieldValue('fileData', jsonData[0])

        const columnHeadings = Object.keys(jsonData[0] || {})

        if (columnHeadings.length < 0) {
          toast.error('Column is empty')
          return
        }

        const formatedOptions = columnHeadings.map((column) => {
          const option: ColumnOption = {
            value: column,
            label: column,
          }
          return option
        })
        setColumnOptions(formatedOptions)
      }
      reader.readAsArrayBuffer(file)
    } catch (error) {
      console.error(error)
    }
  }

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '8px',
      padding: '4px',
      border: 0,
      backgroundColor: mode === 'light' ? '#f9f9f9' : '#1b1b29',
      borderColor: state.isFocused ? '#6c63ff' : '#d1d1d1',
      boxShadow: state.isFocused ? '0 0 0 1px #6c63ff' : null,
      '&:hover': {
        borderColor: '#6c63ff',
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#6c63ff' : null,
      color: state.isSelected ? '#ffffff' : null,
      '&:hover': {
        backgroundColor: '#6c63ff',
        color: '#ffffff',
      },
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '8px',
      backgroundColor: mode === 'light' ? '#f8f0f4' : '#1b1b29',
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)', // Add box shadow
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: mode === 'light' ? '#5e6278' : '#92929f', // Change color of selected value
    }),
  }

  const files = acceptedFiles.map((file) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ))

  const handleVarClick = (textVar: string) => {
    const smsContent = formik.values.sms_content
    const newSmsContent = `${smsContent} ${textVar}`
    formik.setFieldValue('sms_content', newSmsContent)
  }

  useEffect(() => {
    document.title = 'Smart SMS'
    formik.setFieldValue('senderid', defaultSender[0])
    formik.setFieldValue('cm_name', providedCmName)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSender])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle=''
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='er m-0'>Smart SMS</h3>
        </div>

        <div className='card-title m-0'>
          <Link to='/dashboard'>
            <Button variant='primary' size='sm'>
              Dashboard
            </Button>
          </Link>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9 d-flex flex-column'>
            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label  fs-6'>
                <span className='required'>Campaign Name</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  name='cm_name'
                  placeholder='Campaign Name'
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.cm_name && formik.errors.cm_name},
                    {
                      'is-valid': formik.touched.cm_name && !formik.errors.cm_name,
                    }
                  )}
                  value={formik.values.cm_name}
                  onChange={formik.handleChange}
                />
                {formik.touched.cm_name && formik.errors.cm_name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.cm_name}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label  fs-6'>
                <span className='required'>Select Sender ID</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <Select
                  name='senderid'
                  className={clsx(
                    {'is-invalid': formik.touched.senderid && formik.errors.senderid},
                    {
                      'is-valid': formik.touched.senderid && !formik.errors.senderid,
                    }
                  )}
                  placeholder='Choose year value'
                  value={formik.values.senderid}
                  onChange={(selectedOption) => {
                    formik.setFieldValue('senderid', selectedOption)
                  }}
                  options={senderIdsOptions}
                  styles={customStyles}
                />

                {formik.touched.senderid && formik.errors.senderid && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.senderid}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required  fs-6'>Select File</label>
              <div className='col-lg-8 fv-row-3'>
                <section className='container'>
                  <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop excel of csv file, or click to select files</p>
                  </div>
                  {formik.touched.file && formik.errors.file && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.file}</div>
                    </div>
                  )}
                  <aside>
                    <h4 className='mt-4'>Files</h4>
                    <ul>{formik.values.file === '' ? null : files}</ul>
                  </aside>
                </section>
              </div>
            </div>

            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label  fs-6'>
                <span className='required'>Mobile No Column</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <Select
                  name='mobileno'
                  className={clsx(
                    {'is-invalid': formik.touched.mobileno && formik.errors.mobileno},
                    {
                      'is-valid': formik.touched.mobileno && !formik.errors.mobileno,
                    }
                  )}
                  placeholder='Choose year value'
                  value={formik.values.mobileno}
                  onChange={(selectedOption) => {
                    formik.setFieldValue('mobileno', selectedOption)
                  }}
                  options={columnOptions}
                  styles={customStyles}
                />
                <p className='mt-2 fs-6 fw-semibold text-gray-700'>
                  Please upload a excel or csv file then choose column which contains mobile no
                </p>
                {formik.touched.mobileno && formik.errors.mobileno && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.mobileno}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label  fs-6'>Select SMS Type</label>

              <div className='col-lg-8 fv-row'>
                <div className='d-flex align-items-center mt-3'>
                  <label className='form-check form-check-inline form-check-solid me-5'>
                    <input
                      className='form-check-input'
                      name='sms_type'
                      type='radio'
                      value={0}
                      checked={smsState.smsType === 0}
                      onChange={(e) => {
                        handleSmsTypeChange(e)
                      }}
                    />
                    <span className=' ps-2 fs-6'>Text</span>
                  </label>

                  <label className='form-check form-check-inline form-check-solid'>
                    <input
                      className='form-check-input'
                      name='sms_type'
                      type='radio'
                      value={1}
                      checked={smsState.smsType === 1}
                      onChange={(e) => {
                        handleSmsTypeChange(e)
                      }}
                    />
                    <span className=' ps-2 fs-6'>UniCode</span>
                  </label>
                </div>
              </div>
            </div>

            <div className='d-flex flex-wrap gap-2 justify-content-end mb-2 col-8 align-self-end'>
              {columnOptions.length > 0 &&
                columnOptions.map((column, index) => {
                  return (
                    <Badge
                      key={index}
                      bg='primary'
                      className='text-white'
                      style={{cursor: 'pointer'}}
                      onClick={() => handleVarClick(`#${column.value}#`)}
                    >
                      #{column.value}#
                    </Badge>
                  )
                })}
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required  fs-6'>Enter Sms Content</label>
              <div className='col-lg-8 fv-row-3'>
                <textarea
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.sms_content && formik.errors.sms_content},
                    {
                      'is-valid': formik.touched.sms_content && !formik.errors.sms_content,
                    }
                  )}
                  placeholder='SMS Content'
                  rows={5}
                  name='sms_content'
                  style={{resize: 'vertical', fontSize: '1.0rem'}}
                  value={formik.values.sms_content}
                  onChange={(e) => {
                    handleSmsContentChange(e)
                    formik.handleChange(e)
                  }}
                ></textarea>
                {formik.touched.sms_content && formik.errors.sms_content && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.sms_content}</div>
                  </div>
                )}

                <div className='form-text'>
                  {smsState.characterCount} Characters | {smsState.noOfSms} SMS |{' '}
                  {smsState.noOfSms > 1 ? smsState.charPerSmsForLong : smsState.charPerSms} Char/SMS{' '}
                  <span>
                    <button
                      style={{borderRadius: 5, border: 'light'}}
                      type='button'
                      className='px-4 rounded border-0 bg-success fw-semibold text-white'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_3'
                    >
                      Insert SMS Template
                    </button>
                  </span>{' '}
                  |
                </div>
              </div>
            </div>
            <SmsTemplateModal setTemplateText={setTemplateText} isSmartTemplate={1} />

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fs-6 required'>Schedule SMS</label>
              <div className='col-lg-8 fv-row'>
                <div className='d-flex align-items-center mt-3'>
                  <label className='form-check form-check-inline form-check-solid me-5'>
                    <input
                      className='form-check-input'
                      name='sms_schedule'
                      type='radio'
                      value='now'
                      checked={formik.values.sms_schedule === 'now'}
                      onChange={(e) => {
                        formik.setFieldValue('sms_schedule', 'now')
                        setDisableDateTime(true)
                      }}
                    />
                    <span className='ps-2 fs-6'>Send Now</span>
                  </label>
                  <label className='form-check form-check-inline form-check-solid'>
                    <input
                      className='form-check-input'
                      name='sms_schedule'
                      type='radio'
                      value='later'
                      checked={formik.values.sms_schedule === 'later'}
                      onChange={() => {
                        formik.setFieldValue('sms_schedule', 'later')
                        setDisableDateTime(false)
                      }}
                    />
                    <span className='ps-2 fs-6'>Send Later</span>
                  </label>
                </div>

                <div className='col-lg-8 mt-4'>
                  {disableDateTime ? (
                    <div className='date-time-text'>
                      {formik.values ? formik.values.schedule_date.toLocaleString() : ''}
                    </div>
                  ) : (
                    <div className='send-later-date-picker'>
                      <DateTimePicker
                        value={formik.values.schedule_date}
                        name='schedule_date'
                        onChange={(val) => formik.setFieldValue('schedule_date', val)}
                        className='form-select form-select-solid form-select-lg'
                        format='MMMM d, yyyy HH:mm'
                        minDate={new Date()}
                        disableClock={true}
                        clearIcon={null}
                        calendarIcon={null}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              type='button'
              className='btn btn-danger me-2'
              onClick={handleCancel}
              disabled={loading}
            >
              Cancel
            </button>
            <button
              type='submit'
              className='btn btn-primary'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_2'
              disabled={loading}
            >
              {!loading && 'Review'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>

          {
            <>
              <SmartSMSOverviewModal
                formData={formData}
                setIsCreated={setIsCreated}
                setLoading={setLoading}
                formik={formik}
                varMatch={varMatch}
              />

              <SmartContactFeedbackModal
                successCount={success}
                invalidCount={invalidNumber}
                duplicatesCount={duplicate}
                formik={formik}
              />
            </>
          }
        </form>
      </div>
    </div>
  )
}

export {SmartSMS}
