import {Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import AddNewFilterModal from './Modal/AddNewFilterModal'
import DeleteFilterTextModal from './Modal/DeleteFilterTextModal'
import {useSpamFilterProvider} from './SpamFIlterProvider'
import TableContent from '../../utils/components/Table/TableContent'
import {SpamFilterColumn} from './SpamFilterColumn'
import {KTIcon} from '../../../_metronic/helpers'
import Filter from './Filter'
import TablePagination from '../../utils/components/Table/TablePagination'
import {
  ListViewContext,
  ListViewProvider,
  useListView,
} from '../../utils/contextProvider/ListViewProvider'
import SelectList from '../../utils/components/SelectList'

const SpamFilterContent = () => {
  const {
    isLoading,
    setUpdateSpamText,
    filteredSpam,
    totalElement,
    itemsPerPage,
    currentPage,
    handlePageChange,
    searchTerm,
    setSearchTerm,
  } = useSpamFilterProvider()

  const {selected} = useListView()

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle=''
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='er m-0'>
              Spam Filter <span className='form-text'></span>
            </h3>
          </div>

          <div className='card-title m-0'>
            <div className='d-flex gap-4'>
              <Link to='/dashboard'>
                <Button variant='primary' size='sm'>
                  Dashboard
                </Button>
              </Link>
            </div>
            <AddNewFilterModal />
            <DeleteFilterTextModal />
          </div>
        </div>

        <div className='card-header border-0'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <div className='card-title'>
            {selected.length > 0 ? (
              <SelectList context={ListViewContext} />
            ) : (
              <>
                <Filter />
                <button type='button' className='btn btn-light-primary me-3'>
                  <KTIcon iconName='exit-up' className='fs-2' />
                  Export
                </button>
                <button
                  type='button'
                  className='btn btn-primary'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_1'
                  onClick={() => setUpdateSpamText(undefined)}
                >
                  <KTIcon iconName='plus' className='fs-2' />
                  Create New Filter
                </button>
              </>
            )}
          </div>
        </div>

        <TableContent
          isLoading={isLoading}
          tableData={filteredSpam}
          tableColumn={SpamFilterColumn}
          className='text-center'
        >
          <TablePagination
            totalElement={totalElement}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            isLoading={isLoading}
          />
        </TableContent>
      </div>
    </div>
  )
}

const SpamFilter = () => {
  const {isLoading, filteredSpam} = useSpamFilterProvider()

  return (
    <ListViewProvider isLoading={isLoading} data={filteredSpam}>
      <SpamFilterContent />
    </ListViewProvider>
  )
}

export default SpamFilter
