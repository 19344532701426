import {Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import SenderIdMaskingModal from '../Modal/SenderIdMaskingModal'
import ErrorModal from '../Modal/ErrorModal'
import ListPagination from '../../PaymentRequest/ListPagination'
import TableContent from '../../../utils/components/Table/TableContent'
import {SenderIDColumn} from './SenderIDsColumn'
import {useSenderIdProvider} from '../context/SenderIdProvider'
import {KTIcon} from '../../../../_metronic/helpers'
import SenderIdFilter from './SenderIdFilter'
import {useAuth} from '../../auth'
import {
  ListViewContext,
  ListViewProvider,
  useListView,
} from '../../../utils/contextProvider/ListViewProvider'
import SelectList from '../../../utils/components/SelectList'

const SenderIdContent = () => {
  const {currentUser} = useAuth()
  const {
    isLoading,
    filteredSender,
    setUpdateSender,
    downloadSenderIds,
    totalElement,
    itemsPerPage,
    currentPage,
    handlePageChange,
    apiError,
    setApiError,
    searchTerm,
    setSearchTerm,
  } = useSenderIdProvider() || {}

  const {selected} = useListView()

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle=''
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='er m-0'>Sender IDs</h3>
          </div>
        </div>
        <div className='card-header border-0'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search Sender Id'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <div className='card-title'>
            {selected.length > 0 ? (
              <SelectList context={ListViewContext} />
            ) : (
              <>
                <SenderIdFilter />
                <button
                  type='button'
                  className='btn btn-light-primary me-3'
                  onClick={downloadSenderIds}
                >
                  <KTIcon iconName='exit-up' className='fs-2' />
                  Download
                </button>
                {currentUser?.user_profile === 1 || currentUser?.user_profile === 2 ? (
                  <button
                    type='button'
                    className='btn btn-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_1'
                    onClick={() => setUpdateSender(undefined)}
                  >
                    <KTIcon iconName='plus' className='fs-2' />
                    Create Sender Id
                  </button>
                ) : null}
              </>
            )}
          </div>
        </div>

        <TableContent isLoading={isLoading} tableData={filteredSender} tableColumn={SenderIDColumn}>
          <ListPagination
            totalElement={totalElement}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            isLoading={isLoading}
          />
        </TableContent>
        <div className='card-footer'>
          <SenderIdMaskingModal />
          {apiError?.isError && <ErrorModal apiError={apiError} setApiError={setApiError} />}
        </div>
      </div>
    </div>
  )
}

export const SenderId = () => {
  const {isLoading, filteredSender} = useSenderIdProvider()

  return (
    <ListViewProvider isLoading={isLoading} data={filteredSender}>
      <SenderIdContent />
    </ListViewProvider>
  )
}

export default SenderId
