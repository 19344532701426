/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ChangeEvent, FormEvent, PropsWithChildren, useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import toast from 'react-hot-toast'
import axios from 'axios'
import {useAuth} from '../auth'
import {IScheduleData, IScheduleDataArray, Params, ScheduleProps} from './core/_model'
import {KTIcon} from '../../../_metronic/helpers'
import TableContent from '../../utils/components/Table/TableContent'
import TablePagination from '../../utils/components/Table/TablePagination'
import {Column, HeaderProps} from 'react-table'
import {CustomHeader} from '../../utils/components/Table/CustomHeader'
import {formatDate} from '../../utils/helperFunction/_helper'
import SelectionHeader from '../../utils/components/Table/SelectionHeader'
import {ListViewContext, ListViewProvider} from '../../utils/contextProvider/ListViewProvider'
import {SelectionCell} from '../../utils/components/Table/SelectionCell'
import TableHeader from '../../utils/components/Table/TableHeader'

const Schedule: React.FC<ScheduleProps> = ({apiUrl, title}) => {
  const {auth} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [scheduleData, setScheduleData] = useState<IScheduleDataArray>([])
  const [filteredSchedule, setFilteredSchedule] = useState<IScheduleDataArray>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [startTime, setStartTime] = useState<string>('')
  const [endTime, setEndTime] = useState<string>('')
  const [click, setClick] = useState(0)

  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalElement, setTotalElement] = useState(0)
  const [isLastPage, setIsLastPage] = useState(false)

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)

  const handleSearchSubmit = async (event: FormEvent) => {
    event.preventDefault()

    setCurrentPage(1)
    setItemsPerPage(10)
    setClick((prev) => prev + 1)
  }

  const getScheduleData = async (params: any) => {
    try {
      setIsLoading(true)
      toast.loading('Loading Schedule SMS..')
      const response = await axios.get(apiUrl, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
        params: params,
      })
      const result = await response.data
      const data = await result.data
      const lastPage = await result.lastpage
      const totalResult = await result.totalElement
      console.log(data)
      formatData(data ? data : [])
      setIsLastPage(lastPage)
      setTotalElement(totalResult)
      toast.dismiss()
      toast.success('Fetched SMS List')
      setIsLoading(false)
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
      setIsLoading(false)
    }
  }

  const formatData = (data: IScheduleDataArray) => {
    const formattedData: any[] = []

    data.map((item: any, index) => {
      const singleData: IScheduleDataArray = {
        ...item,
        id: index + 1,
      }

      // console.log(singleData)
      formattedData.push(singleData)
      return formattedData
    })

    setScheduleData(formattedData)
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  let showPageLastEtries = !isLastPage ? currentPage * itemsPerPage : totalElement
  let showPageFirstEntries = !isLastPage
    ? showPageLastEtries + 1 - itemsPerPage
    : (currentPage - 1) * itemsPerPage + 1

  let serial: number[] = []

  for (let i = showPageFirstEntries; i <= showPageLastEtries; i++) {
    serial.push(i)
  }

  useEffect(() => {
    const filteredData = scheduleData?.filter(
      (list) =>
        list.sender?.toLowerCase().includes(searchTerm) ||
        list.smsText?.toLowerCase().includes(searchTerm)
    )
    setFilteredSchedule(filteredData)
  }, [scheduleData, searchTerm])

  useEffect(() => {
    document.title = title
  }, [title])

  useEffect(() => {
    if (startTime && endTime) {
      const setParams: Params = {
        pageNumber: currentPage - 1,
        pageSize: itemsPerPage,
        startDate: startTime,
        endDate: endTime,
      }

      getScheduleData(setParams)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, currentPage, click])

  const ScheduleColumn: ReadonlyArray<Column<IScheduleData>> = [
    {
      Header: (props) => <SelectionHeader tableProps={props as any} context={ListViewContext} />,
      id: 'selection',
      Cell: ({...props}) => (
        <SelectionCell id={props.data[props.row.index].id} context={ListViewContext} />
      ),
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Insert Date' className='min-w-150px' />
      ),
      id: 'insertDate',
      Cell: ({...props}) => <>{formatDate(props.data[props.row.index].insertDate)}</>,
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Schedule Date' className='min-w-150px' />
      ),
      id: 'scheduleDate',
      Cell: ({...props}) => <>{formatDate(props.data[props.row.index].scheduleDate)}</>,
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Status' className='min-w-100px' />
      ),
      id: 'status',
      accessor: 'status',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Sender' className='min-w-100px' />
      ),
      id: 'sender',
      accessor: 'sender',
    },

    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Type' className='min-w-100px' />
      ),
      id: 'type',
      accessor: 'type',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Part' className='min-w-100px' />
      ),
      id: 'part',
      accessor: 'part',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Count' className='min-w-100px' />
      ),
      id: 'count',
      accessor: 'count',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='SMS Text' className='min-w-100px' />
      ),
      id: 'smsText',
      accessor: 'smsText',
    },
  ]

  return (
    <ListViewProvider isLoading={isLoading} data={filteredSchedule}>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0 d-flex justify-content-between'>
          <div className='card-title'>
            <h3 className='er mb-0'>{title}</h3>
          </div>
          <div className='card-title'>
            <Link to='/dashboard'>
              <Button variant='primary' size='sm'>
                Dashboard
              </Button>
            </Link>
          </div>
        </div>

        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='Search Group'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <div className='card-title m-0'>
            <TableHeader
              startTime={startTime}
              endTime={endTime}
              itemsPerPage={itemsPerPage}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              handleSearchSubmit={handleSearchSubmit}
              setItemsPerPage={setItemsPerPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>

        <TableContent
          isLoading={isLoading}
          tableData={filteredSchedule}
          tableColumn={ScheduleColumn}
        >
          <TablePagination
            totalElement={totalElement}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            isLoading={isLoading}
          />
        </TableContent>
      </div>
    </ListViewProvider>
  )
}

export default Schedule
