import React, {useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import MaintenanceFormModal from './Modal/MaintenanceFormModal'
import MaintenanceDeleteModal from './Modal/MaintenanaceDeleteModal'

const MaintenanceSettings = () => {
  const [isPlay, setIsPlay] = useState(true)

  return (
    <div className='card col-12 col-md-10'>
      <div className='card-header'>
        <div className='card-title'>
          <h3 className='text-gray-700'>Maintenance</h3>
        </div>
      </div>
      <div className='card-body'>
        <button
          type='button'
          className='btn btn-primary'
          data-bs-toggle='modal'
          data-bs-target='#kt_modal_1'
        >
          <KTIcon iconName='plus' className='fs-2' />
          Schedule Maintenance
        </button>

        <div className='mt-6 p-8 bg-gray-200 rounded d-flex flex-column gap-4 flex-lg-row align-items-lg-center justify-content-lg-between'>
          <div>
            <span className='fs-4 fw-bold'>BTRC Issue</span>
            <p className='fw-semibold text-gray-800 mb-2'>Under Maintenance</p>
            <span className='badge bg-gray-400 text-gray-800'>
              2024-04-12 12:00 - 2024-4-20 12:00
            </span>
            <span className='badge bg-gray-400 text-gray-800 mx-2'>UTC+6:00 Asia/Dhaka</span>
          </div>

          <div className='d-flex gap-2'>
            <button
              type='button'
              className='btn btn-primary btn-sm'
              onClick={() => setIsPlay((prev) => !prev)}
            >
              {isPlay ? (
                <>
                  <i className='fas fa-pause'></i>
                  <span>Pause</span>
                </>
              ) : (
                <>
                  {' '}
                  <i className='fas fa-play'></i>
                  <span>Resume</span>
                </>
              )}
            </button>

            <button
              type='button'
              className='btn btn-light-secondary btn-sm'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_1'
            >
              <i className='fas fa-edit text-gray-800'></i>
              <span className='text-gray-800'>Edit</span>
            </button>

            <button
              type='button'
              className='btn btn-danger btn-sm'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_3'
            >
              <KTIcon iconName='tablet-delete' className='fs-2' />
              <span className='text-gray-800'>Delete</span>
            </button>
          </div>
        </div>
      </div>

      <MaintenanceFormModal />
      <MaintenanceDeleteModal />
    </div>
  )
}

export default MaintenanceSettings
