import React, {useState} from 'react'
import {Form, Button, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom'

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const handleSubmit = (e: {preventDefault: () => void}) => {
    e.preventDefault()

    // Add your logic for handling the form submission here
    console.log('Submitted!')
    console.log('Old Password:', oldPassword)
    console.log('New Password:', newPassword)
    console.log('Confirm Password:', confirmPassword)

    // Clear the form fields
    setOldPassword('')
    setNewPassword('')
    setConfirmPassword('')
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle=''
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='er m-0'>
            Change Password <span className='form-text'></span>
          </h3>
        </div>

        <div className='card-title m-0'>
          <Link to='/dashboard'>
            <Button variant='primary' size='sm'>
              Dashboard
            </Button>
          </Link>
        </div>
      </div>

      <div className='card-body'>
        <form>
          <Row className='mb-4'>
            <label className='col-form-label fs-6'>
              <span>Old Password</span>
            </label>

            <div className='col-lg-6 fv-row'>
              <input
                type='password'
                placeholder='Old Password'
                className='form-control form-control-lg form-control-solid'
              />
            </div>
          </Row>
          <Row className='mb-4'>
            <label className='col-form-label fs-6'>
              <span>New Password</span>
            </label>

            <div className='col-lg-6 fv-row'>
              <input
                type='password'
                placeholder='New Password'
                className='form-control form-control-lg form-control-solid'
              />
            </div>
          </Row>
          <Row className='mb-4'>
            <label className='col-form-label fs-6'>
              <span>Confirm Password</span>
            </label>

            <div className='col-lg-6 fv-row'>
              <input
                type='password'
                placeholder='Re-enter new password'
                className='form-control form-control-lg form-control-solid'
              />
            </div>
          </Row>
          <Button type='submit' variant='primary' size='lg'>
            Submit
          </Button>
        </form>
      </div>
    </div>
  )
}

export default ChangePassword
