import axios from "axios";
import { BASE_URL } from "../config"

const GET_ROUTE_LIST_URL = `${BASE_URL}/api/protect/routeList`
const GET_SMS_TEMPLATE_URL = `${BASE_URL}/api/protect/getSmsTempleteByLoginUserwithPage`

export const getRouteList = async (token : string | undefined) => {
    try {
        const response = await axios.get(GET_ROUTE_LIST_URL, { 
            headers: { 'Authorization': `Bearer ${token}`}
        })    
        const data = await response.data
        // console.log(data)
        return data        
    } catch (error) {
        console.log(error);
    }
}



// export const getSmsTemplates = async (token:) => {
//     try {
//         const response = await axios.get(`${GET_SMS_TEMPLATE_URL}`, {
//             headers: {'Authorization': `Bearer ${auth?.accessToken}`},
//             params: setParams
//         })
//         const data = await response.data
//         const result = await data.data
//         const totalResult = await data.totalElement
//         const lastPage = await data.lastpage
//         setIsLastPage(lastPage)
//         setTotalElement(totalResult)
//         setTemplates(result)
//         setError({isError: false, message: ''})
//         setIsLoading(false)
//     } catch (err) {
//         const errorMessage = (err as Error)?.message || 'An error occurred'
//         setIsLoading(false)
//         setError({isError: true, message: errorMessage})
//     }
// }