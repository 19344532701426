/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Suspense, lazy, useEffect, useState} from 'react'
import DashboardProvider from './DashboardContext'
import CardSkeleton from '../../utils/components/Skeleton/CardSkeleton'
import ListSkeleton from '../../utils/components/Skeleton/ListSkeleton'
import BrandedNonBrandedChart from './Component/Chart'
import {Link} from 'react-router-dom'
import {useAppDispatch} from '../../hook'
import {getAdditionalPriceList, getPriceList} from '../../PriceSlice'
import {useAuth} from '../../modules/auth'
import {MixedWidget1, StatisticsWidget3} from '../../../_metronic/partials/widgets'
import StatisticsDashboardCard from './Component/StatisticsSmsCount'
import DashboardHeader from './Component/DashboardHeader'
import StatisticsSmsCount from './Component/StatisticsSmsCount'
import StatisticsSmsCost from './Component/StatisticsSmsCost'
import {BreakdownByStatus} from './Component/BreakdownByStatus'

const SmsCount = lazy(() => import('./Component/SmsCount'))
const SmsCost = lazy(() => import('./Component/SmsCost'))
const SMSQuantity = lazy(() => import('./Component/SMSQuantity'))
const Balance = lazy(() => import('./Component/Balance'))
const LastFiveTransaction = lazy(() => import('./Component/LastFiveTransection'))
const TopFiveUser = lazy(() => import('./Component/TopFiveUser'))
const TopFiveApiUser = lazy(() => import('./Component/TopFiveApiUser'))
const TopFivePanelUser = lazy(() => import('./Component/TopFivePanelUser'))

const DashboardPage: FC = () => {
  // const {data: balanceData} = useQuery('balance', () => dashboardRequest<IBalance>('/api/protect/balance', auth?.accessToken))
  // const {data} = useQuery(['getAllRechargeInfo', 'getTodayRecharge', 'getCurrentYearRecharge', 'getCurrentMonthRecharge'], async () => {
  //   const getAllRechargeInfoPromise = await dashboardRequest('/api/protect/getallRechargeInfo', auth?.accessToken)
  //   const getTodayRechargePromise = await dashboardRequest('/api/protect/getTodayRecharge', auth?.accessToken)
  //   const getCurrentYearRechargePromise = await dashboardRequest('/api/protect/getCurrentYearRecharge', auth?.accessToken)
  //   const getCurrentMonthRechargePromise = await dashboardRequest('/api/protect/getCurrentMonthRecharge', auth?.accessToken)

  //   const result = await Promise.all([getAllRechargeInfoPromise, getTodayRechargePromise, getCurrentYearRechargePromise, getCurrentMonthRechargePromise])
  //   return result
  // })
  const {auth, currentUser} = useAuth()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getPriceList({token: auth?.accessToken, confId: currentUser?.charging_route_id}))
    dispatch(
      getAdditionalPriceList({token: auth?.accessToken, confId: currentUser?.charging_route_id})
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {/* begin::Col */}
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          {/* <CardSkeleton/> */}
          <Suspense fallback={<CardSkeleton />}>
            <SmsCount className='h-md-50 mb-5 mb-xl-10' />

            {/* <StatisticsSmsCount /> */}
          </Suspense>
          <Suspense fallback={<CardSkeleton />}>
            <Balance labelColor='dark' textColor='gray-300' />
          </Suspense>
        </div>
        {/* begin::Col */}
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <Suspense fallback={<CardSkeleton />}>
            <SmsCost className='h-md-50 mb-5 mb-xl-10' />
            {/* <StatisticsSmsCost /> */}
          </Suspense>
          <Suspense fallback={<CardSkeleton />}>
            <SMSQuantity className='h-md-50 mb-5 mb-xl-10' />
          </Suspense>
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6 align-self-stretch'>
          {/* <EngageWidget10 className='h-md-100' /> */}

          <BreakdownByStatus className='card-xl-stretch h-100' color='primary' />
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12 mb-5'>
          <Suspense fallback={<ListSkeleton />}>
            <BrandedNonBrandedChart />
            {/* <ListSkeleton /> */}
          </Suspense>
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12'>
          <Suspense fallback={<ListSkeleton />}>
            <LastFiveTransaction
              className='card-xxl-stretch mb-xl-3'
              title='Last Five Transection'
            />
          </Suspense>
        </div>
        <div className='col-xl-12'>
          <Suspense fallback={<ListSkeleton />}>
            <TopFiveUser className='card-xxl-stretch mb-xl-3' />
          </Suspense>
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        <div className='col-xl-12'>
          {/* <Suspense fallback={<ListSkeleton />}>
            <TopFiveApiUser className='card-xl-stretch mb-xl-8' />
          </Suspense> */}
        </div>
        {/* <div className='col-xl-4'>
          <ListsWidget6 className='card-xl-stretch mb-xl-8' />
        </div> */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        {/* <div className='col-xl-4'>
          <ListsWidget6 className='card-xl-stretch mb-xl-8' />
        </div> */}
        <div className='col-xl-12'>
          {/* <Suspense fallback={<ListSkeleton />}>
            <TopFivePanelUser className='card-xl-stretch mb-xl-8' />
          </Suspense> */}
        </div>
      </div>
      {/* end::Row */}

      <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-4'>
          {/* <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        /> */}
        </div>
        <div className='col-xxl-8'>
          {/* <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' /> */}
        </div>
      </div>
      {/* <Tables/> */}
    </>
  )
}

const DashboardWrapper: FC = () => {
  // const intl = useIntl()

  useEffect(() => {
    document.title = 'Dashboard'
  }, [])

  return (
    <DashboardProvider>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle> */}
      <DashboardHeader />
      <DashboardPage />
    </DashboardProvider>
  )
}

export {DashboardWrapper}
