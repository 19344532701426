import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import {useThemeMode} from '../../../../_metronic/partials'
import {KTIcon} from '../../../../_metronic/helpers'

const CategoryDetailsSidebar = () => {
  const location = useLocation()
  const {mode} = useThemeMode()

  const activeClass = mode === 'dark' ? 'sidebar-nav-active-dark' : 'sidebar-nav-active-light'

  return (
    <div className='col-12 col-md-2'>
      <ul className='nav nav-stretch nav-line-tabs border-transparent fs-5 fw-semibold flex-column gap-4'>
        <li
          className={
            `px-6 py-2 rounded d-flex align-items-center gap-2 ` +
            (location.pathname === '/knowledgebase/category/details/hello' && activeClass)
          }
        >
          <KTIcon
            iconName={location.pathname === '/settings/email' ? 'up' : 'down'}
            className='fs-2 text-success'
          />
          <Link className='text-gray-700 sidebar-nav' to='/knowledgebase/category/details'>
            Summer
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default CategoryDetailsSidebar
