/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import SelectList from '../../utils/components/SelectList'
import {ListViewContext, useListView} from '../../utils/contextProvider/ListViewProvider'
import {KTIcon} from '../../../_metronic/helpers'

const DeliveryLogReportHeader = () => {
  const {selected} = useListView()

  return (
    <div>
      <div>
        {selected.length > 0 ? (
          <SelectList context={ListViewContext} />
        ) : (
          <>
            {' '}
            <button
              type='button'
              className='btn btn-light-primary me-3'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_2'
            >
              <KTIcon iconName='filter' className='fs-2' />
              Filter
            </button>
            <button
              className='btn btn-light-primary'
              type='button'
              id='dropdownMenuButton'
              data-bs-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <KTIcon iconName='exit-up' className='fs-2' />
              Export
            </button>
            <ul
              className='dropdown-menu menu menu-sub menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4'
              aria-labelledby='dropdownMenuButton'
            >
              <div className='menu-item px-3'>
                <a className='menu-link px-3'>CSV</a>

                <a className='menu-link px-3'>PDF</a>
              </div>
            </ul>
          </>
        )}
      </div>
    </div>
  )
}

export default DeliveryLogReportHeader
