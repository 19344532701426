import React, {ChangeEvent, FC, FormEvent, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Spinner} from 'react-bootstrap'
import {PaymentRequestData} from '../core/_model'
import {usePaymentProvider} from '../PaymentProvider'
import ImageInput from './ImageInput'
import {formatDate} from '../../../utils/helperFunction/_helper'

const ViewPaymentReqModal = () => {
  const {
    fileBlob,
    updatePayment,
    showModal: show,
    hide,
    isView,
    approvePaymentReq,
    rejectPaymentReq,
  } = usePaymentProvider()
  const [paymentData, setPaymentData] = useState<PaymentRequestData>({
    amount: '',
    paymentMedia: '',
    transectionId: '',
  })
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined)
  const [comment, setComment] = useState('Initiate')
  const [fileDataUrl, setFileDataUrl] = useState<any>('')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = event.target
    setPaymentData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0])
    }
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
  }

  const handleApprove = async () => {
    console.log('approve')
    approvePaymentReq(updatePayment!, comment)
  }

  const handleReject = async () => {
    console.log('reject')
    rejectPaymentReq(updatePayment!, comment)
  }

  const convertFile = () => {
    if (!fileBlob) {
      return
    }

    if (updatePayment) {
      const file = new Blob([fileBlob], {type: 'image/jpeg'})
      const fileName = updatePayment.paymentMedia
        ? `${updatePayment.paymentMedia}_slip.png`
        : 'payment_slip.png'
      const mainFile = new File([fileBlob], fileName, {
        type: file.type,
      })
      return mainFile
    } else {
      return undefined
    }
  }

  useEffect(() => {
    if (show && updatePayment) {
      setPaymentData({
        amount: updatePayment.amount,
        paymentMedia: updatePayment.paymentMedia,
        transectionId: updatePayment.transectionId,
      })
      const updateFile = convertFile()
      setSelectedFile(updateFile)

      const fileReader = new FileReader()

      // Check if fileReader is not null before using it
      if (fileReader) {
        fileReader.onload = (e: ProgressEvent<FileReader>) => {
          if (e.target) {
            setFileDataUrl(e.target.result)
          }
        }

        const updateFile = convertFile()
        setSelectedFile(updateFile)

        // Start reading the file
        fileReader.readAsDataURL(updateFile!)
      }
    } else {
      setPaymentData({
        amount: '',
        paymentMedia: '',
        transectionId: '',
      })
      setSelectedFile(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, updatePayment])

  return (
    <div className='modal fade' tabIndex={-3} id='kt_modal_3'>
      <div className='modal-dialog modal-lg'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Payment Request</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={hide}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          {show ? (
            <form onSubmit={handleSubmit}>
              <div className='modal-body'>
                <div className='row mb-4'>
                  <label className='col-lg-4 col-form-label fs-6'>
                    <span className='required'>Amount</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      name='amount'
                      placeholder='ex: 100'
                      className='form-control form-control-lg form-control-solid'
                      value={paymentData.amount}
                      onChange={handleChange}
                      disabled={isView}
                    />
                  </div>
                </div>

                <div className='row mb-4'>
                  <label className='col-lg-4 col-form-label fs-6'>
                    <span className='required'>Payment Media</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <select
                      name='paymentMedia'
                      className='form-control form-control-lg form-control-solid'
                      value={paymentData.paymentMedia}
                      onChange={handleChange}
                      disabled={isView}
                    >
                      <option value='cash'>Cash</option>
                      <option value='bkash'>Bkash</option>
                      <option value='nagad'>Nagad</option>
                      <option value='bankDeopositOrCheque'>Bank Deposit or Cheque</option>
                    </select>
                  </div>
                </div>

                <div className='row mb-4'>
                  <label className='col-lg-4 col-form-label fs-6'>
                    <span className='required'>transectionId / Reference</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      name='transectionId'
                      placeholder='ex: 12xcde56hk87'
                      className='form-control form-control-lg form-control-solid'
                      value={paymentData.transectionId}
                      onChange={handleChange}
                      disabled={isView}
                    />
                  </div>
                </div>

                <div className='row mb-4'>
                  <label className='col-lg-4 col-form-label fs-6'>
                    <span>Payment Slip</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <ImageInput
                      uploadFile={fileBlob}
                      isView={isView}
                      handleFileChange={handleFileChange}
                    />
                    {/* <input
                      type='file'
                      name='paymentSlip'
                      className='form-control form-control-lg form-control-solid'
                      style={{display: 'block'}}
                      onChange={handleFileChange}
                      disabled={isView}
                    /> */}

                    {selectedFile && <p className='mt-2 fs-6'>File: {selectedFile.name}</p>}
                  </div>
                </div>

                <div className='row mb-4'>
                  <label className='col-lg-4 col-form-label fs-6'>
                    <span className='required'>Comment</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      name='comment'
                      className='form-control form-control-lg form-control-solid'
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </div>
                </div>
                {isView && (
                  <>
                    <div className='row mb-2 align-items-center'>
                      <label className='col-lg-4 col-form-label fs-6'>
                        <span>Payment Date</span>
                      </label>

                      <div className='col-lg-8 fv-row'>{formatDate(updatePayment?.insertDate)}</div>
                    </div>

                    <div className='row mb-2 align-items-center'>
                      <label className='col-lg-4 col-form-label fs-6'>
                        <span>Status</span>
                      </label>

                      <div className='col-lg-8 fv-row'>{updatePayment?.status}</div>
                    </div>
                  </>
                )}
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={hide}
                >
                  Discard
                </button>
                <button
                  type='button'
                  className='btn btn-primary'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={handleApprove}
                >
                  Approve
                </button>
                <button
                  type='button'
                  className='btn btn-danger'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={handleReject}
                >
                  Reject
                </button>
              </div>
            </form>
          ) : (
            <div className='d-flex gap-2 justify-content-center mt-8'>
              <p className='fs-6'>Please wait..</p>
              <Spinner animation='border' size='sm' />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ViewPaymentReqModal
