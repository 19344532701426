import {ChangeEvent, FC, createContext, useContext, useEffect, useState} from 'react'
import {
  APPROVE_USER_URL,
  GET_LAST_RECHARGE_URL,
  GET_USER_LIST_URL,
  IBalanceInfo,
  ICountState,
  ISenderIdArray,
  IUser,
  UPDATE_BALANCE_URL,
  UserProviderProps,
} from './core/_model'
import * as XLSX from 'xlsx'
import {saveAs} from 'file-saver'
import {useAuth} from '../auth'
import {useThemeMode} from '../../../_metronic/partials'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import toast from 'react-hot-toast'
import {Badge} from 'react-bootstrap'
import {setUserType} from './core/helper'
import {blockUser, unBlockUser} from './core/userListRequest'

export const UserListContext = createContext<any>(null)

export const UserListProvider: FC<UserProviderProps> = ({children}) => {
  const {auth, loginAs, isLogingAs} = useAuth()
  const {mode} = useThemeMode()
  const [users, setUsers] = useState<ISenderIdArray>([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState({
    isError: false,
    message: '',
  })
  const [updateUser, setUpdateUser] = useState<IUser | undefined>(undefined)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [counts, setCounts] = useState<ICountState>({
    admin: 0,
    reseller: 0,
    client: 0,
    unknown: 0,
  })
  const [filteredUser, setFilteredUser] = useState<ISenderIdArray>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectUserType, setSelectUserType] = useState('')

  const navigate = useNavigate()

  const [sortColumn, setSortColumn] = useState('')
  const [sortOrder, setSortOrder] = useState('')

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalElement, setTotalElement] = useState(0)
  const [isLastPage, setIsLastPage] = useState(false)

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)

  const setParams = {
    pageNumber: currentPage - 1,
    pageSize: itemsPerPage,
  }

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = users?.slice(indexOfFirstItem, indexOfLastItem)

  const handleUpdateProfile = (user: IUser) => {
    navigate('/users/userupdate/' + user.id, {state: user})
  }

  const handleUpdateSenderId = (user: IUser) => {
    navigate(`/users/updatesenderid/${user.id}`, {state: user})
  }

  const hanldeUpdatePrice = (user: IUser) => {
    navigate(`priceupdate/${user.id}`, {state: user})
    setUpdateUser(user)
    console.log(updateUser)
  }

  const handleUserDetails = (user: IUser) => {
    navigate(`/users/userdetails/${user.id}`, {state: user})
  }

  // Sorting
  const handleSort = (column: React.SetStateAction<string>) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortColumn(column)
      setSortOrder('asc')
    }
  }

  const sortedItems = sortColumn
    ? [...currentItems].sort((a, b) => {
        return 0
      })
    : currentItems

  const getLastRechargeDate = async (userid: number) => {
    const res = await axios.get(`${GET_LAST_RECHARGE_URL}/${userid}`, {
      headers: {
        Authorization: `Bearer ${auth?.accessToken}`,
      },
    })
    const data = await res.data
    return data
  }

  // For update password modal
  const handleUpdateModal = (user: IUser) => {
    setUpdateUser(user)
    setShowUpdateModal(true)
  }

  const closeUpdateModal = () => {
    setUpdateUser(undefined)
    setShowUpdateModal(false)
  }

  const getUser = async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(GET_USER_LIST_URL, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
        params: setParams,
      })
      const data = await response.data
      const result = await data?.data
      const totalResult = await data?.totalElement
      const lastPage = await data?.lastpage
      setTotalElement(totalResult)
      setIsLastPage(lastPage)
      setUsers(data ? result : [])
      setTypeCount(result)
      setIsLoading(false)
      setError({isError: false, message: ''})
      toast.success('User List Fetched Successfully')
    } catch (err: any) {
      toast.dismiss()
      const errorMessage = (err as Error)?.message || 'An error occurred'
      setIsLoading(false)
      setError({isError: true, message: errorMessage})
      toast.error(`Error: ${errorMessage}`)
    }
  }

  const approveUser = async (user: IUser) => {
    try {
      toast.loading('Loading..')
      const response = await axios.get(`${APPROVE_USER_URL}/${user.id}`, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      toast.dismiss()
      toast.success('Successfully approved')
      getUser()
      return response
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
    }
  }

  const updateBalanceAPI = async (
    token: string | undefined,
    userid: number | undefined,
    balanceInfo: IBalanceInfo
  ) => {
    try {
      toast.loading('Updating Balance..')
      const response = await axios.post(`${UPDATE_BALANCE_URL}/${userid}`, balanceInfo, {
        headers: {Authorization: `Bearer ${token}`},
      })
      toast.dismiss()
      toast.success('Balance Updated Successfully')
      getUser()
      return response
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error from updating balance: ${error.message}`)
    }
  }

  const setTypeCount = (users: ISenderIdArray) => {
    setCounts({
      admin: 0,
      reseller: 0,
      client: 0,
      unknown: 0,
    })
    users?.forEach((user: IUser) => {
      const type = setUserType(user.user_profile).toLowerCase()
      setCounts((prev) => ({
        ...prev,
        [type]: prev[type] + 1,
      }))
      return user
    })
  }

  const handleBlockUser = async (user: IUser) => {
    await blockUser(auth?.accessToken, user.id)
    await getUser()
  }

  const handleUnBlockUser = async (user: IUser) => {
    await unBlockUser(auth?.accessToken, user.id)
    await getUser()
  }

  const kycForm = (id: number = 0, user: IUser) => {
    let kycFormButton
    if (id === 1) {
      kycFormButton = (
        <Badge style={{cursor: 'pointer'}} bg='success'>
          Done
        </Badge>
      )
    } else {
      kycFormButton = (
        <Badge style={{cursor: 'pointer'}} bg='warning' onClick={() => onKycFormClick(user)}>
          Not Done
        </Badge>
      )
    }
    return kycFormButton
  }

  let showPageLastEtries = !isLastPage ? currentPage * itemsPerPage : totalElement
  // our api page number starting from 0
  let showPageFirstEntries = !isLastPage
    ? showPageLastEtries + 1 - itemsPerPage
    : (currentPage - 1) * itemsPerPage + 1

  let serial: number[] = []

  for (let i = showPageFirstEntries; i <= showPageLastEtries; i++) {
    serial.push(i)
  }

  const downloadFile = () => {
    const formattedData = filteredUser.map((user: any) => {
      const blobNid = new Blob([user.nid])
      const blobTradelicense = new Blob([user.tradelicence])
      const nidFile = new File([blobNid], `${user.user_full_name}-nid.png`, {
        type: blobNid.type,
      }) // need to change
      const tradelicenseFile = new File(
        [blobTradelicense],
        `${user.user_full_name}-tradelicense.png`,
        {type: blobTradelicense.type}
      )
      const singleData = {
        ...user,
        nid: nidFile.name,
        trade_license: tradelicenseFile.name,
      }
      return singleData
    })
    const worksheet = XLSX.utils.json_to_sheet(formattedData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

    // Buffer to store the generated Excel file
    const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'})
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    })

    saveAs(blob, 'userlist.xlsx')
  }

  const onKycFormClick = (user: IUser) => {
    navigate(`/users/kycForm/${user.id}`, {state: user})
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const handleLoginAs = async (userid: string | number | undefined) => {
    // window.location.reload()
    loginAs(auth, userid)

    if (!isLogingAs) {
      navigate('/dashboard')
    }
  }

  useEffect(() => {
    document.title = 'User List'

    const filteredData = users?.filter((user) =>
      user.username && user.user_email
        ? user.username.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
          user.user_full_name.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
          user.user_email.toLowerCase().includes(searchTerm.toLocaleLowerCase())
        : user
    )
    setFilteredUser(filteredData)
    setTypeCount(filteredData)
  }, [users, searchTerm])

  useEffect(() => {
    getUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage])

  const values = {
    isLoading,
    users,
    filteredUser,
    error,
    updateUser,
    setUpdateUser,
    showUpdateModal,
    searchTerm,
    setSearchTerm,
    selectUserType,
    setSelectUserType,
    currentPage,
    setCurrentPage,
    itemsPerPage,
    setItemsPerPage,
    totalElement,
    handlePageChange,
    handleUpdateProfile,
    handleUpdateSenderId,
    hanldeUpdatePrice,
    handleUserDetails,
    handleUpdateModal,
    closeUpdateModal,
    getUser,
    approveUser,
    updateBalanceAPI,
    handleBlockUser,
    handleUnBlockUser,
    kycForm,
    downloadFile,
    handleLoginAs,
    onKycFormClick,
    serial,
  }

  return <UserListContext.Provider value={values}>{children}</UserListContext.Provider>
}

export const useUserListProvider = () => useContext(UserListContext)
