import {BASE_URL} from '../config'
import Reports from './Reports'

const GET_SENDERWISE_REPORT_URL = `${BASE_URL}/api/protect/senderwiseReport`

const SenderwiseReport = () => {
  return <Reports apiUrl={GET_SENDERWISE_REPORT_URL} title='Sender Wise Report' />
}

export default SenderwiseReport
