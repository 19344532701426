import {ChangeEvent, FC, createContext, useContext, useEffect, useState} from 'react'
import {
  CREATE_MASKING_SENDER_URL,
  CREATE_NON_MASKING_SENDER_URL,
  GET_SENDERIDS_URL,
  ISenderIdArray,
  MASRK_DEFAULT_SENDER_URL,
  MaskDataTypes,
  NonMaskDataTypes,
  Route,
  SenderIdProviderProps,
  UPDATE_BRANDED_URL,
  UPDATE_NON_BRANDED_URL,
} from '../core/_model'
import {useAuth} from '../../auth'
import {ISenderId} from '../SettingsModel'
import toast from 'react-hot-toast'
import axios from 'axios'
import {getRouteList} from '../SMSBroadCastRequest'
import * as XLSX from 'xlsx'
import {saveAs} from 'file-saver'

export const SenderIdContext = createContext<any>(null)

export const SenderIdProvider: FC<SenderIdProviderProps> = ({children}) => {
  const {auth, currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState<Route[]>([])
  const [error, setError] = useState({isError: true, message: ''})
  const [senderData, setSenderData] = useState<ISenderIdArray>([])
  const [filteredSender, setFilteredSender] = useState<ISenderIdArray>([])
  const [updateSender, setUpdateSender] = useState<ISenderId | undefined>(undefined)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [apiError, setApiError] = useState({isError: false, message: ''})

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalElement, setTotalElement] = useState(0)
  const [isLastPage, setIsLastPage] = useState(false)

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)

  const setParams = {
    pageNumber: currentPage - 1,
    pageSize: itemsPerPage,
  }

  const getSenderIds = async (params = setParams) => {
    try {
      setIsLoading(true)
      toast.loading('Getting Sender Ids..')
      const response = await axios.get(`${GET_SENDERIDS_URL}/${currentUser?.user_profile}`, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
        params: params,
      })
      const data = await response.data
      const result = await data.data
      const totalResult = await data.totalElement
      const lastPage = await data.lastpage
      setIsLastPage(lastPage)
      setTotalElement(totalResult)
      setSenderData(result)
      setIsLoading(false)
      setError({isError: false, message: ''})
      toast.dismiss()
      toast.success('Fetched Sender Ids')
    } catch (err) {
      const errorMessage = (err as Error)?.message || 'An error occurred'
      setIsLoading(false)
      setError({isError: true, message: errorMessage})
      toast.dismiss()
      toast.error(`Error: ${errorMessage}`)
    }
  }

  const markDefaultSender = async (id: number) => {
    try {
      toast.loading('Processing..')
      const response = await axios.get(`${MASRK_DEFAULT_SENDER_URL}/${id}?senderId=${id}`, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      toast.dismiss()
      getSenderIds()
      return response
    } catch (err) {
      const errorMessage = (err as Error)?.message || 'An error occurred'
      setIsLoading(false)
      setError({isError: true, message: errorMessage})
    }
  }

  const createMaskingSender = async (createMaskData: MaskDataTypes) => {
    try {
      toast.loading('Creating Masking Sender Id..')
      const res = await axios.post(CREATE_MASKING_SENDER_URL, createMaskData, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      const data = await res.data
      toast.dismiss()
      toast.success('Successfully created masking sender id')
      getSenderIds()
      return data
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message
        setApiError({isError: true, message: errorMessage})
        toast.dismiss()
      } else {
        toast.dismiss()
        toast.error(`Error: ${error.message}`)
      }
    }
  }

  const createNonMaskingSender = async (createNonMaskData: NonMaskDataTypes) => {
    try {
      toast.loading('Create Non Masking Sender')
      const res = await axios.post(CREATE_NON_MASKING_SENDER_URL, createNonMaskData, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      const data = await res.data
      getSenderIds()
      toast.dismiss()
      toast.success('Successfully created non masking sender')
      console.log(res)
      return data
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message
        setApiError({isError: true, message: errorMessage})
        toast.dismiss()
      } else {
        console.log(error.message)
        toast.dismiss()
        toast.error(`Error: ${error.message}`)
      }
    }
  }

  const updateNonBranded = async (id: number, updatedNonBranded: NonMaskDataTypes) => {
    try {
      toast.loading('Updating..')
      const res = await axios.put(`${UPDATE_NON_BRANDED_URL}/${id}`, updatedNonBranded, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      const data = await res.data
      getSenderIds()
      toast.dismiss()
      toast.success('Update successfully')
      return data
    } catch (err: any) {
      console.log(err)
      toast.dismiss()
      toast.error(`Error: ${err.message}`)
    }
  }

  const updateBranded = async (id: number, updateBranded: MaskDataTypes) => {
    try {
      toast.loading('Updating..')
      const res = await axios.put(`${UPDATE_BRANDED_URL}/${id}`, updateBranded, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      const data = await res.data
      getSenderIds()
      toast.dismiss()
      toast.success('Update successfully')
      return data
    } catch (err: any) {
      console.log(err)
      toast.dismiss()
      toast.error(`Error: ${err.message}`)
    }
  }

  const handleAction = (sender: ISenderId) => {
    setUpdateSender(sender)
  }

  const handleNewAction = () => {
    setUpdateSender(undefined)
  }

  const hide = () => {
    setUpdateSender(undefined)
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  useEffect(() => {
    document.title = 'Sender IDs'

    getSenderIds()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage])

  useEffect(() => {
    getRouteList(auth?.accessToken)
      .then((res) => {
        setOptions(res)
      })
      .catch((err) => {
        console.log(err)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const filteredData = senderData.filter((sender) =>
      sender.sender.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setFilteredSender(filteredData)
  }, [senderData, searchTerm])

  let showPageLastEtries = !isLastPage ? currentPage * itemsPerPage : totalElement
  let showPageFirstEntries = !isLastPage
    ? showPageLastEtries + 1 - itemsPerPage
    : (currentPage - 1) * itemsPerPage + 1

  let serial: number[] = []

  for (let i = showPageFirstEntries; i <= showPageLastEtries; i++) {
    serial.push(i)
  }

  const downloadSenderIds = () => {
    console.log(filteredSender)
    const worksheet = XLSX.utils.json_to_sheet(filteredSender)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

    // Buffer to store the generated Excel file
    const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'})
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    })

    saveAs(blob, 'senderids.xlsx')
  }
  const values = {
    options,
    isLoading,
    senderData,
    filteredSender,
    updateSender,
    apiError,
    setApiError,
    currentPage,
    itemsPerPage,
    totalElement,
    isLastPage,
    searchTerm,
    setCurrentPage,
    setSearchTerm,
    setItemsPerPage,
    handlePageChange,
    getSenderIds,
    markDefaultSender,
    createMaskingSender,
    createNonMaskingSender,
    updateBranded,
    updateNonBranded,
    setUpdateSender,
    handleSearch,
    downloadSenderIds,
    serial,
    hide,
  }
  return <SenderIdContext.Provider value={values}>{children}</SenderIdContext.Provider>
}

export const useSenderIdProvider = () => useContext(SenderIdContext)
