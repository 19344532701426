import {FC} from 'react'
import {BroadcastHistoryActionProps} from './core/_model'

const BroadcastHistoryAction: FC<BroadcastHistoryActionProps> = ({
  broadcast,
  setShowModal,
  setSingleBroadcast,
}) => {
  return (
    <button
      className='btn btn-light-primary btn-sm'
      data-bs-toggle='modal'
      data-bs-target='#kt_modal_1'
      onClick={() => {
        setShowModal(true)
        setSingleBroadcast(broadcast)
      }}
    >
      View
    </button>
  )
}

export default BroadcastHistoryAction
