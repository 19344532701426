import {BASE_URL} from '../config'
import Schedule from './Schedule'

const GET_SEND_DATA_URL = `${BASE_URL}/api/protect/sent`

const Send = () => {
  return <Schedule apiUrl={GET_SEND_DATA_URL} title='Schedule SMS Send' />
}

export default Send
