import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {ISenderId, QuickSms, QuickSmsForm, SmartSmsForm} from '../SettingsModel'
import {Button} from 'react-bootstrap'
import {useAuth} from '../../auth'
import {VarInputs, getSmsType} from '../QuickSMS'
import {BASE_URL} from '../../config'
import axios from 'axios'
import toast from 'react-hot-toast'
import {FormikProps} from 'formik'
import {useAppSelector} from '../../../hook'
import {PriceList} from '../../../PriceSlice'
import {fileUpload, getAdditionalCost, getCost} from '../core/helper'
import {ICampaignGroup} from '../SMSCampaign'
import {ColumnOption} from '../SmartSMS'
import {numberCounts} from '../../ContactAndGroup/core/helper'

const SMART_SMS_URL = `${BASE_URL}/api/protect/smartSms`

const getVars = (text: string) => {
  const regex = /#(.*?)#/g
  const matches = []
  let match

  // Loop over all matches and push the capturing group into the matches array
  while ((match = regex.exec(text)) !== null) {
    matches.push(match[1])
  }

  const dynamicInputs = matches.reduce<Record<string, string>>((acc, match) => {
    acc[match] = ''
    return acc
  }, {})

  return dynamicInputs
}

const extractValues = (sourceObj: any, targetObj: any) => {
  if (!targetObj) {
    return
  }
  const formatTarget = targetObj
  const extractedValues: any = {}
  for (const key in sourceObj) {
    if (sourceObj.hasOwnProperty(key) && key in formatTarget) {
      extractedValues[key] = formatTarget[key]
    }
  }
  return extractedValues
}

const replaceKeysWithValue = (template: string, obj: any) => {
  const regex = /#(.*?)#/g
  const keys = []
  let match
  while ((match = regex.exec(template)) !== null) {
    keys.push(match[1])
  }

  let replacedString = template
  keys.forEach((key: string) => {
    replacedString = replacedString.replace(`#${key}#`, obj[key])
  })

  return replacedString
}
export interface SendSmartSMSData {
  campaignName?: string
  senderId: number
  mobileNoColumn: string
  smsType: number
  smsContent: string
  sendNow: number
  smsLength: number
  smsPart: number
  scheduleDate: any
}

interface CampaignOverviewProps {
  formData: any
  setIsCreated: React.Dispatch<React.SetStateAction<boolean>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  formik: FormikProps<any>
  varMatch: boolean | undefined
}

const SmartSMSOverviewModal: React.FC<CampaignOverviewProps> = ({
  formData,
  setIsCreated,
  setLoading,
  formik,
  varMatch,
}) => {
  const {auth, currentUser} = useAuth()
  const {price, status, additionalPrice} = useAppSelector((state) => state.price)
  const [cost, setCost] = useState<number>(0)
  const [additionalCost, setAdditionalCost] = useState<number>(0)
  const [smsContent, setSmsContent] = useState('')

  let sendSmartData: SendSmartSMSData = {
    campaignName: formData.cm_name,
    smsType: formData.sms_type,
    smsContent: formData.sms_content,
    senderId: (formData.senderid as ISenderId)?.id,
    smsLength: formData.smsTextCharacterCount,
    sendNow: formData.sms_schedule === 'now' ? 1 : 0,
    scheduleDate:
      formData.sms_schedule === 'now'
        ? new Date().toISOString()
        : formData.schedule_date.toISOString(),
    smsPart: formData.sms_count,
    mobileNoColumn: (formData.mobileno as ISenderId)?.value,
  }

  console.log(sendSmartData)

  const sendSmartSMS = async (values: SmartSmsForm) => {
    toast.loading('Sending Smart SMS..')
    setLoading(true)

    await sendSMS(formData.fileId, sendSmartData)

    setLoading(false)
  }

  const sendSMS = async (fileId: number, sendSmartData: SendSmartSMSData) => {
    try {
      const response = await axios.post(`${SMART_SMS_URL}/${fileId}`, sendSmartData, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      toast.dismiss()
      toast.success('Successfully send smart sms')
      setIsCreated(true)
      console.log(response)
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
    }
  }

  useEffect(() => {
    if (formik.isValid && status === 'success' && price.length > 0 && additionalPrice) {
      const cost = getCost(price, formData)
      const additionalCost = getAdditionalCost(additionalPrice, formData)

      setCost(cost)
      setAdditionalCost(additionalCost)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.isValid, price, additionalPrice, status, formData])

  useEffect(() => {
    if (formik.isValid) {
      const textVars = getVars(formData.sms_content)
      const extractVars = extractValues(textVars, formData.fileData)
      const newContent = replaceKeysWithValue(formData.sms_content, extractVars)
      setSmsContent(newContent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.isValid, formData.sms_content])

  return (
    <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
      <div className={`${formik.isValid ? 'modal-dialog modal-xl' : 'modal-dialog'}`}>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title fs-2'>SMS Summary</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            {formik.isValid ? (
              <div className='d-flex gap-16'>
                <div className='w-50'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <p className='fs-3 fw-medium'>Campaign name</p>
                    <p className='fs-3 fw-medium'>{formData.cm_name}</p>
                  </div>
                  <div className='d-flex align-items-center justify-content-between'>
                    <p className='fs-3 fw-medium'>SMS type</p>
                    <p className='fs-3 fw-medium'>{getSmsType(formData.sms_type)}</p>
                  </div>
                  <div className='d-flex align-items-center justify-content-between'>
                    <p className='fs-3 fw-medium'>SMS Count</p>
                    <p className='fs-3 fw-medium'>{formData.sms_count}</p>
                  </div>
                  <div className='d-flex align-items-center justify-content-between'>
                    <p className='fs-3 fw-medium'>Total Recipeint</p>
                    <p className='fs-3 fw-medium'>{formData.reciepient}</p>
                  </div>
                  <div className='d-flex align-items-center justify-content-between'>
                    <p className='fs-3 fw-medium'>Number of SMS</p>
                    <p className='fs-3 fw-medium'>{formData.noOfSms}</p>
                  </div>
                  <div className='d-flex align-items-center justify-content-between'>
                    <p className='fs-3 fw-medium'>Sender Id</p>
                    <p className='fs-3 fw-medium'>
                      {typeof formData.senderid === 'string'
                        ? formData!.senderid
                        : formData.senderid?.value}
                    </p>
                  </div>
                  <div className='d-flex align-items-center justify-content-between'>
                    <p className='fs-3 fw-medium'>Schedule</p>
                    <p className='fs-3 fw-medium'>
                      {formData.sms_schedule === 'now'
                        ? 'Now'
                        : sendSmartData.scheduleDate.replace('T', ' ').slice(0, -8)}
                    </p>
                  </div>
                  <div className='d-flex align-items-center justify-content-between'>
                    <p className='fs-3 fw-medium'>Cost</p>
                    <p className='fs-3 fw-medium'>{cost.toFixed(2)} BDT</p>
                  </div>

                  <div className='d-flex align-items-center justify-content-between'>
                    <p className='fs-3 fw-medium'>Additional Cost</p>
                    <p className='fs-3 fw-medium'>{additionalCost.toFixed(2)} BDT</p>
                  </div>

                  <div className='d-flex align-items-center justify-content-between'>
                    <p className='fs-3 fw-medium'>Total</p>
                    <p className='fs-3 fw-medium'>{(cost + additionalCost).toFixed(2)} BDT</p>
                  </div>
                </div>

                <div className='w-50'>
                  <p className='fs-3 fw-medium'>SMS preview</p>
                  <div
                    className='fs-5 p-4 bg-light rounded-2 border border-info overflow-scroll'
                    style={{whiteSpace: 'pre-wrap', height: '370px'}}
                  >
                    {smsContent}
                  </div>
                </div>
              </div>
            ) : (
              <div className='alert alert-primary d-flex align-items-center' role='alert'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  fill='currentColor'
                  className='bi bi-exclamation-triangle-fill flex-shrink-0 me-2'
                  viewBox='0 0 16 16'
                  role='img'
                  aria-label='Warning:'
                >
                  <path d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z' />
                </svg>
                <div className='fs-4 fw-semibold'>Please fill up the required field.</div>
              </div>
            )}
          </div>

          <div className='modal-footer' style={{justifyContent: 'space-between'}}>
            {!varMatch && (
              <p className='fs-6 fw-bold text-warning-emphasis text-left w-75'>
                SMS template variable & your file columns variable did not match any one them at SMS
                content!
              </p>
            )}
            <div>
              <Button variant='secondary' data-bs-dismiss='modal' aria-label='Close'>
                Close
              </Button>{' '}
              <Button
                type='button'
                variant='primary'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => sendSmartSMS(formData)}
                disabled={!formik.isValid}
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SmartSMSOverviewModal
