/* eslint-disable jsx-a11y/anchor-is-valid */
import {ChangeEvent, PropsWithChildren, useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import toast from 'react-hot-toast'
import axios from 'axios'
import {useAuth} from '../auth'
import BraodcastHIstoryViewModal from './Modal/BraodcastHIstoryViewModal'
import {GET_BROADCAST_HISTORY_API, IBroadcastArray, ISingleBroadcast} from './core/_model'
import {KTIcon} from '../../../_metronic/helpers'
import TableContent from '../../utils/components/Table/TableContent'
import TablePagination from '../../utils/components/Table/TablePagination'
import {Column, HeaderProps} from 'react-table'
import {CustomHeader} from '../../utils/components/Table/CustomHeader'
import {formatDate} from '../../utils/helperFunction/_helper'
import BroadcastHistoryAction from './BroadcastHistoryAction'
import SelectionHeader from '../../utils/components/Table/SelectionHeader'
import {SelectionCell} from '../../utils/components/Table/SelectionCell'
import {ListViewContext, ListViewProvider} from '../../utils/contextProvider/ListViewProvider'
import BroadcastHistoryHeader from './BroadcastHistoryHeader'
import {Params} from '../Schedules/core/_model'

const TodayBroadCast = () => {
  const {auth, currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [broadcastHistory, setBroadcastHistory] = useState<IBroadcastArray>([])
  const [filterHistory, setFilterHistory] = useState<IBroadcastArray>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [startTime, setStartTime] = useState<string>('')
  const [endTime, setEndTime] = useState<string>('')
  const [showModal, setShowModal] = useState(false)
  const [singleBroadcast, setSingleBroadcast] = useState<ISingleBroadcast | undefined>(undefined)

  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(4)
  const [totalElement, setTotalElement] = useState(0)
  const [isLastPage, setIsLastPage] = useState(false)

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)

  const handleSearchSubmit = (e: any) => {
    e.preventDefault()
    setCurrentPage(1)
    setItemsPerPage(10)
  }

  const getBroadcastHistory = async (params: Params) => {
    try {
      setIsLoading(true)
      toast.loading('Fetching Data..')
      const response = await axios.get(
        `${GET_BROADCAST_HISTORY_API}/${currentUser?.user_profile}`,
        {
          headers: {Authorization: `Bearer ${auth?.accessToken}`},
          params: params,
        }
      )
      const result = await response.data
      const data = await result.data
      const lastPage = await result.lastpage
      const totalResult = await result.totalElement
      console.log(data)
      setIsLastPage(lastPage)
      setTotalElement(totalResult)
      setBroadcastHistory(data ? data : [])
      toast.dismiss()
      toast.success('Broadcast History Fetched Successfully')
      setIsLoading(false)
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
      setIsLoading(false)
    }
  }

  // const formatBroadcastData = (data: any) => {
  //   const formattedArray: IBroadcastArray = []
  //   data.map((singleItem: any, index: number) => {
  //     const {smsTextDto, senderInfoDto} = singleItem
  //     const singleData: ISingleBroadcast = {
  //       id: index + 1,
  //       date: smsTextDto.insDate,
  //       user: smsTextDto.userId,
  //       smsPart: smsTextDto.noOfPart,
  //       smsType: smsTextDto.smsType === 1 ? 'English' : 'Bangla',
  //       sender: senderInfoDto.sender,
  //       count: 'not found',
  //       smsText: smsTextDto.smsText,
  //     }
  //     formattedArray.push(singleData)
  //     return formattedArray
  //   })

  //   setBroadcastHistory(formattedArray)
  // }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  let showPageLastEtries = !isLastPage ? currentPage * itemsPerPage : totalElement
  let showPageFirstEntries = !isLastPage
    ? showPageLastEtries + 1 - itemsPerPage
    : (currentPage - 1) * itemsPerPage + 1

  let serial: number[] = []

  for (let i = showPageFirstEntries; i <= showPageLastEtries; i++) {
    serial.push(i)
  }

  useEffect(() => {
    // console.log(searchTerm)
    const filteredData = broadcastHistory?.filter(
      (list) =>
        list.dateTime?.toString().includes(searchTerm) ||
        list.id?.toString().includes(searchTerm) ||
        list.smsText?.toLowerCase().includes(searchTerm) ||
        list.sender?.toLowerCase().includes(searchTerm)
    )
    // console.log(filteredData)
    setFilterHistory(filteredData)
  }, [broadcastHistory, searchTerm])

  useEffect(() => {
    document.title = 'Broadcast History'
  }, [])

  useEffect(() => {
    if (startTime && endTime) {
      const setParams: Params = {
        pageNumber: currentPage - 1,
        pageSize: itemsPerPage,
        startDate: startTime,
        endDate: endTime,
      }

      getBroadcastHistory(setParams)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, currentPage])

  const broadcastHistoryColumn: ReadonlyArray<Column<ISingleBroadcast>> = [
    {
      Header: (props) => <SelectionHeader tableProps={props as any} context={ListViewContext} />,
      id: 'selection',
      Cell: ({...props}) => (
        <SelectionCell id={props.data[props.row.index].id} context={ListViewContext} />
      ),
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Date' className='min-w-100px' />
      ),
      id: 'dateTime',
      Cell: ({...props}) => <>{formatDate(props.data[props.row.index].dateTime)}</>,
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='User' className='min-w-100px' />
      ),
      id: 'user',
      accessor: 'user',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='SMS Part' className='min-w-100px' />
      ),
      id: 'smsPart',
      accessor: 'smsPart',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='SMS Type' className='min-w-100px' />
      ),
      id: 'smsType',
      accessor: 'smsType',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Sender' className='min-w-100px' />
      ),
      id: 'sender',
      accessor: 'sender',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Count' className='min-w-100px' />
      ),
      id: 'count',
      accessor: 'count',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='SMS Text' className='min-w-100px' />
      ),
      id: 'smsText',
      accessor: 'smsText',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Action' className='min-w-100px' />
      ),
      id: 'action',
      Cell: ({...props}) => (
        <BroadcastHistoryAction
          broadcast={props.data[props.row.index]}
          setShowModal={setShowModal}
          setSingleBroadcast={setSingleBroadcast}
        />
      ),
    },
  ]

  return (
    <ListViewProvider isLoading={isLoading} data={filterHistory}>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header d-flex justify-content-between'>
          <div className='card-title'>
            <h3 className='er mb-0'>Archive Broadcast History</h3>
          </div>
          <div className='card-title'>
            <Link to='/dashboard'>
              <Button variant='primary' size='sm'>
                Dashboard
              </Button>
            </Link>
          </div>
        </div>
        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='Search Group'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <div className='card-title m-0'>
            <BroadcastHistoryHeader
              startTime={startTime}
              endTime={endTime}
              itemsPerPage={itemsPerPage}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              handleSearchSubmit={handleSearchSubmit}
              setItemsPerPage={setItemsPerPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>

        <TableContent
          isLoading={isLoading}
          tableData={filterHistory}
          tableColumn={broadcastHistoryColumn}
        >
          <TablePagination
            totalElement={totalElement}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            isLoading={isLoading}
          />
        </TableContent>
        <BraodcastHIstoryViewModal broadcast={singleBroadcast} setBroadcast={setSingleBroadcast} />
      </div>
    </ListViewProvider>
  )
}

export default TodayBroadCast
