import {ChangeEvent, FC, createContext, useContext, useEffect, useState} from 'react'
import {
  CREATE_SMS_TEMPLATE_URL,
  DELETE_SMS_TEMPLATE_URL,
  GET_SMS_TEMPLATE_URL,
  ISMSTemplate,
  ISMSTemplateArray,
  ITemplateContent,
  SMSTemplateProviderProps,
  UPDATE_SMS_TEMPLATE_URL,
} from '../core/_model'
import {useAuth} from '../../auth'
import toast from 'react-hot-toast'
import axios from 'axios'

export const SMSTemplateContext = createContext<any>(null)

export const SMSTemplateProvider: FC<SMSTemplateProviderProps> = ({children}) => {
  const {auth, currentUser} = useAuth()
  const [templates, setTemplates] = useState<ISMSTemplateArray>([])
  const [filteredTemplate, setFilteredTemplate] = useState<ISMSTemplateArray>([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState({isError: false, message: ''})
  const [updateTemplate, setUpdateTemplate] = useState<ISMSTemplate | undefined>(undefined)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>('')

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalElement, setTotalElement] = useState(0)
  const [isLastPage, setIsLastPage] = useState(false)

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)

  const setParams = {
    pageNumber: currentPage - 1,
    pageSize: itemsPerPage,
  }

  const handleAction = (template: ISMSTemplate) => {
    setUpdateTemplate(template)
    setShowUpdateModal(true)
  }

  const handleNewAction = () => {
    setShowUpdateModal(false)
    setUpdateTemplate(undefined)
  }

  const hide = () => {
    setShowUpdateModal(false)
    setUpdateTemplate(undefined)
  }

  const getSmsTemplates = async () => {
    try {
      setIsLoading(true)
      toast.loading('Fetching SMS Template..')
      const response = await axios.get(`${GET_SMS_TEMPLATE_URL}/${currentUser?.user_profile}`, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
        params: setParams,
      })
      const data = await response.data
      const result = await data.data
      const totalResult = await data.totalElement
      const lastPage = await data.lastpage
      setIsLastPage(lastPage)
      setTotalElement(totalResult)
      setTemplates(result)
      setError({isError: false, message: ''})
      setIsLoading(false)
      toast.dismiss()
      toast.success('Fetched successfully')
    } catch (err) {
      const errorMessage = (err as Error)?.message || 'An error occurred'
      setIsLoading(false)
      setError({isError: true, message: errorMessage})
      toast.dismiss()
      toast.error(`Error:${errorMessage}`)
    }
  }

  const createSmsTemplate = async (template: ITemplateContent) => {
    try {
      toast.loading('Creating new template..')
      const response = await axios.post(CREATE_SMS_TEMPLATE_URL, template, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      const data = await response.data
      toast.dismiss()
      toast.success('Template created successfully.')
      getSmsTemplates()
      return data
    } catch (err) {
      const errorMessage = (err as Error)?.message || 'An error occurred'
      setIsLoading(false)
      setError({isError: true, message: errorMessage})
      toast.dismiss()
      toast.error(`Error:${errorMessage}`)
    }
  }

  const updateSmsTemplate = async (templateId: number, updatedValue: ITemplateContent) => {
    try {
      toast.loading('Updating Template..')
      const response = await axios.put(
        `${UPDATE_SMS_TEMPLATE_URL}/${templateId}?smsTemplateId=${templateId}`,
        updatedValue,
        {
          headers: {Authorization: `Bearer ${auth?.accessToken}`},
        }
      )
      toast.dismiss()
      toast.success('Update template successfully')
      getSmsTemplates()
      return response
    } catch (error) {
      const errorMessage = (error as Error)?.message || 'An error occurred'
      toast.dismiss()
      toast.error(`Error:${errorMessage}`)
      throw new Error(errorMessage)
    }
  }

  const deleteSmsTemplate = async (templateId: number) => {
    try {
      toast.loading('Deleting Template..')
      const response = await axios.delete(
        `${DELETE_SMS_TEMPLATE_URL}/${templateId}?templateId=${templateId}`,
        {
          headers: {Authorization: `Bearer ${auth?.accessToken}`},
        }
      )
      toast.dismiss()
      toast.success('Delete successfully')
      getSmsTemplates()
      return response
    } catch (error) {
      const errorMessage = (error as Error)?.message || 'An error occurred'
      toast.dismiss()
      toast.error(`Error:${errorMessage}`)
      throw new Error(errorMessage)
    }
  }

  useEffect(() => {
    document.title = 'SMS Template'

    getSmsTemplates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage])

  useEffect(() => {
    console.log(templates)
    const filteredData = templates.filter(
      (template) =>
        (template.templateName && template.templateName.toLowerCase().includes(searchTerm)) ||
        (template.smsText && template.smsText.toLowerCase().includes(searchTerm))
    )
    console.log(filteredData)
    setFilteredTemplate(filteredData)
  }, [templates, searchTerm])

  // our api page starting number is from 0 not 1
  let showPageLastEtries = !isLastPage ? currentPage * itemsPerPage : totalElement
  let showPageFirstEntries = !isLastPage
    ? showPageLastEtries + 1 - itemsPerPage
    : (currentPage - 1) * itemsPerPage + 1

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const deleteTemplate = (templateId: number) => {
    deleteSmsTemplate(templateId)
  }

  return (
    <SMSTemplateContext.Provider
      value={{
        isLoading,
        templates,
        filteredTemplate,
        error,
        updateTemplate,
        setUpdateTemplate,
        showUpdateModal,
        currentPage,
        itemsPerPage,
        totalElement,
        isLastPage,
        setCurrentPage,
        setItemsPerPage,
        handlePageChange,
        handleAction,
        handleNewAction,
        hide,
        getSmsTemplates,
        createSmsTemplate,
        deleteTemplate,
        updateSmsTemplate,
        setSearchTerm,
      }}
    >
      {children}
    </SMSTemplateContext.Provider>
  )
}

export const useSMSTemplateProvider = () => useContext(SMSTemplateContext)
