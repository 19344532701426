import React from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {useSenderIdProvider} from '../context/SenderIdProvider'

const SenderIdFilter = () => {
  const {isLoading, itemsPerPage, setCurrentPage, setItemsPerPage} = useSenderIdProvider()
  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        id='dropdownMenuButton'
        data-bs-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
      >
        <KTIcon iconName='filter' className='fs-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div
        className='dropdown-menu menu menu-sub menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-300px w-md-325px py-4'
        aria-labelledby='dropdownMenuButton'
      >
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-gray-900 fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Show Entries:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select entries'
              data-hide-search='true'
              name='entries'
              id='entries'
              value={itemsPerPage}
              onChange={(e) => {
                setCurrentPage(1)
                setItemsPerPage(parseInt(e.target.value))
              }}
            >
              <option value='10'>10</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
              <option value='250'>250</option>
            </select>
          </div>
          {/* end::Input group */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export default SenderIdFilter
