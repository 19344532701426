/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ChangeEvent, PropsWithChildren, useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import toast from 'react-hot-toast'
import axios from 'axios'
import {useAuth} from '../auth'
import {KTIcon} from '../../../_metronic/helpers'
import TodaySMSFilterModal from './Modal/TodaySMSFilterModal'
import {GET_SMS_HISTORY, ISMSData, ISMSDataArray, ISearchParams} from './core/_model'
import TableContent from '../../utils/components/Table/TableContent'
import TablePagination from '../../utils/components/Table/TablePagination'
import {Column, HeaderProps} from 'react-table'
import {CustomHeader} from '../../utils/components/Table/CustomHeader'
import {formatDate} from '../../utils/helperFunction/_helper'
import SelectionHeader from '../../utils/components/Table/SelectionHeader'
import {ListViewContext, ListViewProvider} from '../../utils/contextProvider/ListViewProvider'
import {SelectionCell} from '../../utils/components/Table/SelectionCell'
import TodaySMSHeader from './TodaySMSHeader'

const TodaySMS = () => {
  const {auth} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [todaySms, setTodaySms] = useState<ISMSDataArray>([])
  const [filteredSms, setFilteredSms] = useState<ISMSDataArray>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [click, setClick] = useState(0)
  const [params, setParams] = useState<any>({})

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalElement, setTotalElement] = useState(0)
  const [isLastPage, setIsLastPage] = useState(false)

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)

  let pageNumber = currentPage - 1
  let pageSize = itemsPerPage

  const onSearchFormSubmit = (searchParams: ISearchParams) => {
    setCurrentPage(1)
    setItemsPerPage(10)
    setParams({
      ...searchParams,
    })
    setClick((prev) => prev + 1)
    // console.log(searchParams)
    // getTodayAPISMS(searchParams, pageNumber, pageSize)
  }

  // http://167.71.199.212:9001/api/protect/liveReport/getTodaysms/panel?channel=panel&startTime=2024-02-05T08:00&endTime=2024-02-05T10:00&msisdn=8801677008414&status=0

  // http://167.71.199.212:9001/api/protect/liveReport/getTodaysms/api?channel=api&startTime=2024-02-05T08%3A00&endTime=2024-02-05T10%3A00&msisdn=8801677008414&status=0

  const getTodayAPISMS = async (
    searchParams: ISearchParams,
    pageNumber: number,
    pageSize: number
  ) => {
    try {
      setIsLoading(true)
      toast.dismiss()
      console.log(searchParams)
      // const response = await axios.get(
      //   `${BASE_URL}/api/protect/liveReport/getTodaysms/${
      //     searchParams.channel
      //   }?senderIds=${searchParams.senderIds.join('&senderIds=')}`,
      //   {
      //     headers: {Authorization: `Bearer ${auth?.accessToken}`},
      //     params: {
      //       ...params,
      //       channel: searchParams.channel,
      //       startTime: searchParams.startTime,
      //       endTime: searchParams.endTime,
      //       msisdn: searchParams.msisdn,
      //       status: searchParams.status,
      //     },
      //   }
      // )
      const url =
        searchParams?.senderIds.length > 0
          ? `${GET_SMS_HISTORY}/${searchParams.channel}?senderIds=${searchParams.senderIds.join(
              '&senderIds='
            )}`
          : `${GET_SMS_HISTORY}/${searchParams.channel}`
      setIsLoading(true)
      toast.loading('Getting History..')
      const response = await axios.get(url, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
        params: {
          pageNumber,
          pageSize,
          channel: searchParams.channel,
          startTime: searchParams.startTime,
          endTime: searchParams.endTime,
          msisdn: searchParams.msisdn,
          status: searchParams.status,
        },
      })
      const result = await response.data
      const lastPage = await result.lastpage
      const totalResult = await result.totalElement
      const data = await result.data
      console.log(data)
      setIsLastPage(lastPage)
      setTotalElement(totalResult)
      setTodaySms(data)
      toast.dismiss()
      toast.success('Today SMS Fetched Successfully')
      setIsLoading(false)
    } catch (error: any) {
      setIsLoading(false)
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
      console.log(error)
    }
  }

  // const formatedData = (data: any) => {
  //   let newData: IRowsDataArray = []
  //   data.map((item: any) => {
  //     const {apiSmsInfo, userInformationDto} = item
  //     const singleData: IRowsData = {
  //       smsId: apiSmsInfo.id,
  //       date: apiSmsInfo.insdate,
  //       msisdn: apiSmsInfo.msisdn,
  //       sender: apiSmsInfo.senderid,
  //       smsPart: apiSmsInfo.sms_part,
  //       status: apiSmsInfo.sendstatus,
  //       smsCost: apiSmsInfo.cost,
  //       userName: userInformationDto.username,
  //       smsText: apiSmsInfo.sms_text,
  //       gwsid: '',
  //     }

  //     newData.push(singleData)
  //     return newData
  //   })
  //   setTodayApiSms(newData)
  //   console.log(newData)
  // }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  let showPageLastEtries = !isLastPage ? currentPage * itemsPerPage : totalElement
  let showPageFirstEntries = !isLastPage
    ? showPageLastEtries + 1 - itemsPerPage
    : (currentPage - 1) * itemsPerPage + 1

  let serial: number[] = []

  for (let i = showPageFirstEntries; i <= showPageLastEtries; i++) {
    serial.push(i)
  }

  useEffect(() => {
    document.title = 'Today SMS'
  }, [])

  useEffect(() => {
    const filteredData = todaySms?.filter(
      (list) =>
        list.mssisdn?.toLowerCase().includes(searchTerm) ||
        list.sender?.toLowerCase().includes(searchTerm) ||
        list.username?.toLowerCase().includes(searchTerm) ||
        list.username?.toLowerCase().includes(searchTerm) ||
        list.smsText?.toLowerCase().includes(searchTerm) ||
        list.date?.toLowerCase().includes(searchTerm)
    )
    setFilteredSms(filteredData)
  }, [todaySms, searchTerm])

  useEffect(() => {
    if (params.channel) {
      getTodayAPISMS(params, pageNumber, pageSize)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, currentPage, click])

  const TodaySMSColumn: ReadonlyArray<Column<ISMSData>> = [
    {
      Header: (props) => <SelectionHeader tableProps={props as any} context={ListViewContext} />,
      id: 'selection',
      Cell: ({...props}) => (
        <SelectionCell id={props.data[props.row.index].id} context={ListViewContext} />
      ),
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Date' className='min-w-100px' />
      ),
      id: 'dateTime',
      Cell: ({...props}) => <>{formatDate(props.data[props.row.index].date)}</>,
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='MSSISDN' className='min-w-100px' />
      ),
      id: 'mssisdn',
      accessor: 'mssisdn',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Sender' className='min-w-100px' />
      ),
      id: 'sender',
      accessor: 'sender',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='SMS Part' className='min-w-100px' />
      ),
      id: 'smsPart',
      accessor: 'smsPart',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Status' className='min-w-100px' />
      ),
      id: 'status',
      accessor: 'status',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='SMS Cost' className='min-w-100px' />
      ),
      id: 'cost',
      accessor: 'cost',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Username' className='min-w-100px' />
      ),
      id: 'username',
      accessor: 'username',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='SMS Text' className='min-w-100px' />
      ),
      id: 'smsText',
      accessor: 'smsText',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='GW Res' className='min-w-100px' />
      ),
      id: 'gwRes',
      accessor: 'gwRes',
    },
  ]

  return (
    <ListViewProvider isLoading={isLoading} data={filteredSms}>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header d-flex justify-content-between'>
          <div className='card-title'>
            <h3 className='er mb-0'>SMS Today</h3>
          </div>
          <div className='card-title'>
            <Link to='/dashboard'>
              <Button variant='primary' size='sm'>
                Dashboard
              </Button>
            </Link>
          </div>
        </div>

        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='Search Group'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <div className='card-title m-0'>
            <TodaySMSHeader />

            <TodaySMSFilterModal
              onSearchFormSubmit={onSearchFormSubmit}
              require={true}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>

        <TableContent isLoading={isLoading} tableData={filteredSms} tableColumn={TodaySMSColumn}>
          <TablePagination
            totalElement={totalElement}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            isLoading={isLoading}
          />
        </TableContent>
      </div>
    </ListViewProvider>
  )
}

export default TodaySMS
