import React, {FC} from 'react'
import {ISenderId} from '../core/_model'
import {useSenderIdProvider} from '../context/SenderIdProvider'

type Props = {
  sender: ISenderId
}

const SenderDefault: FC<Props> = ({sender}) => {
  const {markDefaultSender} = useSenderIdProvider()

  const badgeClasses =
    sender.markAsDefault === 1 ? 'badge bg-secondary border-0' : 'badge bg-success border-0'

  return (
    <div>
      <button
        className={badgeClasses}
        onClick={() => markDefaultSender(sender.id)}
        disabled={sender.markAsDefault === 1}
      >
        {sender.markAsDefault === 1 ? 'Default' : 'Mark as default'}
      </button>
    </div>
  )
}

export default SenderDefault
