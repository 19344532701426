import React from 'react'
import {useUserListProvider} from './UserListProvider'
import {KTIcon} from '../../../_metronic/helpers'

const UserFilter = () => {
  const {
    isLoading,
    itemsPerPage,
    setCurrentPage,
    setItemsPerPage,
    selectUserType,
    setSelectUserType,
  } = useUserListProvider()
  return (
    <>
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        id='dropdownMenuButton'
        data-bs-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
      >
        <KTIcon iconName='filter' className='fs-2' />
        Filter
      </button>
      <div
        className='dropdown-menu menu menu-sub menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-300px w-md-325px py-4'
        aria-labelledby='dropdownMenuButton'
      >
        <div className='px-7 py-5'>
          <div className='fs-5 text-gray-900 fw-bolder'>Filter Options</div>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Show Entries:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select entries'
              data-hide-search='true'
              name='entries'
              id='entries'
              value={itemsPerPage}
              onChange={(e) => {
                setCurrentPage(1)
                setItemsPerPage(parseInt(e.target.value))
              }}
            >
              <option value='10'>10</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
              <option value='250'>250</option>
            </select>
          </div>

          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Select User Type</label>
            <select
              className='form-select form-select-solid fw-bolder'
              name='userType'
              id='userType'
              value={selectUserType}
              onChange={(e) => setSelectUserType(e.target.value)}
            >
              <option>Select User Type</option>
              <option>Topreseller</option>
              <option>Client</option>
              <option>Unknown</option>
            </select>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserFilter
