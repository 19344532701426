/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ChangeEvent, FormEvent, PropsWithChildren, useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import toast from 'react-hot-toast'
import axios from 'axios'
import {useAuth} from '../../auth'
import TableContent from '../../../utils/components/Table/TableContent'
import TablePagination from '../../../utils/components/Table/TablePagination'
import {GET_INVOICE_LIST_URL, IInvoiceList, IInvoiceListArray} from '../core/_model'
import {Column, HeaderProps} from 'react-table'
import {CustomHeader} from '../../../utils/components/Table/CustomHeader'
import {formatDate} from '../../../utils/helperFunction/_helper'
import {KTIcon} from '../../../../_metronic/helpers'
import {Params} from '../../Schedules/core/_model'
import SelectionHeader from '../../../utils/components/Table/SelectionHeader'
import {ListViewContext, ListViewProvider} from '../../../utils/contextProvider/ListViewProvider'
import {SelectionCell} from '../../../utils/components/Table/SelectionCell'
import TableHeader from '../../../utils/components/Table/TableHeader'

const RechargeFrom = () => {
  const {auth} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [invoiceList, setInvoiceList] = useState<IInvoiceListArray>([])
  const [filteredInvoiceList, setFilteredInvoiceList] = useState<IInvoiceListArray>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [startTime, setStartTime] = useState<string>('')
  const [endTime, setEndTime] = useState<string>('')
  const [click, setClick] = useState(0)

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalElement, setTotalElement] = useState(0)
  const [isLastPage, setIsLastPage] = useState(false)

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)

  const handleSearchSubmit = async (event: FormEvent) => {
    event.preventDefault()
    setCurrentPage(1)
    setItemsPerPage(10)
    setClick((prev) => prev + 1)
  }

  const getInvoiceList = async (params: any) => {
    try {
      setIsLoading(true)
      toast.loading('Loading Invoice List..')
      const response = await axios.get(GET_INVOICE_LIST_URL, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
        params: params,
      })
      const result = await response.data
      const lastPage = await result.lastpage
      const totalResult = await result.totalElement
      const data = await result.data
      formatData(data ? data : [])
      setIsLastPage(lastPage)
      setTotalElement(totalResult)
      toast.dismiss()
      toast.success('Fetched Invoice List')
      setIsLoading(false)
    } catch (error: any) {
      setIsLoading(false)
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
    }
  }

  const formatData = (data: any) => {
    const formattedData: IInvoiceListArray = []
    data.map((singleItem: any) => {
      const {userInformation, id, invoiceNo, invoiceDate} = singleItem
      const singleData: IInvoiceList = {
        id: id,
        invoice: invoiceNo,
        clientName: userInformation.username,
        invoicedate: invoiceDate,
      }
      formattedData.push(singleData)
      return formattedData
    })
    setInvoiceList(formattedData)
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  let showPageLastEtries = !isLastPage ? currentPage * itemsPerPage : totalElement
  let showPageFirstEntries = !isLastPage
    ? showPageLastEtries + 1 - itemsPerPage
    : (currentPage - 1) * itemsPerPage + 1

  useEffect(() => {
    const filteredData = invoiceList?.filter(
      (list) =>
        list.invoice?.toLowerCase().includes(searchTerm) ||
        list.clientName?.toLowerCase().includes(searchTerm)
    )
    setFilteredInvoiceList(filteredData)
  }, [invoiceList, searchTerm])

  useEffect(() => {
    document.title = 'Invoice List'
  }, [])

  useEffect(() => {
    if (startTime && endTime) {
      const setParams: Params = {
        pageNumber: currentPage - 1,
        pageSize: itemsPerPage,
        startDate: startTime,
        endDate: endTime,
      }

      getInvoiceList(setParams)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, currentPage, click])

  const IInvoiceListColumn: ReadonlyArray<Column<IInvoiceList>> = [
    {
      Header: (props) => <SelectionHeader tableProps={props as any} context={ListViewContext} />,
      id: 'selection',
      Cell: ({...props}) => (
        <SelectionCell id={props.data[props.row.index].id} context={ListViewContext} />
      ),
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Invoice' className='min-w-100px text-center' />
      ),
      id: 'invoice',
      accessor: 'invoice',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Client Name' className='min-w-100px text-center' />
      ),
      id: 'clientName',
      accessor: 'clientName',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Invoice Date' className='min-w-100px text-center' />
      ),
      id: 'invoiceDate',
      Cell: ({...props}) => <>{formatDate(props.data[props.row.index].invoicedate)}</>,
    },
  ]

  return (
    <ListViewProvider isLoading={isLoading} data={filteredInvoiceList}>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0 d-flex justify-content-between'>
          <div className='card-title'>
            <h3 className='er mb-0'>Invoice List</h3>
          </div>
          <div className='card-title'>
            <Link to='/dashboard'>
              <Button variant='primary' size='sm'>
                Dashboard
              </Button>
            </Link>
          </div>
        </div>
        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='Search Group'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className='card-title m-0'>
            <TableHeader
              startTime={startTime}
              endTime={endTime}
              itemsPerPage={itemsPerPage}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              handleSearchSubmit={handleSearchSubmit}
              setItemsPerPage={setItemsPerPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
        <TableContent
          isLoading={isLoading}
          tableData={filteredInvoiceList}
          tableColumn={IInvoiceListColumn}
          className='text-center'
        >
          <TablePagination
            totalElement={totalElement}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            isLoading={isLoading}
          />
        </TableContent>
      </div>
    </ListViewProvider>
  )
}

export default RechargeFrom
