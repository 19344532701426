import React from 'react'
import SpamFilter from './SpamFilter'
import {SpamFilterProvider} from './SpamFIlterProvider'

const SpamFiltrerWrapper = () => {
  return (
    <SpamFilterProvider>
      <SpamFilter />
    </SpamFilterProvider>
  )
}

export default SpamFiltrerWrapper
