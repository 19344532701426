/* eslint-disable no-control-regex */
import React, {useEffect, useState} from 'react'
import {ISenderId, SmsCampaignFormInitValues as initialValues} from './SettingsModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {Button} from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
import {Link} from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import DateTimePicker from 'react-datetime-picker'
import 'react-datetime-picker/dist/DateTimePicker.css'
import 'react-calendar/dist/Calendar.css'
import 'react-clock/dist/Clock.css'
import Select from 'react-select'
import useSenderIdsOptions from '../../hooks/useSenderIdsOptions'
import {useAuth} from '../auth'
import clsx from 'clsx'
import SmsTemplateModal from './Modal/SmsTemplateModal'
import {MDBDataTable} from 'mdbreact'
import axios from 'axios'
import {BASE_URL} from '../config'
import toast from 'react-hot-toast'
import {useThemeMode} from '../../../_metronic/partials'
import {TemplateText, VarInputs, getCurrentTimestamp} from './QuickSMS'
import {CapitalizeFirstLetter} from '../Users/AddressForm'
import SMSCampaignOverviewModal from './Modal/SMSCampaignOverviewModal'
import useSms from './hook/useSms'
import CampaignGroupTable from './CampaignGroupTable'
import {KTIcon} from '../../../_metronic/helpers'

const GET_CAMPAIGN_GROUP_URL = `${BASE_URL}/api/protect/getallCampaignGroup`

export interface ICampaignGroup {
  id: number
  groupName: string
  subcount: number
  parentGroupId: number
  createDate: string
  lastUpdateDate: string
}

export interface ICampaignGroupArray extends Array<ICampaignGroup> {}

const smsCampaignScheme = Yup.object().shape({
  cm_name: Yup.string().required('Campaign name is required.'),
  group: Yup.string().required('Group name is required'),
  fromNumber: Yup.number()
    .required('From Serial Number is required')
    .test('not-zero', 'From Serial Number must not be zero', (value) => value !== 0),
  toNumber: Yup.number()
    .required('To Serial Number is required')
    .test('not-zero', 'To  Serial Number must not be zero', (value) => value !== 0),
  senderid: Yup.object().required('Sender Id is required'),
  sms_content: Yup.string().required('SMS content is required.'),
})

const SMSCampaign: React.FC = () => {
  const {auth, currentUser} = useAuth()
  const {mode} = useThemeMode()
  const {senderIdsOptions, defaultSender} = useSenderIdsOptions()
  const {smsState, handleSmsContentChange, handleSmsTypeChange, backToCountDefault} = useSms()
  const [selectGroup, setSelectGroup] = useState<ICampaignGroup | null>(null)
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null)
  const [formData, setFormData] = useState<any>(initialValues)
  const [groupList, setGroupList] = useState<ICampaignGroupArray>([])
  const [filteredGroupList, setFilteredGroupList] = useState<ICampaignGroupArray>([])
  const [loading, setLoading] = useState(false)
  const [disableDateTime, setDisableDateTime] = useState<Boolean>(true)
  const [templateText, setTemplateText] = useState<TemplateText>({
    isUse: false,
    smsText: '',
    isSmartTemplate: 0,
  })
  const [smartVarField, setSmartVarField] = useState<string[]>([])
  const [varInputs, setVarInputs] = useState<VarInputs | undefined>(undefined)
  const [isCreated, setIsCreated] = useState(false)

  const [searchTerm, setSearchTerm] = useState('')

  const providedCmName = `${currentUser?.user_full_name}- ${getCurrentTimestamp()}`

  const formik = useFormik({
    initialValues,
    validationSchema: smsCampaignScheme,
    onSubmit: (values) => {
      const numbers: number[] = []
      for (let i = values.fromNumber; i <= values.toNumber; i++) {
        numbers.push(i)
      }
      setFormData({
        ...values,
        sms_count: smsState.noOfSms,
        noOfSms: numbers.length * smsState.noOfSms,
        reciepient: numbers.length,
        smsTextCharacterCount: smsState.characterCount,
        sms_type: smsState.smsType,
      })
      console.log(values)
    },
  })

  const handleOnChange = (group: ICampaignGroup) => {
    setSelectGroup(group)
    setSelectedGroupId(group.id)
    formik.setFieldValue('group', group.groupName)
  }

  if (templateText.isUse) {
    formik.setFieldValue('sms_content', templateText.smsText)
    setTemplateText({
      isUse: false,
      smsText: templateText.smsText,
      isSmartTemplate: templateText.isSmartTemplate,
    })
  }

  const smartTemplate = (text: string) => {
    const regex = /#(.*?)#/g
    const matches = []
    let match

    // Loop over all matches and push the capturing group into the matches array
    while ((match = regex.exec(text)) !== null) {
      matches.push(match[1])
    }

    setSmartVarField(matches)

    const dynamicInputs = matches.reduce<Record<string, string>>((acc, match) => {
      acc[match] = ''
      return acc
    }, {})

    setVarInputs(dynamicInputs)
  }

  const handleVarFieldsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target
    setVarInputs((prevData: any) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleCancel = () => {
    formik.resetForm()
    formik.setFieldValue('cm_name', providedCmName)
    formik.setFieldValue('senderid', defaultSender[0])
    setIsCreated(false)
    setFormData(initialValues)
    setSmartVarField([])
    setVarInputs(undefined)
    backToCountDefault()
    setSelectGroup(null)
    setSelectedGroupId(null)
  }

  if (isCreated) {
    handleCancel()
  }

  useEffect(() => {
    if (templateText.isSmartTemplate) {
      smartTemplate(templateText.smsText)
    } else {
      setSmartVarField([])
      setVarInputs(undefined)
    }
  }, [templateText])

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '8px',
      padding: '4px',
      border: 0,
      backgroundColor: mode === 'light' ? '#f9f9f9' : '#1b1b29',
      borderColor: state.isFocused ? '#6c63ff' : '#d1d1d1',
      boxShadow: state.isFocused ? '0 0 0 1px #6c63ff' : null,
      '&:hover': {
        borderColor: '#6c63ff',
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#6c63ff' : null,
      color: state.isSelected ? '#ffffff' : null,
      '&:hover': {
        backgroundColor: '#6c63ff',
        color: '#ffffff',
      },
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '8px',
      backgroundColor: mode === 'light' ? '#f8f0f4' : '#1b1b29',
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)', // Add box shadow
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: mode === 'light' ? '#5e6278' : '#92929f', // Change color of selected value
    }),
  }

  useEffect(() => {
    formik.setFieldValue('cm_name', providedCmName)

    const getCampaignGroup = async () => {
      try {
        const response = await axios.get(GET_CAMPAIGN_GROUP_URL, {
          headers: {Authorization: `Bearer ${auth?.accessToken}`},
        })
        const data = await response.data
        setGroupList(data)
      } catch (error: any) {
        console.log(error)
        toast.dismiss()
        toast.error(`Error: ${error.message}`)
      }
    }

    getCampaignGroup()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    document.title = 'SMS Campaign'

    formik.setFieldValue('cm_name', providedCmName)
    formik.setFieldValue('senderid', defaultSender[0])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSender])

  useEffect(() => {
    const filteredData = groupList.filter((group) =>
      group.groupName.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setFilteredGroupList(filteredData)
  }, [searchTerm, groupList])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle=''
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='er m-0'>SMS Campaign </h3>
        </div>

        <div className='card-title m-0'>
          <Link to='/dashboard'>
            <Button variant='primary' size='sm'>
              Dashboard
            </Button>
          </Link>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <div className='card-body border-top p-9'>
          <div className='d-flex align-items-center position-relative mb-8'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='Search Group'
              className='form-control form-control-solid w-300px ps-14'
              placeholder='Search group name..'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <CampaignGroupTable
            isLoading={loading}
            groupList={filteredGroupList}
            handleOnChange={handleOnChange}
            selectedGroupId={selectedGroupId}
          ></CampaignGroupTable>

          <form onSubmit={formik.handleSubmit} noValidate className='form mt-5'>
            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label  fs-6'>
                <span className='required'>Campaign Name</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  name='cm_name'
                  placeholder='Campaign Name'
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.cm_name && formik.errors.cm_name},
                    {
                      'is-valid': formik.touched.cm_name && !formik.errors.cm_name,
                    }
                  )}
                  value={formik.values.cm_name}
                  onChange={formik.handleChange}
                />
                {formik.touched.cm_name && formik.errors.cm_name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.cm_name}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label  fs-6'>
                <span className='required'>Selected Group</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  name='group'
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.group && formik.errors.group},
                    {
                      'is-valid': formik.touched.group && !formik.errors.group,
                    }
                  )}
                  value={formik.values.group}
                  onChange={formik.handleChange}
                  placeholder='Select Group From Above List'
                />
                {formik.touched.group && formik.errors.group && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.group}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label  fs-6'>
                <span className='required'>From (Serial Number)</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='number'
                  name='fromNumber'
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.fromNumber && formik.errors.fromNumber},
                    {
                      'is-valid': formik.touched.fromNumber && !formik.errors.fromNumber,
                    }
                  )}
                  value={formik.values.fromNumber}
                  onChange={formik.handleChange}
                  placeholder='From Serial Number'
                />
                {formik.touched.fromNumber && formik.errors.fromNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.fromNumber}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label  fs-6'>
                <span className='required'>To (Serial Number)</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='number'
                  name='toNumber'
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.toNumber && formik.errors.toNumber},
                    {
                      'is-valid': formik.touched.toNumber && !formik.errors.toNumber,
                    }
                  )}
                  value={formik.values.toNumber}
                  onChange={formik.handleChange}
                  placeholder='To Serial Number'
                />
                {formik.touched.toNumber && formik.errors.toNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.toNumber}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label  fs-6'>
                <span className='required'>Select Sender ID</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <Select
                  name='senderid'
                  className={clsx(
                    {'is-invalid': formik.touched.senderid && formik.errors.senderid},
                    {
                      'is-valid': formik.touched.senderid && !formik.errors.senderid,
                    }
                  )}
                  placeholder='Choose year value'
                  value={formik.values.senderid}
                  onChange={(selectedOption) => {
                    formik.setFieldValue('senderid', selectedOption)
                  }}
                  options={senderIdsOptions}
                  // options={options}
                  styles={customStyles}
                />

                {formik.touched.senderid && formik.errors.senderid && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.senderid}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fs-6'>Select SMS Type</label>

              <div className='col-lg-8 fv-row'>
                <div className='d-flex align-items-center mt-3'>
                  <label className='form-check form-check-inline form-check-solid me-5'>
                    <input
                      className='form-check-input'
                      name='sms_type'
                      type='radio'
                      value={0}
                      checked={smsState.smsType === 0}
                      onChange={(e) => {
                        handleSmsTypeChange(e)
                      }}
                    />
                    <span className=' ps-2 fs-6'>Text</span>
                  </label>

                  <label className='form-check form-check-inline form-check-solid'>
                    <input
                      className='form-check-input'
                      name='sms_type'
                      type='radio'
                      value={1}
                      checked={smsState.smsType === 1}
                      onChange={(e) => {
                        handleSmsTypeChange(e)
                      }}
                    />
                    <span className=' ps-2 fs-6'>UniCode</span>
                  </label>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required  fs-6'>Enter Sms Content</label>
              <div className='col-lg-8 fv-row-3'>
                <textarea
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.sms_content && formik.errors.sms_content},
                    {
                      'is-valid': formik.touched.sms_content && !formik.errors.sms_content,
                    }
                  )}
                  placeholder='SMS Content'
                  rows={5}
                  name='sms_content'
                  style={{resize: 'vertical', fontSize: '1.0rem'}}
                  value={formik.values.sms_content}
                  onChange={(e) => {
                    handleSmsContentChange(e)
                    formik.handleChange(e)
                    if (smsState.characterCount === 0) {
                      setSmartVarField([])
                      setVarInputs(undefined)
                    }
                  }}
                ></textarea>
                {formik.touched.sms_content && formik.errors.sms_content && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.sms_content}</div>
                  </div>
                )}

                <div className='form-text'>
                  {smsState.characterCount} Characters | {smsState.noOfSms} SMS |{' '}
                  {smsState.noOfSms > 1 ? smsState.charPerSmsForLong : smsState.charPerSms} Char/SMS{' '}
                  <span>
                    <button
                      style={{borderRadius: 5, border: 'light'}}
                      type='button'
                      className='px-4 rounded border-0 bg-success fw-semibold text-white'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_3'
                    >
                      Insert SMS Template
                    </button>
                  </span>
                  |
                </div>
              </div>
            </div>

            {templateText.isSmartTemplate && varInputs
              ? smartVarField.map((field: string, index: number) => {
                  return (
                    <div className='row mb-4' key={index}>
                      <label className='col-lg-4 col-form-label fs-6'>
                        <span className='required'>{CapitalizeFirstLetter(field)}</span>
                      </label>
                      <div className='col-lg-8 fv-row'>
                        <input
                          name={field}
                          placeholder={`Enter ${CapitalizeFirstLetter(field)}`}
                          className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {'is-invalid': varInputs[field]! === ''},
                            {
                              'is-valid': varInputs[field],
                            }
                          )}
                          value={varInputs[field]}
                          onChange={(e) => handleVarFieldsChange(e)}
                        />
                      </div>
                    </div>
                  )
                })
              : null}

            <SmsTemplateModal setTemplateText={setTemplateText} isSmartTemplate={0} />

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fs-6 required'>Schedule SMS</label>
              <div className='col-lg-8 fv-row'>
                <div className='d-flex align-items-center mt-3'>
                  <label className='form-check form-check-inline form-check-solid me-5'>
                    <input
                      className='form-check-input'
                      name='sms_schedule'
                      type='radio'
                      value='now'
                      checked={formik.values.sms_schedule === 'now'}
                      onChange={(e) => {
                        formik.setFieldValue('sms_schedule', 'now')
                        setDisableDateTime(true)
                      }}
                    />
                    <span className='ps-2 fs-6'>Send Now</span>
                  </label>
                  <label className='form-check form-check-inline form-check-solid'>
                    <input
                      className='form-check-input'
                      name='sms_schedule'
                      type='radio'
                      value='later'
                      checked={formik.values.sms_schedule === 'later'}
                      onChange={() => {
                        formik.setFieldValue('sms_schedule', 'later')
                        setDisableDateTime(false)
                      }}
                    />
                    <span className='ps-2 fs-6'>Send Later</span>
                  </label>
                </div>

                <div className='col-lg-8 mt-4'>
                  {disableDateTime ? (
                    <div className='date-time-text'>{new Date().toLocaleString()}</div>
                  ) : (
                    <div className='send-later-date-picker'>
                      <DateTimePicker
                        value={formik.values.schedule_date}
                        name='schedule_date'
                        onChange={(val) => {
                          formik.setFieldValue('schedule_date', val)
                          // console.log(formik.values.schedule_date)
                        }}
                        className='form-select form-select-solid form-select-lg'
                        format='MMMM d, yyyy HH:mm'
                        minDate={new Date()}
                        disableClock={true}
                        clearIcon={null}
                        calendarIcon={null}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <div className='d-flex gap-4'>
                <button type='button' className='btn btn-danger' onClick={() => handleCancel()}>
                  Cancel
                </button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_1'
                  disabled={loading}
                >
                  {!loading && 'Review'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>

            {
              <SMSCampaignOverviewModal
                formData={formData}
                setIsCreated={setIsCreated}
                setLoading={setLoading}
                formik={formik}
                varInputs={varInputs}
                selectGroup={selectGroup}
              />
            }
          </form>
        </div>
      </div>
    </div>
  )
}

export {SMSCampaign}
