import {BASE_URL} from '../config'
import Schedule from './Schedule'

const GET_CANCEL_DATA_URL = `${BASE_URL}/api/protect/cencell`

const Cancel = () => {
  return <Schedule apiUrl={GET_CANCEL_DATA_URL} title='Schedule SMS Cancel' />
}

export default Cancel
