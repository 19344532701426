import {ReactNode} from 'react'
import {BASE_URL} from '../../config'

export const GET_SENDERIDS_URL = `${BASE_URL}/api/protect/getSendersWithPage`
export const MASRK_DEFAULT_SENDER_URL = `${BASE_URL}/api/protect/markAsDefaultSender`
export const CREATE_MASKING_SENDER_URL = `${BASE_URL}/api/protect/createmaskingsender`
export const CREATE_NON_MASKING_SENDER_URL = `${BASE_URL}/api/protect/createNonmaskingSender`
export const UPDATE_NON_BRANDED_URL = `${BASE_URL}/api/protect/updateNonBrandSender`
export const UPDATE_BRANDED_URL = `${BASE_URL}/api/protect/updateBrandedSender`

export const GET_SMS_TEMPLATE_URL = `${BASE_URL}/api/protect/getSmsTempleteByLoginUserwithPage`
export const CREATE_SMS_TEMPLATE_URL = `${BASE_URL}/api/protect/createSmsTemplate`
export const DELETE_SMS_TEMPLATE_URL = `${BASE_URL}/api/protect/deleteSmsTemplate`
export const UPDATE_SMS_TEMPLATE_URL = `${BASE_URL}/api/protect/updateTemplate`

export interface ISMSTemplate {
  id: number
  userId: number
  templateName: string
  smsText: string
  insertDate: string
  isSmartTemplate: number
  variableWithPattern: string
  variables: string
}

export interface ISMSTemplateArray extends Array<ISMSTemplate> {}

export interface SMSTemplateProviderProps {
  children: ReactNode
}

export interface ITemplateContent {
  templateName: string | undefined
  templateText: string | undefined
  isSmartSms: boolean
}

export interface ITemplateInitialValues {
  template_name: string | undefined
  template_text: string | undefined
}
export interface SenderIdProviderProps {
  children: ReactNode
}

export interface ISenderId {
  id: number
  sender: any
  routeconfid: number
  userid: number
  insertdate: string
  assignedby: number
  assignedto: number
  assigneddate: string
  branded: number
  markAsDefault?: number
}

export interface ISenderIdArray extends Array<ISenderId> {}

export type MaskingInfoType = {
  operatorPrefix: string
  routeconfId: number
  masking: string
}

export type MaskDataTypes = {
  senderId: string
  maskingInfo: MaskingInfoType[]
}

export type NonMaskDataTypes = {
  senderId: string
  routeId: number
}

export interface Route {
  createdate: string
  dlravailable: number
  dlrurl: string
  id: number
  isalternate: number
  issetenforce: number
  msisdnvalue: string
  name: string
  passparam: string
  passvalue: string
  prefixnotallow: string
  sendervalue: string
  smsctype: number
  smstextvalue: string
  url: string
  useridparam: string
  useridvalue: string
}

export interface IRouteList extends Array<Route> {}
export interface RouteList {
  options: Route[]
}
