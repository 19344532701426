const ListSkeleton = () => {
  return (
    <div className='card card-xxl-stretch mb-3 h=100'>
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark w-100'>
          <p className='w-50 p-4 bg-secondary rounded'></p>
        </h3>
      </div>
      <div className='card-body pt-0'>
        {[1, 2, 3, 4, 5].map((item) => {
          return <p className='w-100 p-10 bg-secondary rounded' key={item}></p>
        })}
      </div>
    </div>
  )
}

export default ListSkeleton
