/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios'
import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {BASE_URL} from '../config'
import {useAuth} from '../auth'
import DualListBox from 'react-dual-listbox'
import useSenderIdsOptions from '../../hooks/useSenderIdsOptions'
import toast from 'react-hot-toast'
import {ISenderId, ISenderIdArray} from '../SMSBroadCast/core/_model'

const GET_USER_SENDER_ID = `${BASE_URL}/api/protect/getallSenders`
const UPDATE_USER_SENDER_ID = `${BASE_URL}/api/protect/updateSender`

export const UpdateSenderId = () => {
  const {auth} = useAuth()
  const {state: updateUser} = useLocation()
  const {senderIdsOptions, allDefaultIds} = useSenderIdsOptions()
  const navigate = useNavigate()

  const getSenders = async () => {
    try {
      const response = await axios.get(`${GET_USER_SENDER_ID}/${updateUser.id}`, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      const data = await response.data
      formatedData(data)
    } catch (error) {
      console.log(error)
    }
  }

  const [selected, setSelected] = useState<string[]>([])

  const formatedData = (data: ISenderIdArray) => {
    const arrangedOptions: string[] = []
    data.map((id: {sender: any; id: number}) => {
      return arrangedOptions.push(id.sender)
    })
    setSelected(arrangedOptions)
    return arrangedOptions
  }

  const handleUpdateClick = async () => {
    const senderInfo = allDefaultIds.filter((item: ISenderId) => selected.includes(item.sender))

    try {
      toast.loading('Updating..')
      const response = await axios.put(
        `${UPDATE_USER_SENDER_ID}/${updateUser.id}`,
        {senderInfo},
        {
          headers: {Authorization: `Bearer ${auth?.accessToken}`},
        }
      )
      toast.dismiss()
      toast.success('Updated successfully')
      navigate('/users/userlist')
      return response
    } catch (error: any) {
      console.log(error)
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
    }
  }

  useEffect(() => {
    document.title = 'Update Sender Ids'

    getSenders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='er m-0'>Update User Sender ID</h3>
        </div>

        <div className='card-title m-0'>
          <Link to='/dashboard'>
            <Button variant='primary' size='sm'>
              Dashboard
            </Button>
          </Link>
        </div>
      </div>

      <div className='card-body'>
        <div className='row mb-4'>
          <label className='col-lg-4 col-form-label fs-6'>
            <span>User ID</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <input
              name='user_id'
              placeholder='User ID'
              className='form-control form-control-lg form-control-solid'
              defaultValue={updateUser.id}
            />
          </div>
        </div>

        <div className='row mb-4'>
          <label className='col-lg-4 col-form-label fs-6'>
            <span>Username</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <input
              name='username'
              placeholder='Username'
              className='form-control form-control-lg form-control-solid'
              defaultValue={updateUser.username}
            />
          </div>
        </div>

        <DualListBox
          options={senderIdsOptions}
          selected={selected}
          onChange={(labels) => {
            setSelected(labels)
            console.log(labels)
          }}
          canFilter
          icons={{
            moveLeft: <>&lt;</>,
            moveAllLeft: <>&#8810;</>,
            moveRight: <>&gt;</>,
            moveAllRight: <>&#8811;</>,
          }}
          showOrderButtons={false}
          showHeaderLabels={false}
          // style={{ backgroundColor: 'white' }} // Set background color to white
        />
      </div>

      <div className='card-footer'>
        <div className='d-flex gap-5'>
          <Link to='/users/userlist'>
            <Button variant='secondary'>Cancel</Button>
          </Link>
          <Button variant='primary' onClick={handleUpdateClick}>
            Update Sender ID
          </Button>
        </div>
      </div>
    </div>
  )
}
