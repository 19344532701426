import React, {FormEvent, useEffect, useState} from 'react'
import {Button, Row} from 'react-bootstrap'
import AddressForm from './AddressForm'
import {useLocation, useParams} from 'react-router-dom'
import {BASE_URL} from '../config'
import toast from 'react-hot-toast'
import axios from 'axios'
import {useAuth} from '../auth'

const KYC_FORM_URL = `${BASE_URL}/api/protect/kycform`

export interface KYCUserInfo {
  id?: number | string | undefined
  iatUserInfoId?: number
  subscriberName: string
  authorizedPersonName: string
  verificationType: string
  nidNo: string
  passportNo: string
  dateOfBirth: any
  gender: string
  permanentFlatNo: string
  permanentHouseNo: string
  permanentRoadNo: string
  permanentVillage: string
  permanentUpazila: string
  permanentDistrict: string
  permanentPostCode: number
  presentFlatNo: string
  presentHouseNo: string
  presentRoadNo: string
  presentVillage: string
  presentUpazila: string
  presentDistrict: string
  presentPostCode: number
  emailAddress: string
  mailingAddress: string
  fatherName: string
  motherName: string
  spouseName: string
  identifierName: string
  identifierNidNo: string
  identifierPhoneNo: string
}

const KycForm = () => {
  const {auth} = useAuth()
  const [formData, setFormData] = useState<KYCUserInfo>({
    id: 0,
    iatUserInfoId: 0,
    subscriberName: '',
    authorizedPersonName: '',
    verificationType: '',
    nidNo: '',
    passportNo: '',
    dateOfBirth: new Date(),
    gender: '',
    permanentFlatNo: '',
    permanentHouseNo: '',
    permanentRoadNo: '',
    permanentVillage: '',
    permanentUpazila: '',
    permanentDistrict: '',
    permanentPostCode: 0,
    presentFlatNo: '',
    presentHouseNo: '',
    presentRoadNo: '',
    presentVillage: '',
    presentUpazila: '',
    presentDistrict: '',
    presentPostCode: 0,
    emailAddress: '',
    mailingAddress: '',
    fatherName: '',
    motherName: '',
    spouseName: '',
    identifierName: '',
    identifierNidNo: '',
    identifierPhoneNo: '',
  })
  const [isLoading, setIsLoading] = useState(false)

  const {state: user} = useLocation()

  // console.log(user)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = event.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const formSubmit = (event: FormEvent) => {
    event.preventDefault()

    const sendingInfo: KYCUserInfo = {
      ...formData,
      id: user.id,
    }

    console.log(sendingInfo)
    submitKycForm(sendingInfo)
  }

  const submitKycForm = async (sendingInfo: KYCUserInfo) => {
    try {
      setIsLoading(true)
      toast.loading('Submitting KYC Form..')
      const response = await axios.post(`${KYC_FORM_URL}/${sendingInfo.id}`, sendingInfo, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      console.log(response)
      toast.dismiss()
      toast.success('Form Submitted')
      setIsLoading(false)
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
      setIsLoading(false)
    }
  }

  const clonePermanent = () => {
    setFormData((prev) => ({
      ...prev,
      presentFlatNo: formData.permanentFlatNo,
      presentRoadNo: formData.permanentRoadNo,
      presentHouseNo: formData.permanentHouseNo,
      presentVillage: formData.permanentVillage,
      presentUpazila: formData.permanentUpazila,
      presentDistrict: formData.permanentDistrict,
      presentPostCode: formData.permanentPostCode,
    }))
  }

  useEffect(() => {
    document.title = 'KYC Form Fill Up'
  }, [])

  return (
    <div className='card'>
      <div className='card-header'>
        <h3 className='er m-0 card-title'>KYC Form</h3>
      </div>

      <form onSubmit={formSubmit}>
        <div className='card-body'>
          {/* subscriberName */}
          <div className='row mb-4'>
            <label className='col-lg-4 col-form-label  fs-6'>
              <span className='required'>Subscriber Name</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <input
                name='subscriberName'
                placeholder='Subscriber Name'
                className='form-control form-control-lg form-control-solid'
                value={formData.subscriberName}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {/* authorizedPersonName */}
          <div className='row mb-4'>
            <label className='col-lg-4 col-form-label  fs-6'>
              <span className='required'>Authorized Person Name</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <input
                name='authorizedPersonName'
                placeholder='Subscriber Name'
                className='form-control form-control-lg form-control-solid'
                value={formData.authorizedPersonName}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {/* verificationType */}
          <div className='row mb-4'>
            <label className='col-lg-4 col-form-label  fs-6'>
              <span className='required'>Verification Type</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <select
                name='verificationType'
                className='form-control form-control-lg form-control-solid'
                onChange={handleChange}
                required
              >
                <option value=''>Select Verification Type</option>
                <option value='nationalIdNo'>National ID No</option>
                <option value='passportNo'>Passport No</option>
              </select>
            </div>
          </div>

          {formData.verificationType === 'nationalIdNo' && (
            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label  fs-6'>
                <span>National Id No</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  name='nidNo'
                  placeholder='Enter National ID No'
                  className='form-control form-control-lg form-control-solid'
                  value={formData.nidNo}
                  onChange={handleChange}
                />
              </div>
            </div>
          )}

          {formData.verificationType === 'passportNo' && (
            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label fs-6'>
                <span>Passport Id No</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  name='passportNo'
                  placeholder='Enter Passport ID No'
                  className='form-control form-control-lg form-control-solid'
                  value={formData.passportNo}
                  onChange={handleChange}
                />
              </div>
            </div>
          )}

          {/* dateOfBirth */}
          <div className='row mb-4'>
            <label className='col-lg-4 col-form-label fs-6'>
              <span className='required'>Date Of Birth</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <input
                type='datetime-local'
                name='dateOfBirth'
                placeholder='Enter Passport ID No'
                className='form-control form-control-lg form-control-solid'
                value={formData.dateOfBirth}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {/* gender */}
          <div className='d-flex mb-4 align-items-center'>
            <label className='col-lg-4 col-form-label fs-6'>
              <span className='required'>Gender</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <input
                className='form-check-input'
                name='gender'
                type='radio'
                value='male'
                onChange={handleChange}
              />
              <span className='ps-2 fs-6 me-4'>Male</span>
              <input
                className='form-check-input'
                name='gender'
                type='radio'
                value='female'
                onChange={handleChange}
              />
              <span className='ps-2 fs-6'>Female</span>
            </div>
          </div>

          <AddressForm formData={formData} handleChange={handleChange} addressType='permanent' />

          <AddressForm
            formData={formData}
            handleChange={handleChange}
            addressType='present'
            clonePermanent={clonePermanent}
          />

          {/* emailAddress */}
          <div className='row mb-4 mt-10'>
            <label className='col-lg-4 col-form-label  fs-6'>
              <span className='required'>Email Address</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <input
                name='emailAddress'
                placeholder='Enter Email'
                className='form-control form-control-lg form-control-solid'
                value={formData.emailAddress}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {/* mailingAddress */}
          <div className='d-flex mb-4 align-items-center'>
            <label className='col-lg-4 col-form-label fs-6'>
              <span className='required'>Mailing Address</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <input
                className='form-check-input'
                name='mailingAddress'
                type='radio'
                value='permanentAddress'
                onChange={handleChange}
              />
              <span className='ps-2 fs-6 me-4'>Permanent Address</span>
              <input
                className='form-check-input'
                name='mailingAddress'
                type='radio'
                value='presentAddress'
                onChange={handleChange}
              />
              <span className='ps-2 fs-6 me-4'>Present Address</span>
              <input
                className='form-check-input'
                name='mailingAddress'
                type='radio'
                value='ebill/contact'
                onChange={handleChange}
              />
              <span className='ps-2 fs-6 me-4'>E-bill/Contact</span>
              <input
                className='form-check-input'
                name='mailingAddress'
                type='radio'
                value='email'
                onChange={handleChange}
              />
              <span className='ps-2 fs-6 me-4'>Email</span>
            </div>
          </div>

          {/* fatherName */}
          <div className='row mb-4'>
            <label className='col-lg-4 col-form-label  fs-6'>
              <span className='required'>Father's Name</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <input
                name='fatherName'
                placeholder='Enter Father Name'
                className='form-control form-control-lg form-control-solid'
                value={formData.fatherName}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {/* motherName */}
          <div className='row mb-4'>
            <label className='col-lg-4 col-form-label  fs-6'>
              <span className='required'>Mother's Name</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <input
                name='motherName'
                placeholder='Enter Mother Name'
                className='form-control form-control-lg form-control-solid'
                value={formData.motherName}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {/* spouseName */}
          <div className='row mb-4'>
            <label className='col-lg-4 col-form-label  fs-6'>
              <span>Spouse's Name</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <input
                name='spouseName'
                placeholder='Enter Spouse Name'
                className='form-control form-control-lg form-control-solid'
                value={formData.spouseName}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className='mt-10 p-6 border border-secondary rounded'>
            <h4 className='mb-8'>Identifier Information</h4>
            {/* identifierName */}
            <div className='row mb-4 mt-10'>
              <label className='col-lg-4 col-form-label  fs-6'>
                <span className='required'>Name</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  name='identifierName'
                  placeholder='Enter Identifier Name'
                  className='form-control form-control-lg form-control-solid'
                  value={formData.identifierName}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            {/* identifierNidNo */}
            <div className='row mb-4 mt-10'>
              <label className='col-lg-4 col-form-label  fs-6'>
                <span className='required'>National ID No</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  name='identifierNidNo'
                  placeholder='Enter Identifier NID No'
                  className='form-control form-control-lg form-control-solid'
                  value={formData.identifierNidNo}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            {/* identifierPhoneNo */}
            <div className='row mb-4 mt-10'>
              <label className='col-lg-4 col-form-label  fs-6'>
                <span className='required'>Phone No</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  name='identifierPhoneNo'
                  placeholder='Enter Identifier Phone No'
                  className='form-control form-control-lg form-control-solid'
                  value={formData.identifierPhoneNo}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </div>
        </div>

        <div className='card-footer'>
          <Button variant='primary' type='submit' disabled={isLoading}>
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}

export default KycForm
