import React from 'react'
import {ArticleProvider} from './ArticleProvider'
import Article from './Article'

const ArticleWrapper = () => {
  return (
    <ArticleProvider>
      <Article />
    </ArticleProvider>
  )
}

export default ArticleWrapper
