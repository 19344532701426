import React, {Suspense, useEffect} from 'react'
import CardSkeleton from '../../utils/components/Skeleton/CardSkeleton'
import BrandedNonBrandedChart from '../../pages/dashboard/Component/Chart'
import {TicketOverviewCard} from './component/TicketOverviewCard'

const TicketOverview = () => {
  useEffect(() => {
    document.title = 'Tickets Overview'
  }, [])

  return (
    <div>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {/* begin::Col */}
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <Suspense fallback={<CardSkeleton />}>
            <TicketOverviewCard
              className='h-md-50 mb-5 mb-xl-10'
              title='Answered'
              subtitle='Tickets'
              color='#F1416C'
            />
          </Suspense>
          <Suspense fallback={<CardSkeleton />}>
            <TicketOverviewCard
              className='h-md-50 mb-5 mb-xl-10'
              title='In Progress'
              subtitle='Tickets'
              color='#7239EA'
            />
          </Suspense>
        </div>
        {/* begin::Col */}
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <Suspense fallback={<CardSkeleton />}>
            <TicketOverviewCard
              className='h-md-50 mb-5 mb-xl-10'
              title='Open'
              subtitle='Tickets'
              color='#7239EA'
            />
          </Suspense>
          <Suspense fallback={<CardSkeleton />}>
            <TicketOverviewCard
              className='h-md-50 mb-5 mb-xl-10'
              title='Close'
              subtitle='Tickets'
              color='#F1416C'
            />
          </Suspense>
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6 align-self-center'>
          <BrandedNonBrandedChart />
        </div>
        {/* end::Col */}
      </div>
    </div>
  )
}

export default TicketOverview
