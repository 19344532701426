import React, {ChangeEvent, FC, FormEvent, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import toast from 'react-hot-toast'
import {Spinner} from 'react-bootstrap'
import {PaymentReq, PaymentRequestData} from '../core/_model'
import {usePaymentProvider} from '../PaymentProvider'
import {ImageInputComponent} from '../../../../_metronic/assets/ts/components'
import ImageInput from './ImageInput'

const PaymentRequestModal = () => {
  const {
    createPayment,
    updatePayment,
    updatePaymentApi,
    fileBlob,
    showModal: show,
    hide,
  } = usePaymentProvider()
  const [paymentData, setPaymentData] = useState<PaymentRequestData>({
    amount: '',
    paymentMedia: '',
    transectionId: '',
  })
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = event.target
    setPaymentData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0])
    }
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()

    if (
      !paymentData.amount ||
      !paymentData.paymentMedia ||
      !paymentData.transectionId ||
      !selectedFile
    ) {
      toast.error(`Error: Fields can't be empty`)
      return
    }

    if (updatePayment) {
      console.log(updatePayment)
      const sendData: PaymentReq = {
        ...updatePayment,
        amount: paymentData.amount,
        paymentMedia: paymentData.paymentMedia,
        transectionId: paymentData.transectionId,
      }
      updatePaymentApi(sendData, selectedFile)
      setPaymentData({
        amount: '',
        paymentMedia: '',
        transectionId: '',
      })
      hide()
    } else {
      createPayment(paymentData, selectedFile)
      setPaymentData({
        amount: '',
        paymentMedia: '',
        transectionId: '',
      })
      hide()
    }
  }

  const convertFile = () => {
    if (!fileBlob) {
      return
    }

    if (updatePayment) {
      const file = new Blob([fileBlob])
      const fileName = updatePayment.paymentMedia
        ? `${updatePayment.paymentMedia}_slip.png`
        : 'payment_slip.png'
      const mainFile = new File([fileBlob], fileName, {
        type: file.type,
      })
      return mainFile
    } else {
      return undefined
    }
  }

  useEffect(() => {
    if (show && updatePayment) {
      setPaymentData({
        amount: updatePayment.amount,
        paymentMedia: updatePayment.paymentMedia,
        transectionId: updatePayment.transectionId,
      })
      const updateFile = convertFile()
      setSelectedFile(updateFile)
    } else {
      setPaymentData({
        amount: '',
        paymentMedia: 'cash',
        transectionId: '',
      })
      setSelectedFile(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, updatePayment])

  return (
    <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
      <div className='modal-dialog modal-lg'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Payment Request</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={hide}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          {show ? (
            <form onSubmit={handleSubmit}>
              <div className='modal-body'>
                <div className='row mb-4'>
                  <label className='col-lg-4 col-form-label fs-6'>
                    <span className='required'>Amount</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      name='amount'
                      placeholder='ex: 100'
                      className='form-control form-control-lg form-control-solid'
                      value={paymentData.amount}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className='row mb-4'>
                  <label className='col-lg-4 col-form-label fs-6'>
                    <span className='required'>Payment Media</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <select
                      name='paymentMedia'
                      className='form-control form-control-lg form-control-solid'
                      value={paymentData.paymentMedia}
                      onChange={handleChange}
                    >
                      <option value='cash'>Cash</option>
                      <option value='bkash'>Bkash</option>
                      <option value='nagad'>Nagad</option>
                      <option value='bankDeopositOrCheque'>Bank Deposit or Cheque</option>
                    </select>
                  </div>
                </div>

                <div className='row mb-4'>
                  <label className='col-lg-4 col-form-label fs-6'>
                    <span className='required'>transectionId / Reference</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      name='transectionId'
                      placeholder='ex: 12xcde56hk87'
                      className='form-control form-control-lg form-control-solid'
                      value={paymentData.transectionId}
                      onChange={handleChange}
                    />
                    <div className='mt-2 text-warning-emphasis'>
                      Hints: Cheque No/Bkash Txnid/Nagad Txnid/ Other Ref No
                    </div>
                  </div>
                </div>

                <div className='row mb-4'>
                  <label className='col-lg-4 col-form-label fs-6'>
                    <span>Payment Slip</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <ImageInput uploadFile={fileBlob} handleFileChange={handleFileChange} />
                    {/* <input
                      type='file'
                      name='paymentSlip'
                      className='form-control form-control-lg form-control-solid'
                      style={{display: 'block'}}
                      onChange={handleFileChange}
                    /> */}

                    {selectedFile && <p className='fs-6 mt-2'>Fileile: {selectedFile.name}</p>}
                    <div className='mt-2 text-warning-emphasis'>
                      Valid Format: png, jpg, jpeg, pdf
                    </div>
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={hide}
                >
                  Discard
                </button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  {updatePayment ? 'Update Request' : 'Submit Request'}
                </button>
              </div>
            </form>
          ) : (
            <div className='d-flex gap-2 justify-content-center mt-8'>
              <p className='fs-6'>Please wait..</p>
              <Spinner animation='border' size='sm' />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PaymentRequestModal
