import axios from 'axios'
import {BASE_URL} from '../../modules/config'
import {TOP_FIVE_USER_URL} from './core/_model'

export interface LastTransactionType {
  rechargeDate: string | undefined
  amount: number | undefined
}

export type LastFiveTransactionType = Array<LastTransactionType>

export const getLastFiveTransaction = async (
  token: string | undefined
): Promise<LastFiveTransactionType> => {
  const response = await axios.get(`${BASE_URL}/api/protect/lastfivetransection`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  const result = await response.data
  console.log(result)
  return result
}

export const getTopFiveUser = async (token: string | undefined) => {
  try {
    const response = await axios(TOP_FIVE_USER_URL, {
      headers: {Authorization: `Bearer ${token}`},
    })
    const data = await response.data
    return data
  } catch (error: any) {
    console.log(error.message)
    return error.message
  }
}

export const dashboardRequest = async <T>(
  endpoint: string,
  token: string | undefined
): Promise<T> => {
  try {
    const response = await axios.get(`${BASE_URL}${endpoint}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const result = await response.data
    return result
  } catch (error: any) {
    const errorMessage = (error as Error)?.message || 'An error occurred'
    throw new Error(errorMessage)
  }
}
