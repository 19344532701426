import React, {useState} from 'react'
import {DummyReport} from './dummyReport'
import {KTIcon, KTSVG} from '../../../_metronic/helpers'

type Props = {
  message: DummyReport
}

const MessageCell = ({message}: Props) => {
  const [seeMore, setSeeMore] = useState(false)

  return (
    <div>
      <p className='mb-1 d-flex align-items-center gap-2'>
        <KTSVG
          path={
            message.status.toLowerCase() === 'delivered'
              ? '/media/icons/duotune/arrows/arr016.svg'
              : '/media/icons/duotune/arrows/arr019.svg'
          }
          className='svg-icon svg-icon-2x'
        />
        <span>
          {message.status} - {message.error}
        </span>
      </p>
      <p className='mb-1'>{message.errorGroup}</p>
      <p>Reason: {message.reason}</p>
      <p className='text-gray-800'>{seeMore ? message.text : `${message.text.slice(0, 35)}...`}</p>
      {seeMore && (
        <>
          <p className='mb-1'>ID: {message.externalMessageId}</p>
          <p className='mb-1'>Number Of Messages: {message.smsCount}</p>
        </>
      )}

      <button
        type='button'
        className='btn btn-transparent px-0 text-primary'
        onClick={() => setSeeMore((prev) => !prev)}
      >
        {seeMore ? 'See Less' : 'See More'}
      </button>
    </div>
  )
}

export default MessageCell
