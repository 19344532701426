import React from 'react'
import {UserListProvider} from './UserListProvider'
import UserList from './UserList'
import {UserListSelectProvider} from './UserListSelectProvider'

const UserListWrapper = () => {
  return (
    <UserListProvider>
      <UserListSelectProvider>
        <UserList />
      </UserListSelectProvider>
    </UserListProvider>
  )
}

export default UserListWrapper
