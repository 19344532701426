import React, {useState, useEffect, ChangeEvent} from 'react'

interface SmsFormProps {}

interface SmsFormState {
  smsContent: string
  smsType: number
  characterCount: number
  charPerSms: number
  charPerSmsForLong: number
  noOfSms: number
}

const useSms = () => {
  const [smsState, setSmsState] = useState<SmsFormState>({
    smsContent: '',
    smsType: 0,
    characterCount: 0,
    charPerSms: 160,
    charPerSmsForLong: 153,
    noOfSms: 1,
  })

  useEffect(() => {
    const isUnicode = didITypeUnicode(smsState.smsContent)
    const {countArr, nonArr} = getCounts(smsState.smsContent)
    const newCharacterCount = countArr.length * 2 + nonArr.length
    setParameter(isUnicode)

    setSmsState((prevState) => ({
      ...prevState,
      characterCount: newCharacterCount,
      smsType: isUnicode ? 1 : 0,
    }))

    if (newCharacterCount > smsState.charPerSms) {
      setSmsState((prevState) => ({
        ...prevState,
        charPerSmsForLong: isUnicode ? 67 : 153,
        noOfSms: Math.ceil(newCharacterCount / (isUnicode ? 67 : 153)),
      }))
    } else {
      setSmsState((prevState) => ({
        ...prevState,
        charPerSmsForLong: 160,
        noOfSms: 1,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smsState.smsType, smsState.smsContent])

  function didITypeUnicode(text: string): boolean {
    for (let i = 0, l = text.length; i < l; i++) {
      if (text.charCodeAt(i) > 255 || text.charCodeAt(i) === 160) {
        return true
      }
    }
    return false
  }

  function getCounts(text: string): {countArr: string[]; nonArr: string[]} {
    const countArr: string[] = []
    const nonArr: string[] = []
    const arr: string[] = Array.from(text)

    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i] === '[' ||
        arr[i] === ']' ||
        arr[i] === '{' ||
        arr[i] === '}' ||
        arr[i] === '|' ||
        arr[i] === '~' ||
        arr[i] === '^' ||
        arr[i] === '\\'
      ) {
        countArr.push(arr[i])
      } else {
        nonArr.push(arr[i])
      }
    }

    return {countArr, nonArr}
  }

  function setParameter(isUnicode: boolean): void {
    if (isUnicode) {
      setSmsState((prevState) => ({
        ...prevState,
        charPerSms: 70,
      }))
    } else {
      setSmsState((prevState) => ({
        ...prevState,
        charPerSms: 160,
      }))
    }
  }

  function handleSmsTypeChange(event: ChangeEvent<HTMLInputElement>): void {
    setSmsState((prevState) => ({
      ...prevState,
      smsType: parseInt(event.target.value),
    }))
  }

  function handleSmsContentChange(event: ChangeEvent<HTMLTextAreaElement>): void {
    const textContent: string = event.target.value
    setSmsState((prevState) => ({
      ...prevState,
      smsContent: textContent,
    }))
  }

  const backToCountDefault = () => {
    setSmsState({
      smsContent: '',
      smsType: 0,
      characterCount: 0,
      charPerSms: 160,
      charPerSmsForLong: 153,
      noOfSms: 1,
    })
  }

  return {smsState, handleSmsContentChange, handleSmsTypeChange, backToCountDefault}
}

export default useSms
