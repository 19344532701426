import {KTSVG} from '../../../../_metronic/helpers'

const MaintenanceDeleteModal = () => {
  return (
    <div className='modal fade' tabIndex={-3} id='kt_modal_3'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <div className='fs-5 text-gray-900 fw-bolder'>Delete Maintenance</div>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>

          <div className='modal-body'>
            <p className='fs-4 fw-semibold text-warning text-center'>Are you sure?</p>
          </div>

          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-secondary me-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              Cancel
            </button>

            <button
              type='button'
              className='btn btn-primary'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              Yes, Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MaintenanceDeleteModal
