import React, {FormEvent, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Button} from 'react-bootstrap'
import toast from 'react-hot-toast'
import axios from 'axios'
import {useAuth} from '../../auth'
import {
  AdditionalCreateModalProps,
  CREATE_ADDITIONAL_PRICE_URL,
  UPDATE_ADDITIONAL_PRICE_URL,
} from '../core/_model'

const AdditionalCreateModal: React.FC<AdditionalCreateModalProps> = ({
  updateUser,
  additionalPrice,
  getAdditionalPrice,
}) => {
  const {auth} = useAuth()
  const [additionalType, setAdditionalType] = useState('submission')
  const [serviceCharge, setServiceCharge] = useState<number | any>(0)

  useEffect(() => {
    console.log(additionalPrice)
    if (additionalPrice) {
      setAdditionalType(additionalPrice.scSubmissionCount === 0 ? 'submission' : 'count')
      setServiceCharge(additionalPrice.serviceCharge / 100)
    }
  }, [additionalPrice])

  const handleAdditionType = (event: {target: {value: React.SetStateAction<string>}}) => {
    setAdditionalType(event.target.value)
  }

  const handleServiceCharge = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    // Check if the input value is a valid float number
    if (/^-?\d*(\.\d*)?$/.test(value) || value === '') {
      setServiceCharge(value === '' ? '' : parseFloat(value))
    }
  }

  const createAdditionalPrice = async () => {
    const charge = serviceCharge * 100
    const additionalPrice = {
      chargingRouteId: updateUser.charging_route_id,
      serviceCharge: charge,
      scSubmissionCount: additionalType === 'submission' ? 0 : 1,
    }
    try {
      toast.loading('Creating Additional Price..')
      const response = await axios.post(`${CREATE_ADDITIONAL_PRICE_URL}`, additionalPrice, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      toast.dismiss()
      toast.success('Create Additional Price')
      getAdditionalPrice()
      return response
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
    }
  }

  const updateAdditionalPrice = async () => {
    const charge = serviceCharge * 100
    const updatedPrice = {
      serviceCharge: charge,
      scSubmissionCount: additionalType === 'submission' ? 0 : 1,
    }
    try {
      console.log(additionalPrice)
      toast.loading('Updating Additional Price..')
      const response = await axios.put(
        `${UPDATE_ADDITIONAL_PRICE_URL}/${additionalPrice?.id}`,
        updatedPrice,
        {
          headers: {Authorization: `Bearer ${auth?.accessToken}`},
        }
      )
      toast.dismiss()
      toast.success('Update Additional Price')
      getAdditionalPrice()
      return response
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
    }
  }

  const handleCreate = (event: FormEvent) => {
    event.preventDefault()

    if (!additionalType || !serviceCharge) {
      return
    }

    if (additionalPrice) {
      console.log(additionalType, serviceCharge)
      updateAdditionalPrice()
    } else {
      createAdditionalPrice()
    }
  }

  return (
    <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Additional Price Create</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>

          <div className='modal-body'>
            <form onSubmit={handleCreate}>
              <div className='d-flex flex-column align-items-start mb-4'>
                <label className='col-form-label fs-6'>
                  <span className='required'>Transection Type</span>
                </label>
                <select
                  name='additionType'
                  id='additionType'
                  className='form-control'
                  value={additionalType}
                  onChange={handleAdditionType}
                >
                  <option value='submission'>Submission</option>
                  <option value='count'>Count</option>
                </select>
              </div>

              <div className='d-flex flex-column align-items-start mb-4'>
                <label className='col-form-label fs-4 fw-bold'>
                  <span className=''>Service Charge</span>
                </label>

                <div className='col-lg-8 fv- w-100'>
                  <input
                    type='number'
                    placeholder='Ex: 0.7'
                    className='form-control form-control-lg form-control-solid'
                    value={serviceCharge}
                    onChange={handleServiceCharge}
                    required
                  />
                </div>
              </div>

              <div className='d-flex gap-4'>
                <Button variant='secondary' data-bs-dismiss='modal' aria-label='Close'>
                  Close
                </Button>
                <Button type='submit' variant='primary' data-bs-dismiss='modal' aria-label='Close'>
                  {additionalPrice ? 'Update' : 'Create'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdditionalCreateModal
