import {Dispatch, SetStateAction} from 'react'
import {BASE_URL} from '../../config'

export const GET_BROADCAST_HISTORY_API = `${BASE_URL}/api/protect/archive/broadCast`

export const GET_ADDITIONAL_CHARGE_URL = `${BASE_URL}/api/protect/archive/getadditionalcharge`
export const GET_SMS_TOTAL_COST_URL = `${BASE_URL}/api/protect/archive/getSmsTotalCost`
export const GET_SMS_TEXT_URL = `${BASE_URL}/api/protect/archive/getSmsText`
export const GET_MSSISDN_URL = `${BASE_URL}/api/protect/archive/getMssisdn`

export const GET_SMS_HISTORY = `${BASE_URL}/api/protect/archive/getsmsHistory`
export const GET_SMS_DOWNLOAD = `http://103.106.118.123:9002/api/protect/archive/getsmsHistory`
export interface ISingleBroadcast {
  id: number
  dateTime: string
  user: string
  smsPart: number
  smsType: string
  sender: string
  count: number | string
  smsText: string
}

export interface IBroadcastArray extends Array<ISingleBroadcast> {}

export interface BroadcastHistoryActionProps {
  broadcast: ISingleBroadcast
  setShowModal: Dispatch<SetStateAction<boolean>>
  setSingleBroadcast: Dispatch<SetStateAction<ISingleBroadcast | undefined>>
}

export interface AdditionalChargeData {
  chargingRouteId: number
  createDate: string
  id: number
  lastModifiedDate: string
  scSubmissionCount: number
  serviceCharge: number
  status: string
  vat: number
}

export interface SmsData {
  campaignId: number
  groupId: number
  id: number
  insertDate: string
  noOfPart: number
  sendNowSchedule: number
  smsLength: number
  smsText: string
  smsType: number
  status: string
}

export interface BraodcastHistoryProps {
  broadcast: ISingleBroadcast | undefined
  setBroadcast: React.Dispatch<React.SetStateAction<ISingleBroadcast | undefined>>
}

export interface ISMSHistory {
  id: number
  cost: number
  date: string
  gwRes: string
  mssisdn: string
  sender: string
  smsPart: number
  smsText: string
  status: string
  username: string
}

export interface ISMSHistoryArray extends Array<ISMSHistory> {}
