import {Dispatch, SetStateAction} from 'react'
import {ID} from '../../../_metronic/helpers'

export function formatDate(date: any) {
  if (!date) {
    return
  }
  const newDate = new Date(date)
  return newDate.toISOString().replace('T', ' ').slice(0, -8)
}

export const userIdDefine = (id: number | undefined) => {
  let userid
  switch (id) {
    case 1:
      userid = 'topadmin'
      break
    case 2:
      userid = 'topreseller'
      break
    case 3:
      userid = 'client'
  }
  return userid
}

export const brandDefine = (id: number) => {
  let brand
  if (id === 0) {
    brand = 'Non-Branded'
  } else if (id === 1) {
    brand = 'Branded'
  }
  return brand
}

export const userType = (type: number | undefined): string => {
  let typeName = ''
  if (type === 1) {
    typeName = 'Top Admin'
  } else if (type === 2) {
    typeName = 'Top Reseller'
  } else if (type === 3) {
    typeName = 'Client'
  } else {
    typeName = 'Unknown'
  }
  return typeName
}

export function calculatedGroupingIsDisabled<T>(
  isLoading: boolean,
  data: Array<T> | undefined
): boolean {
  return isLoading || !data || data.length === 0
}

export function calculateIsAllDataSelected(
  data: Array<any> | undefined,
  selected: Array<ID>
): boolean {
  return !!data && data.length > 0 && data.length === selected.length
}

export function groupingOnSelect(
  id: ID,
  selected: Array<ID>,
  setSelected: Dispatch<SetStateAction<Array<ID>>>
) {
  if (!id) {
    return
  }

  const updatedSelected = selected.includes(id)
    ? selected.filter((itemId) => itemId !== id)
    : [...selected, id]

  setSelected(updatedSelected)
}

export function groupingOnSelectAll(
  isAllSelected: boolean,
  setSelected: Dispatch<SetStateAction<Array<ID>>>,
  data?: Array<{id?: ID}>
) {
  if (!data || data.length === 0) {
    return
  }

  const selectedIds = isAllSelected ? [] : (data.map((item) => item.id!).filter(Boolean) as ID[])
  setSelected(selectedIds)
}
