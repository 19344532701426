import {Button, Spinner} from 'react-bootstrap'
import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {FormikProps} from 'formik'

interface SmartContactFeedbackModalProps {
  successCount: number
  invalidCount: number
  duplicatesCount: number
  formik: FormikProps<any>
}

const SmartContactFeedbackModal: React.FC<SmartContactFeedbackModalProps> = ({
  successCount,
  invalidCount,
  duplicatesCount,
  formik,
}) => {
  let content

  if (!formik.isValid && (!successCount || !duplicatesCount || !invalidCount)) {
    content = (
      <div className='alert alert-primary d-flex align-items-center' role='alert'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          fill='currentColor'
          className='bi bi-exclamation-triangle-fill flex-shrink-0 me-2'
          viewBox='0 0 16 16'
          role='img'
          aria-label='Warning:'
        >
          <path d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z' />
        </svg>
        <div className='fs-4 fw-semibold'>Please fill up the required field.</div>
      </div>
    )
  }

  if (formik.isValid && (successCount || duplicatesCount || invalidCount)) {
    content = (
      <>
        <div className='modal-body d-flex justify-content-around'>
          <div className='bg-success w-25 h-25 text-center text-light fs-4 fw-bold p-4 rounded'>
            <p>Success</p>
            {successCount}
          </div>
          <div className='bg-danger w-25 h-25 text-center text-light fs-4 fw-bold p-4 rounded'>
            <p>Invalid</p>
            {invalidCount}
          </div>
          <div className='bg-warning w-25 h-25 text-center text-light fs-4 fw-bold p-4 rounded'>
            <p>Duplicates</p>
            {duplicatesCount}
          </div>
        </div>

        <div className='modal-footer'>
          <Button variant='secondary' data-bs-dismiss='modal' aria-label='Close'>
            Cancel
          </Button>
          <Button
            variant='info'
            data-bs-dismiss='modal'
            aria-label='Close'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_1'
          >
            Save Success
          </Button>
        </div>
      </>
    )
  }

  return (
    <div className='modal fade' tabIndex={-2} id='kt_modal_2'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Contact File Feedback</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          {content}
        </div>
      </div>
    </div>
  )
}

export default SmartContactFeedbackModal
