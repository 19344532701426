import React, {FC} from 'react'
import {Settings} from '../accounts/components/settings/Settings'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import SettingsSidebar from './SettingsSidebar'
import EmailSettings from './EmailSettings'
import PaymentSettings from './PaymentSettings'
import {SettingsProvider} from './SettingsProvider'
import InvoiceSettings from './InvoiceSettings'
import UrlSettings from './UrlSettings'
import OthersSettings from './OthersSettings'
import SiteSettings from './SiteSettings'
import MaintenanceSettings from './MaintenanceSettings'

const SettingWrapper: FC = () => {
  return (
    <Routes>
      <Route
        element={
          <SettingsProvider>
            <div className='row'>
              <SettingsSidebar />
              <Outlet />
            </div>
          </SettingsProvider>
        }
      >
        <Route
          path='email'
          element={
            <>
              <EmailSettings />
            </>
          }
        />

        <Route
          path='payment'
          element={
            <>
              <PaymentSettings />
            </>
          }
        />

        <Route
          path='url'
          element={
            <>
              <UrlSettings />
            </>
          }
        />

        <Route
          path='invoice'
          element={
            <>
              <InvoiceSettings />
            </>
          }
        />

        <Route
          path='site'
          element={
            <>
              <SiteSettings />
            </>
          }
        />

        <Route
          path='maintenance'
          element={
            <>
              <MaintenanceSettings />
            </>
          }
        />

        <Route
          path='others'
          element={
            <>
              <OthersSettings />
            </>
          }
        />

        <Route index element={<Navigate to='/settings/email' />} />
      </Route>
    </Routes>
  )
}

export default SettingWrapper
