import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from 'axios'
import {BASE_URL} from './modules/config'
import {number} from 'yup'

const GET_PRICE_LIST_URL = `${BASE_URL}/api/protect/getchargingConf`
const GET_ADDITIONAL_PRICE = `${BASE_URL}/api/protect/additionalCharging`

export interface PriceList {
  [key: string]: any
}

const initialState: PriceList = {
  price: [],
  additionalPrice: '',
  state: '',
  error: '',
}

export const PriceSlice = createSlice({
  name: 'price',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPriceList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getPriceList.fulfilled, (state, action) => {
        state.status = 'success'
        state.price = action.payload
        state.error = ''
      })
      .addCase(getPriceList.rejected, (state, action) => {
        state.status = 'rejected'
        state.error = action.error.message
      })

    builder
      .addCase(getAdditionalPriceList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getAdditionalPriceList.fulfilled, (state, action) => {
        state.status = 'success'
        state.additionalPrice = action.payload
        state.error = ''
      })
      .addCase(getAdditionalPriceList.rejected, (state, action) => {
        state.status = 'rejected'
        state.error = action.error.message
      })
  },
})

export const getPriceList = createAsyncThunk(
  'pricelist',
  async ({token, confId}: {token: string | undefined; confId: number | undefined}) => {
    const response = await axios.get(
      `${GET_PRICE_LIST_URL}/${confId}?charingRouteconfId=${confId}`,
      {
        headers: {Authorization: `Bearer ${token}`},
      }
    )
    return response.data
  }
)

export const getAdditionalPriceList = createAsyncThunk(
  'additionalPriceList',
  async ({token, confId}: {token: string | undefined; confId: number | undefined}) => {
    const response = await axios.get(`${GET_ADDITIONAL_PRICE}/${confId}`, {
      headers: {Authorization: `Bearer ${token}`},
    })
    return response.data
  }
)

export default PriceSlice.reducer
