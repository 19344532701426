/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {BASE_URL} from '../config'
import axios from 'axios'
import {useAuth} from '../auth'
import {settingsPriceList} from '../PriceList/core/helper'
import toast from 'react-hot-toast'
import {setUserType} from './core/helper'
import {IPriceList, IPriceListArray} from '../PriceList/core/_model'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'

const GET_USER_SENDER_ID = `${BASE_URL}/api/protect/getallSenders`

const UserDetails = () => {
  const {auth, currentUser} = useAuth()
  const {state: user} = useLocation()
  const [senderIds, setSenderIds] = useState<any>(undefined)
  const [priceList, setPriceList] = useState<IPriceListArray | undefined>([])

  const getSenders = async () => {
    try {
      const response = await axios.get(`${GET_USER_SENDER_ID}/${user.id}`, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      const data = await response.data
      setSenderIds(data)
    } catch (error) {
      console.log(error)
    }
  }

  const getPriceList = async () => {
    try {
      const priceList = await settingsPriceList(
        auth?.accessToken,
        currentUser?.charging_route_id,
        user.charging_route_id
      )
      setPriceList(priceList)
    } catch (error: any) {
      console.log(error)
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
    }
  }

  useEffect(() => {
    getSenders()
    getPriceList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className='w-100'>
        <div
          className='d-flex flex-column bgi-no-repeat rounded-top w-100'
          style={{
            backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        >
          <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
            User Details
            <span className='fs-8 opacity-75 ps-3'>
              ({user.user_full_name ? user.user_full_name : user.username})
            </span>
          </h3>

          <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9'>
            <li className='nav-item'>
              <a
                className='nav-link text-white opacity-75 opacity-state-100 pb-4 active'
                data-bs-toggle='tab'
                href='#kt_topbar_notifications_1'
              >
                User Info
              </a>
            </li>

            <li className='nav-item'>
              <a
                className='nav-link text-white opacity-75 opacity-state-100 pb-4'
                data-bs-toggle='tab'
                href='#kt_topbar_notifications_2'
              >
                Sender ID's
              </a>
            </li>

            <li className='nav-item'>
              <a
                className='nav-link text-white opacity-75 opacity-state-100 pb-4'
                data-bs-toggle='tab'
                href='#kt_topbar_notifications_3'
              >
                Price & Coverage
              </a>
            </li>
          </ul>
        </div>

        <div className='tab-content card card-body rounded-0 rounded-bottom'>
          <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
            <div className='my-5 px-8'>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted d-flex align-items-center gap-2'>
                  <KTIcon iconName='triangle' className='fs-2' />
                  Username
                </label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-gray-900'>{user.username}</span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted d-flex align-items-center gap-2'>
                  <KTIcon iconName='triangle' className='fs-2' />
                  User Type
                </label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-gray-900'>
                    {setUserType(user.user_profile)}
                  </span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted d-flex align-items-center gap-2'>
                  <KTIcon iconName='triangle' className='fs-2' />
                  Status
                </label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-gray-900'>
                    {setUserType(user.user_status)}
                  </span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted d-flex align-items-center gap-2'>
                  <KTIcon iconName='triangle' className='fs-2' />
                  Email Address
                </label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-gray-900'>
                    {user.user_email ? user.user_email : 'Not Provided'}
                  </span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted d-flex align-items-center gap-2'>
                  <KTIcon iconName='triangle' className='fs-2' />
                  Contact No
                </label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-gray-900'>
                    {user.user_contact ? user.user_contact : 'Not Provided'}
                  </span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted d-flex align-items-center gap-2'>
                  <KTIcon iconName='triangle' className='fs-2' />
                  Current Balance
                </label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-gray-900'>{user.userbalance}</span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted d-flex align-items-center gap-2'>
                  <KTIcon iconName='triangle' className='fs-2' />
                  Last Recharge Date
                </label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-gray-900'>
                    {user.last_recharge_date ? user.last_recharge_date : '--'}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className='tab-pane fade' id='kt_topbar_notifications_2' role='tabpanel'>
            <div className='my-5 px-8'>
              {senderIds && senderIds.length > 0 ? (
                senderIds.map((sender: any) => {
                  return (
                    <span
                      className='col-lg-4 fw-bold text-gray-800 fs-6 fs-bold d-flex align-items-center gap-2 mb-4'
                      key={sender.id}
                    >
                      <KTIcon iconName='triangle' className='fs-2' />
                      {sender.sender}
                    </span>
                  )
                })
              ) : (
                <div className='fs-6 fw-bold text-gray-800 ps-3'>Sender ID not found</div>
              )}
            </div>
          </div>

          <div className='tab-pane fade' id='kt_topbar_notifications_3' role='tabpanel'>
            <div className='mh-325px my-5 px-8'>
              {priceList && priceList.length > 0 ? (
                <div className='table-responsive'>
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    <thead>
                      <tr className='fs-6 fw-bold text-muted text-center'>
                        <th className='min-w-140px'>Prefix</th>
                        <th className='min-w-140px'>Buy Masking Price</th>
                        <th className='min-w-120px'>Buy Non-Masking Price</th>
                        <th className='min-w-120px'>Sell Masking Price</th>
                        <th className='min-w-120px'>Sell Non-Masking Price</th>
                        <th className='min-w-120px'>Status</th>
                      </tr>
                    </thead>

                    <tbody>
                      {priceList.map((list: IPriceList, index: number) => {
                        return (
                          <tr className='text-center' key={list.entityId}>
                            <td>
                              <span className='text-gray-900 fw-bold text-hover-primary fs-6'>
                                {list.prefix}
                              </span>
                            </td>
                            <td>
                              <span className='text-gray-900 fw-bold text-hover-primary fs-6'>
                                {list.buyMaskPrice}
                              </span>
                            </td>
                            <td>
                              <span className='text-gray-900 fw-bold text-hover-primary fs-6'>
                                {list.buyNonMaskPrice}
                              </span>
                            </td>
                            <td>
                              <span className='text-gray-900 fw-bold text-hover-primary fs-6'>
                                {list.sellMaskPrice}
                              </span>
                            </td>
                            <td>
                              <span className='text-gray-900 fw-bold text-hover-primary fs-6'>
                                {list.sellNonMaskPrice}
                              </span>
                            </td>
                            <td>
                              <span className='badge badge-light-success'>Allowed</span>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className='fs-6 text-gray-600 text-center'>Processing..</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserDetails
