import React from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import CategoryDetailsSidebar from './CategoryDetailsSidebar'
import CategoryAside from './CategoryAside'
import ArticleDetails from '../Article/ArticleDetails'

const CategoryDetails = () => {
  return (
    <Routes>
      <Route
        element={
          <div className='row'>
            <CategoryDetailsSidebar />
            <Outlet />
          </div>
        }
      >
        <Route
          path='/hello'
          element={
            <>
              <CategoryAside />
            </>
          }
        />

        <Route
          path='/article/:id'
          element={
            <>
              <ArticleDetails />
            </>
          }
        />

        <Route index element={<Navigate to='/knowledgebase/category/details/hello' />} />
      </Route>
    </Routes>
  )
}

export default CategoryDetails
