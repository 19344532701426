/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ChangeEvent, FormEvent, PropsWithChildren, useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import toast from 'react-hot-toast'
import axios from 'axios'
import {useAuth} from '../../auth'
import {downloadCSV, downloadPDF} from '../../../utils/helperFunction/downloadHelper'
import {KTIcon} from '../../../../_metronic/helpers'
import TableContent from '../../../utils/components/Table/TableContent'
import TablePagination from '../../../utils/components/Table/TablePagination'
import {CustomHeader} from '../../../utils/components/Table/CustomHeader'
import {formatDate} from '../../../utils/helperFunction/_helper'
import {Column, HeaderProps} from 'react-table'
import {IRechargeData, IRechargeDataArray, RechargeProps} from '../core/_model'
import {Params} from '../../Schedules/core/_model'
import SelectionHeader from '../../../utils/components/Table/SelectionHeader'
import {ListViewContext, ListViewProvider} from '../../../utils/contextProvider/ListViewProvider'
import {SelectionCell} from '../../../utils/components/Table/SelectionCell'
import TableHeader from '../../../utils/components/Table/TableHeader'

const Recharge: React.FC<RechargeProps> = ({apiUrl, title}) => {
  const {auth, currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [rechargeFrom, setRechargeFrom] = useState<IRechargeDataArray>([])
  const [filteredRecharge, setFilteredRecharge] = useState<IRechargeDataArray>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [startTime, setStartTime] = useState<string>('')
  const [endTime, setEndTime] = useState<string>('')
  const [click, setClick] = useState(0)

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalElement, setTotalElement] = useState(0)
  const [isLastPage, setIsLastPage] = useState(false)

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)

  const getRechargeData = async (params: any = null) => {
    try {
      setIsLoading(true)
      toast.loading('Loading Data..')
      const response = await axios.get(apiUrl, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
        params: params,
      })
      const result = await response.data
      const data = await result.data
      const lastPage = await result.lastpage
      const totalResult = await result.totalElement
      const formatedData = formateData(data ? data : [])
      setRechargeFrom(formatedData)
      setIsLastPage(lastPage)
      setTotalElement(totalResult)
      toast.dismiss()
      toast.success('Fetched SMS List')
      setIsLoading(false)
      toast.dismiss()
      toast.success('Data get successfully')
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
      setIsLoading(false)
    }
  }

  const formateData = (data: any) => {
    const formattedData: IRechargeDataArray = []

    data.map((singleItem: any, index: number) => {
      const singleData: IRechargeData = {
        id: singleItem.id,
        userId: currentUser?.user_profile,
        name: currentUser?.username,
        // actionuser: singleItem.actionuser,
        rechargedate: singleItem.rechargedate,
        // rechargeby: singleItem.rechargeby,
        paymentmethod: singleItem.paymentmethod,
        type: singleItem.type,
        note: singleItem.note,
        balance: singleItem.balance,
      }
      formattedData.push(singleData)
      return formattedData
    })

    return formattedData
  }

  const handleDownload = async (type: string) => {
    if (!startTime || !endTime) {
      toast.error(`Error: you must fill the dates`)
      return
    }

    const setParams: Params = {
      pageNumber: 0,
      pageSize: totalElement,
      startDate: startTime,
      endDate: endTime,
    }

    try {
      toast.loading('Getting Download data..')
      const response = await axios.get(apiUrl, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
        params: setParams,
      })
      const result = await response.data
      const data = await result.data
      const downloadData = formateData(data ? data : [])
      if (type === 'csv') {
        downloadCSV(downloadData, 'recharge')
      } else {
        downloadPDF({data: downloadData, fileName: 'Recharge To'})
      }
      toast.dismiss()
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
      setIsLoading(false)
    }
  }

  const handleSearchSubmit = (event: FormEvent) => {
    event.preventDefault()

    setCurrentPage(1)
    setItemsPerPage(10)
    setClick((prev) => prev + 1)

    // const params: Params = {
    //   pageNumber: currentPage - 1,
    //   pageSize: itemsPerPage,
    //   startDate: startTime,
    //   endDate: endTime,
    // }

    // getRechargeData(params)
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  let showPageLastEtries = !isLastPage ? currentPage * itemsPerPage : totalElement
  let showPageFirstEntries = !isLastPage
    ? showPageLastEtries + 1 - itemsPerPage
    : (currentPage - 1) * itemsPerPage + 1

  let serial: number[] = []

  for (let i = showPageFirstEntries; i <= showPageLastEtries; i++) {
    serial.push(i)
  }

  useEffect(() => {
    document.title = title
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const filteredData = rechargeFrom?.filter(
      (list) =>
        list.note?.toLowerCase().includes(searchTerm) ||
        list.type?.toLowerCase().includes(searchTerm) ||
        list.paymentmethod?.toLowerCase().includes(searchTerm)
    )
    setFilteredRecharge(filteredData)
  }, [rechargeFrom, searchTerm])

  useEffect(() => {
    if (startTime && endTime) {
      const setParams: Params = {
        pageNumber: currentPage - 1,
        pageSize: itemsPerPage,
        startDate: startTime,
        endDate: endTime,
      }

      getRechargeData(setParams)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, currentPage, click])

  const RechargeColumn: ReadonlyArray<Column<IRechargeData>> = [
    {
      Header: (props) => <SelectionHeader tableProps={props as any} context={ListViewContext} />,
      id: 'selection',
      Cell: ({...props}) => (
        <SelectionCell id={props.data[props.row.index].id} context={ListViewContext} />
      ),
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='User ID' className='min-w-100px' />
      ),
      id: 'userId',
      accessor: 'userId',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Name' className='min-w-100px' />
      ),
      id: 'name',
      accessor: 'name',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Recharge Date' className='min-w-100px' />
      ),
      id: 'rechargeDate',
      Cell: ({...props}) => <>{formatDate(props.data[props.row.index].rechargedate)}</>,
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Payment Method' className='min-w-100px' />
      ),
      accessor: 'paymentmethod',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Type' className='min-w-100px' />
      ),
      id: 'smsType',
      accessor: 'type',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Note' className='min-w-100px' />
      ),
      id: 'note',
      accessor: 'note',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Balance' className='min-w-100px' />
      ),
      id: 'balance',
      accessor: 'balance',
    },
  ]

  return (
    <ListViewProvider isLoading={isLoading} data={filteredRecharge}>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0 d-flex justify-content-between'>
          <div className='card-title'>
            <h3 className='er mb-0'>Transaction History: {title}</h3>
          </div>
          <div className='card-title'>
            <Link to='/dashboard'>
              <Button variant='primary' size='sm'>
                Dashboard
              </Button>
            </Link>
          </div>
        </div>
        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='Search Group'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className='card-title m-0'>
            <TableHeader
              startTime={startTime}
              endTime={endTime}
              itemsPerPage={itemsPerPage}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              handleSearchSubmit={handleSearchSubmit}
              setItemsPerPage={setItemsPerPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
        <TableContent
          isLoading={isLoading}
          tableColumn={RechargeColumn}
          tableData={filteredRecharge}
        >
          {/* <Table>
            <tr>
              <td colSpan={7}>
                Showing {showPageFirstEntries} to {showPageLastEtries} of {totalElement} entries
              </td>
              <td colSpan={1}>Total</td>
              <td colSpan={1}>
                {filteredRecharge.length > 0 &&
                  filteredRecharge.reduce(
                    (acc: number, curr: IRechargeData) => acc + curr.balance,
                    0
                  )}
              </td>
            </tr>
          </Table> */}

          <TablePagination
            totalElement={totalElement}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            isLoading={isLoading}
          />
        </TableContent>
      </div>
    </ListViewProvider>
  )
}

export default Recharge
