import {FC} from 'react'
import {ColumnInstance} from 'react-table'

interface HeaderColumnCustomProps<T extends object> {
  column: ColumnInstance<T>
}

const HeaderColumnCustom: FC<HeaderColumnCustomProps<any>> = ({column}) => (
  <>
    {column.Header && typeof column.Header === 'string' ? (
      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
    ) : (
      column.render('Header')
    )}
  </>
)

export {HeaderColumnCustom}
