import {ChangeEvent} from 'react'
import {BASE_URL} from '../../../modules/config'

export const TOP_FIVE_USER_URL = `${BASE_URL}/api/protect/getTopFiveUser`

export interface IChartData {
  month: string
  masking: number
  nonMasking: number
}

export interface IChartDataArray extends Array<IChartData> {}

export interface ITransaction {
  rechargeDate: string
  amount: number
  type: string
}

export interface ILastFiveTransaction extends Array<ITransaction> {}

export interface ICostData {
  cost: number
  success: boolean
}

export interface IData {
  todaySmsCost: ICostData
  lastweekSmsCost: ICostData
  lastmonthSmsCost: ICostData
}

export interface ICountData {
  smscount: number
  success: boolean
}

export interface ICountsDatas {
  todaySmsCount: ICountData
  lastweekSmsCount: ICountData
  lastmonthSmsCount: ICountData
}

export interface ISMSMaskingInfo {
  nonMasking: number
  masking: number
}

export interface IUserData {
  userInformationDto: {
    id: number
    charging_route_id: number
    userbalance: number
    user_profile: number
    parentid: number
    user_status: string
    user_email: string
    user_mobile: string
    user_full_name: string
  }
  smsCount: number
}

export interface UserDataArray extends Array<IUserData> {}

export interface IBalance {
  balance: number
  success: boolean
}

export interface IRechargeInfo {
  rechargeAmount: number
  withdrawAmount: number
  refundAmount: number
}

export interface IRechargeInfoArray extends Array<IRechargeInfo> {}

export interface ChartValue {
  color: string
  valueNum: number | undefined
}

export interface DashboardContextValue {
  smsCount: ICountsDatas | undefined
  smsCost: IData | undefined
  balance: IBalance | undefined
  maskingInfo: IChartDataArray | undefined
  lastFiveTransaction: ILastFiveTransaction | undefined
  topFiveUser: UserDataArray | undefined
  topFiveApiUser?: UserDataArray | undefined
  topFivePanelUser?: UserDataArray | undefined
  status: {channel: string; count: string}
  onSelectStatus: (event: ChangeEvent<HTMLSelectElement>) => void
}
