import {Context, FC, useContext} from 'react'
import {useListView} from './core/ListViewProvider'

type Props = {
  context: Context<any>
}

const SelectList: FC<Props> = ({context}) => {
  // const {selected} = useListView()
  const {selected} = useContext(context)

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> Selected
      </div>

      <button type='button' className='btn btn-danger'>
        Delete Selected
      </button>
    </div>
  )
}

export default SelectList
