type Props = {
  className: string
  title: string
  subtitle: string
  color: string
  img?: string
}

const TicketOverviewCard = ({className, title, subtitle, color, img}: Props) => (
  <div
    className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`}
    style={{
      backgroundColor: color,
      backgroundImage: `url('${img}')`,
    }}
  >
    <div className='card-header pt-5 text-center'>
      <div
        className='d-flex flex-center rounded-circle h-80px w-80px'
        style={{border: '1px dashed rgba(255, 255, 255, 0.4)', backgroundColor: color}}
      >
        <i className='ki-duotone ki-call text-white fs-2qx lh-0'>
          <span className='path1'></span>
          <span className='path2'></span>
          <span className='path3'></span>
          <span className='path4'></span>
          <span className='path5'></span>
          <span className='path6'></span>
          <span className='path7'></span>
          <span className='path8'></span>
        </i>
      </div>
    </div>
    <div className='card-body d-flex align-items-center pt-0'>
      <div className='d-flex align-items-center'>
        <span className='fs-4hx text-white fw-bold me-6'>{0}</span>

        <div className='fw-bold fs-6 text-white'>
          <span className='d-block'>{title}</span>
          <span className=''>{subtitle}</span>
        </div>
      </div>
    </div>
  </div>
)
export {TicketOverviewCard}
