/* eslint-disable react-hooks/exhaustive-deps */
import {FC, createContext, useContext, useEffect, useState} from 'react'
import {
  APPROVE_PAYMENT_URL,
  CREATE_PAYMENT_URL,
  DELETE_PAYMENT_URL,
  GET_PAYMENT_URL,
  GET_SLIP_URL,
  PaymentProviderProps,
  PaymentReq,
  PaymentRequestData,
  REJECT_PAYMENT_URL,
  UPDATE_PAYMENT_URL,
  UPLOAD_PAYMENT_SLIP_URL,
} from './core/_model'
import {useAuth} from '../auth'
import axios from 'axios'
import toast from 'react-hot-toast'
import {ID} from '../../../_metronic/helpers'

export const PaymentContext = createContext<any>(null)

export const PaymentProvider: FC<PaymentProviderProps> = ({children}) => {
  const {auth, currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [paymentReq, setPaymentReq] = useState<PaymentReq[]>([])
  const [filteredReq, setFilteredReq] = useState<PaymentReq[]>([])
  const [updatePayment, setUpdatePayment] = useState<PaymentReq | undefined>(undefined)
  const [deletePayment, setDeletePayment] = useState<PaymentReq | undefined>(undefined)
  const [showModal, setShowModal] = useState(false)
  const [fileBlob, setFileBlob] = useState('')
  const [isView, setIsView] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>('')

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalElement, setTotalElement] = useState(0)
  const [isLastPage, setIsLastPage] = useState(false)

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)

  let params = {
    pageNumber: currentPage - 1,
    pageSize: itemsPerPage,
  }

  const getPaymentRequest = async () => {
    try {
      setIsLoading(true)
      toast.loading('Getting Payment Request List..')
      const response = await axios.get(`${GET_PAYMENT_URL}/${currentUser?.user_profile}`, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
        params,
      })
      const data = await response.data
      const result = await data.data
      const totalResult = await data.totalElement
      const lastPage = await data.lastpage
      setPaymentReq(result ? result : [])
      setIsLastPage(lastPage)
      setTotalElement(totalResult)
      console.log(data)
      toast.dismiss()
      toast.success('Payment Request List Found')
      setIsLoading(false)
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
      setIsLoading(false)
    }
  }

  const createPayment = async (sendData: PaymentRequestData, selectedFile: File | undefined) => {
    try {
      toast.loading('Requesting Payment..')
      const response = await axios.post(`${CREATE_PAYMENT_URL}`, sendData, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      const data = await response.data
      const reqId = await data.id
      console.log(reqId)
      await uploadPaymentSlip(reqId, selectedFile)
      toast.dismiss()
      toast.success('Payment Request Success')
      getPaymentRequest()
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
    }
  }

  const uploadPaymentSlip = async (paymentId: ID, selectedFile: File | undefined) => {
    let fileData = new FormData()
    fileData.append('file', selectedFile!)
    try {
      const response = await axios.post(`${UPLOAD_PAYMENT_SLIP_URL}/${paymentId}`, fileData, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      console.log(response)
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
    }
  }

  const getSlip = async (paymentId: ID) => {
    try {
      const response = await axios.get(`${GET_SLIP_URL}/${paymentId}`, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      const data = await response.data
      setFileBlob(data.paymentSlip)
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
    }
  }

  const updatePaymentApi = async (payment: PaymentReq, selectFile: File | undefined) => {
    try {
      toast.loading('Updating...')
      console.log(payment)
      const response = await axios.put(`${UPDATE_PAYMENT_URL}/${payment.id}`, payment, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      // const id = typeof payment?.id === 'string' ? payment?.id : payment?.id.toString()
      const id =
        payment?.id != null
          ? typeof payment.id === 'string'
            ? payment.id
            : payment.id.toString()
          : null
      await uploadPaymentSlip(parseInt(id!), selectFile)
      // await uploadPaymentSlip(payment?.id as string, selectFile)
      toast.dismiss()
      toast.success(`Update succesfully`)
      getPaymentRequest()
      console.log(response)
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
    }
  }

  const deletePaymentApi = async () => {
    try {
      toast.loading(`Deleting ${deletePayment?.id}..`)
      const response = await axios.delete(`${DELETE_PAYMENT_URL}/${deletePayment?.id}`, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      console.log(response)
      toast.dismiss()
      toast.success(`Deleted ${deletePayment?.id}`)
      setDeletePayment(undefined)
      getPaymentRequest()
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
    }
  }

  const approvePaymentReq = async (payment: PaymentReq, comment: string) => {
    try {
      toast.loading('Approving...')
      const response = await axios.post(
        `${APPROVE_PAYMENT_URL}/${payment.id}`,
        {comment},
        {
          headers: {Authorization: `Bearer ${auth?.accessToken}`},
        }
      )
      const data = await response.data
      console.log(data)
      toast.dismiss()
      toast.success('Successfully Approved')
      getPaymentRequest()
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
    }
  }

  const rejectPaymentReq = async (payment: PaymentReq, comment: string) => {
    try {
      toast.loading('Rejecting...')
      const response = await axios.post(
        `${REJECT_PAYMENT_URL}/${payment.id}`,
        {comment},
        {
          headers: {Authorization: `Bearer ${auth?.accessToken}`},
        }
      )
      const data = await response.data
      console.log(data)
      toast.dismiss()
      toast.success('Reject Successfully')
      getPaymentRequest()
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
    }
  }

  const handleActionClick = async (payment: PaymentReq) => {
    setUpdatePayment(payment)
    // const id = typeof payment?.id === 'string' ? payment?.id : payment?.id.toString()
    const id =
      payment?.id != null
        ? typeof payment.id === 'string'
          ? payment.id
          : payment.id.toString()
        : null
    await getSlip(parseInt(id!))
    setShowModal(true)
  }

  const hide = () => {
    setUpdatePayment(undefined)
    setShowModal(false)
  }

  useEffect(() => {
    document.title = 'Payment Request'

    getPaymentRequest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage])

  useEffect(() => {
    const filteredData = paymentReq.filter(
      (payment) =>
        payment.transectionId?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        payment.paymentMedia.toLowerCase().includes(searchTerm.toLowerCase()) ||
        payment.comment.toLowerCase().includes(searchTerm.toLowerCase()) ||
        payment.status.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setFilteredReq(filteredData)
  }, [paymentReq, searchTerm])

  const value = {
    isLoading,
    paymentReq,
    filteredReq,
    updatePayment,
    deletePayment,
    showModal,
    fileBlob,
    isView,
    setFileBlob,
    setUpdatePayment,
    setShowModal,
    getPaymentRequest,
    approvePaymentReq,
    rejectPaymentReq,
    updatePaymentApi,
    createPayment,
    deletePaymentApi,
    handleActionClick,
    hide,
    handlePageChange,
    totalElement,
    isLastPage,
    setSearchTerm,
    setIsView,
    currentPage,
    itemsPerPage,
    setItemsPerPage,
    setCurrentPage,
  }

  return <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>
}

export const usePaymentProvider = () => useContext(PaymentContext)
