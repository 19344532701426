import React, {useCallback, useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useDropzone} from 'react-dropzone'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {IFileType} from './CreateUser'
import clsx from 'clsx'
import {cloneUser, updateUserApi, uploadNidTradeLicence} from './core/userListRequest'
import {useAuth} from '../auth'
import toast from 'react-hot-toast'

export interface ICloneUserForm {
  user_type: string | undefined | number
  user_name: string
  user_email: string
  user_contact: string
  user_nid: File | string
  user_tradelicence: File | string
}

export const CloneUserFormInitialValues: ICloneUserForm = {
  user_type: '',
  user_name: '',
  user_email: '',
  user_contact: '',
  user_nid: '',
  user_tradelicence: '',
}

const cloneUserFormValidationSchema = Yup.object().shape({
  user_type: Yup.string().required('User type is required'),
  user_name: Yup.string().required('User name is required'),
  user_email: Yup.string().email('Email must be a valid email').required('Email is required'),
  user_contact: Yup.string().required('User contact is required'),
  user_nid: Yup.mixed()
    .required('NID is required')
    .test('fileFormat', 'NID field only supports jpg/png/jpeg/pdf', (value: IFileType) => {
      if (value) {
        const supportFile = ['jpg', 'png', 'jpeg', 'pdf']
        return supportFile.includes(value.name?.split('.').pop() ?? '')
      }
      return true
    }),
  user_tradelicence: Yup.mixed()
    .required('NID is required')
    .test(
      'fileFormat',
      'Trade Licence field only supports jpg/png/jpeg/pdf',
      (value: IFileType) => {
        if (value) {
          const supportFile = ['jpg', 'png', 'jpeg', 'pdf']
          return supportFile.includes(value.name?.split('.').pop() ?? '')
        }
        return true
      }
    ),
})

const CloneUser = (props: any) => {
  const {auth} = useAuth()
  const {state: updateUser} = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  // console.log(updateUser)

  const onNidDrop = useCallback((acceptedNidFiles: any) => {
    console.log(acceptedNidFiles)
    formik.setFieldValue('user_nid', acceptedNidFiles[0])
    console.log(acceptedNidFiles[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onTradeLicenseDrop = useCallback((licenseFiles: any) => {
    formik.setFieldValue('user_tradelicence', licenseFiles[0])
    console.log(licenseFiles[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    acceptedFiles: acceptedNidFiles,
    getRootProps: getNidRootProps,
    getInputProps: getNidInputProps,
  } = useDropzone({onDrop: onNidDrop})
  const {
    acceptedFiles: licenseFiles,
    getRootProps: getLicenseRootProps,
    getInputProps: getLicenseInputProps,
  } = useDropzone({onDrop: onTradeLicenseDrop})

  const nidFileItems = acceptedNidFiles.map((file) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ))

  const licenseFileItems = licenseFiles.map((file) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ))

  const setUserType = (type: string | number) => {
    let newType
    switch (type) {
      case 1:
        newType = 'admin'
        break
      case 2:
        newType = 'reseller'
        break
      case 3:
        newType = 'client'
        break
      default:
        newType = 'client'
        break
    }
    return newType
  }

  const updateUserValues: ICloneUserForm = {
    user_type: setUserType(updateUser?.user_profile),
    user_name: updateUser?.user_full_name,
    user_email: updateUser?.user_email,
    user_contact: updateUser?.user_mobile,
    user_nid: updateUser?.nid,
    user_tradelicence: updateUser?.trade_license,
  }

  // console.log(updateUserValues.user_nid)

  const formik = useFormik({
    initialValues: updateUser ? updateUserValues : CloneUserFormInitialValues,
    validationSchema: cloneUserFormValidationSchema,
    onSubmit: async (values: ICloneUserForm) => {
      setLoading(true)
      if (updateUser) {
        console.log('update', values)
        const updateValue = {
          name: formik.values.user_name,
          email: formik.values.user_email,
          contactNumber: formik.values.user_contact,
        }
        console.log(formik.values)
        console.log(updateValue)
        let formData = new FormData()
        formData.append('nid', formik.values.user_nid)
        formData.append('tradlicence', formik.values.user_tradelicence)
        console.log(updateValue, updateUser.id)
        const res = await updateUserApi(auth?.accessToken, updateUser.id, updateValue)
        await uploadNidTradeLicence(auth?.accessToken, res.id, formData)
        setLoading(false)
        toast.success('User updated successfully')
        navigate('/users/userlist')
      } else {
        const user = {
          userType: formik.values.user_type,
          name: formik.values.user_name,
          email: formik.values.user_email,
          contactNumber: formik.values.user_contact,
        }
        console.log(user)
        let formData = new FormData()
        formData.append('nid', values.user_nid)
        formData.append('tradlicence', values.user_tradelicence)
        const res = await cloneUser(auth?.accessToken, user)
        await uploadNidTradeLicence(auth?.accessToken, res.id, formData)
        toast.success('User clone successfully')
        setLoading(false)
        navigate('/users/userlist')
      }
    },
  })

  const [updateNidName, setUpdateNidName] = useState('')
  const [updateTradeLicenseName, setUpdateTradeLicenseName] = useState('')

  const convertBlobToFile = () => {
    if (!updateUser) {
      return
    }
    const blobNid = new Blob([updateUserValues.user_nid])
    const blobTradelicense = new Blob([updateUserValues.user_tradelicence])
    const nidFile = new File([blobNid], `${updateUser.user_full_name}-nid.png`, {
      type: blobNid.type,
    }) // need to change
    const tradelicenseFile = new File(
      [blobTradelicense],
      `${updateUser.user_full_name}-tradelicense.png`,
      {type: blobTradelicense.type}
    ) // need to change
    onNidDrop([nidFile])
    onTradeLicenseDrop([tradelicenseFile])
    // console.log(blob.type)
    // console.log(Array(file))
    console.log(formik.values)
    setUpdateNidName(nidFile.name)
    setUpdateTradeLicenseName(tradelicenseFile.name)
  }

  useEffect(() => {
    convertBlobToFile()
    if (updateUser) {
      document.title = 'Update User'
    } else {
      document.title = 'Clone User'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0 cursor-pointer d-flex justify-content-between'>
          <div className='card-title m-0'>
            <h3 className='er m-0'>
              {updateUser ? 'Update User Profile' : 'Clone New Users'}
              <span className='form-text'> (Login ID and Password will be auto generated)</span>
            </h3>
          </div>
          <div className='card-title m-0'>
            <Link to='/dashboard'>
              <Button variant='primary' size='sm'>
                Dashboard
              </Button>
            </Link>
          </div>
        </div>

        <div className='card-body'>
          <form onSubmit={formik.handleSubmit}>
            <div className='mb-3'>
              <label className='form-label'>User Type</label>
              <select
                name='user_type'
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.user_type && formik.errors.user_type},
                  {
                    'is-valid': formik.touched.user_type && !formik.errors.user_type,
                  }
                )}
                value={formik.values.user_type}
                onChange={formik.handleChange}
                disabled={updateUser ? true : false}
              >
                <option value=''>Select user type</option>
                <option value='reseller'>Reseller</option>
                <option value='client'>Client</option>
                {/* <option value='admin'>Admin</option>
                <option value='manager'>Manager</option>
                <option value='employee'>Employee</option> */}
              </select>
              {formik.touched.user_type && formik.errors.user_type && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.user_type}</div>
                </div>
              )}
            </div>
            <div className='mb-3'>
              <label className='form-label'>Name</label>
              <input
                name='user_name'
                type='text'
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.user_name && formik.errors.user_name},
                  {
                    'is-valid': formik.touched.user_name && !formik.errors.user_name,
                  }
                )}
                value={formik.values.user_name}
                onChange={formik.handleChange}
                placeholder='Enter name'
              />
              {formik.touched.user_name && formik.errors.user_name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.user_name}</div>
                </div>
              )}
            </div>
            <div className='mb-3'>
              <label className='form-label'>Email</label>
              <input
                name='user_email'
                type='text'
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.user_email && formik.errors.user_email},
                  {
                    'is-valid': formik.touched.user_email && !formik.errors.user_email,
                  }
                )}
                value={formik.values.user_email}
                onChange={formik.handleChange}
                placeholder='Enter email'
              />
              {formik.touched.user_email && formik.errors.user_email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.user_email}</div>
                </div>
              )}
            </div>
            <div className='mb-3'>
              <label className='form-label'>Contact</label>
              <input
                name='user_contact'
                type='text'
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.user_contact && formik.errors.user_contact},
                  {
                    'is-valid': formik.touched.user_contact && !formik.errors.user_contact,
                  }
                )}
                value={formik.values.user_contact}
                onChange={formik.handleChange}
                placeholder='Enter contact'
              />
              {formik.touched.user_contact && formik.errors.user_contact && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.user_contact}</div>
                </div>
              )}
            </div>
            <div className='row mb-6'>
              <div className='col-lg-4'>
                <label className='col-form-label required fs-6'>Select NID File</label>
                <div {...getNidRootProps({className: 'dropzone'})}>
                  <input {...getNidInputProps()} />
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
                {formik.touched.user_nid && formik.errors.user_nid && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.user_nid}</div>
                  </div>
                )}
                <aside>
                  <h4 className='mt-2'>Files</h4>
                  <ul>{updateNidName ? <li>{updateNidName}</li> : nidFileItems}</ul>
                </aside>
              </div>
              <div className='col-lg-4'>
                <label className='col-form-label required fs-6'>Upload Trade License File</label>
                <div {...getLicenseRootProps({className: 'dropzone'})}>
                  <input {...getLicenseInputProps()} />
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
                {formik.touched.user_tradelicence && formik.errors.user_tradelicence && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.user_tradelicence}</div>
                  </div>
                )}
                <aside>
                  <h4 className='mt-2'>Files</h4>
                  <ul>
                    {updateTradeLicenseName ? <li>{updateTradeLicenseName}</li> : licenseFileItems}
                  </ul>
                </aside>
              </div>
            </div>
            {updateUser && (
              <Link
                to='/users/userlist'
                className='btn btn-secondary m-2'
                style={{pointerEvents: !loading ? 'auto' : 'none'}}
              >
                Cancel
              </Link>
            )}
            <Button
              as='input'
              type='submit'
              value={loading ? 'Please wait..' : updateUser ? 'Update' : 'Create'}
              disabled={loading}
            />
          </form>
        </div>
      </div>
    </div>
  )
}

export default CloneUser
