import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import Select from 'react-select'
import {useThemeMode} from '../../../../_metronic/partials'

const options = [
  {value: 'option one', label: 'option one'},
  {value: 'option two', label: 'option two'},
  {value: 'option three', label: 'option three'},
]

const NewArticleModal = () => {
  const {mode} = useThemeMode()

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '8px',
      padding: '4px',
      border: 0,
      backgroundColor: mode === 'light' ? '#f9f9f9' : '#1b1b29',
      borderColor: state.isFocused ? '#6c63ff' : '#d1d1d1',
      boxShadow: state.isFocused ? '0 0 0 1px #6c63ff' : null,
      '&:hover': {
        borderColor: '#6c63ff',
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#6c63ff' : null,
      color: state.isSelected ? '#ffffff' : null,
      '&:hover': {
        backgroundColor: '#6c63ff',
        color: '#ffffff',
      },
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '8px',
      backgroundColor: mode === 'light' ? '#f8f0f4' : '#1b1b29',
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)', // Add box shadow
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: mode === 'light' ? '#5e6278' : '#92929f', // Change color of selected value
    }),
  }

  return (
    <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
      <div className='modal-dialog modal-xl'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Add New Article</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <form>
              <div className='row mb-4'>
                <label className='col-lg-4 col-form-label fs-6'>
                  <span className='required'>Title</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    name='title'
                    placeholder='Enter Title'
                    className='form-control form-control-lg form-control-solid'
                    required
                  />
                </div>
              </div>

              <div className='row mb-4'>
                <label className='col-lg-4 col-form-label fs-6'>
                  <span className='required'>Slug</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    name='slug'
                    placeholder='Enter slug'
                    className='form-control form-control-lg form-control-solid'
                    required
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fs-6'>
                  <span className='required'>Priority</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <Select
                    name='priority'
                    placeholder='Select priority'
                    options={options}
                    styles={customStyles}
                  />
                </div>
              </div>

              <div className='row mb-4 align-items-center'>
                <label className='col-lg-4 col-form-label fs-6'>
                  <span>Only For Internal</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <div className='form-check form-switch form-check-custom form-check-solid'>
                    <input name='onlyForInternal' className='form-check-input' type='checkbox' />
                  </div>
                </div>
              </div>

              <div className='row mb-4 align-items-center'>
                <label className='col-lg-4 col-form-label fs-6'>
                  <span>Status</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <div className='form-check form-switch form-check-custom form-check-solid'>
                    <input name='status' className='form-check-input' type='checkbox' />
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fs-6'>Description</label>
                <div className='col-lg-8 fv-row-3'>
                  <textarea
                    name='description'
                    placeholder='Enter Description..'
                    className='form-control form-control-lg form-control-solid'
                    rows={5}
                    style={{resize: 'vertical', fontSize: '1.0rem'}}
                    required
                  ></textarea>
                </div>
              </div>

              <div className='d-flex justify-content-end'>
                <button
                  type='button'
                  className='btn btn-light me-4'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  Close
                </button>
                <button
                  type='button'
                  className='btn btn-primary'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  Add Department
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewArticleModal
