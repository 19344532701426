import {Column, HeaderProps} from 'react-table'
import {CustomHeader} from '../../../utils/components/Table/CustomHeader'
import {brandDefine, formatDate} from '../../../utils/helperFunction/_helper'
import UserDefine from './UserDefine'
import {PropsWithChildren} from 'react'
import SenderIdAction from './SenderIdAction'
import {ISenderId} from '../core/_model'
import SenderDefault from './SenderDefault'
import SelectionHeader from '../../../utils/components/Table/SelectionHeader'
import {ListViewContext} from '../../../utils/contextProvider/ListViewProvider'
import {SelectionCell} from '../../../utils/components/Table/SelectionCell'

const SenderIDColumn: ReadonlyArray<Column<ISenderId>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props as any} context={ListViewContext} />,
    id: 'selection',
    Cell: ({...props}) => (
      <SelectionCell id={props.data[props.row.index].id} context={ListViewContext} />
    ),
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Sender' className='min-w-125px' />,
    id: 'sender',
    accessor: 'sender',
    // Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Type' className='min-w-125px' />
    ),
    accessor: 'branded',
    Cell: ({...props}) => <p>{brandDefine(props.data[props.row.index].branded)}</p>,
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Date' className='min-w-125px' />
    ),
    id: 'insertdate',
    accessor: 'insertdate',
    Cell: ({...props}) => <p>{formatDate(props.data[props.row.index].insertdate)}</p>,
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='User' className='min-w-125px' />
    ),
    id: 'user',
    Cell: () => <UserDefine />,
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Default' className='min-w-100px' />
    ),
    id: 'default',
    Cell: ({...props}) => <SenderDefault sender={props.data[props.row.index]} />,
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Actions' className='min-w-100px text-end' />
    ),
    id: 'actions',
    Cell: ({...props}) => <SenderIdAction sender={props.data[props.row.index]} />,
  },
]

export {SenderIDColumn}
