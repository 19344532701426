import {FC, ReactNode, createContext, useContext, useEffect, useState} from 'react'
import {ITicket} from '../core/_model'
import {useAuth} from '../../auth'

interface Props {
  children: ReactNode
}

export const TicketListContext = createContext<any>(null)

const ticket = {
  id: 1,
  ticketCode: '123',
  subject: 'subject',
  date: '10/10/2020',
  department: 'software',
  tags: 'reactjs',
  status: 'active',
}

export const TicketProvider: FC<Props> = ({children}) => {
  const {auth} = useAuth()
  const [isLoading, setIsLoading] = useState(true)
  const [ticketsList, setTicketsList] = useState<ITicket[]>([])
  const [filteredTicketsList, setFilteredTicketsList] = useState<ITicket[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')

  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalElement, setTotalElement] = useState(0)
  const [isLastPage, setIsLastPage] = useState(false)

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)

  const getTicketsList = async () => {
    try {
      setIsLoading(true)
      setTicketsList([ticket])
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  let showPageLastEntries = !isLastPage ? currentPage * itemsPerPage : totalElement
  let showPageFirstEntries = !isLastPage
    ? showPageLastEntries + 1 - itemsPerPage
    : (currentPage - 1) * itemsPerPage + 1

  useEffect(() => {
    const filteredData = ticketsList?.filter(
      (list) =>
        list.status?.toLowerCase().includes(searchTerm) ||
        list.ticketCode?.toLowerCase().includes(searchTerm) ||
        list.subject?.toLowerCase().includes(searchTerm) ||
        list.department?.toLowerCase().includes(searchTerm)
    )
    setFilteredTicketsList(filteredData)
  }, [ticketsList, searchTerm])

  useEffect(() => {
    document.title = 'Tickets List'
    getTicketsList()
  }, [])

  const values = {
    isLoading,
    ticketsList,
    filteredTicketsList,
    searchTerm,
    setSearchTerm,
    handlePageChange,
    itemsPerPage,
    setItemsPerPage,
    setCurrentPage,
    totalElement,
    currentPage,
  }

  return <TicketListContext.Provider value={values}>{children}</TicketListContext.Provider>
}

export const useTicketProvider = () => useContext(TicketListContext)
