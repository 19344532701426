import React, {FormEvent, useRef, useState} from 'react'
import {MDBDataTable} from 'mdbreact'
import {Button, Col, Pagination, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import Select from 'react-select'
import useSenderIdsOptions, {
  ISenderIdsOption,
  ISenderIdsOptionsArray,
} from '../../../hooks/useSenderIdsOptions'
import {useThemeMode} from '../../../../_metronic/partials'

const InvoiceCreate = () => {
  const {mode} = useThemeMode()
  const [selectedOption, setSelectedOption] = useState('')
  const {senderIdsOptions} = useSenderIdsOptions()
  const [selectedValues, setSelectedValues] = useState<ISenderIdsOption>()
  const [selectedSenderId, setSelectedSenderId] = useState<number | null>(null)
  const startTimeRef = useRef<HTMLInputElement>(null)
  const endTimeRef = useRef<HTMLInputElement>(null)

  const handleSelectChange = () => {
    setSelectedValues(selectedValues)
    console.log(selectedValues)
  }

  const handleCreateSubmit = (event: FormEvent) => {
    event.preventDefault()
  }

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '8px',
      padding: '4px',
      border: 0,
      backgroundColor: mode === 'light' ? '#f9f9f9' : '#1b1b29',
      borderColor: state.isFocused ? '#6c63ff' : '#d1d1d1',
      boxShadow: state.isFocused ? '0 0 0 1px #6c63ff' : null,
      '&:hover': {
        borderColor: '#6c63ff',
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#6c63ff' : null,
      color: state.isSelected ? '#ffffff' : null,
      '&:hover': {
        backgroundColor: '#6c63ff',
        color: '#ffffff',
      },
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '8px',
      backgroundColor: mode === 'light' ? '#f8f0f4' : '#1b1b29',
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)', // Add box shadow
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: mode === 'light' ? '#5e6278' : '#92929f', // Change color of selected value
    }),
  }

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0 d-flex justify-content-between'>
          <div className='card-title'>
            <h3 className='er mb-0'>Invoice Create</h3>
          </div>
          <div className='card-title'>
            <Link to='/dashboard'>
              <Button variant='primary' size='sm'>
                Dashboard
              </Button>
            </Link>
          </div>
        </div>
        <div className='card-body'>
          <form onSubmit={handleCreateSubmit}>
            <Col xs={6}>
              <label className='col-form-label fs-6'>
                <span className='required'>Select Sender ID</span>
              </label>
              <Select
                options={senderIdsOptions}
                value={selectedValues}
                onChange={handleSelectChange}
                isSearchable
                placeholder='Select Sender Ids'
                styles={customStyles}
              />
            </Col>
            <Row>
              <Col xs={3}>
                <label className='col-form-label fs-6'>
                  <span className='required'>Start Time</span>
                </label>
                <input
                  type='datetime-local'
                  ref={startTimeRef}
                  className='form-control'
                  min={new Date().toISOString()}
                />
              </Col>
              <Col xs={3}>
                <label className='col-form-label fs-6'>
                  <span className='required'>End Time</span>
                </label>
                <input
                  type='datetime-local'
                  ref={endTimeRef}
                  className='form-control'
                  min={new Date().toISOString()}
                />
              </Col>
            </Row>
            <Button type='submit' variant='primary' className='mt-10'>
              Create
            </Button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default InvoiceCreate
