import React, {FC, ReactNode} from 'react'
import {KTCardBody} from '../../../_metronic/helpers'
import ContentLoading from '../../utils/components/Loading/ContentLoading'
import {ICampaignGroup, ICampaignGroupArray} from './SMSCampaign'

type Props = {
  isLoading: boolean
  children?: ReactNode
  groupList: ICampaignGroupArray
  handleOnChange: (group: ICampaignGroup) => void
  selectedGroupId: number | null
}

const CampaignGroupTable: FC<Props> = ({
  isLoading,
  children,
  groupList,
  handleOnChange,
  selectedGroupId,
}) => {
  return (
    <div className='py-4 border px-4'>
      <div className='table-responsive'>
        <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer table-sm gx-4'>
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <th>Select</th>
              <th>Group Name</th>
              <th>Count</th>
              <th>Parent Id</th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
            {groupList.length > 0 ? (
              groupList.map((group) => {
                return (
                  <tr>
                    <td>
                      <input
                        type='radio'
                        className='form-check-input'
                        name='group'
                        onChange={() => handleOnChange(group)}
                        checked={selectedGroupId === group.id}
                      />
                    </td>
                    <td>{group.groupName}</td>
                    <td>{group.subcount}</td>
                    <td>{group.parentGroupId}</td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {children}
      {isLoading && <ContentLoading />}
    </div>
  )
}

export default CampaignGroupTable
