import React, {FC} from 'react'
import {PaymentReq} from './core/_model'

type Props = {
  payment: PaymentReq
}

const PaymentListStatus: FC<Props> = ({payment}) => {
  let classes
  if (payment.status.toLowerCase() === 'submitted') {
    classes = 'badge badge-light-primary fw-bolder'
  } else if (payment.status.toLowerCase() === 'rejected') {
    classes = 'badge badge-light-danger fw-bolder'
  } else {
    classes = 'badge badge-light-success fw-bolder'
  }
  return <div className={classes}>{payment.status}</div>
}

export default PaymentListStatus
