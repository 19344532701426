import React, {useState, ChangeEvent} from 'react'
import FsLightbox from 'fslightbox-react'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'

type Props = {
  uploadFile?: string
  isView?: boolean
  handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const ImageInput: React.FC<Props> = ({uploadFile, isView, handleFileChange}) => {
  const placeholderFile = toAbsoluteUrl(`media/illustrations/sigma-1/10-dark.png`)
  const uploadSrc = `data:image/png;base64,${uploadFile}`
  const [imageSrc, setImageSrc] = useState(uploadFile ? uploadSrc : placeholderFile)
  const [toggler, setToggler] = useState(false)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleFileChange(event)
    console.log('hello')
    const file = event.target.files?.[0]
    console.log(file)
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        const result = reader.result as string
        setImageSrc(result)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleCancel = () => {
    // Handle cancel action
  }

  const handleRemove = () => {
    // Handle remove action
    setImageSrc('')
  }

  return (
    <div
      className='image-input image-input-outline overlay'
      data-kt-image-input='true'
      style={{backgroundImage: `url(${imageSrc})`}}
    >
      <div
        className='overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded  image-input-wrapper w-125px h-125px'
        style={{backgroundImage: `url(${imageSrc})`, cursor: 'pointer'}}
        onClick={() => setToggler((prev) => !prev)}
      ></div>
      <div
        className='overlay-layer card-rounded bg-dark bg-opacity-25 shadow'
        onClick={() => setToggler((prev) => !prev)}
        style={{cursor: 'pointer'}}
      >
        <i className='bi bi-eye-fill text-white fs-3x'></i>
      </div>
      <label
        className={
          isView
            ? 'btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow d-none'
            : 'btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
        }
        data-kt-image-input-action='change'
        data-bs-toggle='tooltip'
        data-bs-dismiss='click'
        title='Change avatar'
      >
        <i className='ki-duotone ki-pencil fs-6'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
        <input
          type='file'
          name='avatar'
          accept='.png, .jpg, .jpeg'
          onChange={handleChange}
          disabled={isView}
        />
        <input type='hidden' name='avatar_remove' />
      </label>
      {/* <span
        className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
        data-kt-image-input-action='cancel'
        data-bs-toggle='tooltip'
        data-bs-dismiss='click'
        title='Cancel avatar'
        onClick={handleCancel}
      >
        <i className='ki-outline ki-cross fs-3'></i>
      </span> */}
      <span
        className={
          isView
            ? 'btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow d-none'
            : 'btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
        }
        data-kt-image-input-action='remove'
        data-bs-toggle='tooltip'
        data-bs-dismiss='click'
        title='Remove avatar'
        onClick={handleRemove}
      >
        <i className='ki-outline ki-cross fs-3'></i>
      </span>

      <FsLightbox
        toggler={toggler}
        sources={[imageSrc]}
        // slide={lightboxController.slide}
        // onClose={() => setLightboxController({toggler: false, slide: 0})}
      />
    </div>
  )
}

export default ImageInput
