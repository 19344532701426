import React from 'react'
import {DummyReport} from './dummyReport'
import {formatDate} from '../../utils/helperFunction/_helper'

type Props = {
  message: DummyReport
}

const FromCell = ({message}: Props) => {
  return (
    <div>
      <p>{formatDate(message.sendAt)}</p>
      <p className='text-gray-800'>{message.sender}</p>
      <div>
        <p className='mb-1'>Account</p>
        <p className='text-gray-800'>{message.accountName}</p>
      </div>
    </div>
  )
}

export default FromCell
