import {ISenderIdsOption} from '../../hooks/useSenderIdsOptions'

export interface IProfileDetails {
  senderid: string | object | number | readonly string[] | undefined
  smsContent: string | number | readonly string[] | undefined
  avatar: string
  fName: string
  lName: string
  company: string
  contactPhone: string
  companySite: string
  language: string
  timeZone: string
  currency: string
  communications: {
    email: boolean
    phone: boolean
  }
  allowMarketing: boolean
}

export interface IUpdateEmail {
  newEmail: string
  confirmPassword: string
}

export interface IUpdatePassword {
  currentPassword: string
  newPassword: string
  passwordConfirmation: string
}

export interface IConnectedAccounts {
  google: boolean
  github: boolean
  stack: boolean
}

export interface IEmailPreferences {
  successfulPayments: boolean
  payouts: boolean
  freeCollections: boolean
  customerPaymentDispute: boolean
  refundAlert: boolean
  invoicePayments: boolean
  webhookAPIEndpoints: boolean
}

export interface INotifications {
  notifications: {
    email: boolean
    phone: boolean
  }
  billingUpdates: {
    email: boolean
    phone: boolean
  }
  newTeamMembers: {
    email: boolean
    phone: boolean
  }
  completeProjects: {
    email: boolean
    phone: boolean
  }
  newsletters: {
    email: boolean
    phone: boolean
  }
}

export interface IDeactivateAccount {
  confirm: boolean
}

export interface ISenderId {
  value: string
  label: string
  id: number
  branded?: number
  subCount?: any
}

// ==============
export interface QuickSmsForm {
  senderid?: ISenderIdsOption | string
  cm_name: string
  mobile?: string | number
  sms_type: number
  sms_content?: string
  sms_schedule: string
  schedule_date: any
  noOfSms?: number
  sms_count?: number
  reciepient?: string | number
  smsTextCharacterCount?: number
  characterCount?: number
  [key: string]: any
}
export interface GroupSmsForm {
  senderid: ISenderId | string | undefined
  groupid: ISenderId | string | undefined
  // senderid: string | object | number | readonly string[] | undefined
  // groupid: string | object | number | readonly string[] | undefined
  cm_name: string
  sms_type: number
  sms_content: string
  sms_schedule: string
  noOfSms?: number
  sms_count: number
  schedule_date: any
  smsTextCharacterCount: number
  [key: string]: any
}

export interface ContactFileSmsForm {
  cm_name: string
  senderid: ISenderId | string | undefined
  // senderid: string | object | number | readonly string[] | undefined
  contact_file: File | string
  sms_type: number
  sms_content: string
  sms_count?: number
  noOfSms?: number
  sms_schedule: string
  reciepient?: string | number
  schedule_date: any
  smsTextCharacterCount: number
  [key: string]: any
}

export interface SMSCampaignForm {
  cm_name: string
  group: string
  fromNumber: number
  toNumber: number
  senderid: ISenderId | string | undefined
  sms_type: number
  sms_content: string
  sms_count?: number
  sms_schedule: string
  schedule_date: any
  smsTextCharacterCount: number
}

export interface SmartSmsForm {
  cm_name: string
  senderid: string | object | number | readonly string[] | undefined
  mobileno: string | object | number | readonly string[] | undefined
  file: File | string
  sms_type: string | number | null
  sms_content: string
  sms_schedule: string
  schedule_date: any
  sms_count?: number
  reciepient?: string | number
  noOfSms?: number
  smsTextCharacterCount: number
  fileId: number | undefined
  fileData: any
}

export const QuickSmsFormInitValues: QuickSmsForm = {
  cm_name: '',
  senderid: undefined,
  mobile: '',
  sms_type: 0,
  sms_content: '',
  sms_schedule: 'now',
  schedule_date: new Date(),
  smsTextCharacterCount: 0,
  characterCount: 0,
}

export const GroupSmsFormInitValues: GroupSmsForm = {
  cm_name: '',
  groupid: undefined,
  senderid: undefined,
  sms_type: 1,
  sms_count: 0,
  sms_content: '',
  sms_schedule: 'now',
  schedule_date: new Date(),
  smsTextCharacterCount: 0,
}

export const ContactFileSmsFormInitValues: ContactFileSmsForm = {
  cm_name: '',
  senderid: undefined,
  contact_file: '',
  sms_type: 1,
  sms_content: '',
  sms_schedule: 'now',
  schedule_date: new Date(),
  smsTextCharacterCount: 0,
}

export const SmsCampaignFormInitValues: SMSCampaignForm = {
  cm_name: '',
  group: '',
  fromNumber: 0,
  toNumber: 0,
  senderid: undefined,
  sms_type: 1,
  sms_content: '',
  sms_schedule: 'now',
  schedule_date: new Date(),
  smsTextCharacterCount: 0,
}

export const SmartSmsFormInitValues: SmartSmsForm = {
  cm_name: '',
  senderid: undefined,
  mobileno: '',
  file: '',
  sms_type: 1,
  sms_content: '',
  sms_schedule: 'now',
  schedule_date: new Date(),
  smsTextCharacterCount: 0,
  reciepient: 0,
  fileId: undefined,
  fileData: {},
}
export interface options {
  value: string | number
  label: string
}

export const optionsValue: options = {
  value: '',
  label: '',
}

// ===============
export const profileDetailsInitValues: IProfileDetails = {
  senderid: undefined,
  avatar: '/media/avatars/300-1.jpg',
  fName: 'Max',
  lName: 'Smith',
  company: 'Keenthemes',
  contactPhone: '044 3276 454 935',
  companySite: 'keenthemes.com',
  language: '',
  timeZone: '',
  currency: '',
  communications: {
    email: false,
    phone: false,
  },
  allowMarketing: false,
  smsContent: undefined,
}

export const updateEmail: IUpdateEmail = {
  newEmail: 'support@keenthemes.com',
  confirmPassword: '',
}

export const updatePassword: IUpdatePassword = {
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: '',
}

export const connectedAccounts: IConnectedAccounts = {
  google: true,
  github: true,
  stack: false,
}

export const emailPreferences: IEmailPreferences = {
  successfulPayments: false,
  payouts: true,
  freeCollections: false,
  customerPaymentDispute: true,
  refundAlert: false,
  invoicePayments: true,
  webhookAPIEndpoints: false,
}

export const notifications: INotifications = {
  notifications: {
    email: true,
    phone: true,
  },
  billingUpdates: {
    email: true,
    phone: true,
  },
  newTeamMembers: {
    email: true,
    phone: false,
  },
  completeProjects: {
    email: false,
    phone: true,
  },
  newsletters: {
    email: false,
    phone: false,
  },
}

export const deactivateAccount: IDeactivateAccount = {
  confirm: false,
}

// =================
export interface QuickSms {
  senderId: string | number | readonly string[] | undefined
  // name: string
}
