import {BASE_URL} from '../../config'
import {ReactNode} from 'react'

export const GET_SPAM_FILTER_URL = `${BASE_URL}/api/protect/getallspamfilterwithpage`
export const CREATE_SPAM_FILTER_URL = `${BASE_URL}/api/protect/createSpamFilter`
export const UPDATE_SPAM_FILTER_TEXT = `${BASE_URL}/api/protect/updatespamfilter`
export const DELETE_SPAM_TEXT_URL = `${BASE_URL}/api/protect/deletespam`

export interface SpamFilterData {
  id: number
  userId: number
  filterText: string
  insertDate: string
}

export interface SpamFilterContextProps {
  children: ReactNode
}

export interface SendSpamFilter {
  id?: number
  userId: number | undefined
  filterText: string
}
