import React, {FormEvent, useState} from 'react'
import {Col} from 'react-bootstrap'
import Flatpickr from 'react-flatpickr'
import useSenderIdsOptions, {
  ISenderIdsOption,
  ISenderIdsOptionsArray,
} from '../../hooks/useSenderIdsOptions'
import Select from 'react-select'
import {useThemeMode} from '../../../_metronic/partials'

const FormFilter = () => {
  const {senderIdsOptions} = useSenderIdsOptions()
  const {mode} = useThemeMode()
  const [dateState, setDateState] = useState<any>({
    startTime: new Date(),
    endTime: new Date(),
  })
  const [date, setDate] = useState<any>('Yesterday')
  const [msisdn, setMsisdn] = useState<number | null>(null)
  const [selectedValues, setSelectedValues] = useState<ISenderIdsOptionsArray[]>([])
  const [selectedSenderId, setSelectedSenderId] = useState<number[]>([])

  const handleSelectChange = (selectedOptions: any) => {
    if (selectedOptions) {
      const values = selectedOptions.map((option: ISenderIdsOption) => option.value)
      const id = selectedOptions.map((option: ISenderIdsOption) => option.id)
      setSelectedValues(values)
      setSelectedSenderId(id)
    } else {
      setSelectedValues([])
    }
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
  }

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '8px',
      padding: '4px',
      border: 0,
      backgroundColor: mode === 'light' ? '#f9f9f9' : '#1b1b29',
      borderColor: state.isFocused ? '#6c63ff' : '#d1d1d1',
      boxShadow: state.isFocused ? '0 0 0 1px #6c63ff' : null,
      '&:hover': {
        borderColor: '#6c63ff',
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#6c63ff' : null,
      color: state.isSelected ? '#ffffff' : null,
      '&:hover': {
        backgroundColor: '#6c63ff',
        color: '#ffffff',
      },
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '8px',
      backgroundColor: mode === 'light' ? '#f8f0f4' : '#1b1b29',
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)', // Add box shadow
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: mode === 'light' ? '#5e6278' : '#92929f', // Change color of selected value
    }),
  }

  return (
    <form onSubmit={handleSubmit} className='mb-10'>
      <Col className='mb-2'>
        <label className='form-label fs-6 fw-bold'>Date</label>
        <select
          className='form-select form-select-solid fw-bolder'
          data-kt-select2='true'
          data-placeholder='Select option'
          data-allow-clear='true'
          data-kt-user-table-filter='two-step'
          data-hide-search='true'
          onChange={(e) => setDate(e.target.value)}
          value={date}
        >
          <option value=''></option>
          <option value='Yesterday'>Yesterday</option>
          <option value='20 mins ago'>3 hours ago</option>
          <option value='5 hours ago'>24 hours ago</option>
          <option value='2 days ago'>2 days ago</option>
        </select>
      </Col>

      <Col className='mb-2'>
        <div>
          <label className='col-form-label fs-6'>
            <span>Start Time</span>
          </label>

          <Flatpickr
            data-enable-time
            value={dateState.date}
            onChange={([startTime]) => {
              setDateState({startTime})
            }}
            className='form-control'
            placeholder='Pick date & time'
          />
        </div>
      </Col>

      <Col className='mb-2'>
        <div>
          <label className='col-form-label fs-6'>
            <span>End Time</span>
          </label>

          <Flatpickr
            data-enable-time
            value={dateState.date}
            onChange={([endTime]) => {
              setDateState({endTime})
            }}
            className='form-control'
            placeholder='Pick date & time'
          />
        </div>
      </Col>

      <Col>
        <label className='col-form-label fs-6'>
          <span>MSISDN</span>
        </label>
        <input
          type='number'
          className='form-control'
          value={msisdn === null ? '' : msisdn}
          onChange={(e) => {
            const value = e.target.value
            setMsisdn(value === '' ? null : parseInt(value))
          }}
          placeholder='Enter MSISDN'
        />
      </Col>

      <Col>
        <div>
          <label className='col-form-label fs-6'>
            <span>Select Sender ID</span>
          </label>
          <Select
            isMulti
            options={senderIdsOptions}
            value={senderIdsOptions.filter((option) => selectedValues.includes(option.value))}
            onChange={handleSelectChange}
            isSearchable
            placeholder='Select Sender Ids'
            styles={customStyles}
          />
        </div>
      </Col>

      <Col className='mb-2'>
        <label className='form-check form-check-sm form-check-custom form-check-solid d-flex align-items-center justify-content-between border-bottom'>
          <span className='col-form-label fs-6'>Status</span>
          <input className='form-check-input' type='checkbox' value='all' />
        </label>

        <label className='form-check form-check-sm form-check-custom form-check-solid d-flex align-items-center justify-content-between'>
          <span className='col-form-label fs-6 text-gray-700'>Send</span>
          <input className='form-check-input' type='checkbox' value='1' />
        </label>

        <label className='form-check form-check-sm form-check-custom form-check-solid d-flex align-items-center justify-content-between'>
          <span className='col-form-label fs-6 text-gray-700'>Rejected</span>
          <input className='form-check-input' type='checkbox' value='2' />
        </label>
      </Col>

      <Col className='mb-2'>
        <label className='form-check form-check-sm form-check-custom form-check-solid d-flex align-items-center justify-content-between border-bottom'>
          <span className='col-form-label fs-6'>Channel</span>
          <input className='form-check-input' type='checkbox' value='all' />
        </label>

        <label className='form-check form-check-sm form-check-custom form-check-solid d-flex align-items-center justify-content-between'>
          <span className='col-form-label fs-6 text-gray-700'>API</span>
          <input className='form-check-input' type='checkbox' value='api' />
        </label>

        <label className='form-check form-check-sm form-check-custom form-check-solid d-flex align-items-center justify-content-between'>
          <span className='col-form-label fs-6 text-gray-700'>Panel</span>
          <input className='form-check-input' type='checkbox' value='panel' />
        </label>
      </Col>
    </form>
  )
}

export default FormFilter
