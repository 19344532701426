/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Feeds} from '../../widgets/components/Feeds'
import {
  FeedsWidget2,
  FeedsWidget3,
  FeedsWidget4,
  FeedsWidget5,
} from '../../../../_metronic/partials/widgets'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'

const ArticleDetails = () => {
  return (
    <div className={`card col-12 col-md-10`}>
      {/* begin::Body */}
      <div className='card-body pb-0'>
        {/* begin::Header */}
        <div className='d-flex align-items-center mb-5'>
          {/* begin::User */}
          <div className='d-flex align-items-center flex-grow-1'>
            {/* begin::Avatar */}
            <div className='symbol symbol-40px me-5'>
              <span className='symbol-label bg-light-success'>
                <KTIcon iconName='abstract-26' className='fs-2 text-success' />
              </span>
            </div>
            {/* end::Avatar */}

            {/* begin::Info */}
            <div className='d-flex flex-column'>
              <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                Summer001
              </a>
            </div>
            {/* end::Info */}
          </div>
          {/* end::User */}
        </div>
        {/* end::Header */}

        {/* begin::Post */}
        <div className='mb-5'>
          {/* begin::Image */}
          <div
            className='bgi-no-repeat bgi-size-cover rounded min-h-250px mb-5'
            style={{
              backgroundImage: `url('${toAbsoluteUrl('/media/stock/900x600/20.jpg')}')`,
            }}
          ></div>
          {/* end::Image */}

          {/* begin::Text */}
          <div className='text-gray-800 mb-5'>
            Outlines keep you honest. They stop you from indulging in poorly thought-out metaphors
            about driving and keep you focused on the overall structure of your post
          </div>
          {/* end::Text */}
        </div>
        {/* end::Post */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export default ArticleDetails
