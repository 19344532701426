import React, {FormEvent, useEffect, useState} from 'react'
import {useSettingsProvider} from './SettingsProvider'
import ContentLoading from '../../utils/components/Loading/ContentLoading'
import {AccountInfo, Invoice, InvoiceFooter, InvoiceHeader} from './core/_model'

const InvoiceSettings = () => {
  const {isLoading, setIsLoading, invoiceData, getAllOptions, updateOptions} = useSettingsProvider()

  const [invoiceHeader, setInvoiceHeader] = useState<InvoiceHeader>({
    address: undefined,
    bin_no: undefined,
    company_name: undefined,
    tin_no: undefined,
  })

  const [invoiceFooter, setInvoiceFooter] = useState<InvoiceFooter>({
    contact: undefined,
    email: undefined,
    section_one: undefined,
  })

  const [accountInfo, setAccountInfo] = useState<AccountInfo>({
    account_name: undefined,
    account_number: undefined,
    bank_address: undefined,
    bank_name: undefined,
    branch_name: undefined,
    routing_no: undefined,
    swift_id: undefined,
  })

  const changeInvoiceHeader = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = event.target
    setInvoiceHeader((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const changeInvoiceFooter = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {name, value} = event.target
    setInvoiceFooter((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const changeAccountInfo = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = event.target
    setAccountInfo((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    setIsLoading(true)

    const updatedInvoiceFooter: InvoiceFooter = {
      ...invoiceFooter,
      section_two: accountInfo,
    }

    const updatedInvoice: Invoice = {
      invoice_header: invoiceHeader,
      invoice_footer: updatedInvoiceFooter,
    }

    const updatedInvoiceData = {
      id: invoiceData.id,
      optionsName: invoiceData.optionsName,
      optionsValue: JSON.stringify(updatedInvoice),
    }

    await updateOptions(updatedInvoiceData)

    getAllOptions()
  }

  useEffect(() => {
    if (invoiceData) {
      const {optionsValue} = invoiceData
      console.log(optionsValue)
      setInvoiceHeader({
        address: optionsValue.invoice_header.address,
        bin_no: optionsValue.invoice_header.bin_no,
        company_name: optionsValue.invoice_header.company_name,
        tin_no: optionsValue.invoice_header.tin_no,
      })

      setAccountInfo({
        account_name: optionsValue.invoice_footer.section_two?.account_name,
        account_number: optionsValue.invoice_footer.section_two?.account_number,
        bank_address: optionsValue.invoice_footer.section_two?.bank_address,
        bank_name: optionsValue.invoice_footer.section_two?.bank_name,
        branch_name: optionsValue.invoice_footer.section_two?.branch_name,
        routing_no: optionsValue.invoice_footer.section_two?.routing_no,
        swift_id: optionsValue.invoice_footer.section_two?.swift_id,
      })

      setInvoiceFooter({
        contact: optionsValue.invoice_footer.contact,
        email: optionsValue.invoice_footer.email,
        section_one: optionsValue.invoice_footer.section_one,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceData])

  return (
    <div className='card col-12 col-md-10'>
      <div className='card-header'>
        <div className='card-title'>
          <h3 className='text-gray-700'>Invoice Settings</h3>
        </div>
      </div>

      <div className='card-body'>
        {isLoading ? (
          <ContentLoading />
        ) : (
          <form onSubmit={handleSubmit}>
            <div className=''>
              <h4 className='mb-8 text-gray-800'>Invoice Header</h4>

              <div className='row mb-4'>
                <label className='col-lg-4 col-form-label  fs-6'>
                  <span>Address</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    name='address'
                    placeholder='Address'
                    className='form-control form-control-lg form-control-solid'
                    value={invoiceHeader.address}
                    onChange={changeInvoiceHeader}
                    required
                  />
                </div>
              </div>

              <div className='row mb-4'>
                <label className='col-lg-4 col-form-label  fs-6'>
                  <span>Bin No</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    name='bin_no'
                    placeholder='Bin No'
                    className='form-control form-control-lg form-control-solid'
                    value={invoiceHeader.bin_no}
                    onChange={changeInvoiceHeader}
                    required
                  />
                </div>
              </div>

              <div className='row mb-4'>
                <label className='col-lg-4 col-form-label  fs-6'>
                  <span>Company Name</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    name='company_name'
                    placeholder='Company Name'
                    className='form-control form-control-lg form-control-solid'
                    value={invoiceHeader.company_name}
                    onChange={changeInvoiceHeader}
                    required
                  />
                </div>
              </div>

              <div className='row mb-4'>
                <label className='col-lg-4 col-form-label  fs-6'>
                  <span>Tin No</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    name='tin_no'
                    placeholder='Tin No'
                    className='form-control form-control-lg form-control-solid'
                    value={invoiceHeader.tin_no}
                    onChange={changeInvoiceHeader}
                    required
                  />
                </div>
              </div>
            </div>

            <div className='mt-16'>
              <h4 className='mb-8 text-gray-800'>Account Information</h4>

              <div className='row mb-4'>
                <label className='col-lg-4 col-form-label  fs-6'>
                  <span>Account Name</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    name='account_name'
                    placeholder='Contact'
                    className='form-control form-control-lg form-control-solid'
                    value={accountInfo.account_name}
                    onChange={changeAccountInfo}
                    required
                  />
                </div>
              </div>

              <div className='row mb-4'>
                <label className='col-lg-4 col-form-label  fs-6'>
                  <span>Account Number</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    name='account_number'
                    placeholder='Account Number'
                    className='form-control form-control-lg form-control-solid'
                    value={accountInfo.account_number}
                    onChange={changeAccountInfo}
                    required
                  />
                </div>
              </div>

              <div className='row mb-4'>
                <label className='col-lg-4 col-form-label  fs-6'>
                  <span>Bank Name</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    name='bank_name'
                    placeholder='Bank Name'
                    className='form-control form-control-lg form-control-solid'
                    value={accountInfo.bank_name}
                    onChange={changeAccountInfo}
                    required
                  />
                </div>
              </div>

              <div className='row mb-4'>
                <label className='col-lg-4 col-form-label  fs-6'>
                  <span>Branch Name</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    name='branch_name'
                    placeholder='Branch Name'
                    className='form-control form-control-lg form-control-solid'
                    value={accountInfo.branch_name}
                    onChange={changeAccountInfo}
                    required
                  />
                </div>
              </div>

              <div className='row mb-4'>
                <label className='col-lg-4 col-form-label  fs-6'>
                  <span>Bank Address</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    name='bank_address'
                    placeholder='Bank Address'
                    className='form-control form-control-lg form-control-solid'
                    value={accountInfo.bank_address}
                    onChange={changeAccountInfo}
                    required
                  />
                </div>
              </div>

              <div className='row mb-4'>
                <label className='col-lg-4 col-form-label  fs-6'>
                  <span>Routing No</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    name='routing_no'
                    placeholder='Routing No'
                    className='form-control form-control-lg form-control-solid'
                    value={accountInfo.routing_no}
                    onChange={changeAccountInfo}
                    required
                  />
                </div>
              </div>

              <div className='row mb-4'>
                <label className='col-lg-4 col-form-label  fs-6'>
                  <span>Swift ID</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    name='swift_id'
                    placeholder='Swift ID'
                    className='form-control form-control-lg form-control-solid'
                    value={accountInfo.swift_id}
                    onChange={changeAccountInfo}
                    required
                  />
                </div>
              </div>
            </div>

            <div className='mt-16'>
              <h4 className='mb-8 text-gray-800'>Invoice Footer</h4>

              <div className='row mb-4'>
                <label className='col-lg-4 col-form-label  fs-6'>
                  <span>Contact</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    name='contact'
                    placeholder='Contact'
                    className='form-control form-control-lg form-control-solid'
                    value={invoiceFooter.contact}
                    onChange={changeInvoiceFooter}
                    required
                  />
                </div>
              </div>

              <div className='row mb-4'>
                <label className='col-lg-4 col-form-label  fs-6'>
                  <span>Email</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    name='email'
                    placeholder='Email'
                    className='form-control form-control-lg form-control-solid'
                    value={invoiceFooter.email}
                    onChange={changeInvoiceFooter}
                    required
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fs-6'>Section One</label>
                <div className='col-lg-8 fv-row-3'>
                  <textarea
                    name='section_one'
                    value={invoiceFooter.section_one}
                    onChange={changeInvoiceFooter}
                    className='form-control form-control-lg form-control-solid'
                    rows={5}
                    style={{resize: 'vertical', fontSize: '1.0rem'}}
                    required
                  ></textarea>
                </div>
              </div>
            </div>

            <div className='d-flex justify-content-end'>
              <button type='submit' className='btn btn-primary'>
                Save Changes
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

export default InvoiceSettings
