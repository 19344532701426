import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import './core/settings.css'
import {useThemeMode} from '../../../_metronic/partials'
import {KTIcon} from '../../../_metronic/helpers'

const SettingsSidebar = () => {
  const location = useLocation()
  const {mode} = useThemeMode()

  const activeClass = mode === 'dark' ? 'sidebar-nav-active-dark' : 'sidebar-nav-active-light'

  return (
    <div className='col-12 col-md-2'>
      <ul className='nav nav-stretch nav-line-tabs border-transparent fs-5 fw-semibold flex-column gap-4'>
        <li
          className={
            `px-6 py-2 rounded d-flex align-items-center gap-2 ` +
            (location.pathname === '/settings/email' && activeClass)
          }
        >
          <KTIcon
            iconName={location.pathname === '/settings/email' ? 'text' : 'abstract-13'}
            className='fs-2 text-primary'
          />
          <Link className='text-gray-700 sidebar-nav' to='/settings/email'>
            Email
          </Link>
        </li>

        <li
          className={
            `px-6 py-2 rounded d-flex align-items-center gap-2 ` +
            (location.pathname === '/settings/payment' && activeClass)
          }
        >
          <KTIcon
            iconName={location.pathname === '/settings/payment' ? 'text' : 'abstract-13'}
            className='fs-2 text-primary'
          />
          <Link className='text-gray-700 sidebar-nav' to='/settings/payment'>
            Payment
          </Link>
        </li>

        <li
          className={
            `px-6 py-2 rounded d-flex align-items-center gap-2 ` +
            (location.pathname === '/settings/url' && activeClass)
          }
        >
          <KTIcon
            iconName={location.pathname === '/settings/url' ? 'text' : 'abstract-13'}
            className='fs-2 text-primary'
          />
          <Link className='text-gray-700 sidebar-nav' to='/settings/url'>
            URL
          </Link>
        </li>

        <li
          className={
            `px-6 py-2 rounded d-flex align-items-center gap-2 ` +
            (location.pathname === '/settings/invoice' && activeClass)
          }
        >
          <KTIcon
            iconName={location.pathname === '/settings/invoice' ? 'text' : 'abstract-13'}
            className='fs-2 text-primary'
          />
          <Link className='text-gray-700 sidebar-nav' to='/settings/invoice'>
            Invoice
          </Link>
        </li>

        <li
          className={
            `px-6 py-2 rounded d-flex align-items-center gap-2 ` +
            (location.pathname === '/settings/site' && activeClass)
          }
        >
          <KTIcon
            iconName={location.pathname === '/settings/site' ? 'text' : 'abstract-13'}
            className='fs-2 text-primary'
          />
          <Link className='text-gray-700 sidebar-nav' to='/settings/site'>
            Site
          </Link>
        </li>

        <li
          className={
            `px-6 py-2 rounded d-flex align-items-center gap-2 ` +
            (location.pathname === '/settings/maintenance' && activeClass)
          }
        >
          <KTIcon
            iconName={location.pathname === '/settings/maintenance' ? 'text' : 'abstract-13'}
            className='fs-2 text-primary'
          />
          <Link className='text-gray-700 sidebar-nav' to='/settings/maintenance'>
            Maintenance
          </Link>
        </li>

        <li
          className={
            `px-6 py-2 rounded d-flex align-items-center gap-2 ` +
            (location.pathname === '/settings/others' && activeClass)
          }
        >
          <KTIcon
            iconName={location.pathname === '/settings/others' ? 'text' : 'abstract-13'}
            className='fs-2 text-primary'
          />
          <Link className='text-gray-700 sidebar-nav' to='/settings/others'>
            Others
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default SettingsSidebar
