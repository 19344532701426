import {useContext} from 'react'
import {DashboardContext} from '../DashboardContext'

const DashboardHeader = () => {
  const contextValue = useContext(DashboardContext)
  const status = contextValue?.status
  const onSelectStatus = contextValue?.onSelectStatus

  return (
    <div className='d-flex justify-content-between align-items-center mb-10'>
      <div>
        <select
          className='form-select form-select-solid fw-bolder'
          name='channel'
          data-kt-select2='true'
          data-placeholder='Select option'
          data-allow-clear='true'
          data-kt-user-table-filter='two-step'
          data-hide-search='true'
          onChange={(e) => onSelectStatus!(e)}
          value={status!.channel}
        >
          <option value='All Channel'>All Channel</option>
          <option value='Panel'>Panel</option>
          <option value='API'>API</option>
        </select>
      </div>

      <div>
        <select
          className='form-select form-select-solid fw-bolder'
          name='count'
          data-kt-select2='true'
          data-placeholder='Select option'
          data-allow-clear='true'
          data-kt-user-table-filter='two-step'
          data-hide-search='true'
          onChange={(e) => onSelectStatus!(e)}
          value={status!.count}
        >
          <option value='today'>Today</option>
          <option value='lastWeek'>Last Week</option>
          <option value='lastMonth'>Last Month</option>
        </select>
      </div>
    </div>
  )
}

export default DashboardHeader
