import {Context, FC, useContext, useMemo} from 'react'
import {ID} from '../../../../_metronic/helpers'

type Props = {
  id: ID
  context: Context<any>
}

const SelectionCell: FC<Props> = ({id, context}) => {
  const {selected, onSelect} = useContext(context)
  const isSelected = useMemo(() => selected.includes(id), [id, selected])

  return (
    <div className='form-check form-check-custom form-check-solid'>
      <input
        className='form-check-input'
        type='checkbox'
        data-kt-check={isSelected}
        data-kt-check-target='#kt_table_users .form-check-input'
        checked={isSelected}
        onChange={() => onSelect(id)}
      />
    </div>
  )
}

export {SelectionCell}
