/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'

type Props = {
  className: string
  image: string
  title: string
  articleCount: number
  description: string
}

const OverviewCard: React.FC<Props> = ({className, image, title, articleCount, description}) => {
  return (
    <Link to='/knowledgebase/category/details' className='text-gray-800'>
      <div
        className={`card bgi-no-repeat ${className}`}
        style={{
          backgroundPosition: 'right top',
          backgroundSize: '30% auto',
          backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/' + image)})`,
        }}
      >
        {/* begin::Body */}
        <div className='card-body'>
          <a href='#' className='card-title fw-bold text-muted text-hover-primary fs-4'>
            {title}
          </a>

          <div className='fw-bold text-primary my-6'>{articleCount} Articles</div>

          <p
            className='text-dark-75 fw-semibold fs-5 m-0'
            dangerouslySetInnerHTML={{__html: description}}
          ></p>
        </div>
        {/* end::Body */}
      </div>
    </Link>
  )
}

export {OverviewCard}
