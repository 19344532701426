const CardSkeleton = () => {
  return (
    <div className={`card card-flush h-50 h-md-50 mb-5 mb-xl-10`}>
      <div className='card-header pt-5'>
        <p className='w-50 bg-secondary h-50 rounded'></p>
      </div>

      <div className='card-body w-100 mt-10 '>
        <p className='w-100 p-2 bg-secondary rounded'></p>
        <p className='w-50 bg-secondary p-2 rounded'></p>
        <p className='w-75 bg-secondary p-2 rounded'></p>
      </div>

      <div className='card-footer'>
        <p className='w-50 bg-secondary h-100 rounded'></p>
      </div>
    </div>
  )
}

export default CardSkeleton
