import {Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../_metronic/helpers'
import {Dispatch, SetStateAction, useState} from 'react'
import toast from 'react-hot-toast'
import {useAuth} from '../auth'

export const handleClickCopy = (
  textToCopy: string | undefined,
  setCopied: Dispatch<SetStateAction<boolean>>
) => {
  navigator.clipboard
    .writeText(textToCopy as string)
    .then(() => {
      setCopied(true)

      toast.dismiss()
      toast.success('Successfully copied')

      setTimeout(() => {
        setCopied(false)
      }, 2000)
    })
    .catch((error: any) => {
      setCopied(false)
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
    })
}

const ApiKey = () => {
  const {currentUser} = useAuth()
  const [copied, setCopied] = useState<boolean>(false)

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle=''
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='er m-0'>
            API Key <span className='form-text'></span>
          </h3>
        </div>

        <div className='card-title m-0'>
          <Link to='/developeroptions/documentation'>
            <Button variant='primary' size='sm'>
              Documentation
            </Button>
          </Link>
        </div>
      </div>

      <div className='card-body'>
        <div className='d-flex align-items-center mb-4 gap-2'>
          <KTIcon iconName='key' className='fs-2 text-success' />
          <span className='text-dark fs-4 fw-bold'>Your API Key:</span>
        </div>
        <div className='input-group mb-3'>
          <input
            type='text'
            className='form-control text-dark'
            value={currentUser?.api_key}
            disabled
          />
          <div className='input-group-text' style={{padding: 0}}>
            <button
              type='button'
              className='btn btn-primary'
              style={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0}}
              onClick={() => handleClickCopy(currentUser?.api_key, setCopied)}
              disabled={copied}
            >
              Copy
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApiKey
