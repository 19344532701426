import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import {checkUserAsync, signOutAsync} from '../authSlice'
import {useAppDispatch} from '../../../hook'
import {getAdditionalPriceList, getPriceList} from '../../../PriceSlice'
import axios from 'axios'
import {BASE_URL} from '../../config'
import {useNavigate} from 'react-router-dom'

const LOGIN_AS_USER_URL = `${BASE_URL}/api/protect/loginAs`

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
  loginAs: (auth: AuthModel | undefined, userid: string | number | undefined) => void
  parentAuth: AuthModel | undefined
  logoutAsParent: () => void
  isLogingAs: boolean
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  loginAs: () => {},
  parentAuth: undefined,
  logoutAsParent: () => {},
  isLogingAs: false,
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  // const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [parentCount, setParentCount] = useState(authHelper.getStorageParentCount())
  const [parentAuth, setPatentAuth] = useState<AuthModel | undefined>(
    authHelper.getParentJwt(authHelper.getStorageParentCount())
  )
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getJwt())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [isLogingAs, setIsLogingAs] = useState(false)
  const dispatch = useAppDispatch()

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      // authHelper.setAuth(auth)
      // console.log(auth)
      authHelper.setJwt(auth)
      console.log(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const saveParentAuth = (auth: AuthModel | undefined) => {
    setPatentAuth(auth)
    if (parentAuth) {
      authHelper.setParentJwt(
        authHelper.getParentJwt(authHelper.getStorageParentCount()),
        authHelper.getStorageParentCount()
      )
    }
  }

  const logout = () => {
    dispatch(signOutAsync())
    saveAuth(undefined)
    setCurrentUser(undefined)
    authHelper.setJwt(undefined)
  }

  const logoutAsParent = async () => {
    setIsLogingAs(true)
    // setParentCount((prev) => prev - 1)
    let count = authHelper.getStorageParentCount()
    // setParentCount(newCount)
    saveAuth(authHelper.getParentJwt(authHelper.getStorageParentCount()))
    authHelper.removeParentJwt(count)
    const {data: userInfo} = await getUserByToken(parentAuth?.accessToken)
    if (userInfo) {
      setCurrentUser(userInfo)
      // console.log(userInfo)
    }

    count--
    authHelper.setStorageParentCount(count)
    setPatentAuth(authHelper.getParentJwt(authHelper.getStorageParentCount()))
    setIsLogingAs(false)
  }

  const loginAs = async (auth: AuthModel | undefined, userid: string | number | undefined) => {
    try {
      // setParentCount((prev) => prev + 1)
      let count = authHelper.getStorageParentCount()
      count++
      console.log(count)
      authHelper.setStorageParentCount(count)
      setIsLogingAs(true)
      saveParentAuth(auth)
      const response = await axios.get(`${LOGIN_AS_USER_URL}/${userid}`, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      const data = await response.data
      saveAuth(data)
      authHelper.setParentJwt(auth, count)
      const {data: userInfo} = await getUserByToken(data.accessToken)
      if (userInfo) {
        setCurrentUser(userInfo)
        // console.log(userInfo)
      }
      console.log(data)
      setIsLogingAs(false)
    } catch (error) {
      saveParentAuth(undefined)
      saveAuth(auth)
      setIsLogingAs(false)
      console.log(error)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        loginAs,
        parentAuth,
        logoutAsParent,
        isLogingAs,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser, isLogingAs} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const dispatch = useAppDispatch()
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const userIn = await dispatch(checkUserAsync(apiToken))

          if (userIn) {
            // console.log(userIn)
          }

          const {data} = await getUserByToken(apiToken)
          if (data) {
            setCurrentUser(data)
            dispatch(getPriceList({token: apiToken, confId: data?.charging_route_id}))
            dispatch(
              getAdditionalPriceList({
                token: apiToken,
                confId: data?.charging_route_id,
              })
            )
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      authHelper.getJwt()

      return () => (didRequest.current = true)
    }

    if (auth && auth.accessToken) {
      requestUser(auth.accessToken)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // console.log(auth?.accessToken)
    // eslint-disable-next-line
  }, [])

  if (showSplashScreen) {
    return <LayoutSplashScreen />
  } else if (isLogingAs) {
    return (
      <p className='vh-100 d-flex justify-content-center align-items-center fs-4 fw-semibold'>
        Getting there..
      </p>
    )
  }

  return <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
