import {Dispatch, SetStateAction} from 'react'
import {BASE_URL} from '../../config'

export const GET_TODAY_BROADCAST_URL = `${BASE_URL}/api/protect/getTodayBroadcastwithPage`

export const GET_ADDITIONAL_CHARGE_URL = `${BASE_URL}/api/protect/getadditionalcharge`
export const GET_SMS_TOTAL_COST_URL = `${BASE_URL}/api/protect/getTotalCost`
export const GET_SMS_TEXT_URL = `${BASE_URL}/api/protect/getsms`
export const GET_MSSISDN_URL = `${BASE_URL}/api/protect/getTotalMssisdn`

export const GET_TODAY_API_SMS_URL = `${BASE_URL}/api/protect/liveReport/getTodaysms/apisms`
export const GET_BROADCAST_HISTORY_API = `${BASE_URL}/api/protect/archive/broadCast`
// /api/protect/liveReport/getTodaysms/panel
export const GET_SMS_HISTORY = `${BASE_URL}/api/protect/archive/getsmsHistory`

export const GET_SUMMARY_LOG_URL = `${BASE_URL}/api/protect/getSummeryLogForClient`

// export const GET_TODAY_API_SMS_URL = `${BASE_URL}/api/protect/liveReport/getTodaysms/apisms`
// /api/protect/liveReport/getTodaysms/panel
// /api/protect/liveReport/getRetrySmsforpanel
export interface ITodayBroadcast {
  id: number
  dateTime: string
  user: string
  smsPart: number
  smsType: number | string
  sender: string
  count: number
  smsText: string
}

export interface ITodayBroadcastArray extends Array<ITodayBroadcast> {}

export interface AdditionalChargeData {
  chargingRouteId: number
  createDate: string
  id: number
  lastModifiedDate: string
  scSubmissionCount: number
  serviceCharge: number
  status: string
  vat: number
}

export interface SmsData {
  campaignId: number
  groupId: number
  id: number
  insertDate: string
  noOfPart: number
  sendNowSchedule: number
  smsLength: number
  smsText: string
  smsType: number
  status: string
  cmName: string
}

export interface ISummaryLog {
  serial: number
  channel: string
  sender: string
  routeName: string
  sendStatus: string
  smsCount: number
  username: string
  smsHit: number
}

export interface ISummarySmsData {
  id: number
  name: string
  send_status: number
  sender: string
  smsCount: number
  smsHit: number
  task: string
  user_name: string
}

export interface ISummaryLogArray extends Array<ISummarySmsData> {}

export interface ISMSData {
  id: number
  date: string
  mssisdn: string
  sender: string
  smsPart: number
  status: string
  cost: number
  username: string
  smsText: string
  gwRes: string
}

export interface ISMSDataArray extends Array<ISMSData> {}

export interface ISearchParams {
  senderIds: number[]
  startTime: string | undefined
  channel: string | undefined
  endTime: string | undefined
  msisdn: number | null
  status: number | undefined
}
export interface ISMSChannelOpt {
  value: string
  label: string
}

export interface IStatusOpt {
  value: number
  label: string
}

export interface ISMSChannelOptArray extends Array<ISMSChannelOpt> {}

export interface IStatusOptArray extends Array<IStatusOpt> {}

export interface IRetrySMSData {
  id: number
  date: string
  msisdn: string
  sender: number
  smsPart: number
  status: string
  smsCost: number
  username: string
  smsText: string
  gwsid: any
}

export interface IRetrySMSDataArray extends Array<IRetrySMSData> {}

export interface HeaderProps {
  isLoading: boolean
  itemsPerPage: number
  setItemsPerPage: Dispatch<SetStateAction<number>>
  setCurrentPage: Dispatch<SetStateAction<number>>
}
