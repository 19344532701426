import {AuthModel} from './_models'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

export const getJwt = (): AuthModel | undefined => {
  const jwt = cookies.get('jwt_auth')
  if (!jwt) {
    return
  }
  // console.log(jwt)
  return {
    accessToken: jwt,
  }
}

export const getParentJwt = (parentCount: number): AuthModel | undefined => {
  const jwt = cookies.get(`jwt_auth_parent_${parentCount}`)
  if (!jwt) {
    return
  }
  // console.log(jwt)
  return {
    accessToken: jwt,
  }
}

const now: any = new Date()

// TODO: need to get and set JWT from the server
export const setJwt = (auth: AuthModel | undefined) => {
  // console.log(auth.accessToken)
  if (auth && auth.accessToken) {
    cookies.set('jwt_auth', auth.accessToken, {
      path: '/',
      expires: new Date(now * 5000),
      // secure: true,
      // httpOnly: true
    })
  } else {
    cookies.remove('jwt_auth', {path: '/'})
  }

  const jwt = cookies.get('jwt_auth')
  // if(!jwt) {
  //   return
  // }
  // console.log(jwt)
}

export const setParentJwt = (auth: AuthModel | undefined, parentCount: number) => {
  // console.log(auth.accessToken)
  // cookies.set('jwt_auth_parent', auth?.accessToken, {
  //   path: '/',
  //   expires: new Date(now * 5000),
  //   // secure: true,
  //   // httpOnly: true
  // })

  console.log(parentCount)

  if (auth && auth.accessToken) {
    cookies.set(`jwt_auth_parent_${parentCount}`, auth.accessToken, {
      path: '/',
      expires: new Date(now * 5000),
      // secure: true,
      // httpOnly: true
    })
  } else {
    cookies.remove(`jwt_auth_parent_${parentCount}`, {path: '/'})
  }

  const jwt = cookies.get('jwt_auth')
  // if(!jwt) {
  //   return
  // }
  // console.log(jwt)
}

export const removeParentJwt = (parentCount: number) => {
  cookies.remove(`jwt_auth_parent_${parentCount}`, {path: '/'})
}

export const getStorageParentCount = () => {
  const number = localStorage.getItem('parentCount')
  return number ? parseInt(number, 10) : 0
}

export const setStorageParentCount = (count: number) => {
  localStorage.setItem('parentCount', count.toString())
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.accessToken) {
        config.headers.Authorization = `Bearer ${auth.accessToken}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
