import axios from 'axios'
import {BASE_URL} from '../../config'
import toast from 'react-hot-toast'
import {ISenderId} from './_model'

const UPLOAD_FILE_URL = `${BASE_URL}/api/protect/contactFileupload`

export const getCost = (price: any, formData: any) => {
  let cost

  const maskingPrice = price[0].brandPrice / 100
  const nonMaskingPrice = price[0].nonBrandPrice / 100
  const isMasking = (formData.senderid as ISenderId)?.branded === 1

  if (isMasking) {
    cost = (formData?.noOfSms ?? 0) * maskingPrice
  } else {
    cost = (formData?.noOfSms ?? 0) * nonMaskingPrice
  }

  return cost
}

export const getAdditionalCost = (additionalPrice: any, formData: any) => {
  let additionalCost

  const additionalPrc = additionalPrice.serviceCharge / 100

  additionalCost = (formData?.reciepient ?? 0) * additionalPrc

  return additionalCost
}

export const fileUpload = async (token: string | undefined, fileData: any) => {
  try {
    const response = await axios.post(UPLOAD_FILE_URL, fileData, {
      headers: {Authorization: `Bearer ${token}`},
    })
    const data = await response.data
    const fileId = await data.id
    return fileId
  } catch (error: any) {
    toast.error(`Error: ${error.message}`)
  }
}
