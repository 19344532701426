import React, {FC} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {SearchFormFIlterProps} from '../core/_model'

const SearchFormFIlter: FC<SearchFormFIlterProps> = ({
  startTime,
  endTime,
  itemsPerPage,
  setStartTime,
  setEndTime,
  handleSearchSubmit,
  setCurrentPage,
  setItemsPerPage,
}) => {
  return (
    <>
      <button
        type='button'
        className='btn btn-light-primary me-3'
        id='dropdownMenuButton'
        data-bs-toggle='dropdown'
        aria-expanded='false'
      >
        <KTIcon iconName='filter' className='fs-2' />
        Filter
      </button>
      <div
        className='dropdown-menu menu menu-sub menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-300px w-md-350px py-4'
        aria-labelledby='dropdownMenuButton'
      >
        <div className='px-7 py-5'>
          <div className='fs-5 text-gray-900 fw-bolder'>Filter Options</div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          <form onSubmit={handleSearchSubmit}>
            <div className='mb-10'>
              <label className='form-label fs-6 fw-bold'>Show Entries:</label>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select entries'
                data-hide-search='true'
                name='entries'
                id='entries'
                value={itemsPerPage}
                onChange={(e) => {
                  setCurrentPage(1)
                  setItemsPerPage(parseInt(e.target.value))
                }}
              >
                <option value='10'>10</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
                <option value='250'>250</option>
              </select>
            </div>
            <div className='mb-4'>
              <label className='form-label fs-6 fw-bold'>
                <span className='required'>Start Time:</span>
              </label>
              <input
                type='datetime-local'
                className='form-control'
                min={new Date().toISOString()}
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
              />
            </div>

            <div className='mb-4'>
              <label className='form-label fs-6 fw-bold'>
                <span className='required'>End Time:</span>
              </label>
              <input
                type='datetime-local'
                className='form-control'
                min={new Date().toISOString()}
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
              />
            </div>

            <div className='d-flex justify-content-end mt-6'>
              <button
                type='button'
                className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                data-kt-menu-dismiss='true'
                data-kt-user-table-filter='reset'
              >
                Reset
              </button>
              <button
                type='submit'
                className='btn btn-primary fw-bold px-6'
                data-kt-menu-dismiss='true'
                data-kt-user-table-filter='filter'
              >
                Apply
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default SearchFormFIlter
