import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'

const CategoryAside = () => {
  return (
    <div className='card col-12 col-md-10'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>Summer</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>I Hate Summer</span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pt-5'>
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-7'>
          {/* begin::Symbol */}
          <div className='symbol symbol-40px me-5'>
            <span className='symbol-label bg-light-success'>
              <KTIcon iconName='abstract-26' className='fs-2 text-success' />
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Text */}
          <div className='d-flex flex-column'>
            <Link
              to='/knowledgebase/category/details/article/1'
              className='text-dark text-hover-primary fs-6 fw-bold'
            >
              Summer001
            </Link>
            {/* <span className='text-muted fw-semibold'>Project Manager</span> */}
          </div>
          {/* end::Text */}
        </div>
        {/* end::Item */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export default CategoryAside
