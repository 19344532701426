import React, {ChangeEvent} from 'react'
import {KYCUserInfo} from './KycForm'
import {useParams} from 'react-router-dom'

interface AddressFormProps {
  formData: KYCUserInfo | any
  handleChange: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
  addressType: string
  clonePermanent?: () => void
}

export const CapitalizeFirstLetter = (word: string) => word.charAt(0).toUpperCase() + word.slice(1)

const AddressForm: React.FC<AddressFormProps> = ({
  formData,
  handleChange,
  addressType,
  clonePermanent,
}) => {
  return (
    <div className='mt-10 p-6 border border-secondary rounded'>
      <div className='mb-8 d-flex gap-2 align-items-center'>
        <h4>{CapitalizeFirstLetter(addressType)} Address</h4>
        {addressType === 'present' && (
          <button
            type='button'
            className='p-0 m-0 text-primary bg-transparent fs-6 border-0 fw-semibold'
            onClick={clonePermanent}
          >
            (same as permanent address)
          </button>
        )}
      </div>
      <div className='d-flex gap-4'>
        {/* permanentFlatNo */}
        <div className='d-flex flex-column align-items-start mb-4 flex-fill'>
          <label className='col-form-label fs-6'>
            <span>Float No</span>
          </label>

          <div className='fv- w-100'>
            <input
              name={`${addressType}FlatNo`}
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter Flat No'
              value={formData[`${addressType}FlatNo`]}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* permanentHouseNo */}
        <div className='d-flex flex-column align-items-start mb-4 flex-fill'>
          <label className='col-form-label fs-6'>
            <span>House No</span>
          </label>

          <div className='fv- w-100'>
            <input
              name={`${addressType}HouseNo`}
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter House No'
              value={formData[`${addressType}HouseNo`]}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* permanentRoadNo */}
        <div className='d-flex flex-column align-items-start mb-4 flex-fill'>
          <label className='col-form-label fs-6'>
            <span>Road No</span>
          </label>

          <div className='fv- w-100'>
            <input
              name={`${addressType}RoadNo`}
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter Road No'
              value={formData[`${addressType}RoadNo`]}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <div className='d-flex justify-content-between gap-4'>
        {/* permanentRoadNo */}
        <div className='d-flex flex-column align-items-start mb-4 flex-fill'>
          <label className='col-form-label fs-6'>
            <span className='required'>Village/Area</span>
          </label>

          <div className='fv- w-100'>
            <input
              name={`${addressType}Village`}
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter Village/Area'
              value={formData[`${addressType}Village`]}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* permanentUpazila */}
        <div className='d-flex flex-column align-items-start mb-4 flex-fill'>
          <label className='col-form-label fs-6'>
            <span className='required'>Upazila/Thana</span>
          </label>

          <div className='fv- w-100'>
            <input
              name={`${addressType}Upazila`}
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter Upazila/Thana'
              value={formData[`${addressType}Upazila`]}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <div className='d-flex justify-content-between gap-4'>
        {/* permanentDistrict */}
        <div className='d-flex flex-column align-items-start mb-4 flex-fill'>
          <label className='col-form-label fs-6'>
            <span className='required'>District</span>
          </label>

          <div className='fv- w-100'>
            <input
              name={`${addressType}District`}
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter District'
              value={formData[`${addressType}District`]}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* permanentPostCode */}
        <div className='d-flex flex-column align-items-start mb-4 flex-fill'>
          <label className='col-form-label fs-6'>
            <span className='required'>Post Office Code</span>
          </label>

          <div className='fv- w-100'>
            <input
              name={`${addressType}PostCode`}
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter Upazila/Thana'
              value={formData[`${addressType}PostCode`]}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddressForm
