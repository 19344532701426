import {TicketProvider} from './TicketListProvider'
import TicketContainer from './TicketsList'

const TIcketsListWrapper = () => {
  return (
    <>
      <TicketProvider>
        <TicketContainer />
      </TicketProvider>
    </>
  )
}

export default TIcketsListWrapper
