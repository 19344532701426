/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {SpamFilterData} from './core/_model'
import {useSpamFilterProvider} from './SpamFIlterProvider'

type Props = {
  spam: SpamFilterData
}

const SpamFilterAction: FC<Props> = ({spam}) => {
  const {handleUpdateClick, setDeleteSpamText} = useSpamFilterProvider()

  return (
    <>
      <button
        className='btn btn-secondary btn-sm'
        type='button'
        id='dropdownMenuButton'
        data-bs-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
      >
        Action
        {/* <KTIcon iconName='down' className='fs-5' /> */}
      </button>
      <ul
        className='dropdown-menu menu menu-sub menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        aria-labelledby='dropdownMenuButton'
      >
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_1'
            onClick={() => handleUpdateClick(spam)}
          >
            Edit
          </a>
        </div>
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_2'
            onClick={() => setDeleteSpamText(spam)}
          >
            Delete
          </a>
        </div>
      </ul>
    </>
  )
}

export default SpamFilterAction
