import {BASE_URL} from '../config'
import Reports from './Reports'

const GET_OP_AND_SENDER_WISE_REPORT_URL = `${BASE_URL}/api/protect/senderAndOpwiseReport`

const OPandSenderwiseReport = () => {
  return <Reports apiUrl={GET_OP_AND_SENDER_WISE_REPORT_URL} title='OP And Sender Wise Report' />
}

export default OPandSenderwiseReport
