/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {
  HeaderNotificationsMenu,
  HeaderUserMenu,
  Search,
  ThemeModeSwitcher,
  useThemeMode,
} from '../../../partials'
import {useLayout} from '../../core'
import {useAuth} from '../../../../app/modules/auth'
import {UserMenu} from '../../../partials/layout/header-menus/UserMenu'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'fs-1'

const Navbar = () => {
  const {currentUser} = useAuth()
  const {config} = useLayout()
  const {mode} = useThemeMode()

  const avatar =
    mode === 'dark' ? '/media/svg/avatars/blank-dark.svg' : '/media/svg/avatars/blank.svg'

  return (
    <div className='app-navbar flex-shrink-0'>
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTIcon iconName='element-plus' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div> */}

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        {/* <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        > */}
        {/* <img src={toAbsoluteUrl('/media/avatars/iatLogo90x49.png')} alt='' /> */}
        {/* <img src={toAbsoluteUrl(`${avatar}`)} alt='User' /> */}
        {/* </div> */}
        <div
          className={clsx('cursor-pointer symbol dropdown', userAvatarClass)}
          data-bs-toggle='dropdown'
          aria-expanded='false'
          data-bs-auto-close='outside'
          data-bs-offset='10,20'
        >
          <img src={toAbsoluteUrl(`${avatar}`)} alt='User' />
        </div>
        <div className='dropdown-menu'>
          <UserMenu />
        </div>
        {/* <HeaderUserMenu /> */}
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
