import {Column, HeaderProps} from 'react-table'
import {ITicket} from '../core/_model'
import SelectionHeader from '../../../utils/components/Table/SelectionHeader'
import {SelectionCell} from '../../../utils/components/Table/SelectionCell'
import {ListViewContext} from '../../../utils/contextProvider/ListViewProvider'
import {CustomHeader} from '../../../utils/components/Table/CustomHeader'
import {formatDate} from '../../../utils/helperFunction/_helper'
import {PropsWithChildren} from 'react'
import TicketAction from './TicketAction'

export const TicketColumn: ReadonlyArray<Column<ITicket>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props as any} context={ListViewContext} />,
    id: 'selection',
    Cell: ({...props}) => (
      <SelectionCell id={props.data[props.row.index].id} context={ListViewContext} />
    ),
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Ticket Code' className='min-w-150px' />
    ),
    id: 'ticketCode',
    accessor: 'ticketCode',
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Subject' className='min-w-150px' />
    ),
    id: 'subject',
    accessor: 'subject',
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Date' className='min-w-150px' />
    ),
    id: 'date',
    Cell: ({...props}) => <>{formatDate(props.data[props.row.index].date)}</>,
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Department' className='min-w-150px' />
    ),
    id: 'department',
    accessor: 'department',
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Tags' className='min-w-150px' />
    ),
    id: 'tags',
    accessor: 'tags',
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Status' className='min-w-150px' />
    ),
    id: 'status',
    accessor: 'status',
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Action' className='min-w-150px' />
    ),
    id: 'action',
    Cell: () => <TicketAction />,
  },
]
