/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, {useCallback, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Form, Button, Tabs, Tab} from 'react-bootstrap'
import {useAuth} from '../../auth'
import {v4 as uuid} from 'uuid'
import axios from 'axios'
import {BASE_URL} from '../../config'
import {Route} from '../core/_model'
import {useSenderIdProvider} from '../context/SenderIdProvider'
import ContentLoading from '../../../utils/components/Loading/ContentLoading'

const GET_SINGLE_MASKING_SENDER = `${BASE_URL}/api/protect/getSender`

interface ICheckboxValues {
  [key: string]: boolean
}
interface IOperatorNumbers {
  [key: string]: string
}
interface ISelectValues {
  [key: string]: string | undefined
}
interface ITextValues {
  [key: string]: string
}

const operator = [1, 2, 3, 4, 5, 6, 7]

const SenderIdMaskingModal = () => {
  const {auth} = useAuth()
  const {
    options,
    updateSender: updateSenderId,
    hide,
    createMaskingSender,
    createNonMaskingSender,
    updateNonBranded,
    updateBranded,
  } = useSenderIdProvider()
  const [key, setKey] = useState('masking')
  const [selectRoute, setSelectRoute] = useState<string | undefined>('')
  const [nonMaskingSender, setNonMaskingSender] = useState('')
  const [maskingSender, setMaskingSender] = useState('')

  const [loading, setLoading] = useState(false)

  const [checkboxValues, setCheckboxValues] = useState<ICheckboxValues>({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
    checkbox5: false,
    checkbox6: false,
    checkbox7: false,
  })

  const [operatorNumbers] = useState<IOperatorNumbers>({
    operatorNumber1: '88013',
    operatorNumber2: '88014',
    operatorNumber3: '88015',
    operatorNumber4: '88016',
    operatorNumber5: '88017',
    operatorNumber6: '88018',
    operatorNumber7: '88019',
  })

  const [selectValues, setSelectValues] = useState<ISelectValues>({
    select1: 'Option 1',
    select2: 'Option 1',
    select3: 'Option 1',
    select4: 'Option 1',
    select5: 'Option 1',
    select6: 'Option 1',
    select7: 'Option 1',
  })

  const [textValues, setTextValues] = useState<ITextValues>({
    text1: '',
    text2: '',
    text3: '',
    text4: '',
    text5: '',
    text6: '',
    text7: '',
  })

  const handleTextChange = useCallback(
    (prefix: string, value: string) => {
      setTextValues((prevValues) => ({
        ...prevValues,
        [prefix]: value,
      }))
    },
    [setTextValues]
  )

  const handleCheckboxChange = (prefix: string) => {
    setCheckboxValues((prevValues) => {
      const updatedValues = {
        ...prevValues,
        [prefix]: !prevValues[prefix],
      }
      const range = prefix.charAt(prefix.length - 1)
      if (updatedValues[prefix]) {
        setTextValues((prevValues) => ({
          ...prevValues,
          [`text${range}`]: maskingSender,
        }))
      } else {
        setTextValues((prevValues) => ({
          ...prevValues,
          [`text${range}`]: '',
        }))
      }
      return updatedValues
    })
  }

  const handleSelectChange = (prefix: string, value: string) => {
    setSelectValues((prevValues) => ({
      ...prevValues,
      [prefix]: value,
    }))
  }

  const getCheckedValues = () => {
    const checkedValues = []
    for (let number = 1; number <= operator.length; number++) {
      const prefix = `checkbox${number}`
      if (checkboxValues[prefix]) {
        const isRoute: any = options.find(
          (opt: Route) => opt.name === selectValues[`select${number}`]
        )
        const value = {
          operatorPrefix: operatorNumbers[`operatorNumber${number}`],
          // routeconfId: selectValues[`select${number}`],
          routeconfId: isRoute ? isRoute.id : '',
          masking: textValues[`text${number}`],
        }
        checkedValues.push(value)
      }
    }
    return checkedValues
  }

  const getSingleMaskingSender = async (id: number) => {
    try {
      setLoading(true)
      const response = await axios.get(`${GET_SINGLE_MASKING_SENDER}/${id}?senderId=${id}`, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      const {senderInfoDto, maskingDetailsDto} = await response.data
      // console.log(maskingDetailsDto)
      setMaskingSender(senderInfoDto.sender)

      Object.entries(operatorNumbers).forEach((itemOperator, indexOperator) => {
        // console.log(itemOperator)
        maskingDetailsDto.forEach((itemMaskDetails: any) => {
          console.log(itemMaskDetails.senderrouteid)
          if (itemOperator[1] === itemMaskDetails.operatorprefix) {
            // console.log(indexOperator)
            const prefixNum = indexOperator + 1
            setCheckboxValues((prevValues) => ({
              ...prevValues,
              [`checkbox${prefixNum}`]: true,
            }))

            setTextValues((prevValues) => ({
              ...prevValues,
              [`text${prefixNum}`]: itemMaskDetails.masking,
            }))

            const route = options.find((opt: Route) => opt.id === itemMaskDetails.routeconfId)

            setSelectValues((prevValues) => ({
              ...prevValues,
              [`select${prefixNum}`]: route?.name,
            }))
          }
        })
      })

      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (updateSenderId) {
      setNonMaskingSender('')
      setSelectRoute('')
      setMaskingSender('')
      setCheckboxValues({
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
        checkbox4: false,
        checkbox5: false,
        checkbox6: false,
        checkbox7: false,
      })
      setTextValues({
        text1: '',
        text2: '',
        text3: '',
        text4: '',
        text5: '',
        text6: '',
        text7: '',
      })
      setSelectValues({
        select1: 'Option 1',
        select2: 'Option 1',
        select3: 'Option 1',
        select4: 'Option 1',
        select5: 'Option 1',
        select6: 'Option 1',
        select7: 'Option 1',
      })
      if (updateSenderId.branded === 0) {
        console.log(updateSenderId)
        setKey('nonmasking')
        setNonMaskingSender(updateSenderId.sender)
        const route = options.find((opt: Route) => opt.id === updateSenderId.routeconfid)
        setSelectRoute(route?.name)
      } else {
        setKey('masking')
        getSingleMaskingSender(updateSenderId.id)
      }
    } else {
      setNonMaskingSender('')
      setSelectRoute('')
      setMaskingSender('')
      setCheckboxValues({
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
        checkbox4: false,
        checkbox5: false,
        checkbox6: false,
        checkbox7: false,
      })
      setTextValues({
        text1: '',
        text2: '',
        text3: '',
        text4: '',
        text5: '',
        text6: '',
        text7: '',
      })
      setSelectValues({
        select1: 'Option 1',
        select2: 'Option 1',
        select3: 'Option 1',
        select4: 'Option 1',
        select5: 'Option 1',
        select6: 'Option 1',
        select7: 'Option 1',
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSenderId])

  const handleMasking = (e: any) => {
    e.preventDefault()
    // console.log(maskingSenderName.current.value)
    if (!maskingSender) {
      return
    }
    const data = {
      senderId: maskingSender,
      maskingInfo: getCheckedValues(),
    }

    if (updateSenderId) {
      updateBranded(updateSenderId.id, data)
    } else {
      createMaskingSender(data)
    }
    console.log(data)
  }

  const handleNonMasking = (e: any) => {
    e.preventDefault()
    const isRoute: any = options.find((opt: Route) => opt.name === selectRoute)

    if (isRoute) {
      const data = {
        senderId: nonMaskingSender,
        routeId: isRoute.id,
      }
      if (updateSenderId) {
        updateNonBranded(updateSenderId.id, data)
      } else {
        createNonMaskingSender(data)
      }
    } else {
      return
    }
  }

  return (
    <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h4 className='modal-title fs-2'>Sender</h4>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={() => hide()}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
            {/* <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={() => hide()}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div> */}
          </div>

          {loading ? (
            <ContentLoading />
          ) : (
            <Tabs
              activeKey={key}
              onSelect={(k: any) => setKey(k)}
              id='controlled-tab'
              className='mb-3'
            >
              <Tab
                eventKey='masking'
                className='px-8 pb-10'
                title='Masking'
                disabled={updateSenderId ? (key === 'nonmasking' ? true : false) : false}
              >
                <form onSubmit={handleMasking}>
                  <div className='mb-4'>
                    <label className='col-lg-4 col-form-label fs-6'>
                      <span>Sender Name</span>
                    </label>

                    <div className='col-lg-12 fv-row'>
                      <input
                        // name='cm_name'
                        // ref={maskingSenderName}
                        value={maskingSender}
                        onChange={(e) => setMaskingSender(e.target.value)}
                        placeholder='Sender Name'
                        className='form-control form-control-lg form-control-solid'
                      />
                    </div>
                  </div>

                  <div className='mb-4'>
                    <label className='col-lg-4 col-form-label  fs-6'>
                      <span>Select operator</span>
                    </label>
                    {operator.map((number) => {
                      return (
                        <div className='d-flex align-items-center mb-6 gap-3' key={number}>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              checked={checkboxValues[`checkbox${number}`]}
                              onChange={(e) => {
                                e.persist()
                                handleCheckboxChange(`checkbox${number}`)
                              }}
                            />
                            <label className='form-check-label'>
                              {operatorNumbers[`operatorNumber${number}`]}
                            </label>
                          </div>
                          <Form.Select
                            value={selectValues[`select${number}`]}
                            onChange={(e) => {
                              e.persist()
                              handleSelectChange(`select${number}`, e.target.value)
                            }}
                            placeholder='Select a route'
                          >
                            <option>Select a route</option>
                            {options &&
                              options.map((option: any) => {
                                return (
                                  <option key={uuid()} value={option.name}>
                                    {option.name}
                                  </option>
                                )
                              })}
                          </Form.Select>
                          <div className='col-lg-6'>
                            <input
                              type='text'
                              value={textValues[`text${number}`]}
                              onChange={(e) => {
                                e.persist()
                                handleTextChange(`text${number}`, e.target.value)
                              }}
                              placeholder={`Branded name ${
                                operatorNumbers[`operatorNumber${number}`]
                              } prefix`}
                              className='form-control form-control-lg form-control-solid'
                            />
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div>
                    <Button
                      type='submit'
                      variant='primary'
                      size='lg'
                      className='d-block mt-8 w-100'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      {updateSenderId ? 'Update' : 'Save'}
                    </Button>
                  </div>
                </form>
              </Tab>

              <Tab
                eventKey='nonmasking'
                title='Non Masking'
                className='px-8 pb-10'
                disabled={updateSenderId ? (key === 'masking' ? true : false) : false}
              >
                <form onSubmit={handleNonMasking}>
                  <div className='mb-4'>
                    <label className='col-lg-4 col-form-label  fs-6'>
                      <span className='required fs-4'>Sender Name</span>
                    </label>

                    <div className='col-lg-12 fv-row'>
                      <input
                        name='cm_name'
                        placeholder='Sender Name'
                        value={nonMaskingSender}
                        onChange={(e) => setNonMaskingSender(e.target.value)}
                        className='form-control form-control-lg form-control-solid'
                        required
                      />
                    </div>
                  </div>

                  <label className='col-lg-4 col-form-label  fs-6'>
                    <span className='required fs-4'>Route</span>
                  </label>
                  <Form.Select
                    value={selectRoute}
                    onChange={(e) => setSelectRoute(e.target.value)}
                    placeholder='Select a route'
                    required
                  >
                    <option>Select a route</option>
                    {options &&
                      options.map((option: any) => {
                        return (
                          <option key={option.id} value={option.name}>
                            {option.name}
                          </option>
                        )
                      })}
                  </Form.Select>
                  <span className='fs-6'>Which route will be used to send the message</span>
                  <div>
                    <Button
                      type='submit'
                      variant='primary'
                      size='lg'
                      className='d-block mt-8 w-100'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      {updateSenderId ? 'Update' : 'Save'}
                    </Button>
                  </div>
                </form>
              </Tab>
            </Tabs>
          )}
        </div>
      </div>
    </div>
  )
}

export default SenderIdMaskingModal
