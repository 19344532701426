import React, {FormEvent, useEffect, useState} from 'react'
import {useSettingsProvider} from './SettingsProvider'
import {OptionsObject} from './core/_model'
import ContentLoading from '../../utils/components/Loading/ContentLoading'

const PaymentSettings = () => {
  const {isLoading, setIsLoading, paymentOptionsObj, getAllOptions, updateOptions} =
    useSettingsProvider()

  const [paymentOptions, setPaymentOptions] = useState<OptionsObject>({
    gatewayName: undefined,
    gatewayMode: undefined,
    appKey: undefined,
    secretKey: undefined,
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = event.target
    setPaymentOptions((prevData) => ({
      ...prevData,
      [name]: {
        ...prevData[name],
        optionsValue: value,
      },
    }))
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    setIsLoading(true)

    for (const key in paymentOptions) {
      await updateOptions(paymentOptions[key])
    }

    getAllOptions()
  }

  useEffect(() => {
    setPaymentOptions({
      gatewayName: paymentOptionsObj.payment_gateway_name,
      gatewayMode: paymentOptionsObj.payment_gateway_mode,
      appKey: paymentOptionsObj.payment_gateway_app_key,
      secretKey: paymentOptionsObj.payment_gateway_secret_key,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return (
    <div className='card col-12 col-md-10'>
      <div className='card-header'>
        <div className='card-title'>
          <h3 className='text-gray-700'>Payment Settings</h3>
        </div>
      </div>
      <div className='card-body'>
        {isLoading ? (
          <ContentLoading />
        ) : (
          <form onSubmit={handleSubmit}>
            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label fs-6'>
                <span>Gateway name</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  name='gatewayName'
                  placeholder='Gateway name'
                  className='form-control form-control-lg form-control-solid'
                  value={paymentOptions.gatewayName?.optionsValue}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label fs-6'>
                <span>Gateway mode</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  name='gatewayMode'
                  placeholder='Gateway mode'
                  className='form-control form-control-lg form-control-solid'
                  value={paymentOptions.gatewayMode?.optionsValue}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label fs-6'>
                <span>Gateway app key</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  name='appKey'
                  placeholder='Gateway app key'
                  className='form-control form-control-lg form-control-solid'
                  value={paymentOptions.appKey?.optionsValue}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label fs-6'>
                <span>Gateway secret key</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  name='secretKey'
                  placeholder='Gateway secret key'
                  className='form-control form-control-lg form-control-solid'
                  value={paymentOptions.secretKey?.optionsValue}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className='d-flex justify-content-end'>
              <button type='submit' className='btn btn-primary'>
                Save Changes
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

export default PaymentSettings
