import React from 'react'
import {SMSTemplete} from './SMSTemplete'
import {SMSTemplateProvider} from '../context/SMSTemplateProvider'

const SMSTemplateWrapper = () => {
  return (
    <SMSTemplateProvider>
      <SMSTemplete />
    </SMSTemplateProvider>
  )
}

export default SMSTemplateWrapper
