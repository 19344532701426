import {Tab, Tabs} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import {CodeBlock} from '../../../../_metronic/partials'
import {useAuth} from '../../auth'
import React from 'react'
import {exampleCodeBaseArray} from '../core/ExampleCodeBase'
import {IExampleCodeBase} from '../core/_model'

interface CodeExampleModalProps {
  apikey: string | undefined
}

const CodeExampleModal: React.FC<CodeExampleModalProps> = ({apikey}) => {
  const {currentUser} = useAuth()
  const exampleCodeBase = exampleCodeBaseArray(apikey, currentUser?.user_mobile)

  return (
    <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
      <div className='modal-dialog modal-fullscreen'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Code Examples</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <Tabs defaultActiveKey='php' id='justify-tab-example' className='mb-3' justify>
              {exampleCodeBase.map((codeBlock: IExampleCodeBase) => {
                return (
                  <Tab
                    eventKey={codeBlock.languageType.toLowerCase()}
                    key={codeBlock.id}
                    title={codeBlock.languageType}
                  >
                    {codeBlock.codes.map((code: string, index: number) => {
                      return <CodeBlock key={index} code={code} language={codeBlock.language} />
                    })}
                  </Tab>
                )
              })}
            </Tabs>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-success'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CodeExampleModal
