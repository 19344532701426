import React, {FC} from 'react'
import {useUserListProvider} from './UserListProvider'
import {IUser} from './core/_model'

type Props = {
  user: IUser
}

const UserKycFormButton: FC<Props> = ({user}) => {
  const {kycForm} = useUserListProvider()
  return <div>{kycForm(user.is_kyc_form_filled, user)}</div>
}

export default UserKycFormButton
