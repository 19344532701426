import toast from 'react-hot-toast'
import {BASE_URL} from '../../config'
import axios from 'axios'

export const GENERATE_API_KEY_URL = `${BASE_URL}/api/protect/generateApi`

export const singleSms = `
{
    "response":[
        {
            "status":0,
            "id":296334,
            "msisdn":"88019XXXXXXXX"
        }
    ]
}
`

export const multipleSms = `
{
    "response":[
        {
            "status":0,
            "id":296334,
            "msisdn":"88017XXXXXXXX"
        },
        {
            "status":0,
            "id":296335,
            "msisdn":"88019XXXXXXXX"
        },
        {
            "status":0,
            "id":296336,
            "msisdn":"88018XXXXXXXX"
        }
    ]
}`

export const generateApiKey = async (userid: number | undefined, token: string | undefined) => {
  try {
    toast.loading('Generate new key..')
    const response = await axios.put(`${GENERATE_API_KEY_URL}/${userid}`, userid, {
      headers: {Authorization: `Bearer ${token}`},
    })
    const data = await response.data
    const key = await data.api_key
    toast.dismiss()
    toast.success('API key generated')
    return key
  } catch (error: any) {
    toast.dismiss()
    toast.error(`Error: ${error.message}`)
  }
}
