import React, {FC} from 'react'
import {ISenderId} from '../core/_model'
import {useSenderIdProvider} from '../context/SenderIdProvider'
import {Button} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'

type SenderIdsActionProps = {
  sender: ISenderId
}

const SenderIdAction: FC<SenderIdsActionProps> = ({sender}) => {
  const {setUpdateSender} = useSenderIdProvider()

  return (
    <div className='text-end'>
      <button
        type='button'
        className='btn btn-icon btn-light btn-sm'
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_1'
        onClick={() => setUpdateSender(sender)}
      >
        <KTIcon iconName='pencil' className='fs-6' />
      </button>
    </div>
  )
}

export default SenderIdAction
