import {Dispatch, ReactNode, SetStateAction} from 'react'
import {BASE_URL} from '../../config'
import {number} from 'yup'
import {ID} from '../../../../_metronic/helpers'

export const CREATE_PAYMENT_URL = `${BASE_URL}/api/protect/createPayment`
export const UPLOAD_PAYMENT_SLIP_URL = `${BASE_URL}/api/protect/uploadPaymentSlip`
export const GET_PAYMENT_URL = `${BASE_URL}/api/protect/getallPayments`
export const GET_SLIP_URL = `${BASE_URL}/api/protect/getSlip`
export const UPDATE_PAYMENT_URL = `${BASE_URL}/api/protect/updatePaymentRequest`
export const DELETE_PAYMENT_URL = `${BASE_URL}/api/protect/deletePayment`
export const REJECT_PAYMENT_URL = `${BASE_URL}/api/protect/rejectPayment`
export const APPROVE_PAYMENT_URL = `${BASE_URL}/api/protect/approvePayment`

export interface PaymentProviderProps {
  children: ReactNode
}

export interface PaymentRequestData {
  amount: string | number
  paymentMedia: string
  transectionId: string
}

export interface PaymentReq {
  id: ID
  userId: number
  amount: number | string
  paymentMedia: string
  insertDate: string
  previousBalance: number
  changedBalance: number
  bonus: number
  status: string
  comment: string
  transectionId: string
}

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID | undefined>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  isAllSelected: false,
  disabled: false,
}
