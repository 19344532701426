import clsx from 'clsx'
import {KTSVG} from '../../../../_metronic/helpers'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {Button} from 'react-bootstrap'
import {useEffect, useState} from 'react'
import {ITemplateInitialValues} from '../core/_model'
import {useSMSTemplateProvider} from '../context/SMSTemplateProvider'

const TemplateInitialValues: ITemplateInitialValues = {
  template_name: '',
  template_text: '',
}

const createTemplateSchema = Yup.object().shape({
  template_name: Yup.string().required('Campaign name is required.'),
  template_text: Yup.string().required('Campaign name is required.'),
})

const CreateSmsTemplateModal = () => {
  const {
    createSmsTemplate,
    updateTemplate: template,
    showUpdateModal: show,
    hide,
    updateSmsTemplate: updateTemplate,
  } = useSMSTemplateProvider()
  const [smartCheck, setSmartCheck] = useState(false)

  const initialValues: ITemplateInitialValues = template
    ? {
        template_name: template?.templateName || '',
        template_text: template?.smsText || '',
      }
    : TemplateInitialValues

  const formik = useFormik({
    initialValues,
    validationSchema: createTemplateSchema,
    // enableReinitialize: true,
    onSubmit: (values) => {
      const templateContent = {
        templateName: values.template_name,
        templateText: values.template_text,
        isSmartSms: smartCheck,
      }
      if (template) {
        updateTemplate(template.id, templateContent)
      } else {
        createSmsTemplate(templateContent)
      }
      handleClear()
      // navigator('/smsbroadcast/smstemplete')
    },
  })

  const handleClear = () => {
    hide()
    formik.resetForm()
  }

  useEffect(() => {
    if (show && template) {
      const valuesToBeUpdate = {
        template_name: template?.templateName || '',
        template_text: template?.smsText || '',
      }
      formik.setValues(valuesToBeUpdate)
      setSmartCheck(template.isSmartTemplate === 0 ? false : true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, template])

  return (
    <div
      className='modal fade'
      style={template && {visibility: !show ? 'hidden' : undefined}}
      tabIndex={-1}
      id='kt_modal_1'
    >
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title fs-2'>
              {template ? 'Update Template' : 'Create New Template'}
            </h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={() => handleClear()}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <form onSubmit={formik.handleSubmit}>
              <div className='mb-4'>
                <label className='col-lg-4 col-form-label  fs-6'>
                  <span className='required'>Template Name</span>
                </label>

                <div className='col-lg-12 fv-row'>
                  <input
                    name='template_name'
                    placeholder='Template Name'
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.template_name && formik.errors.template_name},
                      {
                        'is-valid': formik.touched.template_name && !formik.errors.template_name,
                      }
                    )}
                    value={formik.values.template_name}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.template_name && formik.errors.template_name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'></div>
                    </div>
                  )}
                </div>
              </div>

              <div className='mb-4'>
                <label className='col-lg-4 col-form-label  fs-6'>
                  <span className='required'>Template Text</span>
                </label>
                <div className='col-lg-12 fv-row'>
                  <textarea
                    name='template_text'
                    placeholder='Template Text'
                    rows={5}
                    style={{resize: 'vertical', fontSize: '1.0rem'}}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.template_text && formik.errors.template_text},
                      {
                        'is-valid': formik.touched.template_text && !formik.errors.template_text,
                      }
                    )}
                    value={formik.values.template_text}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.template_text && formik.errors.template_text && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'></div>
                    </div>
                  )}
                </div>

                {smartCheck && (
                  <p className='fs-5 fw-medium mt-2'>
                    Use <span className='text-primary'> #variable_name# </span>for dynamically
                    update value
                  </p>
                )}
              </div>

              <div className='form-check form-switch d-flex align-items-center gap-4 mt-8 mb-8'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='flexSwitchCheckChecked'
                  onChange={() => setSmartCheck((prev: any) => !prev)}
                  checked={smartCheck}
                />
                <label className='form-check-label fs-4 fw-bold' htmlFor='flexSwitchCheckChecked'>
                  Smart SMS
                </label>
              </div>
              <Button
                type='button'
                className='mt-6 w-100'
                variant='secondary'
                size='lg'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => formik.resetForm()}
              >
                Cancel
              </Button>
              <Button
                type='submit'
                className='mt-6 w-100'
                variant='primary'
                size='lg'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                Save
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateSmsTemplateModal
