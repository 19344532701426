import {Column} from 'react-table'
import {CustomHeader} from './CustomHeader'
import {PaymentActionsCell} from './PaymentActionCell'
import {PaymentReq} from './core/_model'
import SelectionHeader, {SelectionHeaderProps} from '../../utils/components/Table/SelectionHeader'
import {SelectionCell} from '../../utils/components/Table/SelectionCell'
import PaymentListStatus from './PaymentListStatus'
import {ListViewContext} from './core/ListViewProvider'
import {formatDate} from '../../utils/helperFunction/_helper'

const PaymentColumns: ReadonlyArray<Column<PaymentReq>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props as any} context={ListViewContext} />,
    id: 'selection',
    Cell: ({...props}) => (
      <SelectionCell id={props.data[props.row.index].id} context={ListViewContext} />
    ),
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Payment Type' className='min-w-125px' />
    ),
    id: 'name',
    accessor: 'paymentMedia',
    // Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Transaction ID' className='min-w-125px' />
    ),
    accessor: 'transectionId',
  },
  // {
  //   Header: (props) => (
  //     <CustomHeader tableProps={props} title='Previous Balance' className='min-w-125px' />
  //   ),
  //   id: 'previousBalance',
  //   accessor: 'previousBalance',
  // },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Paid Amount' className='min-w-125px' />
    ),
    id: 'amount',
    accessor: 'amount',
    // Cell: ({...props}) => <UserTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  },
  // {
  //   Header: (props) => <CustomHeader tableProps={props} title='Bonus' className='min-w-125px' />,
  //   id: 'bonus',
  //   accessor: 'bonus',
  // },
  // {
  //   Header: (props) => (
  //     <CustomHeader tableProps={props} title='Update Balance' className='min-w-125px' />
  //   ),
  //   id: 'changedBalance',
  //   accessor: 'changedBalance',
  // },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Payment Date' className='min-w-125px' />
    ),
    id: 'insertDate',
    accessor: 'insertDate',
    Cell: ({...props}) => <p>{formatDate(props.data[props.row.index].insertDate)}</p>,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    id: 'status',
    accessor: 'status',
    Cell: ({...props}) => <PaymentListStatus payment={props.data[props.row.index]} />,
  },
  // {
  //   Header: (props) => (
  //     <CustomHeader tableProps={props} title='Admin Comment' className='min-w-125px' />
  //   ),
  //   id: 'comment',
  //   accessor: 'comment',
  // },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Actions' className='min-w-100px' />,
    id: 'actions',
    Cell: ({...props}) => <PaymentActionsCell payment={props.data[props.row.index]} />,
  },
]

export {PaymentColumns}
