import axios from 'axios'
import {useEffect, useState} from 'react'
import {useAuth} from '../modules/auth'
import {BASE_URL} from '../modules/config'

const CONTACT_GROUP_LIST_URL = `${BASE_URL}/api/protect/getallcontactGrouplist`

export type GroupIdType = {
  value: any
  label: string
  id: number
  subCount: number
}

const useContactGroupOptions = (groupChange = false) => {
  const {auth, currentUser} = useAuth()
  const [group, setGroup] = useState<GroupIdType[]>([])
  let groupOptions: GroupIdType[] = []

  const getContactGroup = async () => {
    try {
      const response = await axios.get(
        `${CONTACT_GROUP_LIST_URL}/${currentUser?.user_profile}?userId=${currentUser?.user_profile}`,
        {
          headers: {Authorization: `Bearer ${auth?.accessToken}`},
        }
      )
      const data = await response.data

      const extractData = data.map((grp: any) => ({
        value: grp.groupName,
        label: grp.groupName,
        id: grp.id,
        subCount: grp.subCount,
      }))
      console.log(extractData)
      setGroup(extractData)
      console.log(group)
    } catch (err) {
      const errorMessage = (err as Error)?.message || 'An error occurred'
      console.log(errorMessage)
    }
  }

  useEffect(() => {
    console.log('hell')
    getContactGroup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupChange])

  return {group, getContactGroup}
}

export default useContactGroupOptions
