/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ChangeEvent, FormEvent, PropsWithChildren, useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {useAuth} from '../auth'
import toast from 'react-hot-toast'
import axios from 'axios'
import {IReportData, OpwiseReportProps} from './core/_model'
import ReportFilter from './ReportFilter'
import {KTIcon} from '../../../_metronic/helpers'
import TableContent from '../../utils/components/Table/TableContent'
import {Column, HeaderProps} from 'react-table'
import {CustomHeader} from '../../utils/components/Table/CustomHeader'
import {formatDate} from '../../utils/helperFunction/_helper'
import TablePagination from '../../utils/components/Table/TablePagination'
import SelectionHeader from '../../utils/components/Table/SelectionHeader'
import {ListViewContext, ListViewProvider} from '../../utils/contextProvider/ListViewProvider'
import {SelectionCell} from '../../utils/components/Table/SelectionCell'
import ReportHeader from './ReportHeader'

const Reports: React.FC<OpwiseReportProps> = ({apiUrl, title}) => {
  const {auth} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [opWiseReport, setOpWiseReport] = useState<IReportData[]>([])
  const [filterReport, setFilterReport] = useState<IReportData[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [startTime, setStartTime] = useState<string>('')
  const [endTime, setEndTime] = useState<string>('')
  const [reportType, setReportType] = useState<string>('daily')
  const [click, setClick] = useState(0)

  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalElement, setTotalElement] = useState(0)
  const [isLastPage, setIsLastPage] = useState(false)

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)

  const onSearchSubmit = (event: FormEvent) => {
    event.preventDefault()

    setCurrentPage(1)
    setItemsPerPage(10)
    setClick((prev) => prev + 1)
  }

  const getOpWiseReport = async (params: any, type: string | undefined = 'daily') => {
    try {
      setIsLoading(true)
      toast.loading(`Getting Op Wise ${type} report...`)
      const response = await axios.get(`${apiUrl}/${type}`, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
        params: params,
      })
      const result = await response.data
      const data = await result.data
      const lastPage = await result.lastpage
      const totalResult = await result.totalElement
      setOpWiseReport(data ? data : [])
      formatData(data ? data : [])
      setIsLastPage(lastPage)
      setTotalElement(totalResult)
      setIsLoading(false)
      toast.dismiss()
      toast.success('Fetched Op Wise Report')
    } catch (error: any) {
      setIsLoading(false)
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
    }
  }

  const formatData = (data: any[]) => {
    const formattedData: any[] = []
    console.log(data)

    data.map((item: any, index) => {
      const singleData: IReportData = {
        id: index + 1,
        date: item.sdate ? item.sdate : item.month,
        name: item.userInformation ? item.userInformation : item.routeConf,
        senderId: item.senderInfo,
        status: item.status,
        count: item.smsCount,
        hit: item.smsHit,
        channel: item.panelApi,
      }

      // console.log(singleData)
      formattedData.push(singleData)
      return formattedData
    })

    setOpWiseReport(formattedData)
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  let showPageLastEntries = !isLastPage ? currentPage * itemsPerPage : totalElement
  let showPageFirstEntries = !isLastPage
    ? showPageLastEntries + 1 - itemsPerPage
    : (currentPage - 1) * itemsPerPage + 1

  useEffect(() => {
    const filteredData = opWiseReport?.filter(
      (list) =>
        list.status?.toLowerCase().includes(searchTerm) ||
        list.senderId?.toLowerCase().includes(searchTerm) ||
        list.panelApi?.toLowerCase().includes(searchTerm)
    )
    console.log(filteredData)
    setFilterReport(filteredData)
  }, [opWiseReport, searchTerm])

  useEffect(() => {
    document.title = title
  }, [title])

  useEffect(() => {
    if (startTime && endTime) {
      const setParams = {
        pageNumber: currentPage - 1,
        pageSize: itemsPerPage,
        startDate: startTime,
        endDate: endTime,
      }

      getOpWiseReport(setParams, reportType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, currentPage, click])

  const ReportColumn: ReadonlyArray<Column<IReportData>> = [
    {
      Header: (props) => <SelectionHeader tableProps={props as any} context={ListViewContext} />,
      id: 'selection',
      Cell: ({...props}) => (
        <SelectionCell id={props.data[props.row.index].id} context={ListViewContext} />
      ),
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Date' className='min-w-150px' />
      ),
      id: 'date',
      Cell: ({...props}) => <>{formatDate(props.data[props.row.index].date)}</>,
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Name' className='min-w-150px' />
      ),
      id: 'name',
      accessor: 'name',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Sender ID' className='min-w-150px' />
      ),
      id: 'senderId',
      accessor: 'senderId',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Status' className='min-w-100px' />
      ),
      id: 'status',
      accessor: 'status',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Count' className='min-w-100px' />
      ),
      id: 'count',
      accessor: 'count',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Hit' className='min-w-100px' />
      ),
      id: 'hit',
      accessor: 'hit',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Channel' className='min-w-100px' />
      ),
      id: 'channel',
      accessor: 'channel',
    },
  ]

  //   <tfoot>
  //   <tr className='w-100'>
  //     <td colSpan={4} className='text-center'>
  //       Total
  //     </td>
  //     <td>
  //       {filterReport.reduce((acc, currentValue) => {
  //         return acc + currentValue.count
  //       }, 0)}
  //     </td>
  //     <td>
  //       {filterReport.reduce((acc, currentValue) => {
  //         return acc + currentValue.hit
  //       }, 0)}
  //     </td>
  //     <td></td>
  //   </tr>
  //   <tr className='w-100'>
  //     <td colSpan={12}>
  //       Showing {showPageFirstEntries} to {showPageLastEntries} of {totalElement} entries
  //     </td>
  //   </tr>
  // </tfoot>

  return (
    <ListViewProvider isLoading={isLoading} data={filterReport}>
      <div className='card'>
        <div className='card-header d-flex justify-content-between'>
          <h3 className='card-title'>{title}</h3>
          <div className='card-title'>
            <Button variant='primary' size='sm' className='me-2'>
              Download
            </Button>
          </div>
        </div>

        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='Search Group'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <div className='card-title m-0'>
            <ReportHeader
              startTime={startTime}
              endTime={endTime}
              itemsPerPage={itemsPerPage}
              reportType={reportType}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              handleSearchSubmit={onSearchSubmit}
              setItemsPerPage={setItemsPerPage}
              setCurrentPage={setCurrentPage}
              setReportType={setReportType}
            />
          </div>
        </div>

        <TableContent isLoading={isLoading} tableData={filterReport} tableColumn={ReportColumn}>
          <TablePagination
            totalElement={totalElement}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            isLoading={isLoading}
          />
        </TableContent>
      </div>
    </ListViewProvider>
  )
}

export default Reports
