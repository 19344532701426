import React, {FormEvent, useEffect, useState} from 'react'
import {useSettingsProvider} from './SettingsProvider'
import {OptionsObject} from './core/_model'
import ContentLoading from '../../utils/components/Loading/ContentLoading'
import {optionsValue} from '../SMSBroadCast/SettingsModel'

const SiteSettings = () => {
  const {isLoading, setIsLoading, siteOptionObj, getAllOptions, updateOptions} =
    useSettingsProvider()

  const [siteOption, setSiteOption] = useState<OptionsObject>({
    siteUrl: undefined,
    siteName: undefined,
    onlineRegParentId: undefined,
    userCanReg: undefined,
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = event.target
    setSiteOption((prevData) => ({
      ...prevData,
      [name]: {
        ...prevData[name],
        optionsValue: value,
      },
    }))
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    setIsLoading(true)

    for (const key in siteOption) {
      await updateOptions(siteOption[key])
    }

    getAllOptions()

    console.log(siteOption)
  }

  useEffect(() => {
    setSiteOption({
      siteUrl: siteOptionObj.siteurl,
      siteName: siteOptionObj.site_name,
      onlineRegParentId: siteOptionObj.online_reg_parent_id,
      userCanReg: siteOptionObj.users_can_register,
    })

    console.log(siteOptionObj.users_can_register?.optionsValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return (
    <div className='card col-12 col-md-10'>
      <div className='card-header'>
        <div className='card-title'>
          <h3 className='text-gray-700'>Payment Settings</h3>
        </div>
      </div>
      <div className='card-body'>
        {isLoading ? (
          <ContentLoading />
        ) : (
          <form onSubmit={handleSubmit}>
            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label fs-6'>
                <span>Site URL</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  name='siteUrl'
                  placeholder='Site URL'
                  className='form-control form-control-lg form-control-solid'
                  value={siteOption.siteUrl?.optionsValue}
                  onChange={handleChange}
                  disabled
                />
              </div>
            </div>

            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label fs-6'>
                <span>Gateway name</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  name='siteName'
                  placeholder='Site name'
                  className='form-control form-control-lg form-control-solid'
                  value={siteOption.siteName?.optionsValue}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className='row mb-4 align-items-center'>
              <label className='col-lg-4 col-form-label fs-6'>
                <span>User Can Register</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <div className='form-check form-switch form-check-custom form-check-solid'>
                  <input
                    name='userCanReg'
                    className='form-check-input'
                    type='checkbox'
                    value={siteOption.userCanReg?.optionsValue}
                    checked={siteOption.userCanReg?.optionsValue === '1' ? true : false}
                    onChange={(e) => {
                      console.log(siteOption.userCanReg)
                      setSiteOption((prev) => ({
                        ...prev,
                        userCanReg:
                          prev.userCanReg?.optionsValue === '1'
                            ? {...prev.userCanReg, optionsValue: '0'}
                            : {...prev.userCanReg, optionsValue: '1'},
                      }))
                    }}
                  />
                </div>
              </div>
            </div>

            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label fs-6'>
                <span>Online Registration Parent ID</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  name='onlineRegParentId'
                  placeholder='Site name'
                  className='form-control form-control-lg form-control-solid'
                  value={siteOption.onlineRegParentId?.optionsValue}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className='d-flex justify-content-end'>
              <button type='submit' className='btn btn-primary'>
                Save Changes
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

export default SiteSettings
