import React, {ChangeEvent, useEffect, useState} from 'react'
import {QuickSmsForm, QuickSmsFormInitValues as initialValues} from '../SMSBroadCast/SettingsModel'
import * as Yup from 'yup'
import {FormikProps, FormikValues, useFormik} from 'formik'
import {Button} from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
import {Link} from 'react-router-dom'
import DateTimePicker from 'react-datetime-picker'
import 'react-datetime-picker/dist/DateTimePicker.css'
import 'react-calendar/dist/Calendar.css'
import 'react-clock/dist/Clock.css'
import Select from 'react-select'
import clsx from 'clsx'
import {useAuth} from '../auth'
import useSenderIdsOptions from '../../hooks/useSenderIdsOptions'
import QuickSMSOverview from './Modal/QuickSMSOverview'
import SmsTemplateModal from './Modal/SmsTemplateModal'
import {useThemeMode} from '../../../_metronic/partials'
import {CapitalizeFirstLetter} from '../Users/AddressForm'
import useSms from './hook/useSms'

let quickSmsSchema = Yup.object().shape({
  cm_name: Yup.string().required('Campaign name is required.'),
  senderid: Yup.object().required('Sender Id is required'),
  mobile: Yup.mixed().required('Mobile is Required.'),
  sms_content: Yup.string().required('SMS content is required.'),
})

const options = [
  {value: 'option1', label: 'option1'},
  {value: 'option2', label: 'option2'},
  {value: 'option3', label: 'option3'},
  {value: 'option4', label: 'option4'},
  {value: 'option5', label: 'option5'},
]

export const getSmsType = (type: number) => {
  let newType
  if (type === 0) {
    newType = 'English'
  }
  if (type === 1) {
    newType = 'Unicode'
  }
  return newType
}

export const getRecipients = (
  recipients: string | number | readonly string[] | undefined | any
) => {
  return recipients?.split('\n').length
}

export interface TemplateText {
  isUse: boolean
  smsText: string
  isSmartTemplate?: number
}

export interface VarInputs {
  [key: string]: string
}

export const getCurrentTimestamp = () => {
  const now = new Date()
  const year = now.getFullYear()
  const month = String(now.getMonth() + 1).padStart(2, '0') // Months are zero-based
  const day = String(now.getDate()).padStart(2, '0')
  const hours = String(now.getHours()).padStart(2, '0')
  const minutes = String(now.getMinutes()).padStart(2, '0')
  const seconds = String(now.getSeconds()).padStart(2, '0')

  return `${year}${month}${day}${hours}${minutes}${seconds}`
}

const QuickSMS: React.FC = () => {
  const {currentUser} = useAuth()
  const {mode} = useThemeMode()
  const {senderIdsOptions, defaultSender} = useSenderIdsOptions()
  const {smsState, handleSmsContentChange, handleSmsTypeChange, backToCountDefault} = useSms()
  const [formData, setFormData] = useState<QuickSmsForm>(initialValues)
  const [loading, setLoading] = useState(false)
  const [disableDateTime, setDisableDateTime] = useState<Boolean>(true)
  const [templateText, setTemplateText] = useState<TemplateText>({
    isUse: false,
    smsText: '',
    isSmartTemplate: 0,
  })
  const [smartVarField, setSmartVarField] = useState<string[]>([])
  const [varInputs, setVarInputs] = useState<VarInputs | undefined>(undefined)
  const [isCreated, setIsCreated] = useState(true)

  const providedCmName = `${currentUser?.user_full_name}- ${getCurrentTimestamp()}`

  const QuickSmsFormInitValues: QuickSmsForm = {
    cm_name: '',
    senderid: defaultSender[0],
    mobile: '',
    sms_type: 0,
    sms_content: '',
    sms_schedule: 'now',
    schedule_date: new Date(),
    smsTextCharacterCount: 0,
    characterCount: 0,
  }

  // const generateInitialValues = (smartFields: string[], values: FormikValues): QuickSmsForm => {
  //   const dynamicFields: Partial<QuickSmsForm> = smartFields.reduce(
  //     (acc: Partial<QuickSmsForm>, field: string) => {
  //       acc[field] = ''
  //       return acc
  //     },
  //     {} as Partial<QuickSmsForm>
  //   )

  //   return {
  //     ...values,
  //     ...dynamicFields,
  //   } as QuickSmsForm
  // }

  const formik: FormikProps<QuickSmsForm> = useFormik({
    initialValues: QuickSmsFormInitValues,
    validationSchema: quickSmsSchema,
    onSubmit: (values) => {
      const reciepientNo = getRecipients(formik.values.mobile)
      // const sms_type = getSmsType(formik.values.sms_type)
      setFormData({
        ...values,
        sms_count: smsState.noOfSms,
        noOfSms: reciepientNo * smsState.noOfSms,
        reciepient: reciepientNo,
        characterCount: smsState.characterCount,
        sms_type: smsState.smsType,
      })

      console.log(values)
    },
  })

  useEffect(() => {
    document.title = 'Quick SMS'

    formik.setFieldValue('cm_name', providedCmName)
    formik.setFieldValue('senderid', defaultSender[0])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSender])

  const handleCancel = () => {
    formik.resetForm()
    formik.setFieldValue('cm_name', providedCmName)
    formik.setFieldValue('senderid', defaultSender[0])
    setIsCreated(false)
    setFormData(initialValues)
    setSmartVarField([])
    setVarInputs(undefined)
    backToCountDefault()
  }

  if (isCreated) {
    handleCancel()
  }

  if (templateText.isUse) {
    formik.setFieldValue('sms_content', templateText.smsText)
    setTemplateText({
      isUse: false,
      smsText: templateText.smsText,
      isSmartTemplate: templateText.isSmartTemplate,
    })
  }

  const smartTemplate = (text: string) => {
    const regex = /#(.*?)#/g
    const matches = []
    let match

    // Loop over all matches and push the capturing group into the matches array
    while ((match = regex.exec(text)) !== null) {
      matches.push(match[1])
    }

    setSmartVarField(matches)

    const dynamicInputs = matches.reduce<Record<string, string>>((acc, match) => {
      acc[match] = ''
      return acc
    }, {})

    setVarInputs(dynamicInputs)
  }

  const handleVarFieldsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target
    setVarInputs((prevData: any) => ({
      ...prevData,
      [name]: value,
    }))
  }

  useEffect(() => {
    if (templateText.isSmartTemplate) {
      smartTemplate(templateText.smsText)
    } else {
      setSmartVarField([])
      setVarInputs(undefined)
    }
  }, [templateText])

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '8px',
      padding: '4px',
      border: 0,
      backgroundColor: mode === 'light' ? '#f9f9f9' : '#1b1b29',
      borderColor: state.isFocused ? '#6c63ff' : '#d1d1d1',
      boxShadow: state.isFocused ? '0 0 0 1px #6c63ff' : null,
      '&:hover': {
        borderColor: '#6c63ff',
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#6c63ff' : null,
      color: state.isSelected ? '#ffffff' : null,
      '&:hover': {
        backgroundColor: '#6c63ff',
        color: '#ffffff',
      },
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '8px',
      backgroundColor: mode === 'light' ? '#f8f0f4' : '#1b1b29',
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)', // Add box shadow
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: mode === 'light' ? '#5e6278' : '#92929f', // Change color of selected value
    }),
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='er m-0'>
            Quick SMS <span className='form-text'>Send SMS to Recipient (new)</span>
          </h3>
        </div>

        <div className='card-title m-0'>
          <Link to='/pricelist/price'>
            <Button variant='primary' size='sm'>
              Dashboard
            </Button>
          </Link>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label  fs-6'>
                <span className='required'>Campaign Name</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  name='cm_name'
                  placeholder='Campaign Name'
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.cm_name && formik.errors.cm_name},
                    {
                      'is-valid': formik.touched.cm_name && !formik.errors.cm_name,
                    }
                  )}
                  value={formik.values.cm_name}
                  onChange={formik.handleChange}
                />
                {formik.touched.cm_name && formik.errors.cm_name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.cm_name}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label  fs-6'>
                <span className='required'>Select Sender ID</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <Select
                  name='senderid'
                  className={clsx(
                    {'is-invalid': formik.touched.senderid && formik.errors.senderid},
                    {
                      'is-valid': formik.touched.senderid && !formik.errors.senderid,
                    }
                  )}
                  placeholder='Choose year value'
                  value={formik.values.senderid}
                  onChange={(selectedOption) => {
                    formik.setFieldValue('senderid', selectedOption)
                  }}
                  options={senderIdsOptions}
                  // options={options}
                  styles={customStyles}
                />

                {formik.touched.senderid && formik.errors.senderid && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.senderid}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required  fs-6'>Enter Mobile Numbers</label>
              <div className='col-lg-8 fv-row-3'>
                <textarea
                  name='mobile'
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.mobile && formik.errors.mobile},
                    {
                      'is-valid': formik.touched.mobile && !formik.errors.mobile,
                    }
                  )}
                  placeholder='Please start with country code and New Line Separated'
                  rows={5}
                  onChange={formik.handleChange}
                  style={{resize: 'vertical', fontSize: '1.0rem'}}
                  value={formik.values.mobile}
                ></textarea>
                {formik.touched.mobile && formik.errors.mobile && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.mobile}</div>
                  </div>
                )}
              </div>
            </div>

            {/* sms_type */}

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label  fs-6'>Select SMS Type</label>

              <div className='col-lg-8 fv-row'>
                <div className='d-flex align-items-center mt-3'>
                  <label className='form-check form-check-inline form-check-solid me-5'>
                    <input
                      className='form-check-input'
                      name='sms_type'
                      type='radio'
                      value={0}
                      checked={smsState.smsType === 0}
                      onChange={(e) => {
                        handleSmsTypeChange(e)
                      }}
                    />
                    <span className=' ps-2 fs-6'>Text</span>
                  </label>

                  <label className='form-check form-check-inline form-check-solid'>
                    <input
                      className='form-check-input'
                      name='sms_type'
                      type='radio'
                      value={1}
                      checked={smsState.smsType === 1}
                      onChange={(e) => {
                        handleSmsTypeChange(e)
                      }}
                    />
                    <span className=' ps-2 fs-6'>UniCode</span>
                  </label>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required  fs-6'>Enter Sms Content</label>
              <div className='col-lg-8 fv-row-3'>
                <textarea
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.sms_content && formik.errors.sms_content},
                    {
                      'is-valid': formik.touched.sms_content && !formik.errors.sms_content,
                    }
                  )}
                  placeholder='SMS Content'
                  rows={5}
                  name='sms_content'
                  style={{resize: 'vertical', fontSize: '1.0rem'}}
                  value={formik.values.sms_content}
                  onChange={(e) => {
                    handleSmsContentChange(e)
                    formik.handleChange(e)
                    if (smsState.characterCount === 0) {
                      setSmartVarField([])
                      setVarInputs(undefined)
                    }
                  }}
                ></textarea>
                {formik.touched.sms_content && formik.errors.sms_content && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.sms_content}</div>
                  </div>
                )}

                <div className='form-text'>
                  {smsState.characterCount} Characters | {smsState.noOfSms} SMS |{' '}
                  {smsState.noOfSms > 1 ? smsState.charPerSmsForLong : smsState.charPerSms} Char/SMS{' '}
                  <span>
                    <button
                      type='button'
                      className='px-4 rounded border-0 bg-success fw-semibold text-white'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_3'
                    >
                      Insert SMS Template
                    </button>
                  </span>
                </div>
              </div>
            </div>

            {templateText.isSmartTemplate && varInputs
              ? smartVarField.map((field: string, index: number) => {
                  return (
                    <div className='row mb-4' key={index}>
                      <label className='col-lg-4 col-form-label fs-6'>
                        <span className='required'>{CapitalizeFirstLetter(field)}</span>
                      </label>
                      <div className='col-lg-8 fv-row'>
                        <input
                          name={field}
                          placeholder={`Enter ${CapitalizeFirstLetter(field)}`}
                          className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {'is-invalid': varInputs[field]! === ''},
                            {
                              'is-valid': varInputs[field],
                            }
                          )}
                          value={varInputs[field]}
                          onChange={(e) => handleVarFieldsChange(e)}
                        />
                      </div>
                    </div>
                  )
                })
              : null}

            <SmsTemplateModal setTemplateText={setTemplateText} isSmartTemplate={0} />

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fs-6 required'>Schedule SMS</label>
              <div className='col-lg-8 fv-row'>
                <div className='d-flex align-items-center mt-3'>
                  <label className='form-check form-check-inline form-check-solid me-5'>
                    <input
                      className='form-check-input'
                      name='sms_schedule'
                      type='radio'
                      value='now'
                      checked={formik.values.sms_schedule === 'now'}
                      onChange={(event) => {
                        formik.setFieldValue('sms_schedule', event.target.value)
                        setDisableDateTime(true)
                        console.log(formik.values.sms_schedule)
                      }}
                    />
                    <span className='ps-2 fs-6'>Send Now</span>
                  </label>
                  <label className='form-check form-check-inline form-check-solid'>
                    <input
                      className='form-check-input'
                      name='sms_schedule'
                      type='radio'
                      value='later'
                      checked={formik.values.sms_schedule === 'later'}
                      onChange={(event) => {
                        formik.setFieldValue('sms_schedule', event.target.value)
                        setDisableDateTime(false)
                      }}
                    />
                    <span className='ps-2 fs-6'>Send Later</span>
                  </label>
                </div>

                <div className='col-lg-8 mt-4'>
                  {disableDateTime ? (
                    <div className='date-time-text'>{new Date().toLocaleString()}</div>
                  ) : (
                    <div className='send-later-date-picker'>
                      <DateTimePicker
                        value={formik.values.schedule_date}
                        name='schedule_date'
                        onChange={(val) => {
                          formik.setFieldValue('schedule_date', val)
                        }}
                        className='form-select form-select-solid form-select-lg'
                        format='MMMM d, yyyy HH:mm'
                        minDate={new Date()}
                        disableClock={true}
                        clearIcon={null}
                        calendarIcon={null}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              type='button'
              className='btn btn-danger me-2'
              onClick={handleCancel}
              disabled={loading}
            >
              Cancel
            </button>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={loading}
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_1'
            >
              {!loading && 'Review'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            {
              <QuickSMSOverview
                formData={formData}
                setIsCreated={setIsCreated}
                setLoading={setLoading}
                formik={formik}
                varInputs={varInputs}
              />
            }
          </div>
        </form>
      </div>
    </div>
  )
}

export {QuickSMS}
