import {ChangeEvent, ReactNode, createContext, useState} from 'react'
import {dashboardRequest, getTopFiveUser} from './dashboardRequest'
import {useAuth} from '../../modules/auth'
import {useQuery} from 'react-query'
import {
  DashboardContextValue,
  IBalance,
  IChartDataArray,
  ICostData,
  ICountData,
  ICountsDatas,
  IData,
  ILastFiveTransaction,
  UserDataArray,
} from './core/_model'

interface DashboardProviderProps {
  children: ReactNode
}

export const DashboardContext = createContext<DashboardContextValue | null>(null)

const DashboardProvider = ({children}: DashboardProviderProps) => {
  const {auth} = useAuth()
  const [status, setStatus] = useState({
    channel: 'allChannel',
    count: 'today',
  })

  const onSelectStatus = (event: ChangeEvent<HTMLSelectElement>) => {
    const {name, value} = event.target

    setStatus((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const {data: smsCount, status: smsCountStatus} = useQuery<ICountsDatas>(
    ['todaySmsCount', 'lastweekSmsCount', 'lastmonthSmsCount'],
    async () => {
      const todaySmsCountPromise = dashboardRequest<ICountData>(
        '/api/protect/todaysms',
        auth?.accessToken
      )
      const lastweekSmsCountPromise = dashboardRequest<ICountData>(
        '/api/protect/lastweeksms',
        auth?.accessToken
      )
      const lastmonthCountPromise = dashboardRequest<ICountData>(
        '/api/protect/lastmonthsms',
        auth?.accessToken
      )

      const [todaySmsCount, lastweekSmsCount, lastmonthSmsCount] = await Promise.all([
        todaySmsCountPromise,
        lastweekSmsCountPromise,
        lastmonthCountPromise,
      ])

      return {todaySmsCount, lastweekSmsCount, lastmonthSmsCount}
    }
  )

  const {data: smsCost, status: smsCostStatus} = useQuery<IData | undefined>({
    queryKey: ['todaySmsCost', 'lastweekSmsCost', 'lastmonthSmsCost'],
    queryFn: async () => {
      const todaySmsCostPromise = dashboardRequest<ICostData>(
        '/api/protect/todaysmscost',
        auth?.accessToken
      )
      const lastweekSmsCostPromise = dashboardRequest<ICostData>(
        '/api/protect/lastweeksmscost',
        auth?.accessToken
      )
      const lastmonthCostPromise = dashboardRequest<ICostData>(
        '/api/protect/lastmonthsmscost',
        auth?.accessToken
      )

      const [todaySmsCost, lastweekSmsCost, lastmonthSmsCost] = await Promise.all([
        todaySmsCostPromise,
        lastweekSmsCostPromise,
        lastmonthCostPromise,
      ])

      return {todaySmsCost, lastweekSmsCost, lastmonthSmsCost}
    },
    // enabled: !!smsCount,
  })

  const {data: balance, status: balanceStatus} = useQuery<IBalance | undefined>({
    queryKey: 'balance',
    queryFn: async () => {
      const data = await dashboardRequest<IBalance>('/api/protect/balance', auth?.accessToken)
      return data
    },
    // enabled: !!smsCost,
  })

  const {data: maskingInfo, status: maskingInfoStatus} = useQuery<IChartDataArray | undefined>({
    queryKey: 'smsMaskingInfo',
    queryFn: async () => {
      const data = await dashboardRequest<IChartDataArray>(
        '/api/protect/smsmaskinginfo',
        auth?.accessToken
      )
      return data
    },
    // enabled: !!balance,
  })

  const {data: lastFiveTransaction, status: lastFiveTransactionStatus} = useQuery<
    ILastFiveTransaction | undefined
  >({
    queryKey: 'lastFiveTransaction',
    queryFn: async () => {
      const data = await dashboardRequest<ILastFiveTransaction>(
        '/api/protect/lastfivetransection',
        auth?.accessToken
      )
      return data
    },
    // enabled: !!maskingInfo,
  })

  const {data: topFiveUser} = useQuery<UserDataArray | undefined>({
    queryKey: 'topFiveUser',
    queryFn: async () => {
      const data = await getTopFiveUser(auth?.accessToken)
      return data
    },
    // enabled: !!lastFiveTransaction,
  })

  // const {data: topFiveApiUser} = useQuery({
  //   queryKey: 'topFiveApiUser',
  //   queryFn: async () => {
  //     const data = await dashboardRequest<any>('/api/protect/topFiveApiUser', auth?.accessToken)
  //     return data
  //   },
  //   enabled: !!topFiveUser,
  // })

  // const {data: topFivePanelUser} = useQuery({
  //   queryKey: 'topFivePanelUser',
  //   queryFn: async () => {
  //     const data = await dashboardRequest<any>('/api/protect/topFivePanelUser', auth?.accessToken)
  //     return data
  //   },
  //   enabled: !!topFiveApiUser,
  // })

  return (
    <DashboardContext.Provider
      value={{
        smsCount,
        smsCost,
        balance,
        maskingInfo,
        lastFiveTransaction,
        topFiveUser,
        status,
        onSelectStatus,
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}

export default DashboardProvider
