import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { Pagination } from 'react-bootstrap';

export interface IElipsisPaginationProps {
    totalElement: number
    itemsPerPage: number
    currentPage: number
    handlePageChange: (pageNumber: number) => void
}

const EllipsisPagination: React.FC<IElipsisPaginationProps> = ({totalElement, itemsPerPage, currentPage, handlePageChange }) => {

    const visiblePages = 5

    const totalPages = Math.ceil(totalElement / itemsPerPage)    

    const renderPaginationItems = () => {
        const items = []
        let startPage
        let endPage

        // If there are fewer pages than visible pages, show all pages
        if (totalPages <= visiblePages) {
            startPage = 1
            endPage = totalPages
            } else {
            // Otherwise, calculate startPage and endPage based on current page
            const halfVisible = Math.floor(visiblePages / 2)
            if (currentPage <= halfVisible) {
                startPage = 1
                endPage = visiblePages
            } else if (currentPage + halfVisible >= totalPages) {
                startPage = totalPages - visiblePages + 1
                endPage = totalPages;
            } else {
                startPage = (currentPage) - halfVisible
                endPage = (currentPage) + halfVisible
            }
        }

        // Render Previous button
        items.push(
            <Pagination.Prev
                key="prev"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
            />
        )

        // Render first page if necessary
        if (startPage > 1) {
        items.push(
            <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
                1
            </Pagination.Item>
        )
        if (startPage > 2) {
            items.push(<Pagination.Ellipsis key="ellipsis-start" />)
        }
        }

        // Render visible page numbers
        for (let i = startPage; i <= endPage; i++) {
        items.push(
            <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => handlePageChange(i)}
            >
            {i}
            </Pagination.Item>
        )
        }

        // Render last page if necessary
        if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
            items.push(<Pagination.Ellipsis key="ellipsis-end" />);
        }
        items.push(
            <Pagination.Item
            key={totalPages}
            onClick={() => handlePageChange(totalPages)}
            >
            {totalPages}
            </Pagination.Item>
        )
        }

        // Render Next button
        items.push(
        <Pagination.Next
            key="next"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
        />
        )

        return items
    }

    return <Pagination>{renderPaginationItems()}</Pagination>
}

export default EllipsisPagination
