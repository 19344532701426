import axios from 'axios'
import {BASE_URL} from '../../config'
import toast from 'react-hot-toast'
import {IBalanceInfo} from './_model'

const BLOCK_USER_URL = `${BASE_URL}/api/protect/blockUser`
const UNBLOCK_USER_URL = `${BASE_URL}/api/protect/UnblockUser`
const CLONE_USER_URL = `${BASE_URL}/api/protect/cloneUser`
const UPLOAD_NID_TRADELICENSE_URL = `${BASE_URL}/api/protect/uploadNidAndTradeLicence`
const UPDATE_USER_URL = `${BASE_URL}/api/protect/updateUser`
const UPDATE_PASSWORD_URL = `${BASE_URL}/api/protect/updatePassword`
const UPDATE_BALANCE_URL = `${BASE_URL}/api/protect/updateBalance`

export const cloneUser = async (token: string | undefined, user: any) => {
  try {
    const res = await axios.post(CLONE_USER_URL, user, {
      headers: {Authorization: `Bearer ${token}`},
    })
    const data = await res.data
    console.log(data)
    return data
  } catch (error: any) {
    console.log(error)
    toast.dismiss()
    toast.error(`Error: ${error.message}`)
  }
}

export const uploadNidTradeLicence = async (
  token: string | undefined,
  userId: number,
  formData: FormData
) => {
  try {
    const res = await axios.post(`${UPLOAD_NID_TRADELICENSE_URL}/${userId}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    })
    console.log(res.data)
    return res.data
  } catch (err: any) {
    console.error(err)
    toast.dismiss()
    toast.error(`Error: ${err.message} (not uploaded NID & Tradelicence)`)
  }
}

export const updateUserApi = async (
  token: string | undefined,
  userId: number,
  updatedData: any
) => {
  try {
    const res = await axios.put(`${UPDATE_USER_URL}/${userId}`, updatedData, {
      headers: {Authorization: `Bearer ${token}`},
    })
    const data = await res.data
    console.log(data)
    return data
  } catch (error: any) {
    toast.dismiss()
    toast.error(`Error: ${error.message}`)
  }
}

export const updatePasswordApi = async (
  token: string | undefined,
  userId: number,
  updatedPass: any
) => {
  const res = await axios.put(`${UPDATE_PASSWORD_URL}/${userId}`, updatedPass, {
    headers: {Authorization: `Bearer ${token}`},
  })
  console.log(res)
  toast.success(`Updated User ${userId} Password`)
  return res
}

export const blockUser = async (token: string | undefined, userid: number | undefined) => {
  const res = await axios.get(`${BLOCK_USER_URL}/${userid}`, {
    headers: {Authorization: `Bearer ${token}`},
  })
  const data = await res.data
  toast.success(`User ${userid} Blocked`)
  return data
}

export const unBlockUser = async (token: string | undefined, userid: number | undefined) => {
  const res = await axios.get(`${UNBLOCK_USER_URL}/${userid}`, {
    headers: {Authorization: `Bearer ${token}`},
  })
  const data = await res.data
  console.log(data)
  toast.success(`User ${userid} Unblocked`)
  return data
}

export const updateBalanceAPI = async (
  token: string | undefined,
  userid: number | undefined,
  balanceInfo: IBalanceInfo
) => {
  try {
    toast.loading('Updating Balance..')
    const response = await axios.post(`${UPDATE_BALANCE_URL}/${userid}`, balanceInfo, {
      headers: {Authorization: `Bearer ${token}`},
    })
    toast.dismiss()
    toast.success('Balance Updated Successfully')
    return response
  } catch (error: any) {
    toast.dismiss()
    toast.error(`Error from updating balance: ${error.message}`)
  }
}
