import {BASE_URL} from '../../config'

export const GET_CHARGING_URL = `${BASE_URL}/api/protect/getchargingConf`
export const GET_ADDITIONAL_PRICE = `${BASE_URL}/api/protect/additionalCharging`
export const UPDATE_PRICE_LIST_URL = `${BASE_URL}/api/protect/protect/updateBatchChargingConf`

export interface IChargingRoute {
  id: number
  chargingRouteId: number
  operatorPrefix: string
  brandPrice: number
  nonBrandPrice: number
  createDate: string
  lastModDate: string
  status: string
}
export interface IPrice {
  country: string
  prefix: string
  price: string
  status: string
}

export interface AdditionalPrice {
  id: number
  chargingRouteId: number
  vat: number
  serviceCharge: number
  scSubmissionCount: number
  createDate: string
  lastModifiedDate: string
  status: string
}

export interface IChargingRouteArray extends Array<IChargingRoute> {}

export interface IPriceArray extends Array<IPrice> {}

export const CREATE_ADDITIONAL_PRICE_URL = `${BASE_URL}/api/protect/createAdditionalCharging`
export const UPDATE_ADDITIONAL_PRICE_URL = `${BASE_URL}/api/protect/update/additionalCharging`

export interface AdditionalCreateModalProps {
  updateUser: any
  additionalPrice: AdditionalPrice | undefined
  getAdditionalPrice: () => void
}

export interface IChargingRoute {
  id: number
  chargingRouteId: number
  operatorPrefix: string
  brandPrice: number
  nonBrandPrice: number
  createDate: string
  lastModDate: string
  status: string
}

export interface IPriceList {
  serial: number
  entityId: number
  prefix: string
  buyMaskPrice: number
  buyNonMaskPrice: number
  sellMaskPrice: number
  sellNonMaskPrice: number
}

export interface IUpdateEachEntity {
  id: number
  prefix: string
  sellMaskPrice: number | null
  sellNonMaskPrice: number | null
}

export interface IUpdatedPrice {
  sellMasking: (number | null)[]
  sellNonMasking: (number | null)[]
}

export interface IChargingRouteArray extends Array<IChargingRoute> {}

export interface IPriceListArray extends Array<IPriceList> {}

export interface IUpdateEachEntityArray extends Array<IUpdateEachEntity> {}

export interface IAllPriceListData {
  chargingPrice: IChargingRouteArray
  userPrice: IChargingRouteArray
}
