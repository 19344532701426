import React from 'react'
import {DummyReport} from './dummyReport'
import {formatDate} from '../../utils/helperFunction/_helper'

type Props = {
  message: DummyReport
}

const ToCell = ({message}: Props) => {
  return (
    <div>
      <div>
        <p>{formatDate(message.doneAt)}</p>
        <p className='text-gray-800'>{message.destination}</p>
        <div>
          <p className='mb-1'>Network</p>
          <p className='text-gray-800'>{message.networkName}</p>
        </div>
      </div>
    </div>
  )
}

export default ToCell
