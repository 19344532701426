import {Column, HeaderProps} from 'react-table'
import {PropsWithChildren} from 'react'
import {IPrice} from './core/_model'
import {CustomHeader} from '../../utils/components/Table/CustomHeader'

const PriceListColumn: ReadonlyArray<Column<IPrice>> = [
  // {
  //   Header: (props) => <SelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Country' className='min-w-125px text-center' />
    ),
    id: 'country',
    Cell: () => <span>Bangladesh</span>,
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Prefix' className='min-w-125px text-center' />
    ),
    accessor: 'prefix',
  },

  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader
        tableProps={props}
        title='Price/SMS(Masking/Non-Masking)'
        className='min-w-125px text-center'
      />
    ),
    id: 'price',
    accessor: 'price',
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Status' className='min-w-125px text-center' />
    ),
    accessor: 'status',
  },
]

export {PriceListColumn}
