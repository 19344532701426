import React, {FormEvent, useEffect, useState} from 'react'
import {useSettingsProvider} from './SettingsProvider'
import ContentLoading from '../../utils/components/Loading/ContentLoading'
import {OptionsObject} from './core/_model'

const EmailSettings = () => {
  const {isLoading, setIsLoading, emailOptionsObj, updateOptions, getAllOptions} =
    useSettingsProvider()

  const [emailSettingsOptions, setEmailSettingsOptions] = useState<OptionsObject>({
    emailAddress: undefined,
    smtpHost: undefined,
    emailUser: undefined,
    emailPass: undefined,
    smtpPort: undefined,
    encryption: undefined,
    emailProtocol: undefined,
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = event.target
    setEmailSettingsOptions((prevData) => ({
      ...prevData,
      [name]: {
        ...prevData[name],
        optionsValue: value,
      },
    }))
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    setIsLoading(true)

    for (const key in emailSettingsOptions) {
      await updateOptions(emailSettingsOptions[key])
    }

    getAllOptions()
  }

  useEffect(() => {
    if (!isLoading) {
      setEmailSettingsOptions({
        emailAddress: emailOptionsObj.mail_adderss,
        smtpHost: emailOptionsObj.mail_smtp_host,
        emailUser: emailOptionsObj.mail_user,
        emailPass: emailOptionsObj.mail_pass,
        smtpPort: emailOptionsObj.mail_smtp_port,
        encryption: emailOptionsObj.mail_encryption,
        emailProtocol: emailOptionsObj.mail_protocol,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return (
    <div className='card col-12 col-md-10'>
      <div className='card-header'>
        <div className='card-title'>
          <h3 className='text-gray-700'>Email Settings</h3>
        </div>
      </div>
      <div className='card-body'>
        {isLoading ? (
          <ContentLoading />
        ) : (
          <form onSubmit={handleSubmit}>
            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label  fs-6'>
                <span>Email Address</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  name='emailAddress'
                  placeholder='Email Address'
                  className='form-control form-control-lg form-control-solid'
                  value={emailSettingsOptions.emailAddress?.optionsValue}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label  fs-6'>
                <span>SMTP Host</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  name='smtpHost'
                  placeholder='SMTP Host'
                  className='form-control form-control-lg form-control-solid'
                  value={emailSettingsOptions.smtpHost?.optionsValue}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label  fs-6'>
                <span>Email User</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  name='emailUser'
                  placeholder='Email User'
                  className='form-control form-control-lg form-control-solid'
                  value={emailSettingsOptions.emailUser?.optionsValue}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label  fs-6'>
                <span>Email Password</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  name='emailPass'
                  placeholder='Email Pass'
                  className='form-control form-control-lg form-control-solid'
                  value={emailSettingsOptions.emailPass?.optionsValue}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label  fs-6'>
                <span>SMTP Port</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  name='smtpPort'
                  placeholder='SMTP Port'
                  className='form-control form-control-lg form-control-solid'
                  value={emailSettingsOptions.smtpPort?.optionsValue}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label  fs-6'>
                <span>Encryption</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  name='encryption'
                  placeholder='Encryption'
                  className='form-control form-control-lg form-control-solid'
                  value={emailSettingsOptions.encryption?.optionsValue}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label  fs-6'>
                <span>Email Protocol</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  name='emailProtocol'
                  placeholder='Email Protocol'
                  className='form-control form-control-lg form-control-solid'
                  value={emailSettingsOptions.emailProtocol?.optionsValue}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className='d-flex justify-content-end'>
              <button type='submit' className='btn btn-primary'>
                Save Changes
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

export default EmailSettings
