import React from 'react'
import {Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import CreateSmsTemplateModal from '../Modal/CreateSmsTemplateModal'
import {useSMSTemplateProvider} from '../context/SMSTemplateProvider'
import TableContent from '../../../utils/components/Table/TableContent'
import ListPagination from '../../PaymentRequest/ListPagination'
import {SMSTemplateColumn} from './SMSTemplateColumn'
import {KTIcon} from '../../../../_metronic/helpers'
import SMSTemplateFilter from './SMSTemplateFilter'
import {
  ListViewContext,
  ListViewProvider,
  useListView,
} from '../../../utils/contextProvider/ListViewProvider'
import SelectList from '../../../utils/components/SelectList'

const SMSTempleteContent: React.FC = () => {
  const {
    isLoading,
    handleNewAction,
    searchTerm,
    setSearchTerm,
    filteredTemplate,
    totalElement,
    itemsPerPage,
    currentPage,
    handlePageChange,
  } = useSMSTemplateProvider()

  const {selected} = useListView()

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='er m-0'>Sms Templete</h3>
        </div>

        <div className='card-title m-0 d-flex gap-4'>
          <Link to='/dashboard'>
            <Button variant='primary' size='sm'>
              Dashboard
            </Button>
          </Link>
        </div>
      </div>

      <div className='card-header border-0'>
        <div className='card-title'>
          <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-250px ps-14'
              placeholder='Search Template'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <div className='card-title'>
          {selected.length > 0 ? (
            <SelectList context={ListViewContext} />
          ) : (
            <>
              <SMSTemplateFilter />
              <button type='button' className='btn btn-light-primary me-3'>
                <KTIcon iconName='exit-up' className='fs-2' />
                Export
              </button>
              <button
                type='button'
                className='btn btn-primary'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_1'
                onClick={handleNewAction}
              >
                <KTIcon iconName='plus' className='fs-2' />
                New Template
              </button>
            </>
          )}
        </div>
      </div>

      <TableContent
        isLoading={isLoading}
        tableData={filteredTemplate}
        tableColumn={SMSTemplateColumn}
      >
        <ListPagination
          totalElement={totalElement}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          isLoading={isLoading}
        />
      </TableContent>
      <CreateSmsTemplateModal />
    </div>
  )
}

const SMSTemplete = () => {
  const {isLoading, filteredTemplate} = useSMSTemplateProvider()

  return (
    <ListViewProvider isLoading={isLoading} data={filteredTemplate}>
      <SMSTempleteContent />
    </ListViewProvider>
  )
}

export {SMSTemplete}
