import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

import {GroupSMS} from '../modules/SMSBroadCast/GroupSMS'
import {ContactFileSMS} from '../modules/SMSBroadCast/ContactFileSMS'
import {SmartSMS} from '../modules/SMSBroadCast/SmartSMS'
import {QuickSMS} from '../modules/SMSBroadCast/QuickSMS'
import CloneUser from '../modules/Users/CloneUser'
import CreateUser from '../modules/Users/CreateUser'
import Price from '../modules/PriceList/Price'
import TodayBroadCast from '../modules/LiveReports/TodayBroadCast'
import TodaySMS from '../modules/LiveReports/TodaySMS'
import SummeryLogs from '../modules/LiveReports/SummeryLogs'
import MNOBalanceNotifyList from '../modules/Monitoring/MNOBalanceNotifyList'
import BalanceNotificationLog from '../modules/Monitoring/BalanceNotificationLog'
import BalanceThreshold from '../modules/Monitoring/BalanceThreshold'
import LogoUpload from '../modules/Settings/LogoUpdates'
import BroadCastHistory from '../modules/Archive/BroadCastHistory'
import SMSHistory from '../modules/Archive/SMSHistory'
import RechargeFrom from '../modules/Billing/TransactionReports/RechargeFrom'
import RechargeTo from '../modules/Billing/TransactionReports/RechargeTo'
import InvoiceCreate from '../modules/Billing/Invoice/InvoiceCreate'
import InvoiceList from '../modules/Billing/Invoice/InvoiceList'
import Send from '../modules/Schedules/Send'
import Pending from '../modules/Schedules/Pending'
import ChangePassword from '../modules/DeveloperOptions/ChangePassword'
import OpwiseReport from '../modules/SummeryReport/OpwiseReport'
import SenderwiseReport from '../modules/SummeryReport/SenderwiseReport'
import OPandSenderwiseReport from '../modules/SummeryReport/OPandSenderwiseReport'
import {SMSCampaign} from '../modules/SMSBroadCast/SMSCampaign'
import SpamFilter from '../modules/SpamFilter/SpamFilter'
import {RoleBasedRoute} from './RoleBasedRoute'
import RetrySmsList from '../modules/LiveReports/RetrySmsList'
import {UpdateSenderId} from '../modules/Users/UpdateSenderId'
import Cancel from '../modules/Schedules/Cancel'
import PriceUpdate from '../modules/PriceList/PriceUpdate'
import ApiKey from '../modules/DeveloperOptions/ApiKey'
import Documentation from '../modules/DeveloperOptions/Documentation'
import KycForm from '../modules/Users/KycForm'
import UserDetails from '../modules/Users/UserDetails'
import Payment from '../modules/PaymentRequest/Payment'
import SenderIdWrapper from '../modules/SMSBroadCast/Sender/SenderIdWrapper'
import SMSTemplateWrapper from '../modules/SMSBroadCast/SMSTemplate/SMSTemplateWrapper'
import SpamFiltrerWrapper from '../modules/SpamFilter/SpamFiltrerWrapper'
import UserListWrapper from '../modules/Users/UserListWrapper'
import DeliveryLogReport from '../modules/Archive/DeliveryLogReport'
import SettingWrapper from '../modules/Settings/SettingWrapper'
import TicketOverview from '../modules/Ticket/TicketOverview'
import TicketsList from '../modules/Ticket/TIcketList/TicketsList'
import TIcketsListWrapper from '../modules/Ticket/TIcketList/TicketsListWrapper'
import Department from '../modules/Ticket/Department'
import ArticleWrapper from '../modules/KnowledgeBase/Article/ArticleWrapper'
import CategoryWrapper from '../modules/KnowledgeBase/Category/CategoryWrapper'
import KnowledgeBaseOverview from '../modules/KnowledgeBase/KnowledgeBaseOverview'
import CategoryDetails from '../modules/KnowledgeBase/Category/CategoryDetails'
import ArticleDetails from '../modules/KnowledgeBase/Article/ArticleDetails'

// const DashboardWrapper = lazy(() => import('../pages/dashboard/DashboardWrapper'))
const ContactAndGroup = lazy(() => import('../modules/ContactAndGroup/ContactAndGroup'))

const PrivateRoutes = () => {
  // const {currentUser} = useAuth()
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='smsbroadcast/quicksms'
          element={
            <SuspensedView>
              <QuickSMS />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='smsbroadcast/groupsms/*'
          element={
            <SuspensedView>
              <GroupSMS />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='smsbroadcast/contactfilesms/*'
          element={
            <SuspensedView>
              <ContactFileSMS />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='smsbroadcast/senderid/*'
          element={
            <SuspensedView>
              <SenderIdWrapper />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='smsbroadcast/smscampaign/*'
          element={
            <SuspensedView>
              <SMSCampaign />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='smsbroadcast/smartsms/*'
          element={
            <SuspensedView>
              <SmartSMS />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='smsbroadcast/smstemplete/*'
          element={
            <SuspensedView>
              <SMSTemplateWrapper />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='contactAndgroup/*'
          element={
            <SuspensedView>
              <ContactAndGroup />
            </SuspensedView>
          }
        ></Route>

        {/* <Route
          path='smsfiltertext/*'
          element={
            <SuspensedView>
              <SmsFilterText />
            </SuspensedView>
          }
        ></Route> */}

        <Route element={<RoleBasedRoute allowedRoles={[1, 2]} />}>
          <Route
            path='smsfilter/*'
            element={
              <SuspensedView>
                <SpamFiltrerWrapper />
              </SuspensedView>
            }
          />
        </Route>

        <Route
          path='paymentrequest/*'
          element={
            <SuspensedView>
              <Payment />
            </SuspensedView>
          }
        ></Route>

        <Route element={<RoleBasedRoute allowedRoles={[1, 2]} />}>
          <Route
            path='users/userlist/*'
            element={
              <SuspensedView>
                <UserListWrapper />
              </SuspensedView>
            }
          />
        </Route>

        <Route element={<RoleBasedRoute allowedRoles={[1, 2]} />}>
          <Route
            path='users/:useraction/*'
            element={
              <SuspensedView>
                <CloneUser />
              </SuspensedView>
            }
          />
        </Route>

        <Route element={<RoleBasedRoute allowedRoles={[1, 2]} />}>
          <Route
            path='users/createuser/*'
            element={
              <SuspensedView>
                <CreateUser />
              </SuspensedView>
            }
          />
        </Route>

        <Route element={<RoleBasedRoute allowedRoles={[1, 2]} />}>
          <Route
            path='users/kycForm/:id'
            element={
              <SuspensedView>
                <KycForm />
              </SuspensedView>
            }
          />
        </Route>

        <Route element={<RoleBasedRoute allowedRoles={[1, 2]} />}>
          <Route
            path='users/updatesenderid/:id/*'
            element={
              <SuspensedView>
                <UpdateSenderId />
              </SuspensedView>
            }
          />
        </Route>

        <Route element={<RoleBasedRoute allowedRoles={[1, 2]} />}>
          <Route
            path='users/userdetails/:id/*'
            element={
              <SuspensedView>
                <UserDetails />
              </SuspensedView>
            }
          />
        </Route>

        <Route
          path='livereports/todaybroadcast/*'
          element={
            <SuspensedView>
              <TodayBroadCast />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='livereports/todaysms/*'
          element={
            <SuspensedView>
              <TodaySMS />
            </SuspensedView>
          }
        ></Route>

        {/* <Route path='livereports/smstodayapi/*'
          element={
            <SuspensedView>
              <SMSTodayAPI />
            </SuspensedView>
          }>         
        </Route> */}

        {/* <Route
          path='livereports/smstodayapireportdownload/*'
          element={
            <SuspensedView>
              <SMSTodayAPIReportDownload />
            </SuspensedView>
          }>         
        </Route> */}

        <Route
          path='livereports/summerylogs/*'
          element={
            <SuspensedView>
              <SummeryLogs />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='livereports/retrysmslist/*'
          element={
            <SuspensedView>
              <RetrySmsList />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='pricelist/price/*'
          element={
            <SuspensedView>
              <Price />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='users/userlist/priceupdate/:id'
          element={
            <SuspensedView>
              <PriceUpdate />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='archive/broadcasthistory/*'
          element={
            <SuspensedView>
              <BroadCastHistory />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='archive/smshistory/*'
          element={
            <SuspensedView>
              <SMSHistory />
            </SuspensedView>
          }
        ></Route>

        {/* <Route path='archive/smshistoryapi/*'
          element={
            <SuspensedView>
              <SMSHistoryAPI />
            </SuspensedView>
          }>         
        </Route> */}

        <Route
          path='archive/archivedeliverylogreport/*'
          element={
            <SuspensedView>
              <DeliveryLogReport />
            </SuspensedView>
          }
        ></Route>

        {/* <Route path='archive/apireportdownload/*'
          element={
            <SuspensedView>
              <APIReportDownload />
            </SuspensedView>
          }>         
        </Route> */}
        {/* 
        <Route path='archive/panelreportdownload/*'
          element={
            <SuspensedView>
              <PanelReportDownload/>
            </SuspensedView>
          }>         
        </Route> */}

        <Route element={<RoleBasedRoute allowedRoles={[1, 2, 3]} />}>
          <Route
            path='Billing/TransactionReports/rechargefrom/*'
            element={
              <SuspensedView>
                <RechargeFrom />
              </SuspensedView>
            }
          />
        </Route>

        <Route element={<RoleBasedRoute allowedRoles={[1, 2]} />}>
          <Route
            path='Billing/TransactionReports/rechargeto/*'
            element={
              <SuspensedView>
                <RechargeTo />
              </SuspensedView>
            }
          />
        </Route>

        <Route element={<RoleBasedRoute allowedRoles={[1, 2]} />}>
          <Route
            path='Billing/Invoice/invoicecreate/*'
            element={
              <SuspensedView>
                <InvoiceCreate />
              </SuspensedView>
            }
          />
        </Route>

        <Route element={<RoleBasedRoute allowedRoles={[1, 2, 3]} />}>
          <Route
            path='Billing/Invoice/invoicelist/*'
            element={
              <SuspensedView>
                <InvoiceList />
              </SuspensedView>
            }
          />
        </Route>

        {/* <Route
          path='Billing/TransactionReports/rechargefrom/*'
          element={
            <SuspensedView>
              <RechargeFrom />
            </SuspensedView>
          }
        ></Route> */}

        {/* <Route
          path='Billing/TransactionReports/rechargeto/*'
          element={
            <SuspensedView>
              <RechargeTo />
            </SuspensedView>
          }
        ></Route> */}

        {/* <Route
          path='Billing/Invoice/invoicecreate/*'
          element={
            <SuspensedView>
              <InvoiceCreate />
            </SuspensedView>
          }
        ></Route> */}

        {/* <Route
          path='Billing/Invoice/invoicelist/*'
          element={
            <SuspensedView>
              <InvoiceList />
            </SuspensedView>
          }
        ></Route> */}

        <Route
          path='Schedules/send/*'
          element={
            <SuspensedView>
              <Send />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='Schedules/pending/*'
          element={
            <SuspensedView>
              <Pending />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='Schedules/cancel/*'
          element={
            <SuspensedView>
              <Cancel />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='SummeryReport/opwisereport/*'
          element={
            <SuspensedView>
              <OpwiseReport />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='SummeryReport/senderwisereport/*'
          element={
            <SuspensedView>
              <SenderwiseReport />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='SummeryReport/opandSenderwisereport/*'
          element={
            <SuspensedView>
              <OPandSenderwiseReport />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='DeveloperOptions/changepassword/*'
          element={
            <SuspensedView>
              <ChangePassword />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='DeveloperOptions/apikey/*'
          element={
            <SuspensedView>
              <ApiKey />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='DeveloperOptions/documentation/*'
          element={
            <SuspensedView>
              <Documentation />
            </SuspensedView>
          }
        ></Route>

        {/* Ticket Start */}
        <Route
          path='tickets/overview/*'
          element={
            <SuspensedView>
              <TicketOverview />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='tickets/list/*'
          element={
            <SuspensedView>
              <TIcketsListWrapper />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='tickets/department/*'
          element={
            <SuspensedView>
              <Department />
            </SuspensedView>
          }
        ></Route>
        {/* Ticket End */}

        {/* Knowledge Base Start */}
        <Route
          path='knowledgebase/overview/*'
          element={
            <SuspensedView>
              <KnowledgeBaseOverview />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='knowledgebase/article/*'
          element={
            <SuspensedView>
              <ArticleWrapper />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='knowledgebase/category/*'
          element={
            <SuspensedView>
              <CategoryWrapper />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='knowledgebase/category/details/*'
          element={
            <SuspensedView>
              <CategoryDetails />
            </SuspensedView>
          }
        ></Route>

        {/* <Route
          path='knowledgebase/article/details/*'
          element={
            <SuspensedView>
              <ArticleDetails />
            </SuspensedView>
          }
        ></Route> */}
        {/* Knowledge Base End */}

        <Route
          path='settings/*'
          element={
            <SuspensedView>
              <SettingWrapper />
            </SuspensedView>
          }
        ></Route>
      </Route>
      {/* End Master Layout */}

      <Route
        path='Monitoring/mnobalancenotifylist/*'
        element={
          <SuspensedView>
            <MNOBalanceNotifyList />
          </SuspensedView>
        }
      ></Route>

      <Route
        path='Monitoring/balancethreshold/*'
        element={
          <SuspensedView>
            <BalanceThreshold />
          </SuspensedView>
        }
      ></Route>

      <Route
        path='Monitoring/balancenotificationlog/*'
        element={
          <SuspensedView>
            <BalanceNotificationLog />
          </SuspensedView>
        }
      ></Route>

      <Route element={<RoleBasedRoute allowedRoles={[1]} />}>
        <Route
          path='Settings/logoupdates/*'
          element={
            <SuspensedView>
              <LogoUpload />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='spamFilter/*'
          element={
            <SuspensedView>
              <SpamFilter />
            </SuspensedView>
          }
        ></Route>

        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
