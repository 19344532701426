import { Navigate, Outlet } from "react-router-dom"
import { useAuth } from "../modules/auth"

type RoleType = {
  allowedRoles: number[]
}

export const RoleBasedRoute = ({allowedRoles} : RoleType) => {
    const {currentUser} = useAuth()
    const hasAccess = allowedRoles.find((role) => currentUser?.user_profile === role);
 
  return (
    hasAccess ? <Outlet/> : <Navigate to='/error' />
  )
}
