import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'

const NewCategoryModal = () => {
  return (
    <div className='modal fade' tabIndex={-2} id='kt_modal_2'>
      <div className='modal-dialog modal-xl'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Add New Category</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <form>
              <div className='row mb-4'>
                <label className='col-lg-4 col-form-label fs-6'>
                  <span className='required'>Category</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    name='category'
                    placeholder='Enter Category'
                    className='form-control form-control-lg form-control-solid'
                    required
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fs-6'>Description</label>
                <div className='col-lg-8 fv-row-3'>
                  <textarea
                    name='description'
                    placeholder='Enter Description..'
                    className='form-control form-control-lg form-control-solid'
                    rows={5}
                    style={{resize: 'vertical', fontSize: '1.0rem'}}
                    required
                  ></textarea>
                </div>
              </div>

              <div className='row mb-4'>
                <label className='col-lg-4 col-form-label fs-6'>
                  <span className='required'>Order</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    name='order'
                    placeholder='Enter order'
                    className='form-control form-control-lg form-control-solid'
                    required
                  />
                </div>
              </div>

              <div className='row mb-4 align-items-center'>
                <label className='col-lg-4 col-form-label fs-6'>
                  <span>Status</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <div className='form-check form-switch form-check-custom form-check-solid'>
                    <input name='status' className='form-check-input' type='checkbox' />
                  </div>
                </div>
              </div>

              <div className='d-flex justify-content-end'>
                <button
                  type='button'
                  className='btn btn-light me-4'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  Close
                </button>
                <button
                  type='button'
                  className='btn btn-primary'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  Add Department
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewCategoryModal
