import {FC, createContext, useContext, useEffect, useState} from 'react'
import {WithChildren} from '../../../../_metronic/helpers'
import {useAuth} from '../../auth'
import {ICategory} from '../core/_model'

export const CategoryContext = createContext<any>(null)

export const CategoryProvider: FC<WithChildren> = ({children}) => {
  const {auth} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [category, setCategory] = useState<ICategory[]>([])
  const [filteredCategory, setFilteredCategory] = useState<ICategory[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')

  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalElement, setTotalElement] = useState(0)
  const [isLastPage, setIsLastPage] = useState(false)

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)

  let showPageLastEntries = !isLastPage ? currentPage * itemsPerPage : totalElement
  let showPageFirstEntries = !isLastPage
    ? showPageLastEntries + 1 - itemsPerPage
    : (currentPage - 1) * itemsPerPage + 1

  useEffect(() => {
    const filteredData = category?.filter(
      (list) =>
        list.description?.toLowerCase().includes(searchTerm) ||
        list.category?.toLowerCase().includes(searchTerm)
    )
    setFilteredCategory(filteredData)
  }, [category, searchTerm])

  useEffect(() => {
    document.title = 'Category'
  }, [])

  const values = {
    isLoading,
    category,
    filteredCategory,
    searchTerm,
    setSearchTerm,
    handlePageChange,
    itemsPerPage,
    setItemsPerPage,
    setCurrentPage,
    totalElement,
    currentPage,
  }

  return <CategoryContext.Provider value={values}>{children}</CategoryContext.Provider>
}

export const useCategoryProvider = () => useContext(CategoryContext)
