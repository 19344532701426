import React from 'react'
import {PaymentProvider} from './PaymentProvider'
import PaymentRequest from './PaymentRequest'
import {ListViewProvider} from './core/ListViewProvider'

const Payment = () => {
  return (
    <PaymentProvider>
      <ListViewProvider>
        <PaymentRequest />
      </ListViewProvider>
    </PaymentProvider>
  )
}

export default Payment
