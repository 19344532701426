/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {IUser} from './core/_model'
import {Button} from 'react-bootstrap'
import {useUserListProvider} from './UserListProvider'

type Props = {
  user: IUser
}

const UserAction: FC<Props> = ({user}) => {
  const {
    handleLoginAs,
    handleUnBlockUser,
    approveUser,
    handleUpdateProfile,
    hanldeUpdatePrice,
    handleUpdateSenderId,
    handleUpdateModal,
    handleBlockUser,
  } = useUserListProvider()
  return (
    <>
      {user.user_status === 'S' ? (
        <Button variant='danger' size='sm' onClick={() => handleUnBlockUser(user)}>
          Unblock
        </Button>
      ) : user.user_status === 'P' ? (
        <Button variant='dark' size='sm' onClick={() => approveUser(user)}>
          Pending
        </Button>
      ) : (
        <div>
          <button
            className='btn btn-secondary btn-sm'
            type='button'
            id='dropdownMenuButton'
            data-bs-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            Action
            {/* <KTIcon iconName='down' className='fs-5' /> */}
          </button>
          <ul
            className='dropdown-menu menu menu-sub menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4'
            aria-labelledby='dropdownMenuButton'
          >
            <div className='menu-item px-3'>
              <a className='menu-link px-3' onClick={() => handleLoginAs(user.id)}>
                Login as..
              </a>
            </div>
            <div className='menu-item px-3'>
              <a className='menu-link px-3' onClick={() => handleUpdateProfile(user)}>
                Update Profile
              </a>
            </div>
            <div className='menu-item px-3'>
              <a className='menu-link px-3' onClick={() => hanldeUpdatePrice(user)}>
                Update Price
              </a>
            </div>
            <div className='menu-item px-3'>
              <a
                className='menu-link px-3'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_2'
                onClick={() => handleUpdateModal(user)}
              >
                Update Balance
              </a>
            </div>
            <div className='menu-item px-3'>
              <a className='menu-link px-3' onClick={() => handleUpdateSenderId(user)}>
                Update Sender ID
              </a>
            </div>
            <div className='menu-item px-3'>
              <a
                className='menu-link px-3'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_1'
                onClick={() => handleUpdateModal(user)}
              >
                Update User Id/Pass
              </a>
            </div>
            <div className='menu-item px-3'>
              <a
                className='menu-link px-3'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_2'
                onClick={() => handleBlockUser(user)}
              >
                Block User
              </a>
            </div>
          </ul>
        </div>
      )}
    </>
  )
}

export default UserAction
