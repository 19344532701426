import React, {Dispatch, FC, SetStateAction, useRef, useState} from 'react'
import {ISearchParams} from '../../LiveReports/core/_model'
import useSenderIdsOptions, {ISenderIdsOption} from '../../../hooks/useSenderIdsOptions'
import Select from 'react-select'
import {useThemeMode} from '../../../../_metronic/partials'

export interface Props {
  onSearchFormSubmit: (searchData: ISearchParams) => void
  itemsPerPage: number
  setItemsPerPage: Dispatch<SetStateAction<number>>
  setCurrentPage: Dispatch<SetStateAction<number>>
}

const LogReportFilterModal: FC<Props> = ({
  onSearchFormSubmit,
  itemsPerPage,
  setItemsPerPage,
  setCurrentPage,
}) => {
  const {senderIdsOptions} = useSenderIdsOptions()
  const {mode} = useThemeMode()
  const [selectedOption, setSelectedOption] = useState<ISenderIdsOption>()
  const [msisdn, setMsisdn] = useState<number | null>(null)
  const startTimeRef = useRef<HTMLInputElement>(null)
  const endTimeRef = useRef<HTMLInputElement>(null)

  const handleSelectChange = (selected: any) => {
    setSelectedOption(selected)
  }

  const handleApplyFilter = () => {
    const formData: any = {
      senderId: selectedOption?.id,
      msisdn: msisdn,
      startTime: startTimeRef.current?.value,
      endTime: endTimeRef.current?.value,
    }

    console.log(formData)

    onSearchFormSubmit(formData)
  }

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '8px',
      padding: '4px',
      border: 0,
      backgroundColor: mode === 'light' ? '#f9f9f9' : '#1b1b29',
      borderColor: state.isFocused ? '#6c63ff' : '#d1d1d1',
      boxShadow: state.isFocused ? '0 0 0 1px #6c63ff' : null,
      '&:hover': {
        borderColor: '#6c63ff',
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#6c63ff' : null,
      color: state.isSelected ? '#ffffff' : null,
      '&:hover': {
        backgroundColor: '#6c63ff',
        color: '#ffffff',
      },
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '8px',
      backgroundColor: mode === 'light' ? '#f8f0f4' : '#1b1b29',
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)', // Add box shadow
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: mode === 'light' ? '#5e6278' : '#92929f', // Change color of selected value
    }),
  }

  return (
    <div className='modal fade' tabIndex={-2} id='kt_modal_2'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <div className='fs-5 text-gray-900 fw-bolder'>Filter Options</div>
          </div>

          <div className='modal-content'>
            <div className='px-7 py-5'>
              <div className='mb-4'>
                <label className='form-label fs-6 fw-bold'>Show Entries:</label>
                <select
                  className='form-select form-select-solid fw-bolder'
                  data-kt-select2='true'
                  data-placeholder='Select entries'
                  data-hide-search='true'
                  name='entries'
                  id='entries'
                  value={itemsPerPage}
                  onChange={(e) => {
                    setCurrentPage(1)
                    setItemsPerPage(parseInt(e.target.value))
                  }}
                >
                  <option value='10'>10</option>
                  <option value='50'>50</option>
                  <option value='100'>100</option>
                  <option value='250'>250</option>
                </select>
              </div>

              <div className='mb-4'>
                <label className='form-label fs-6 fw-bold'>Select Sender ID:</label>
                <Select
                  options={senderIdsOptions}
                  value={selectedOption}
                  onChange={handleSelectChange}
                  isSearchable
                  placeholder='Select Sender Ids'
                  styles={customStyles}
                />
              </div>

              <div className='mb-4'>
                <label className='form-label fs-6 fw-bold'>
                  <span className='required'>Start Time:</span>
                </label>
                <input
                  type='datetime-local'
                  ref={startTimeRef}
                  className='form-control'
                  min={new Date().toISOString()}
                />
              </div>

              <div className='mb-4'>
                <label className='form-label fs-6 fw-bold'>
                  <span className='required'>End Time:</span>
                </label>
                <input
                  type='datetime-local'
                  ref={endTimeRef}
                  className='form-control'
                  min={new Date().toISOString()}
                />
              </div>

              <div className='mb-4'>
                <label className='form-label fs-6 fw-bold'>
                  <span>MSSISDN:</span>
                </label>
                <input
                  type='number'
                  className='form-control'
                  value={msisdn === null ? '' : msisdn}
                  onChange={(e) => {
                    const value = e.target.value
                    setMsisdn(value === '' ? null : parseInt(value))
                  }}
                  placeholder='Enter MSISDN'
                />
              </div>

              <div className='d-flex justify-content-end'>
                <button
                  type='button'
                  className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  Reset
                </button>
                <button
                  type='button'
                  className='btn btn-primary fw-bold px-6'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={handleApplyFilter}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>

          {/* <div className='modal-footer'>
        <button
          type='button'
          className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
          data-bs-dismiss='modal'
          aria-label='Close'
        >
          Reset
        </button>
        <button type='button' className='btn btn-primary fw-bold px-6'>
          Apply
        </button>
      </div> */}
        </div>
      </div>
    </div>
  )
}

export default LogReportFilterModal
