/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const TicketAction = () => {
  return (
    <div>
      <button
        className='btn btn-secondary btn-sm'
        type='button'
        id='dropdownMenuButton'
        data-bs-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
      >
        Action
        {/* <KTIcon iconName='down' className='fs-5' /> */}
      </button>
      <ul
        className='dropdown-menu menu menu-sub menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        aria-labelledby='dropdownMenuButton'
      >
        <div className='menu-item px-3'>
          <a className='menu-link px-3'>Edit</a>
        </div>
        <div className='menu-item px-3'>
          <a className='menu-link px-3'>View</a>
        </div>
        <div className='menu-item px-3'>
          <a className='menu-link px-3' data-bs-toggle='modal' data-bs-target='#kt_modal_2'>
            Delete
          </a>
        </div>
      </ul>
    </div>
  )
}

export default TicketAction
