/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ChangeEvent, PropsWithChildren, useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import toast from 'react-hot-toast'
import axios from 'axios'
import {BASE_URL} from '../config'
import {useAuth} from '../auth'
import {IRetrySMSData, IRetrySMSDataArray, ISearchParams} from './core/_model'
import {KTIcon} from '../../../_metronic/helpers'
import TodaySMSFilterModal from './Modal/TodaySMSFilterModal'
import TableContent from '../../utils/components/Table/TableContent'
import TablePagination from '../../utils/components/Table/TablePagination'
import {Column, HeaderProps} from 'react-table'
import {CustomHeader} from '../../utils/components/Table/CustomHeader'
import {formatDate} from '../../utils/helperFunction/_helper'
import SelectionHeader from '../../utils/components/Table/SelectionHeader'
import {ListViewContext, ListViewProvider} from '../../utils/contextProvider/ListViewProvider'
import {SelectionCell} from '../../utils/components/Table/SelectionCell'
import RetrySmsListHeader from './RetrySmsListHeader'

const RetrySmsList = () => {
  const {auth} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [retrySms, setRetrySms] = useState<IRetrySMSDataArray>([])
  const [filteredSms, setFilteredSms] = useState<IRetrySMSDataArray>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchParams, setSearchParams] = useState<any>({})
  const [click, setClick] = useState(0)

  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalElement, setTotalElement] = useState(0)
  const [isLastPage, setIsLastPage] = useState(false)

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)

  let pageNumber = currentPage - 1
  let pageSize = itemsPerPage

  const onSearchFormSubmit = (searchParams: ISearchParams) => {
    // console.log(searchParams)
    setSearchParams(searchParams)
    setCurrentPage(1)
    setItemsPerPage(10)
    setClick((prev) => prev + 1)

    // getRetrySms(searchParams, pageNumber, pageSize)
  }

  const getRetrySms = async (searchParams: ISearchParams, pageNumber: number, pageSize: number) => {
    try {
      setIsLoading(true)
      toast.loading('Loading Retry SMS..')
      // console.log(searchParams)
      const response = await axios.get(
        `${BASE_URL}/api/protect/liveReport/getRetrySmsfor${
          searchParams.channel
        }?senderIds=${searchParams.senderIds.join('&senderIds=')}`,
        {
          headers: {Authorization: `Bearer ${auth?.accessToken}`},
          params: {
            pageNumber: pageNumber,
            pageSize: pageSize,
            startTime: searchParams.startTime,
            channel: searchParams.channel,
            endTime: searchParams.endTime,
            msisdn: searchParams.msisdn,
            status: searchParams.status,
          },
        }
      )
      const result = await response.data
      const data = await result.data
      const lastPage = await result.lastpage
      const totalResult = await result.totalElement
      setRetrySms(data ? data : [])
      setIsLastPage(lastPage)
      setTotalElement(totalResult)
      toast.dismiss()
      toast.success('SMS Fetched Successfully')
      setIsLoading(false)
    } catch (error: any) {
      setIsLoading(false)
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
    }
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  let showPageLastEtries = !isLastPage ? currentPage * itemsPerPage : totalElement
  let showPageFirstEntries = !isLastPage
    ? showPageLastEtries + 1 - itemsPerPage
    : (currentPage - 1) * itemsPerPage + 1

  let serial: number[] = []

  for (let i = showPageFirstEntries; i <= showPageLastEtries; i++) {
    serial.push(i)
  }

  useEffect(() => {
    const filteredData = retrySms?.filter(
      (list) =>
        list.msisdn?.toLowerCase().includes(searchTerm) ||
        list.date?.toLowerCase().includes(searchTerm)
    )
    setFilteredSms(filteredData)
  }, [retrySms, searchTerm])

  useEffect(() => {
    document.title = 'Retry SMS List'
  }, [])

  useEffect(() => {
    if (searchParams.channel) {
      getRetrySms(searchParams, pageNumber, pageSize)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, currentPage, click])

  const RetrySMSColumn: ReadonlyArray<Column<IRetrySMSData>> = [
    {
      Header: (props) => <SelectionHeader tableProps={props as any} context={ListViewContext} />,
      id: 'selection',
      Cell: ({...props}) => (
        <SelectionCell id={props.data[props.row.index].id} context={ListViewContext} />
      ),
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Date' className='min-w-100px' />
      ),
      id: 'dateTime',
      Cell: ({...props}) => <>{formatDate(props.data[props.row.index].date)}</>,
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='MSSISDN' className='min-w-100px' />
      ),
      id: 'mssisdn',
      accessor: 'msisdn',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Sender' className='min-w-100px' />
      ),
      id: 'sender',
      accessor: 'sender',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='SMS Part' className='min-w-100px' />
      ),
      id: 'smsPart',
      accessor: 'smsPart',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Status' className='min-w-100px' />
      ),
      id: 'status',
      accessor: 'status',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='SMS Cost' className='min-w-100px' />
      ),
      id: 'smsCost',
      accessor: 'smsCost',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Username' className='min-w-100px' />
      ),
      id: 'username',
      accessor: 'username',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='SMS Text' className='min-w-100px' />
      ),
      id: 'smsText',
      accessor: 'smsText',
    },
  ]

  return (
    <ListViewProvider isLoading={isLoading} data={filteredSms}>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header d-flex justify-content-between'>
          <div className='card-title'>
            <h3 className='er mb-0'>Retry SMS List</h3>
          </div>
          <div className='card-title'>
            <Link to='/dashboard'>
              <Button variant='primary' size='sm'>
                Dashboard
              </Button>
            </Link>
          </div>
        </div>

        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='Search Group'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <div className='card-title m-0'>
            <RetrySmsListHeader />

            <TodaySMSFilterModal
              onSearchFormSubmit={onSearchFormSubmit}
              require={true}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>

        <TableContent isLoading={isLoading} tableData={filteredSms} tableColumn={RetrySMSColumn}>
          <TablePagination
            totalElement={totalElement}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            isLoading={isLoading}
          />
        </TableContent>
      </div>
    </ListViewProvider>
  )
}

export default RetrySmsList
