import axios from 'axios'
import {FC, ReactNode, createContext, useContext, useEffect, useState} from 'react'
import {
  EmailOptions,
  GET_ALL_OPTIONS_URL,
  Invoice,
  InvoiceState,
  OptionsObject,
  OtherOptions,
  PaymentOptions,
  SettingOptions,
  UPDATE_OPTIONS_URL,
  URLOptions,
} from './core/_model'
import {useAuth} from '../auth'
import {toast} from 'react-hot-toast'

type Props = {
  children: ReactNode
}

export const SettingsContext = createContext<any>(null)

export const SettingsProvider: FC<Props> = ({children}) => {
  const {auth} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [emailOptions, setEmailOptions] = useState<EmailOptions[]>([])
  const [emailOptionsObj, setEmailOptionsObj] = useState<OptionsObject>({})
  const [paymentOptionsObj, setPaymentOptionsObj] = useState<OptionsObject>({})
  const [urlOptionsObj, setUrlOptionsObj] = useState<OptionsObject>({})
  const [siteOptionObj, setSiteOptionObj] = useState<OptionsObject>({})
  const [othersOptionsObj, setOthersOptionsObj] = useState<OptionsObject>({})
  const [invoiceData, setInvoiceData] = useState<InvoiceState | undefined>(undefined)

  const getAllOptions = async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(`${GET_ALL_OPTIONS_URL}`, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      const result = await response.data
      extractEmailData(result)
      extractPaymentData(result)
      extractUrlData(result)
      extractOthers(result)
      extractSite(result)
      extractInvoice(result)
      setIsLoading(false)
    } catch (error: any) {
      console.log(error.message)
      setIsLoading(false)
    }
  }

  const extractInvoice = (data: any) => {
    const invoiceData = data.filter((obj: any) => obj.id === 15)[0]
    const invoice: Invoice = JSON.parse(invoiceData.optionsValue)
    setInvoiceData({
      id: invoiceData.id,
      optionsName: invoiceData.optionsName,
      optionsValue: invoice,
    })
  }

  const extractSite = (data: any) => {
    const sitesData = data.filter((obj: any) => obj.id >= 1 && obj.id <= 4)

    const formatOptions = filteredData(sitesData)
    console.log(formatOptions)

    setSiteOptionObj(formatOptions)
  }

  const extractOthers = (data: any) => {
    const costDigitData: OtherOptions = data.filter((obj: any) => obj.id === 10)[0]
    const storeForwardData: OtherOptions = data.filter((obj: any) => obj.id === 14)[0]
    const isSmsSenderData: OtherOptions = data.filter((obj: any) => obj.id === 18)[0]
    const restData: OtherOptions[] = data.filter((obj: any) => obj.id >= 27 && obj.id <= 29)

    const othersOptionData: OtherOptions[] = [
      ...restData,
      storeForwardData,
      isSmsSenderData,
      costDigitData,
    ]

    const formatOptions = filteredData(othersOptionData)

    console.log(formatOptions)

    setOthersOptionsObj(formatOptions)
  }

  const extractUrlData = (data: any) => {
    const filteredUrlData: URLOptions[] = data.filter((obj: any) => obj.id >= 11 && obj.id <= 13)

    const formatOptions = filteredData(filteredUrlData)

    setUrlOptionsObj(formatOptions)
  }

  const extractPaymentData = (data: any) => {
    const filteredPaymentData: PaymentOptions[] = data.filter(
      (obj: any) => obj.id >= 5 && obj.id <= 9
    )

    const formatOptions = filteredData(filteredPaymentData)

    setPaymentOptionsObj(formatOptions)
  }

  const extractEmailData = (data: any) => {
    const filteredEmailData: EmailOptions[] = data.filter(
      (obj: any) => obj.id >= 19 && obj.id <= 26
    )
    setEmailOptions(filteredEmailData)

    const formatOptions = filteredData(filteredEmailData)

    setEmailOptionsObj(formatOptions)
  }

  const filteredData = (data: SettingOptions[]) => {
    const formatOptions: OptionsObject = {}

    data.map((obj: SettingOptions) => {
      const keyName = obj.optionsName
      formatOptions[keyName] = {
        id: obj.id,
        optionsName: obj.optionsName,
        optionsValue: obj.optionsValue,
      }
      return null
    })

    return formatOptions
  }

  const updateOptions = async (options: OtherOptions) => {
    try {
      const response = await axios.put(`${UPDATE_OPTIONS_URL}/${options.id}`, options, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
      })
      console.log(response)
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    document.title = 'Settings'

    getAllOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const values = {
    isLoading,
    setIsLoading,
    emailOptions,
    emailOptionsObj,
    paymentOptionsObj,
    urlOptionsObj,
    othersOptionsObj,
    siteOptionObj,
    invoiceData,
    updateOptions,
    getAllOptions,
  }

  return <SettingsContext.Provider value={values}>{children}</SettingsContext.Provider>
}

export const useSettingsProvider = () => useContext(SettingsContext)
