import axios from 'axios'
import {BASE_URL} from '../../config'
import {IAllPriceListData, IChargingRoute, IPriceList, IPriceListArray} from './_model'

const GET_CHARGING_URL = `${BASE_URL}/api/protect/getchargingConf`

export const convertTaka = (amount: number) => parseFloat((amount / 100).toFixed(4))
export const convertPaisa = (amount: number) => parseFloat((amount * 100).toFixed(4))

export const getPriceLists = async (
  token: string | undefined,
  currentUserRoute: number | undefined,
  getUserId: number | undefined
): Promise<IAllPriceListData> => {
  try {
    const [chargingRouteResponse, userPriceResponse] = await Promise.all([
      axios.get(`${GET_CHARGING_URL}/${currentUserRoute}?charingRouteconfId=${currentUserRoute}`, {
        headers: {Authorization: `Bearer ${token}`},
      }),
      axios.get(`${GET_CHARGING_URL}/${getUserId}?charingRouteconfId=${getUserId}`, {
        headers: {Authorization: `Bearer ${token}`},
      }),
    ])

    const chargingRouteData = chargingRouteResponse.data
    const userPriceData = userPriceResponse.data

    return {
      chargingPrice: chargingRouteData,
      userPrice: userPriceData,
    }
  } catch (error: any) {
    throw error
  }
}

export const settingsPriceList = async (
  token: string | undefined,
  currentUserRoute: number | undefined,
  getUserId: number | undefined
): Promise<IPriceListArray | undefined> => {
  try {
    const priceLists = await getPriceLists(token, currentUserRoute, getUserId)
    const chargingData: any[] = []
    const userPriceData: any[] = []

    priceLists.chargingPrice.map((item: IChargingRoute) => {
      const singleData: any = {
        prefix: item.operatorPrefix,
        buyMaskPrice: convertTaka(item.brandPrice),
        buyNonMaskPrice: convertTaka(item.nonBrandPrice),
      }
      chargingData.push(singleData)
      return chargingData
    })

    priceLists.userPrice.map((item: IChargingRoute) => {
      const singleData: any = {
        id: item.id,
        sellMaskPrice: convertTaka(item.brandPrice),
        sellNonMaskPrice: convertTaka(item.nonBrandPrice),
      }
      userPriceData.push(singleData)
      return userPriceData
    })

    const combinedData: IPriceList[] = chargingData.map((item: any, index: number) => {
      const userPrice = userPriceData[index]
      return {
        serial: index + 1,
        entityId: userPrice.id,
        prefix: item.prefix,
        buyMaskPrice: item.buyMaskPrice,
        buyNonMaskPrice: item.buyNonMaskPrice,
        sellMaskPrice: userPrice.sellMaskPrice,
        sellNonMaskPrice: userPrice.sellNonMaskPrice,
      }
    })
    return combinedData
  } catch (error) {
    console.log(error)
    return undefined
  }
}

// const getPriceLists = async (): Promise<IAllPriceListData> => {
//   try {
//     toast.loading('Loading Price Lists..')

//     const [chargingRouteResponse, userPriceResponse] = await Promise.all([
//       axios.get(
//         `${GET_CHARGING_URL}/${currentUser?.charging_route_id}?charingRouteconfId=${currentUser?.charging_route_id}`,
//         {
//           headers: {Authorization: `Bearer ${auth?.accessToken}`},
//         }
//       ),
//       axios.get(
//         `${GET_CHARGING_URL}/${updateUser.charging_route_id}?charingRouteconfId=${updateUser.charging_route_id}`,
//         {
//           headers: {Authorization: `Bearer ${auth?.accessToken}`},
//         }
//       ),
//     ])

//     const chargingRouteData = chargingRouteResponse.data
//     const userPriceData = userPriceResponse.data

//     console.log(userPriceData)

//     toast.dismiss()
//     toast.success('Fetched Price Lists Successfully')

//     return {
//       chargingPrice: chargingRouteData,
//       userPrice: userPriceData,
//     }
//   } catch (error: any) {
//     toast.dismiss()
//     toast.error(`Error: ${error.message}`)
//     throw error
//   }
// }

//   const settingsPriceList = async () => {
//     try {
//       const priceLists = await getPriceLists()
//       const chargingData: any[] = []
//       const userPriceData: any[] = []

//       priceLists.chargingPrice.map((item: IChargingRoute) => {
//         const singleData: any = {
//           prefix: item.operatorPrefix,
//           buyMaskPrice: convertTaka(item.brandPrice),
//           buyNonMaskPrice: convertTaka(item.nonBrandPrice),
//         }
//         chargingData.push(singleData)
//         return chargingData
//       })

//       priceLists.userPrice.map((item: IChargingRoute) => {
//         const singleData: any = {
//           id: item.id,
//           sellMaskPrice: convertTaka(item.brandPrice),
//           sellNonMaskPrice: convertTaka(item.nonBrandPrice),
//         }
//         userPriceData.push(singleData)
//         return userPriceData
//       })

//       const combinedData: IPriceListArray = chargingData.map((item: any, index: number) => {
//         const userPrice = userPriceData[index]
//         return {
//           serial: index + 1,
//           entityId: userPrice.id,
//           prefix: item.prefix,
//           buyMaskPrice: item.buyMaskPrice,
//           buyNonMaskPrice: item.buyNonMaskPrice,
//           sellMaskPrice: userPrice.sellMaskPrice,
//           sellNonMaskPrice: userPrice.sellNonMaskPrice,
//         }
//       })
//       setPriceList(combinedData)
//     } catch (error) {
//       console.log(error)
//     }
//   }
