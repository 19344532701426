import React, {FC} from 'react'
import {useSpamFilterProvider} from './SpamFIlterProvider'

type Props = {
  indexNo: number
}

const ListSerial: FC<Props> = ({indexNo}) => {
  const {serial} = useSpamFilterProvider()

  return <div>{serial[indexNo]}</div>
}

export default ListSerial
