import {Column, HeaderProps} from 'react-table'
import {PropsWithChildren} from 'react'
import {AdditionalPrice} from './core/_model'
import {CustomHeader} from '../../utils/components/Table/CustomHeader'

const AdditionalPriceColumn: ReadonlyArray<Column<AdditionalPrice>> = [
  // {
  //   Header: (props) => <SelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader
        tableProps={props}
        title='Submission Count'
        className='min-w-125px text-center'
      />
    ),
    id: 'submissionCount',
    Cell: ({...props}) => (
      <p>{props.data[props.row.index].scSubmissionCount === 0 ? 'Submission' : 'Count'}</p>
    ),
  },
  {
    Header: (props: PropsWithChildren<HeaderProps<any>>) => (
      <CustomHeader tableProps={props} title='Service Charge' className='min-w-125px text-center' />
    ),
    id: 'serviceCharge',
    Cell: ({...props}) => <p>{props.data[props.row.index].serviceCharge / 100}</p>,
  },
]

export {AdditionalPriceColumn}
