import React, {useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import {updatePasswordApi} from '../core/userListRequest'
import {useAuth} from '../../auth'
import {useUserListProvider} from '../UserListProvider'

const UpdatePass = () => {
  const {auth} = useAuth()
  const {updateUser, showUpdateModal: show, hide} = useUserListProvider()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    if (show) {
      setUsername(updateUser?.user_full_name || '')
      setPassword(updateUser?.userpass || '')
    }
  }, [show, updateUser])

  const updatePassword = () => {
    updatePasswordApi(auth?.accessToken, updateUser?.id, {password: password})
    hide()
  }

  return (
    <div
      className='modal fade'
      style={{visibility: !show ? 'hidden' : undefined}}
      tabIndex={-1}
      id='kt_modal_1'
    >
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Update User Name/Password</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <form>
            <div className='modal-body'>
              <div className='d-flex flex-column align-items-start mb-4'>
                <label className='col-form-label fs-4 fw-bold'>
                  <span className=''>User Name</span>
                </label>

                <div className='col-lg-8 fv- w-100'>
                  <input
                    name=''
                    placeholder='User Name'
                    className='form-control form-control-lg form-control-solid'
                    value={username && username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
              </div>

              <div className='d-flex flex-column align-items-start mb-4'>
                <label className='col-form-label fs-4 fw-bold'>
                  <span className=''>Password</span>
                </label>

                <div className='col-lg-8 fv- w-100'>
                  <input
                    name=''
                    placeholder='******'
                    className='form-control form-control-lg form-control-solid'
                    value={password && password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <div>
                <Button variant='secondary' data-bs-dismiss='modal' aria-label='Close'>
                  Close
                </Button>{' '}
                <Button
                  type='button'
                  variant='primary'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={updatePassword}
                >
                  Update
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default UpdatePass
