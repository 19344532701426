/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ChangeEvent, PropsWithChildren, useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import toast from 'react-hot-toast'
import axios from 'axios'
import {useAuth} from '../auth'
import {downloadCSV, downloadPDF} from '../../utils/helperFunction/downloadHelper'
import {ISearchParams} from '../LiveReports/core/_model'
import {GET_SMS_DOWNLOAD, GET_SMS_HISTORY, ISMSHistory, ISMSHistoryArray} from './core/_model'
import {KTIcon} from '../../../_metronic/helpers'
import TableContent from '../../utils/components/Table/TableContent'
import TablePagination from '../../utils/components/Table/TablePagination'
import {Column, HeaderProps} from 'react-table'
import {CustomHeader} from '../../utils/components/Table/CustomHeader'
import {formatDate} from '../../utils/helperFunction/_helper'
import FilterModal from './Modal/FIlterModal'
import SelectionHeader from '../../utils/components/Table/SelectionHeader'
import {ListViewContext, ListViewProvider} from '../../utils/contextProvider/ListViewProvider'
import {SelectionCell} from '../../utils/components/Table/SelectionCell'
import SMSHistoryHeader from './SMSHistoryHeader'

const SMSHistory = () => {
  const {auth} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [smsHistory, setSmsHistory] = useState<ISMSHistoryArray>([])
  const [filterHistory, setFilterHistory] = useState<ISMSHistoryArray>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [click, setClick] = useState(0)
  const [params, setParams] = useState<any>({})

  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalElement, setTotalElement] = useState(0)
  const [isLastPage, setIsLastPage] = useState(false)

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)

  let pageNumber = currentPage - 1
  let pageSize = itemsPerPage

  const onSearchFormSubmit = (searchParams: ISearchParams) => {
    console.log(searchParams)
    setCurrentPage(1)
    setItemsPerPage(10)
    setParams({
      ...searchParams,
    })
    setClick((prev) => prev + 1)

    // getSmsHistory(searchParams)
  }

  const getSmsHistory = async (searchParams: any, pageNumber: number, pageSize: number) => {
    // ?senderIds=${searchParams.senderIds.join('&senderIds=')}
    try {
      const url =
        searchParams?.senderIds.length > 0
          ? `${GET_SMS_HISTORY}/${searchParams.channel}?senderIds=${searchParams.senderIds.join(
              '&senderIds='
            )}`
          : `${GET_SMS_HISTORY}/${searchParams.channel}`
      setIsLoading(true)
      toast.loading('Getting History..')
      const response = await axios.get(url, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
        params: {
          ...searchParams,
          pageNumber,
          pageSize,
        },
      })
      const result = await response.data
      const data = await result.data
      const lastPage = await result.lastpage
      const totalResult = await result.totalElement
      setSmsHistory(data ? data : [])
      setIsLastPage(lastPage)
      setTotalElement(totalResult)
      toast.dismiss()
      toast.success('SMS History Fetched')
      setIsLoading(false)
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
      setIsLoading(false)
    }
  }

  const onDownload = async (searchParams: ISearchParams, type: string) => {
    console.log(searchParams)

    try {
      const url =
        searchParams?.senderIds.length > 0
          ? `${GET_SMS_DOWNLOAD}/${searchParams.channel}?senderIds=${searchParams.senderIds.join(
              '&senderIds='
            )}`
          : `${GET_SMS_DOWNLOAD}/${searchParams.channel}`
      const response = await axios.get(url, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
        params: {
          ...searchParams,
          pageNumber: 0,
          pageSize: totalElement,
        },
      })
      const result = await response.data
      const data = await result.data
      if (type === 'csv') {
        downloadCSV(data, 'smshistory')
      } else {
        downloadPDF({data: data, fileName: 'SMS History'})
      }
      console.log(data)
      toast.dismiss()
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
    }

    // getSmsHistory(searchParams)
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  let showPageLastEntries = !isLastPage ? currentPage * itemsPerPage : totalElement
  let showPageFirstEntries = !isLastPage
    ? showPageLastEntries + 1 - itemsPerPage
    : (currentPage - 1) * itemsPerPage + 1

  let serial: number[] = []

  for (let i = showPageFirstEntries; i <= showPageLastEntries; i++) {
    serial.push(i)
  }

  useEffect(() => {
    const filteredData = smsHistory?.filter(
      (list) =>
        list.mssisdn?.toLowerCase().includes(searchTerm) ||
        list.sender?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        list.status?.toLowerCase().includes(searchTerm)
    )
    setFilterHistory(filteredData)
  }, [smsHistory, searchTerm])

  useEffect(() => {
    document.title = 'SMS History'
  }, [])

  useEffect(() => {
    if (params.channel) {
      getSmsHistory(params, pageNumber, pageSize)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, currentPage, click])

  const SMSHistoryColumn: ReadonlyArray<Column<ISMSHistory>> = [
    {
      Header: (props) => <SelectionHeader tableProps={props as any} context={ListViewContext} />,
      id: 'selection',
      Cell: ({...props}) => (
        <SelectionCell id={props.data[props.row.index].id} context={ListViewContext} />
      ),
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Date' className='min-w-100px' />
      ),
      id: 'dateTime',
      Cell: ({...props}) => <>{formatDate(props.data[props.row.index].date)}</>,
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='MSSISDN' className='min-w-100px' />
      ),
      id: 'mssisdn',
      accessor: 'mssisdn',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Sender' className='min-w-100px' />
      ),
      id: 'sender',
      accessor: 'sender',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='SMS Part' className='min-w-100px' />
      ),
      id: 'smsPart',
      accessor: 'smsPart',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Status' className='min-w-100px' />
      ),
      id: 'status',
      accessor: 'status',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='SMS Cost' className='min-w-100px' />
      ),
      id: 'cost',
      accessor: 'cost',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Username' className='min-w-100px' />
      ),
      id: 'username',
      accessor: 'username',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='SMS Text' className='min-w-100px' />
      ),
      id: 'smsText',
      accessor: 'smsText',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='GW Res' className='min-w-100px' />
      ),
      id: 'gwRes',
      accessor: 'gwRes',
    },
  ]

  return (
    <ListViewProvider isLoading={isLoading} data={filterHistory}>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0 d-flex justify-content-between'>
          <div className='card-title'>
            <h3 className='er mb-0'>Archive SMS History</h3>
          </div>
          <div className='card-title'>
            <Link to='/dashboard'>
              <Button variant='primary' size='sm'>
                Dashboard
              </Button>
            </Link>
          </div>
        </div>

        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='Search Group'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <div className='card-title m-0'>
            <SMSHistoryHeader />

            <FilterModal
              onSearchFormSubmit={onSearchFormSubmit}
              require={true}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
        <TableContent
          isLoading={isLoading}
          tableData={filterHistory}
          tableColumn={SMSHistoryColumn}
        >
          <TablePagination
            totalElement={totalElement}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            isLoading={isLoading}
          />
        </TableContent>
      </div>
    </ListViewProvider>
  )
}

export default SMSHistory
