import axios from 'axios'
import {BASE_URL} from '../../config'
import {IContactData} from './_model'

const FILE_UPLOAD_URL = `${BASE_URL}/api/protect/contactFileupload`

export const validateMobileNumber = (phoneNumber: string): boolean => {
  const regex = /^(\+?880|0)1[1-9]\d{8}$/
  return regex.test(phoneNumber)
}

export const numberCounts = (data: any, noCol = '') => {
  let invalidCount = 0
  let duplicateCount = 0
  let successCount = 0
  const successItem: IContactData[] = []
  const encounteredNumbers: Set<string> = new Set()

  data.forEach((item: any) => {
    const [mobileNumber] = Object.keys(item)

    let keyName = item[mobileNumber]

    if (noCol) {
      keyName = item[noCol]
    }

    if (!validateMobileNumber(keyName)) {
      invalidCount++
    } else {
      if (encounteredNumbers.has(keyName)) {
        duplicateCount++
      } else {
        encounteredNumbers.add(keyName)
        successCount++
        successItem.push(item)
        // console.log(item)
      }
    }
  })

  return {invalidCount, duplicateCount, successCount, successItem}
}

export const fileupload = async (
  token: string | undefined,
  fileData: any,
  numberColumnName: string
) => {
  try {
    const response = await axios.post(`${FILE_UPLOAD_URL}/${numberColumnName}`, fileData, {
      headers: {Authorization: `Bearer ${token}`},
    })
    if (response.status === 200) {
      const data = await response.data
      if (data && 'id' in data) {
        const duplicateCount = data.duplicateCount
        const invalidCount = data.invalidCount
        const successCount = data.successCount
        const fileId = data.id
        console.log(response)
        return {duplicateCount, invalidCount, successCount, fileId}
      } else {
        // Handle the case where 'fileId' property is missing
        console.error("Property 'fileId' not found in response data.")
        return null // Or handle it according to your application logic
      }
    }
  } catch (error) {
    console.log(error)
  }
}
