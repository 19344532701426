/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {PropsWithChildren, useEffect, useRef, useState} from 'react'
import {Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {useAuth} from '../auth'
import useSenderIdsOptions, {ISenderIdsOption} from '../../hooks/useSenderIdsOptions'
import toast from 'react-hot-toast'
import {BASE_URL} from '../config'
import axios from 'axios'
import LogReportFilterModal from './Modal/LogReportFilterModal'
import {KTIcon} from '../../../_metronic/helpers'
import TableContent from '../../utils/components/Table/TableContent'
import TablePagination from '../../utils/components/Table/TablePagination'
import {Column, HeaderProps} from 'react-table'
import {CustomHeader} from '../../utils/components/Table/CustomHeader'
import {formatDate} from '../../utils/helperFunction/_helper'
import SelectionHeader from '../../utils/components/Table/SelectionHeader'
import {ListViewContext, ListViewProvider} from '../../utils/contextProvider/ListViewProvider'
import {SelectionCell} from '../../utils/components/Table/SelectionCell'
import DeliveryLogReportHeader from './DeliveryLogReportHeader'

const GET_LOG_REPORT_URL = `${BASE_URL}/api/protect/archive/deliveryLogReport`

const DeliveryLogReport = () => {
  const {auth, currentUser} = useAuth()
  const {senderIdsOptions} = useSenderIdsOptions()
  const [selectedOption, setSelectedOption] = useState<ISenderIdsOption>()
  const [isLoading, setIsLoading] = useState(false)
  const [logReportsData, setLogReportsData] = useState<any>([])
  const [filteredLogReportsData, setFilteredLogReportsData] = useState<any>([])
  const [msisdn, setMsisdn] = useState<number | null>(null)
  const startTimeRef = useRef<HTMLInputElement>(null)
  const endTimeRef = useRef<HTMLInputElement>(null)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [click, setClick] = useState(0)
  const [params, setParams] = useState<any>({})

  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalElement, setTotalElement] = useState(0)
  const [isLastPage, setIsLastPage] = useState(false)

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)

  let pageNumber = currentPage - 1
  let pageSize = itemsPerPage

  const handleSelectChange = (selected: any) => {
    setSelectedOption(selected)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const setParams = {
      startDate: startTimeRef.current?.value,
      endDate: endTimeRef.current?.value,
      senderId: selectedOption?.id,
      mssisdn: msisdn,
    }

    console.log(setParams)
    getLogReport(setParams, pageNumber, pageSize)
  }

  const onSearchFormSubmit = (formData: any) => {
    setCurrentPage(1)
    setItemsPerPage(10)
    setParams({
      ...formData,
    })
    setClick((prev) => prev + 1)
  }

  const getLogReport = async (params: any, pageNumber: number, pageSize: number) => {
    try {
      setIsLoading(true)
      toast.loading('Loading Log Report..')
      const response = await axios.get(`${GET_LOG_REPORT_URL}/${currentUser?.user_profile}`, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
        params: {
          ...params,
          pageNumber,
          pageSize,
        },
      })
      const result = await response.data
      const data = await result.data
      const lastPage = await result.lastpage
      const totalResult = await result.totalElement
      setLogReportsData(data ? data : [])
      setIsLastPage(lastPage)
      setTotalElement(totalResult)
      toast.dismiss()
      toast.success('Fetched Log Report')
      setIsLoading(false)
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
      setIsLoading(false)
    }
  }

  let showPageLastEntries = !isLastPage ? currentPage * itemsPerPage : totalElement
  let showPageFirstEntries = !isLastPage
    ? showPageLastEntries + 1 - itemsPerPage
    : (currentPage - 1) * itemsPerPage + 1

  let serial: number[] = []

  for (let i = showPageFirstEntries; i <= showPageLastEntries; i++) {
    serial.push(i)
  }

  useEffect(() => {
    const filteredData = logReportsData?.filter(
      (list: any) =>
        list.mssisdn?.toLowerCase().includes(searchTerm) ||
        list.sender?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setFilteredLogReportsData(filteredData)
  }, [logReportsData, searchTerm])

  useEffect(() => {
    document.title = 'Delivery Log Report'
  }, [])

  useEffect(() => {
    if (params.startTime) {
      getLogReport(params, pageNumber, pageSize)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, currentPage, click])

  const LogReportColumn: ReadonlyArray<Column<any>> = [
    {
      Header: (props) => <SelectionHeader tableProps={props as any} context={ListViewContext} />,
      id: 'selection',
      Cell: ({...props}) => (
        <SelectionCell id={props.data[props.row.index].id} context={ListViewContext} />
      ),
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Date' className='min-w-100px' />
      ),
      id: 'dateTime',
      Cell: ({...props}) => <>{formatDate(props.data[props.row.index].date)}</>,
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='MSSISDN' className='min-w-100px' />
      ),
      id: 'mssisdn',
      accessor: 'mssisdn',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Sender' className='min-w-100px' />
      ),
      id: 'sender',
      accessor: 'sender',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='SMS Part' className='min-w-100px' />
      ),
      id: 'smsPart',
      accessor: 'smsPart',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Status' className='min-w-100px' />
      ),
      id: 'status',
      accessor: 'status',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='SMS Cost' className='min-w-100px' />
      ),
      id: 'cost',
      accessor: 'cost',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Username' className='min-w-100px' />
      ),
      id: 'username',
      accessor: 'username',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='SMS Text' className='min-w-100px' />
      ),
      id: 'smsText',
      accessor: 'smsText',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='GW Res' className='min-w-100px' />
      ),
      id: 'gwRes',
      accessor: 'gwRes',
    },
  ]

  return (
    <ListViewProvider isLoading={isLoading} data={filteredLogReportsData}>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0 d-flex justify-content-between'>
          <div className='card-title'>
            <h3 className='er mb-0'>Delivery Log Report</h3>
          </div>
          <div className='card-title'>
            <Link to='/dashboard'>
              <Button variant='primary' size='sm'>
                Dashboard
              </Button>
            </Link>
          </div>
        </div>
        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='Search Group'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <div className='card-title m-0'>
            <DeliveryLogReportHeader />

            <LogReportFilterModal
              onSearchFormSubmit={onSearchFormSubmit}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
        <TableContent
          isLoading={isLoading}
          tableData={filteredLogReportsData}
          tableColumn={LogReportColumn}
        >
          <TablePagination
            totalElement={totalElement}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            isLoading={isLoading}
          />
        </TableContent>
      </div>
    </ListViewProvider>
  )
}

export default DeliveryLogReport
