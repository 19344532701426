import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react'
import {useSettingsProvider} from './SettingsProvider'
import {OptionsObject} from './core/_model'
import ContentLoading from '../../utils/components/Loading/ContentLoading'

const OthersSettings = () => {
  const {isLoading, setIsLoading, othersOptionsObj, getAllOptions, updateOptions} =
    useSettingsProvider()

  const [othersOptions, setOthersOptions] = useState<OptionsObject>({
    costDigit: undefined,
    autoRetry: undefined,
    isSmsSender: undefined,
    manualRetry: undefined,
    mnpCheck: undefined,
    storeForward: undefined,
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = event.target
    setOthersOptions((prevData) => ({
      ...prevData,
      [name]: {...prevData[name], optionsValue: value},
    }))
  }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {name} = event.target

    setOthersOptions((prevData) => ({
      ...prevData,
      [name]:
        prevData[name].optionsValue === '1'
          ? {...prevData[name], optionsValue: '0'}
          : {...prevData[name], optionsValue: '1'},
    }))
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    setIsLoading(true)

    for (const key in othersOptions) {
      await updateOptions(othersOptions[key])
    }

    getAllOptions()
  }

  useEffect(() => {
    setOthersOptions({
      costDigit: othersOptionsObj.cost_digit,
      autoRetry: othersOptionsObj.auto_retry,
      isSmsSender: othersOptionsObj.is_sms_sender,
      manualRetry: othersOptionsObj.manual_retry,
      mnpCheck: othersOptionsObj.mnp_check,
      storeForward: othersOptionsObj.store_forward,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return (
    <div className='card col-12 col-md-10'>
      <div className='card-header'>
        <div className='card-title'>
          <h3 className='text-gray-700'>Others Settings</h3>
        </div>
      </div>
      <div className='card-body'>
        {isLoading ? (
          <ContentLoading />
        ) : (
          <form onSubmit={handleSubmit}>
            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label fs-6'>
                <span>Cost Digit</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  name='costDigit'
                  className='form-control form-control-lg form-control-solid'
                  value={othersOptions.costDigit?.optionsValue}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className='row mb-4 align-items-center'>
              <label className='col-lg-4 col-form-label fs-6'>
                <span>Auto Retry</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <div className='form-check form-switch form-check-custom form-check-solid'>
                  <input
                    name='autoRetry'
                    className='form-check-input'
                    type='checkbox'
                    value={othersOptions.autoRetry?.optionsValue}
                    checked={othersOptions.autoRetry?.optionsValue === '1' ? true : false}
                    onChange={handleCheckboxChange}
                  />
                </div>
              </div>
            </div>

            <div className='row mb-4 align-items-center'>
              <label className='col-lg-4 col-form-label fs-6'>
                <span>Manual Retry</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <div className='form-check form-switch form-check-custom form-check-solid'>
                  <input
                    name='manualRetry'
                    className='form-check-input'
                    type='checkbox'
                    value={othersOptions.manualRetry?.optionsValue}
                    checked={othersOptions.manualRetry?.optionsValue === '1' ? true : false}
                    onChange={handleCheckboxChange}
                  />
                </div>
              </div>
            </div>

            <div className='row mb-4 align-items-center'>
              <label className='col-lg-4 col-form-label fs-6'>
                <span>MNP Check</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <div className='form-check form-switch form-check-custom form-check-solid'>
                  <input
                    name='mnpCheck'
                    className='form-check-input'
                    type='checkbox'
                    value={othersOptions.mnpCheck?.optionsValue}
                    checked={othersOptions.mnpCheck?.optionsValue === '1' ? true : false}
                    onChange={handleCheckboxChange}
                  />
                </div>
              </div>
            </div>

            <div className='row mb-4 align-items-center'>
              <label className='col-lg-4 col-form-label fs-6'>
                <span>Store Forward</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <div className='form-check form-switch form-check-custom form-check-solid'>
                  <input
                    name='storeForward'
                    className='form-check-input'
                    type='checkbox'
                    value={othersOptions.storeForward?.optionsValue}
                    checked={othersOptions.storeForward?.optionsValue === '1' ? true : false}
                    onChange={handleCheckboxChange}
                  />
                </div>
              </div>
            </div>

            <div className='row mb-4 align-items-center'>
              <label className='col-lg-4 col-form-label fs-6'>
                <span>SMS Sender</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <div className='form-check form-switch form-check-custom form-check-solid'>
                  <input
                    name='isSmsSender'
                    className='form-check-input'
                    type='checkbox'
                    value={othersOptions.isSmsSender?.optionsValue}
                    checked={othersOptions.isSmsSender?.optionsValue === 'false' ? false : true}
                    onChange={(e) => {
                      setOthersOptions((prev) => ({
                        ...prev,
                        isSmsSender: {
                          ...prev['isSmsSender'],
                          optionsValue:
                            prev['isSmsSender'].optionsValue === 'false' ? 'true' : 'false',
                        },
                      }))
                    }}
                  />
                </div>
              </div>
            </div>

            <div className='d-flex justify-content-end'>
              <button type='submit' className='btn btn-primary'>
                Save Changes
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

export default OthersSettings
