/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {PropsWithChildren, useEffect, useState} from 'react'
import {useAuth} from '../auth'
import {IDepartment} from './core/_model'
import TableContent from '../../utils/components/Table/TableContent'
import TablePagination from '../../utils/components/Table/TablePagination'
import {KTIcon} from '../../../_metronic/helpers'
import {Column, HeaderProps} from 'react-table'
import {CustomHeader} from '../../utils/components/Table/CustomHeader'
import NewDepartmentModal from './Modal/NewDepartmentModal'
import SelectionHeader from '../../utils/components/Table/SelectionHeader'
import {ListViewContext, ListViewProvider} from '../../utils/contextProvider/ListViewProvider'
import {SelectionCell} from '../../utils/components/Table/SelectionCell'
import DepartmentHeader from './DepartmentHeader'

const Department = () => {
  const {auth} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [departments, setDepartments] = useState<IDepartment[]>([])
  const [filteredDepartments, setFilteredDepartments] = useState<IDepartment[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')

  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalElement, setTotalElement] = useState(0)
  const [isLastPage, setIsLastPage] = useState(false)

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)

  let showPageLastEntries = !isLastPage ? currentPage * itemsPerPage : totalElement
  let showPageFirstEntries = !isLastPage
    ? showPageLastEntries + 1 - itemsPerPage
    : (currentPage - 1) * itemsPerPage + 1

  useEffect(() => {
    const filteredData = departments?.filter(
      (list) =>
        list.name?.toLowerCase().includes(searchTerm) ||
        list.email?.toLowerCase().includes(searchTerm) ||
        list.description?.toLowerCase().includes(searchTerm)
    )
    setFilteredDepartments(filteredData)
  }, [departments, searchTerm])

  useEffect(() => {
    document.title = 'Departments'
  }, [])

  const DepartmentColumn: ReadonlyArray<Column<IDepartment>> = [
    {
      Header: (props) => <SelectionHeader tableProps={props as any} context={ListViewContext} />,
      id: 'selection',
      Cell: ({...props}) => (
        <SelectionCell id={props.data[props.row.index].id} context={ListViewContext} />
      ),
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Name' className='min-w-150px' />
      ),
      id: 'name',
      accessor: 'name',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Description' className='min-w-150px' />
      ),
      id: 'description',
      accessor: 'description',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Email' className='min-w-150px' />
      ),
      id: 'email',
      accessor: 'email',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Hidden' className='min-w-150px' />
      ),
      id: 'hidden',
      accessor: 'hidden',
    },
  ]

  return (
    <ListViewProvider isLoading={isLoading} data={filteredDepartments}>
      <div className='card'>
        <div className='card-header d-flex justify-content-between'>
          <h3 className='card-title'>All Department</h3>
          <div className='card-title'></div>
        </div>

        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='Search Group'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <div className='card-title m-0'>
            <DepartmentHeader
              isLoading={isLoading}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>

        <TableContent
          isLoading={isLoading}
          tableData={filteredDepartments}
          tableColumn={DepartmentColumn}
        >
          <TablePagination
            totalElement={totalElement}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            isLoading={isLoading}
          />
        </TableContent>
      </div>
      <NewDepartmentModal />
    </ListViewProvider>
  )
}

export default Department
