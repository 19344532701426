import {ReactNode, useMemo} from 'react'
import {KTCardBody} from '../../../../_metronic/helpers'
import {Column, ColumnInstance, Row, useTable} from 'react-table'

import ContentLoading from '../Loading/ContentLoading'
import {CustomRow} from './CustomRow'
import {HeaderColumnCustom} from './HeaderColumnCustom'

interface TableContentProps<TableData extends object> {
  children?: ReactNode
  isLoading: boolean
  tableData: TableData[]
  tableColumn: ReadonlyArray<Column<TableData>>
  className?: string
}

const TableContent = <TableData extends {}>({
  children,
  isLoading,
  tableData,
  tableColumn,
  className,
}: TableContentProps<TableData>) => {
  const data = useMemo(() => tableData, [tableData])
  const columns = useMemo(() => tableColumn, [tableColumn])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer table-sm gx-4'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<TableData>) => (
                <HeaderColumnCustom key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className={`text-gray-600 fw-bold ${className}`} {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<TableData>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {children}
      {isLoading && <ContentLoading />}
    </KTCardBody>
  )
}

export default TableContent
