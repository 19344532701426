/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React from 'react'
import {Pagination} from 'react-bootstrap'

export interface IElipsisPaginationProps {
  totalElement: number
  itemsPerPage: number
  currentPage: number
  handlePageChange: (pageNumber: number) => void
  isLoading: boolean
}

const TablePagination: React.FC<IElipsisPaginationProps> = ({
  totalElement,
  itemsPerPage,
  currentPage,
  handlePageChange,
  isLoading,
}) => {
  const visiblePages = 5

  const totalPages = Math.ceil(totalElement / itemsPerPage)

  const renderPaginationItems = () => {
    const items = []
    let startPage
    let endPage

    // If there are fewer pages than visible pages, show all pages
    if (totalPages <= visiblePages) {
      startPage = 1
      endPage = totalPages
    } else {
      // Otherwise, calculate startPage and endPage based on current page
      const halfVisible = Math.floor(visiblePages / 2)
      if (currentPage <= halfVisible) {
        startPage = 1
        endPage = visiblePages
      } else if (currentPage + halfVisible >= totalPages) {
        startPage = totalPages - visiblePages + 1
        endPage = totalPages
      } else {
        startPage = currentPage - halfVisible
        endPage = currentPage + halfVisible
      }
    }

    items.push(
      <li
        key='prev'
        className={clsx('page-item', {
          disabled: isLoading || currentPage === 1,
        })}
      >
        <a
          className={clsx('page-link', {
            // 'page-text': link.label === 'Previous' || link.label === 'Next',
            // 'me-5': link.label === 'Previous',
          })}
          onClick={() => handlePageChange(currentPage - 1)}
          style={{cursor: 'pointer'}}
        >
          Previous
        </a>
      </li>
    )

    // Render first page if necessary
    if (startPage > 1) {
      items.push(
        <li
          key={1}
          className={clsx('page-item', {
            active: currentPage === 1,
            disabled: isLoading,
          })}
        >
          <a
            className={clsx('page-link', {
              // 'page-text': link.label === 'Previous' || link.label === 'Next',
              // 'me-5': link.label === 'Previous',
            })}
            onClick={() => handlePageChange(1)}
            style={{cursor: 'pointer'}}
          >
            1
          </a>
        </li>
      )
      if (startPage > 2) {
        items.push(<Pagination.Ellipsis key='ellipsis-start' />)
      }
    }

    // Render visible page numbers
    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <li
          key={i}
          className={clsx('page-item', {
            active: currentPage === i,
            disabled: isLoading,
          })}
        >
          <a
            className={clsx('page-link', {
              // 'page-text': link.label === 'Previous' || link.label === 'Next',
              // 'me-5': link.label === 'Previous',
            })}
            onClick={() => handlePageChange(i)}
            style={{cursor: 'pointer'}}
          >
            {i}
          </a>
        </li>
      )
    }

    // Render last page if necessary
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        items.push(<Pagination.Ellipsis key='ellipsis-end' />)
      }
      items.push(
        <li
          key={totalPages}
          className={clsx('page-item', {
            active: currentPage === totalPages,
            disabled: isLoading,
          })}
        >
          <a
            className={clsx('page-link', {
              // 'page-text': link.label === 'Previous' || link.label === 'Next',
              // 'me-5': link.label === 'Previous',
            })}
            onClick={() => handlePageChange(totalPages)}
            style={{cursor: 'pointer'}}
          >
            {totalPages}
          </a>
        </li>
      )
    }

    items.push(
      <li
        key='next'
        className={clsx('page-item', {
          disabled: isLoading || currentPage === totalPages,
        })}
      >
        <a
          className={clsx('page-link', {
            // 'page-text': link.label === 'Previous' || link.label === 'Next',
            // 'me-5': link.label === 'Previous',
          })}
          onClick={() => handlePageChange(currentPage + 1)}
          style={{cursor: 'pointer'}}
        >
          Next
        </a>
      </li>
    )

    return items
  }

  return (
    <div className='row pt-4'>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-start'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            <li
              className={clsx('page-item', {
                disabled: isLoading || currentPage === 1,
              })}
            >
              <a onClick={() => handlePageChange(1)} className='page-link'>
                First
              </a>
            </li>
            {renderPaginationItems()}
            <li
              className={clsx('page-item', {
                disabled: isLoading || currentPage === totalPages,
              })}
            >
              <a
                onClick={() => handlePageChange(totalPages)}
                style={{cursor: 'pointer'}}
                className='page-link'
              >
                Last
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default TablePagination
