import {Dispatch, FormEvent, SetStateAction} from 'react'
import {BASE_URL} from '../../config'

export const GET_RECHARGE_FROM_URL = `${BASE_URL}/api/protect/rechargeFrom`

export interface IRechargeData {
  id: number
  userId?: number | undefined
  name: string | undefined
  actionuser?: number
  rechargedate: string
  balance: number
  note: string
  rechargeby?: number
  paymentmethod: string
  type: string
}

export interface IRechargeDataArray extends Array<IRechargeData> {}

export interface RechargeProps {
  apiUrl: string
  title: string
}

export interface SearchFormFIlterProps {
  startTime: string
  endTime: string
  itemsPerPage: number
  setStartTime: Dispatch<SetStateAction<string>>
  setEndTime: Dispatch<SetStateAction<string>>
  handleSearchSubmit: (event: FormEvent) => void
  setCurrentPage: Dispatch<SetStateAction<number>>
  setItemsPerPage: Dispatch<SetStateAction<number>>
}

export const GET_INVOICE_LIST_URL = `${BASE_URL}/api/protect/invoice/invoiceList`

export interface IInvoiceList {
  id: number
  invoice: string
  clientName: string
  invoicedate: string
}

export interface IInvoiceListArray extends Array<IInvoiceList> {}
