import React, {ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState} from 'react'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {Table, Form, Button, Pagination, Spinner, Alert, Row, Col} from 'react-bootstrap'
import axios from 'axios'
import {useAuth} from '../../auth'
import EllipsisPagination from '../ElipsisPagination'
import {TemplateText} from '../QuickSMS'
import {GET_SMS_TEMPLATE_URL, ISMSTemplate, ISMSTemplateArray} from '../core/_model'
import {userType} from '../../../utils/helperFunction/_helper'
import SMSTemplateWrapper from '../SMSTemplate/SMSTemplateWrapper'

interface ISmsTemplateProps {
  setTemplateText: React.Dispatch<React.SetStateAction<TemplateText>>
  isSmartTemplate: number
}

const SmsTemplateModal: FC<ISmsTemplateProps> = ({setTemplateText, isSmartTemplate}) => {
  const {auth, currentUser} = useAuth()
  const [templates, setTemplates] = useState<ISMSTemplateArray>([])
  const [filteredUser, setFilteredUser] = useState<ISMSTemplateArray>([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState({isError: false, message: ''})
  const [searchTerm, setSearchTerm] = useState<string>('')

  const [itemsPerPage, setItemsPerPage] = useState(5)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalElement, setTotalElement] = useState(0)
  const [isLastPage, setIsLastPage] = useState(false)

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)

  const setParams = {
    pageNumber: currentPage - 1,
    pageSize: itemsPerPage,
  }

  const getSmsTemplates = async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(`${GET_SMS_TEMPLATE_URL}/${currentUser?.user_profile}`, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
        params: setParams,
      })
      const data = await response.data
      const result = await data.data
      const totalResult = await data.totalElement
      const lastPage = await data.lastpage
      // console.log(data)
      setIsLastPage(lastPage)
      setTotalElement(totalResult)
      const filterResult = result.filter(
        (item: ISMSTemplate) => item.isSmartTemplate === isSmartTemplate
      )
      setTemplates(filterResult ? filterResult : [])
      setError({isError: false, message: ''})
      setIsLoading(false)
    } catch (err) {
      const errorMessage = (err as Error)?.message || 'An error occurred'
      setIsLoading(false)
      setError({isError: true, message: errorMessage})
    }
  }

  const handleUseClick = (template: ISMSTemplate) => {
    setTemplateText({
      isUse: true,
      smsText: template.smsText,
      isSmartTemplate: template.isSmartTemplate,
    })
  }

  useEffect(() => {
    getSmsTemplates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  useEffect(() => {
    const filteredData = templates.filter(
      (template) =>
        (template.templateName && template.templateName.toLowerCase().includes(searchTerm)) ||
        (template.smsText && template.smsText.toLowerCase().includes(searchTerm))
    )
    // console.log(filteredData)
    setFilteredUser(filteredData)
  }, [templates, searchTerm])

  let showPageLastEtries = !isLastPage ? currentPage * itemsPerPage : totalElement
  let showPageFirstEntries = !isLastPage
    ? showPageLastEtries + 1 - itemsPerPage
    : (currentPage - 1) * itemsPerPage + 1

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
    console.log(e.target.value)
  }

  if (isLoading) {
    return <></>
  }

  if (error.isError) {
    return <></>
  }

  return (
    <div className='modal fade' tabIndex={-3} id='kt_modal_3'>
      <div className='modal-dialog modal-xl'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>SMS Template</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            {!templates ? (
              <Alert variant='primary'>
                <Alert.Heading>No Data Available</Alert.Heading>
              </Alert>
            ) : (
              <>
                <div className='d-flex justify-content-between align-items-end w-100'>
                  <div className='d-flex align-items-center position-relative my-1'>
                    <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                    <input
                      type='text'
                      data-kt-user-table-filter='Search Group'
                      className='form-control form-control-solid w-250px ps-14'
                      placeholder='Search by name'
                      value={searchTerm}
                      onChange={handleSearch}
                    />
                  </div>
                  <div>
                    <label className='form-label fs-6 fw-bold'>Show Entries:</label>
                    <select
                      className='form-select form-select-solid fw-bolder'
                      data-kt-select2='true'
                      data-placeholder='Select entries'
                      data-hide-search='true'
                      name='entries'
                      id='entries'
                      value={itemsPerPage}
                      onChange={(e) => {
                        setCurrentPage(1)
                        setItemsPerPage(parseInt(e.target.value))
                      }}
                    >
                      <option value='10'>10</option>
                      <option value='50'>50</option>
                      <option value='100'>100</option>
                      <option value='250'>250</option>
                    </select>
                  </div>
                </div>

                <div className='mh-325px my-5 px-8'>
                  {filteredUser && filteredUser.length > 0 ? (
                    <div className='table-responsive'>
                      <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                        <thead>
                          <tr className='fs-6 fw-bold text-muted'>
                            <th className='min-w-140px'>Template Name</th>
                            <th className='min-w-140px'>SMS Text</th>
                            <th className='min-w-140px'>User</th>
                            <th className='min-w-140px'>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {filteredUser.map((item: ISMSTemplate, index: number) => {
                            return (
                              <tr key={item.id}>
                                <td>
                                  <span className='text-gray-900 fw-bold text-hover-primary fs-6'>
                                    {item.templateName}
                                  </span>
                                </td>
                                <td>
                                  <span className='text-gray-900 fw-bold text-hover-primary fs-6'>
                                    {item.smsText}
                                  </span>
                                </td>
                                <td>
                                  <span className='text-gray-900 fw-bold text-hover-primary fs-6'>
                                    {userType(currentUser?.user_profile)}
                                  </span>
                                </td>
                                <td>
                                  <button
                                    className='btn btn-secondary btn-sm'
                                    type='button'
                                    onClick={() => handleUseClick(item)}
                                    data-bs-dismiss='modal'
                                    aria-label='Close'
                                  >
                                    Use
                                  </button>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                      <p className='fs-6 text-muted'>
                        Showing {showPageFirstEntries} to {showPageLastEtries} of {totalElement}
                        entries
                      </p>
                      <EllipsisPagination
                        totalElement={totalElement}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  ) : (
                    <p className='fs-6 text-gray-600 text-center'>Processing..</p>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SmsTemplateModal
