import React, {FC} from 'react'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {usePaymentProvider} from '../PaymentProvider'

const PaymentDeleteModal = () => {
  const {deletePaymentApi} = usePaymentProvider()

  return (
    <div className='modal fade' tabIndex={-2} id='kt_modal_2'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'> Create New Spam Filter</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body text-center'>
            <p className='fs-4 text-danger'>Are you sure?</p>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-light'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              Cancle
            </button>
            <button
              type='button'
              className='btn btn-primary'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={deletePaymentApi}
            >
              Yes Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentDeleteModal
