/* eslint-disable jsx-a11y/anchor-is-valid */
import {ChangeEvent, PropsWithChildren, useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {useAuth} from '../auth'
import toast from 'react-hot-toast'
import axios from 'axios'
import {GET_SUMMARY_LOG_URL, ISummaryLogArray, ISummarySmsData} from './core/_model'
import TableContent from '../../utils/components/Table/TableContent'
import TablePagination from '../../utils/components/Table/TablePagination'
import {CustomHeader} from '../../utils/components/Table/CustomHeader'
import {Column, HeaderProps} from 'react-table'
import {KTIcon} from '../../../_metronic/helpers'
import SelectionHeader from '../../utils/components/Table/SelectionHeader'
import {ListViewContext, ListViewProvider} from '../../utils/contextProvider/ListViewProvider'
import {SelectionCell} from '../../utils/components/Table/SelectionCell'
import SummeryLogsHeader from './SummeryLogsHeader'

const SummaryLogs = () => {
  const {auth, currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [summaryLogs, setSummaryLogs] = useState<ISummaryLogArray>([])
  const [filteredSummaryLogs, setFilteredSummaryLogs] = useState<ISummaryLogArray>([])
  const [click, setClick] = useState(0)
  const [searchTerm, setSearchTerm] = useState<string>('')

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalElement, setTotalElement] = useState(0)
  const [isLastPage, setIsLastPage] = useState(false)

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)

  let params = {
    pageNumber: currentPage - 1,
    pageSize: itemsPerPage,
  }

  const getSummaryLogs = async () => {
    try {
      toast.loading('Fetching Data..')
      setIsLoading(true)
      const response = await axios.get(GET_SUMMARY_LOG_URL, {
        headers: {Authorization: `Bearer ${auth?.accessToken}`},
        params: params,
      })
      const result = await response.data
      const totalResult = await result.totalElement
      const lastPage = await result.lastpage
      const data = await result.data
      console.log(data)
      setSummaryLogs(data ? data : [])
      setIsLastPage(lastPage)
      setTotalElement(totalResult)
      toast.dismiss()
      toast.success('Fetched Summary Logs Data')
      setIsLoading(false)
    } catch (error: any) {
      toast.dismiss()
      toast.error(`Error: ${error.message}`)
      console.log(error)
      setIsLoading(false)
    }
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  let showPageLastEtries = !isLastPage ? currentPage * itemsPerPage : totalElement
  let showPageFirstEntries = !isLastPage
    ? showPageLastEtries + 1 - itemsPerPage
    : (currentPage - 1) * itemsPerPage + 1

  let serial: number[] = []

  for (let i = showPageFirstEntries; i <= showPageLastEtries; i++) {
    serial.push(i)
  }

  useEffect(() => {
    document.title = 'Summery Logs'

    getSummaryLogs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage])

  useEffect(() => {
    const filteredData = summaryLogs?.filter(
      (list) =>
        list.name?.toLowerCase().includes(searchTerm) ||
        list.sender?.toLowerCase().includes(searchTerm)
    )
    setFilteredSummaryLogs(filteredData)
  }, [summaryLogs, searchTerm])

  const SummeryLogsColumn: ReadonlyArray<Column<ISummarySmsData>> = [
    {
      Header: (props) => <SelectionHeader tableProps={props as any} context={ListViewContext} />,
      id: 'selection',
      Cell: ({...props}) => (
        <SelectionCell id={props.data[props.row.index].id} context={ListViewContext} />
      ),
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Channel' className='min-w-100px' />
      ),
      id: 'channel',
      accessor: 'task',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Sender' className='min-w-100px' />
      ),
      id: 'sender',
      accessor: 'sender',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Name' className='min-w-100px' />
      ),
      id: 'name',
      accessor: 'name',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Send Status' className='min-w-100px' />
      ),
      id: 'sendStatus',
      accessor: 'send_status',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='SMS Count' className='min-w-100px' />
      ),
      id: 'smsCount',
      accessor: 'smsCount',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='Username' className='min-w-100px' />
      ),
      id: 'username',
      accessor: 'name',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title='SMS Hit' className='min-w-100px' />
      ),
      id: 'smsHit',
      accessor: 'smsHit',
    },
  ]

  return (
    <ListViewProvider isLoading={isLoading} data={filteredSummaryLogs}>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0 d-flex justify-content-between'>
          <div className='card-title'>
            <h3 className='er mb-0'>Live Report Summery Logs</h3>
          </div>
          <div className='card-title'>
            <Link to='/dashboard'>
              <Button variant='primary' size='sm'>
                Dashboard
              </Button>
            </Link>
          </div>
        </div>
        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='Search Group'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <div className='card-title m-0'>
            <SummeryLogsHeader
              isLoading={isLoading}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
        <TableContent
          isLoading={isLoading}
          tableData={filteredSummaryLogs}
          tableColumn={SummeryLogsColumn}
        >
          <TablePagination
            totalElement={totalElement}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            isLoading={isLoading}
          />
        </TableContent>
      </div>
    </ListViewProvider>
  )
}

export default SummaryLogs
